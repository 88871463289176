import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { downloadFileAPI } from '../../api/file';
import { API_URL } from '../../constant';
import {
  setIsOpenDrawer,
  setResourceListMode,
  setSelectedRowData,
  setShowFormData
} from '../../redux/actions/formAction';
import { Mode, Role } from '../../utils/types/role';
import AddResources from '../AddUser/AddResources';
import { OrderListDiv } from '../common/styles';
import ResourcesComponent from '../ResourcesComponent';

const ResourceFiles: React.FC<any> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    dispatch,
    itemList,
    addData,
    buttonLabel,
    viewMode,
    selectedRowData,
    view,
    customerDataList
  } = props;
  const { keys, rows } = selectedRowData;

  const success = () => {
    message.success('file downloaded successfully');
  };

  useEffect(() => {
    if (itemList && (itemList.length || itemList.length === 0)) {
      setItems([...itemList]);
    } else {
      setItems([]);
    }
  }, [itemList]);

  const AddItem = (e) => {
    e.preventDefault();
    setItems(keys);
    addData(keys);
    hideModal();
  };

  const handleDelete = (itemId) => {
    const data: any = items.filter((todo) => todo !== itemId);
    setItems(data);
    addData(data);
  };

  const showModal = () => {
    const filteredRows = rows.filter((x) => itemList.includes(x._id));
    dispatch(setSelectedRowData({ keys: itemList, rows: filteredRows }));
    dispatch(setResourceListMode(Mode.Create));
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    dispatch(setResourceListMode(''));
  };

  const onCancelModal = () => {
    hideModal();
  };

  const openResorceDrawar = (data) => {
    const createFormData = {
      id: data._id,
      userType: Role.Business,
      formMode: Mode.View,
      formLabel: 'Resources'
    };

    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };
  const downloadFile = (fileUrl: string) => {
    if (fileUrl) {
      const data = {
        url: fileUrl
      };
      setLoading(true);
      downloadFileAPI(data).then((res) => {
        const url = `${API_URL}static/${res.fileName}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(this.response);
          const tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = res.fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
          setLoading(false);
          success();
        };
        xhr.send();
      });
    }
  };
  return (
    <>
      <Spin spinning={isLoading} />
      <Listing
        items={items}
        openDrawar={openResorceDrawar}
        isLoading={isLoading}
        customerDataList={customerDataList}
        downloadFile={downloadFile}
        selectedRow={rows}
        delete={handleDelete}
        viewMode={viewMode}
        addData={addData}
      />
      {!viewMode ? (
        <>
          {view != 'View' && (
            <Button type="dashed" onClick={showModal} className="add-more-content">
              <PlusOutlined /> {buttonLabel || 'Add more Target skills'}
            </Button>
          )}
          <Modal
            title="Resources"
            visible={isOpen}
            onOk={AddItem}
            className="wide-modal"
            onCancel={onCancelModal}
          >
            <Form.Item
              name="notes"
              rules={[{ required: false, message: 'Please input your app details!' }]}
            >
              <ResourcesComponent addskillresource onlyDisplayActiveResources />
            </Form.Item>
          </Modal>
        </>
      ) : null}
    </>
  );
};

const Listing: React.FC<any> = (props) => {
  const { selectedRow, items, openDrawar, downloadFile, customerDataList, isLoading } = props;
  const modifyItem = selectedRow.filter((word) => items.includes(word._id)) || [];
  return (
    <Row>
      {modifyItem.map((data: any, index: number) => (
        <>
          <Col xs={!props.viewMode ? 20 : 24} sm={!props.viewMode ? 22 : 24}>
            <Form.Item style={{ marginBottom: 12, pointerEvents: 'auto' }}>
              {customerDataList.type != 'Customer' && (
                <OrderListDiv onClick={() => openDrawar(data)}>
                  {' '}
                  <span className="disc-icon" />
                  {data.name}
                </OrderListDiv>
              )}
              {customerDataList.type === 'Customer' && (
                <OrderListDiv onClick={() => downloadFile(data.file)}>
                  {' '}
                  <span className="disc-icon" />
                  {data.name}
                </OrderListDiv>
              )}
            </Form.Item>
          </Col>
          {!props.viewMode ? (
            <Col xs={4} sm={2}>
              {modifyItem.length > 0 ? (
                <MinusCircleOutlined
                  style={{ padding: 10 }}
                  onClick={(e) => props.delete(data._id)}
                />
              ) : null}
            </Col>
          ) : null}
        </>
      ))}
      <AddResources />
    </Row>
  );
};

function mapStateToProps(state: any) {
  return {
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    customerDataList: state.notificationReducer.customerDataList
  };
}

export default connect(mapStateToProps)(ResourceFiles);
