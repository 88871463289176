import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchAuthorApi } from '../../api/author';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import {
  setIsOpenDrawer,
  setSelectedRowData,
  setShowFormData
} from '../../redux/actions/formAction';
import { setAuthorList } from '../../redux/actions/userMemberAction';
import { Mode, Role } from '../../utils/types/role';
import { ColDiv, EditViewIcon } from '../common/styles';

const AuthorTableComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    authorData,
    resourceListMode,
    PerPageRecords,
    selectedRowData,
    currentLoginUser
  } = props;

  const [current, setCurrent] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isloading, setisloading] = useState<boolean>(false);
  const fetchAuthorList = useCallback(
    (pageSize: number = 0, skip: number = 0, searchTerm: string) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        search: searchTerm
      };
      dispatch(setCustomerLoading(false));
      return searchAuthorApi(options);
    },
    [dispatch]
  );

  useEffect(() => {
    setisloading(true);
    fetchAuthorList(PerPageRecords, 0, props.searchTerm)
      .then((res) => {
        const { data, count } = res;
        setisloading(false);
        dispatch(setAuthorList(data));
        setCurrent(1);
        setTotalCount(count);
      })
      .catch((err) => {
        setisloading(false);
      });
  }, [dispatch, fetchAuthorList]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Author'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.firstName && b.firstName && a.firstName.localeCompare(b.firstName),
      render: (text, record) => (
        <ColDiv>
          {record?.firstName} {record?.middleName} {record?.lastName}
        </ColDiv>
      ),
      width: 250
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email && b.email && a.email.localeCompare(b.email),
      render: (text, record) => <ColDiv>{record?.email}</ColDiv>,
      width: 250
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <ColDiv>{record?.title}</ColDiv>,
      width: 250
    },
    {
      title: 'Qualification',
      dataIndex: 'qualification',
      key: 'qualification',
      render: (text, record) => <ColDiv>{record?.qualifications}</ColDiv>,
      width: 250
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  const onSelectChange = (selectedkeys: any, selectedRows: any) => {
    const { keys, rows } = selectedRowData;
    if (keys.indexOf(selectedkeys) === -1) {
      keys.push(selectedkeys);
      rows.push(selectedRows);
    } else {
      keys.splice(keys.indexOf(selectedkeys), 1);
      rows.splice(
        rows.findIndex((a) => a._id === selectedRows._id),
        1
      );
    }
    dispatch(setSelectedRowData({ keys, rows }));
  };

  return (
    <Spin spinning={isloading}>
      <Table
        className={props.className + (resourceListMode === '' ? ' hide' : '')}
        columns={columns}
        dataSource={authorData}
        pagination={false}
      />
    </Spin>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(AuthorTableComponent);
