import { API_URL } from '../constant';

export const getGoogleUrl = (from: string) => {
  const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  const redirectUri = `${API_URL}googleCalendarOauth`;
  const clientId = process.env.VITE_GOOGLE_OAUTH_CLIENT_ID;
  if (!redirectUri) {
    throw new Error('Google OAuth Redirect URI not provided');
  }
  if (!clientId) {
    throw new Error('Google OAuth Client ID not provided');
  }
  const options = {
    redirect_uri: redirectUri,
    client_id: clientId,
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    scope: [
      'https://www.googleapis.com/auth/calendar',
    ].join(' '),
    state: from,
  };

  const qs = new URLSearchParams(options);
  console.log(options);

  return `${rootUrl}?${qs.toString()}`;
};
