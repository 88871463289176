import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getBroadcastMessage = () => apiGet(`${API_URL}broadcastMessage`);

export const postBroadcastMessage = (data) => apiPost(`${API_URL}broadcastMessage`, data);

export const patchBroadcastMessage = (id, data) =>
  apiPatch(`${API_URL}broadcastMessage/${id}`, data);

export const deleteBroadcastMessage = (id) => apiDelete(`${API_URL}broadcastMessage/${id}`);

export const publishBroadcastMessage = (id) => apiPost(`${API_URL}broadcastMessage/${id}`);

export const searchBroadcastMessageApi = (options) => apiGet(`${API_URL}broadcastMessage`, options);
