import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAssetEventsAPI } from '../../../api/asset';
import { userMemberDataType } from '../../../constant';
import { setIsOpenDrawer, setShowFormData } from '../../../redux/actions/formAction';
import { RootState } from '../../../redux/store';
import PRIVILEGES from '../../../utils/privilege';
import { Mode } from '../../../utils/types/role';
import { AddButton, UserContainer } from '../../common/styles';
import AssetEventsModal from './AssetEventsModal';
import AssetEventsTable from './AssetEventsTable';

export interface AssetEvent {
  _id: string;
  name: string;
  status: 'Draft' | 'Published' | 'Hidden';
}

const AssetEventsComponent = () => {
  const dispatch = useDispatch();

  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const [assetEvents, setAssetEvents] = useState<AssetEvent[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0
  });
  const [loading, setLoading] = useState(false);

  const canCreate = PRIVILEGES.AssetEvents.Create?.includes(currentLoginUser.type);

  const fetchAssetEvents = async () => {
    try {
      const res = await getAssetEventsAPI(pagination);
      setAssetEvents(res.data);

      const newPagination = { ...pagination, total: res.count };
      setPagination(newPagination);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      await fetchAssetEvents();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    fetchAssetEvents();
  }, [pagination.page]);

  const onPageChange = (page: number, pageSize?: number) => {
    const newPagination = { ...pagination, page, pageSize: pageSize || pagination.pageSize };
    setPagination(newPagination);
  };

  const openModal = (id: string = '', mode: Mode) => {
    const formData = {
      id,
      formMode: mode,
      formLabel: 'Asset Event'
    };
    dispatch(setShowFormData(formData));
    dispatch(setIsOpenDrawer(true));
  };

  return (
    <Spin spinning={loading}>
      <UserContainer className="items-center">
        {canCreate && (
          <AddButton type="primary" onClick={() => openModal(undefined, Mode.Create)}>
            <PlusOutlined />
          </AddButton>
        )}
        <Link
          to="/dashboard/avatar"
          className="rounded border-w-2 border border-solid mx-4 p-1 px-4"
        >
          Back
        </Link>
        <AssetEventsModal onSubmit={fetchAssetEvents} />
      </UserContainer>

      <div className="site-layout-background">
        <AssetEventsTable
          assetEvents={assetEvents}
          className="resources-table"
          onOpenModal={openModal}
        />
        <Pagination
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onPageChange}
          className="my-pagination"
        />
      </div>
    </Spin>
  );
};

export default AssetEventsComponent;
