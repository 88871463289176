import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Checkbox, Space, Table, Tag } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userMemberDataType } from '../../../constant';
import {
  setIsOpenDrawer,
  setSelectedRowData,
  setShowFormData
} from '../../../redux/actions/formAction';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { RootState } from '../../../redux/store';
import { getResizedImageName } from '../../../utils/file';
import useVideoPlayerModal from '../../../utils/hooks/useVideoPlayerModal';
import PRIVILEGES from '../../../utils/privilege';
import { Mode, Role } from '../../../utils/types/role';
import { ColDiv, EditViewIcon } from '../../common/styles';
import { Short } from '../ShortsComponent';

const colors = ['tag1-style', 'tag2-style', 'tag3-style', 'tag4-style', 'tag5-style'];

interface ShortsTableComponentProps {
  className?: string;
  shorts: Short[];
}

const ShortsTableComponent = ({ className, shorts, ...tableProps }: ShortsTableComponentProps) => {
  const dispatch = useDispatch();
  const { selectedRowData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const [formUrl, setformUrl] = useState('');

  const { component: videoPlayerComponent, open: openVideoPlayer } = useVideoPlayerModal(formUrl);

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Video'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const handleCancel = () => {
    setformUrl('');
    document.getElementById('hidePointerInput')?.setAttribute('src', '');
  };

  const handleThumbnailClick = (video: any, title: string) => {
    setformUrl(video);
    document.getElementById('hidePointerInput')?.setAttribute('src', video);
    openVideoPlayer();
  };
  const onSelectChange = (selectedkeys: any, selectedRows: any) => {
    const { keys, rows } = selectedRowData;
    if (keys.indexOf(selectedkeys) === -1) {
      keys.push(selectedkeys);
      rows.push(selectedRows);
    } else {
      keys.splice(keys.indexOf(selectedkeys), 1);
      rows.splice(
        rows.findIndex((a) => a._id === selectedRows._id),
        1
      );
    }
    dispatch(setSelectedRowData({ keys, rows }));
  };

  const canEdit = PRIVILEGES.Shorts.Edit?.includes(currentLoginUser.type as Role);

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Checkbox
          onChange={() => onSelectChange(record._id, record)}
          checked={selectedRowData.keys.indexOf(record._id) !== -1}
        />
      )
    },
    {
      title: 'Video',
      dataIndex: '',
      key: 'video',
      width: 200,
      render: (text, record) => (
        <ColDiv>
          {record.thumbnail ? (
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => handleThumbnailClick(record.videoLink, record.title)}
              src={getResizedImageName(record.thumbnail, '400x400')}
              alt="thumbnail"
              width="180px"
              height="120px"
            />
          ) : (
            ''
          )}
        </ColDiv>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 300,
      sorter: (a, b) => a.title && b.title && a.title.localeCompare(b.title),
      render: (text, record) => <ColDiv>{record.title}</ColDiv>
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: 300,
      render: (text, record) => (
        <ColDiv>
          {record.categories?.map((category, index) => (
            <Tag className={`${colors[index % 5]} video-tag-style`}>
              {category.name} - {category.level?.name}
            </Tag>
          ))}
        </ColDiv>
      )
    },
    {
      title: 'Related Shorts',
      dataIndex: 'relatedShorts',
      key: 'relatedShorts',
      width: 300,
      render: (text, record) => (
        <ColDiv>
          {record.relatedShorts?.map((one, index) => (
            <Tag className={`${colors[index % 5]} video-tag-style`}>{one.title}</Tag>
          ))}
        </ColDiv>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 50,
      render: (text, record) => <ColDiv>{record.status}</ColDiv>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <>
      <Table
        className={className}
        columns={columns}
        dataSource={shorts}
        pagination={false}
        scroll={{ x: 1000 }}
        {...tableProps}
      />

      {videoPlayerComponent}
    </>
  );
};

export default ShortsTableComponent;
