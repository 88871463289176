import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { SelectTypeContainer, SelectTypeContainerInline } from './styles';
import { setCustomerInfo, setCustomerLoading } from '../../redux/actions/customerAction';
import { dashBoardFilterByDateType } from '../../constant';
import { getSelectedMemberDashboardApi } from '../../api/customer';

const { Option } = Select;

const SelectDataByType: React.FC<any> = (props) => {
  const {
    dispatch, customerSelectedMember, customerInfo, inline,
  } = props;

  const handleSelect = (value: any) => {
    dispatch(setCustomerLoading(true));

    const selectedTypeData = dashBoardFilterByDateType && dashBoardFilterByDateType.filter((data: any) => data.name === value);
    const selectedDate = selectedTypeData[0];
    if (customerSelectedMember.length) {
      const data = {
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate,
      };
      dispatch(setCustomerInfo([]));

      if (customerSelectedMember[0]._id) {
        getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
          const { data } = res;
          dispatch(setCustomerLoading(false));
          dispatch(setCustomerInfo(data));
        });
      }
    }
  };

  return inline ? (
    <SelectTypeContainerInline>
      <Select defaultValue={dashBoardFilterByDateType.length && dashBoardFilterByDateType[dashBoardFilterByDateType.length - 1].name} onChange={handleSelect}>
        {
          dashBoardFilterByDateType.map((optionItem:any) => <Option value={optionItem.name}>{optionItem.name}</Option>)
        }
      </Select>
    </SelectTypeContainerInline>
  ) : (
    <SelectTypeContainer>
      <Select defaultValue={dashBoardFilterByDateType.length && dashBoardFilterByDateType[dashBoardFilterByDateType.length - 1].name} onChange={handleSelect}>
        {
          dashBoardFilterByDateType.map((optionItem:any) => <Option value={optionItem.name}>{optionItem.name}</Option>)
        }
      </Select>
    </SelectTypeContainer>
  );
};

function mapStateToProps(state: any) {
  return {
    customerInfo: state.customerRuducer.customerInfo,
    selectCustomer: state.customerRuducer.selectCustomer,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
  };
}

export default connect(mapStateToProps)(SelectDataByType);
