import React from 'react';
import { Redirect } from 'react-router-dom';
import SiteUnderMaintainence from '../../components/SiteUnderMaintainence';

const Demo: React.FC = () => (
  <>
    <Redirect to="login" />
    {/* <SiteUnderMaintainence /> */}
  </>
);

export default Demo;
