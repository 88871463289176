import { API_URL } from '../constant';
import { apiGet, apiPatch, apiPost } from '../utils/api';

export const createMemberApi = (member) => apiPost(`${API_URL}member`, member);

export const listMemberApi = (profileId) => apiGet(`${API_URL}member/${profileId}`);

export const updateMemberApi = (profileId, data) => apiPatch(`${API_URL}member/${profileId}`, data);

export const listMemberUserApi = (profileId) => apiGet(`${API_URL}member/all/${profileId}`);
export const membersPaginationApi = (options) => apiGet(`${API_URL}member`, options);

export const searchMemberApi = (searchText, filter = 'Active') =>
  apiGet(`${API_URL}member?search=${searchText}&filter=${filter}`);

export const createMemberFavUrlApi = (data) => apiPost(`${API_URL}favourite`, data);

export const getMemberFavUrls = (memberId) => apiGet(`${API_URL}favourite/${memberId}`);

export const updateMemberFavUrlApi = (favouriteId, data) =>
  apiPatch(`${API_URL}favourite/${favouriteId}`, data);
