import { Role } from './types/role';

interface IRolePrivileges {
  View?: Role[];
  Create?: Role[];
  Edit?: Role[];
  Delete?: Role[];
}
const PRIVILEGES: {
  Resources: IRolePrivileges;
  ResourceObjectives: IRolePrivileges;
  Videos: IRolePrivileges;
  VideoCategories: IRolePrivileges;
  Course: IRolePrivileges;
  Workshops: IRolePrivileges;
  HomePrompts: IRolePrivileges;
  HomeCategories: IRolePrivileges;
  Shorts: IRolePrivileges;
  ShortCategories: IRolePrivileges;
  ShortLevels: IRolePrivileges;
  Assets: IRolePrivileges;
  AssetEvents: IRolePrivileges;
} = {
  Resources: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin]
  },
  ResourceObjectives: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  Videos: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin]
  },
  VideoCategories: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  Course: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin]
  },
  Workshops: {
    View: [Role.Admin, Role.Business],
    Create: [],
    Edit: [Role.Admin]
  },
  HomePrompts: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  HomeCategories: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  Shorts: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  ShortCategories: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  ShortLevels: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  Assets: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  },
  AssetEvents: {
    View: [Role.Admin, Role.Business],
    Create: [Role.Admin],
    Edit: [Role.Admin],
    Delete: [Role.Admin]
  }
};

export default PRIVILEGES;
