import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { RoleTypeProps } from '../constant';

const { Option } = Select;

interface BusinessFormProps {
    userType: RoleTypeProps[];
    handleChange: (value: string) => void;
    disabled: boolean;
    isMobile?:any
}

const BusinessForm: React.FC<BusinessFormProps> = (props) => {
  const { isMobile } = props;
  return (
    <Form.Item
      label="Business User Type"
      name="businessUserType"
      className="no-boder"
      rules={[{ required: true, message: 'Please select your Business User Type!' }]}
    >
      <Select getPopupContainer={(triggerNode) => triggerNode.parentElement} mode="multiple" onChange={props.handleChange} disabled={props.disabled}>
        {props.userType.map((data: RoleTypeProps, index: number) => <Option key={index} value={data.label}>{data.label}</Option>)}
      </Select>
    </Form.Item>
  );
};

function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(BusinessForm);
