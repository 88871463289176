import { Drawer } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import CourseForm from '../FormComponent/CourseForm';

interface AddUserProps {
  showFormData?: any;
  dispatch?: any;
  pagination?: number;
  PerPageRecords?: number;
  changePageToOne?: () => void;
  searchTerm?: string;
  setVisible?: any;
  visible?: boolean;
}

export interface goalTypeListType {
  title: string;
  value: string;
}

const AddCourse: React.FC<AddUserProps> = (props) => {
  const {
    dispatch,
    visible,
    setVisible,
    showFormData,
    pagination,
    PerPageRecords,
    changePageToOne,
    searchTerm
  } = props;

  const closeDrawer = () => {
    setVisible(false);
    dispatch(setIsOpenDrawer(false));
  };

  return (
    <Drawer
      maskClosable={false}
      title={`${`${showFormData.formMode} ${showFormData.formLabel}`} Form`}
      placement="right"
      closable={false}
      visible={visible}
      width="520"
      className="user-drawer"
      onClose={closeDrawer}
    >
      <CourseForm
        close={closeDrawer}
        cancel={() => dispatch(setIsOpenDrawer(false))}
        userRole={showFormData.userType}
        courseID={showFormData?.id}
        editMode={showFormData.edit}
        viewMode={showFormData.formMode}
        visible={visible}
        pagination={pagination}
        PerPageRecords={PerPageRecords}
        changePageToOne={changePageToOne}
        searchTerm={searchTerm}
      />
    </Drawer>
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData
  };
}

export default connect(mapStateToProps)(AddCourse);
