import { MinusOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Pagination, Table, TableColumnType } from 'antd';
import React, { useContext, useState } from 'react';
import { useMemberId } from '../../utils/hooks/useCustomerId';
import { ColDiv } from '../common/styles';
import { MyResourcesContext } from './MyResourcesComponent';
import ResourceStatusTag from './ResourceStatusTag';
import useResourcesSearch from './useResourcesSearch';
import useShareResourcessMutation from './useShareResourcesMutation';

export interface IResourcesSearchResultProps {
  searchTerm?: string;
}

export default function ResourcesSearchResult(props: IResourcesSearchResultProps) {
  const myResourcesContext = useContext<any>(MyResourcesContext);
  const sharedCurPage = myResourcesContext.pagination.current;
  const myResourcesList = myResourcesContext.pagination.allResources;
  const { searchTerm = '' } = props;

  const memberId = useMemberId();
  const { data, pagination, setPagination, isFetching } = useResourcesSearch(searchTerm);
  const { data: resourceData = [], count = 0 } = data || {};
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const {
    onShare,
    onUnshare,
    shareResourceMutation: { isLoading: shareLoading },
    unShareResourceMutation: { isLoading: unshareLoading }
  } = useShareResourcessMutation(memberId, sharedCurPage);

  const allSharedResourcesIds = myResourcesList.map((x) => x._id);
  const resourcesIdsWithTask = myResourcesList.filter((x) => x.hasTasks).map((x) => x._id);

  const onSelectChange = (selectedKeys, selectedRows) => {
    setSelectedIds(selectedKeys);
  };

  const isAllSelectedUnshared =
    selectedIds.length > 0 && selectedIds.every((id) => !allSharedResourcesIds.includes(id));
  const isAllSelectedShared =
    selectedIds.length > 0 && selectedIds.every((id) => allSharedResourcesIds.includes(id));

  const columns: TableColumnType<any>[] = [
    {
      title: 'Resources',
      dataIndex: '',
      key: 'thumbnail',
      width: 150,
      render: (text, record) => (
        <ColDiv>
          {record.thumbnail ? (
            <img
              style={{ cursor: 'pointer' }}
              alt="thumbnail"
              width="90px"
              height="60px"
              src={record.thumbnail}
            />
          ) : (
            ''
          )}
        </ColDiv>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 400
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      render: (value, record) => {
        const shared = allSharedResourcesIds.includes(record._id);
        return <ResourceStatusTag status={shared ? 'Shared' : record.status} />;
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => {
        const shared = allSharedResourcesIds.includes(record._id);
        const hasTasks = resourcesIdsWithTask.includes(record._id);
        return (
          <>
            {!hasTasks && (
              <>
                {!shared ? (
                  <Button
                    icon={<ShareAltOutlined />}
                    type="text"
                    disabled={shareLoading}
                    onClick={() => onShare(record._id)}
                  />
                ) : (
                  <Button
                    icon={<MinusOutlined />}
                    type="text"
                    disabled={unshareLoading}
                    onClick={() => onUnshare(record._id)}
                  />
                )}
              </>
            )}
          </>
        );
      }
    }
  ];
  const paginationConfig = {
    onChange: (page, pageSize = 10) => {
      setPagination({ pageSize, current: page });
    },
    current: pagination.current,
    total: count,
    pageSize: pagination.pageSize
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={resourceData}
        scroll={{ y: 'calc(100vh - 22rem)' }}
        pagination={false}
        rowSelection={{
          onChange: onSelectChange,
          selectedRowKeys: selectedIds,
          getCheckboxProps: (record) => ({
            disabled: resourcesIdsWithTask.includes(record._id),
            className: resourcesIdsWithTask.includes(record._id) ? 'hidden' : ''
          })
        }}
        rowKey="_id"
      />
      <div className="flex justify-between mt-4">
        {isAllSelectedShared ? (
          <Button
            icon={<MinusOutlined />}
            type="default"
            shape="round"
            disabled={unshareLoading}
            onClick={() => onUnshare(selectedIds)}
          >
            Remove
          </Button>
        ) : (
          <Button
            icon={<ShareAltOutlined />}
            type="ghost"
            shape="round"
            disabled={!isAllSelectedUnshared || shareLoading}
            onClick={() => onShare(selectedIds)}
          >
            Add
          </Button>
        )}

        <Pagination {...paginationConfig} />
      </div>
    </>
  );
}
