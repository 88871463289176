import React, { useCallback, useState, useEffect } from 'react';
import { Table, Space, Collapse } from 'antd';
import MileStoneComponent from '../MileStoneComponent';
import { EditViewIcon, EmptyCol, MileStoneDiv } from '../common/styles';
import AddGoalComponent from '../AddUser/AddGoalComponent';
import { Role } from '../../utils/types/role';
import { formattedDateWithDuration } from '../common/utils';
import ExpandPlus from '../../images/icons/Paper_Plus.svg';
import ExpandMinus from '../../images/icons/Paper_Minus.svg';
import ShowPasswordBlack from '../../images/icons/showPasswordBlack.png';
import EditIcon from '../../images/icons/EditIcon.svg';
import MyGoalMobileView from './MyGoalMobileViewAccordian';
import EmptyTableData from '../../images/empty-table-data.svg';
import { paginationConfig } from '../../constant';

const { Panel } = Collapse;

const MyGoalTableComponent: React.FC<any> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const { goalData, loginUserType } = props;
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const handleChange = (pagination, filters, sorter, extra) => {
    // console.log('Various parameters', pagination, filters, sorter, extra);
  };
  const handleScreenSize = () => {
    setScreenSize(window.innerWidth);
  };
  const choosePlanColorSwitch = (complexity) => {
    switch (complexity) {
    case 'Advanced': return 'complex-style';
    case 'Ambitious': return 'highly-ambitious';
    case 'Basic': return 'moderate-color';
    case 'Intermediate': return 'simple-color';
    }
  };

  const StatusColorSwitch = (status_color) => {
    switch (status_color) {
    case 'Achieved': return 'achieved-color';
    case 'In-progress': return 'inProgress-color';
    case 'Planned': return 'planned-color';
    case 'On-hold': return 'on-hold-color';
    case 'Cancelled': return 'cancelled-color';
    case 'Pending': return 'pending-color-for-goals';
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleScreenSize);
  }, []);

  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandMinus} style={{ width: '22px' }} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandPlus} style={{ width: '15px' }} />
      </a>
    );
  };
  const customExpandIconMobileVIew = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandMinus} style={{ width: '22px' }} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandPlus} style={{ width: '15px' }} />
      </a>
    );
  };

  const recordsContainer = (record) =>

  //    if(record?.isPrimary){
  //        return "goal-table-text goal-primary"
  //    }
  //    if(record?.status){
  //    return StatusColorSwitch(record?.status)
  //    }
  //    else if(record?.updatedAt){
  //     return StatusColorSwitch(record?.status)
  //    }

    'goal-table-text';

  const columncontainer = [
    {
      render: (text, record) => <div className={recordsContainer(record)}>{(record.name ? record.name : ('--'))}</div>,
    },
  ];

  const columns = [

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name && b.name) && a.name.localeCompare(b.name),
      render: (text, record) => <div className={recordsContainer(record)}>{(record.name ? record.name : ('--'))}</div>,
    },
    {
      title: 'Complexity',
      dataIndex: 'complexity',
      key: 'complexity',
      sorter: (a, b) => a.complexity && b.complexity && (a.complexity.name).localeCompare(b.complexity.name),
      render: (text, record) => <div className={choosePlanColorSwitch(record?.complexity?.name)}>{(record.complexity ? (record.complexity && record.complexity.name) : ('--'))}</div>,
    },
    {
      title: 'Outcome Type',
      dataIndex: 'typeOfGoal',
      key: 'typeOfGoal',
      sorter: (a, b) => a.typeOfGoal.name && b.typeOfGoal.name && (a.typeOfGoal.name).localeCompare(b.typeOfGoal.name),
      render: (text, record) => (
        <div className={recordsContainer(record)}>
          {(record.typeOfGoal ? (record.typeOfGoal && record.typeOfGoal.name) : ('--'))}
          {' '}
        </div>
      ),
    },
    {
      title: 'Last Activity Date (GMT+11)',
      dataIndex: 'lastActivity',
      key: 'lastActivity',
      sorter: (a, b) => {
        a = a.updatedAt || '';
        b = b.updatedAt || '';
        return a.localeCompare(b);
      },
      render: (text, record) => (
        <div className={recordsContainer(record)}>
          {' '}
          {(record.updatedAt ? <span className="lastActivity">{formattedDateWithDuration(record.updatedAt)}</span> : <EmptyCol>--</EmptyCol>)}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <AddGoalComponent onCloseDrawer={onCloseDrawer} user={Role.Customer} formTypeLabel="Outcome" chooseForm="View" edit={false} icon={<img src={ShowPasswordBlack} className="show-eye-icon" />} id={record._id} />
            {loginUserType === Role.Business ? <AddGoalComponent onCloseDrawer={onCloseDrawer} user={Role.Customer} formTypeLabel="Outcome" chooseForm="Edit" edit icon={<img src={EditIcon} />} id={record._id} /> : null}
          </EditViewIcon>
        </Space>
      ),
    },

  ];

  const fixForOldStatus = (status) => (['Active', 'Pending', 'Complete'].includes(status) ? 'Planned' : status);

  const modifyData = goalData.map((itemData) => ({ ...itemData, key: itemData._id, status: fixForOldStatus(itemData.status) })) || [];

  const achievedGoals = modifyData.filter((x) => x.status === 'Achieved');
  const cancelledGoals = modifyData.filter((x) => x.status === 'Cancelled');
  const inProgressGoals = modifyData.filter((x) => x.status === 'In-progress');
  const plannedGoals = modifyData.filter((x) => x.status === 'Planned');
  const onHoldGoals = modifyData.filter((x) => x.status === 'On-hold');

  return (
    <>
      {screenSize <= 992
        ? (
          <div>
            {achievedGoals?.length > 0
                && (
                  <>
                    <h2 className="achieved-status-style">Achieved Goals</h2>
                    <Table
                      className={`${props.className} mobile-goal-page-table-container`}
                      columns={columncontainer}
                      dataSource={achievedGoals}
                      onChange={handleChange}
                      expandIcon={(props) => customExpandIconMobileVIew(props)}
                      locale={{
                        emptyText: (<div className="empty-data-table-container-show">
                          <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                          <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                        </div>),
                      }}
                      expandedRowRender={(record) => <MileStoneDiv><MyGoalMobileView goalInfo={record} skillData={record?.milestones} goalData={goalData} loginUserType={loginUserType} /></MileStoneDiv>}
                      pagination={false}
                    />
                  </>
                )}

            {inProgressGoals?.length > 0
                && (
                  <>
                    <h2 className="in-progress-status-style">In-Progress Goals</h2>
                    <Table
                      className={`${props.className} mobile-goal-page-table-container`}
                      columns={columncontainer}
                      dataSource={inProgressGoals}
                      onChange={handleChange}
                      expandIcon={(props) => customExpandIconMobileVIew(props)}
                      locale={{
                        emptyText: (<div className="empty-data-table-container-show">
                          <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                          <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                        </div>),
                      }}
                      expandedRowRender={(record) => <MileStoneDiv><MyGoalMobileView goalInfo={record} goalData={goalData} loginUserType={loginUserType} /></MileStoneDiv>}
                      pagination={false}
                    />
                  </>
                )}
            {plannedGoals?.length > 0
                && (
                  <>
                    <h2 className="planned-status-style">Planned Goals</h2>
                    <Table
                      className={`${props.className} mobile-goal-page-table-container`}
                      columns={columncontainer}
                      dataSource={plannedGoals}
                      onChange={handleChange}
                      expandIcon={(props) => customExpandIconMobileVIew(props)}
                      locale={{
                        emptyText: (<div className="empty-data-table-container-show">
                          <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                          <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                        </div>),
                      }}
                      expandedRowRender={(record) => <MileStoneDiv><MyGoalMobileView goalInfo={record} goalData={goalData} loginUserType={loginUserType} /></MileStoneDiv>}
                      pagination={false}
                    />
                  </>
                )}
            {onHoldGoals?.length > 0
                && (
                  <>
                    <h2 className="onHold-status-style">On-hold Goals</h2>
                    <Table
                      className={`${props.className} mobile-goal-page-table-container`}
                      columns={columncontainer}
                      dataSource={onHoldGoals}
                      onChange={handleChange}
                      expandIcon={(props) => customExpandIconMobileVIew(props)}
                      locale={{
                        emptyText: (<div className="empty-data-table-container-show">
                          <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                          <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                        </div>),
                      }}
                      expandedRowRender={(record) => <MileStoneDiv><MyGoalMobileView goalInfo={record} goalData={goalData} loginUserType={loginUserType} /></MileStoneDiv>}
                      pagination={false}
                    />
                  </>
                )}
            {cancelledGoals?.length > 0
                && (
                  <>
                    <h2 className="cancelled-status-style">Cancelled Goals</h2>
                    <Table
                      className={`${props.className} mobile-goal-page-table-container`}
                      columns={columncontainer}
                      dataSource={cancelledGoals}
                      onChange={handleChange}
                      expandIcon={(props) => customExpandIconMobileVIew(props)}
                      locale={{
                        emptyText: (<div className="empty-data-table-container-show">
                          <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                          <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                        </div>),
                      }}
                      expandedRowRender={(record) => <MileStoneDiv><MyGoalMobileView goalInfo={record} goalData={goalData} loginUserType={loginUserType} /></MileStoneDiv>}
                      pagination={false}
                    />
                  </>
                )}
          </div>
        )
        : (
          <div>
            {achievedGoals?.length > 0
                    && (
                      <>
                        <h2 className="achieved-status-style">Achieved Goals</h2>
                        <Table
                          className={props.className}
                          columns={columns}
                          dataSource={achievedGoals}
                          onChange={handleChange}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandedRowRender={(record) => <MileStoneDiv className="myGoalMilestoneOuter"><MileStoneComponent goalInfo={record} /></MileStoneDiv>}
                          locale={{
                            emptyText: (<div className="empty-data-table-container-show">
                              <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                              <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                            </div>),
                          }}
                          pagination={false}
                        />
                      </>
                    )}

            {inProgressGoals?.length > 0
                    && (
                      <>
                        <h2 className="in-progress-status-style">In-Progress Goals</h2>
                        <Table
                          className={props.className}
                          columns={columns}
                          dataSource={inProgressGoals}
                          onChange={handleChange}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandedRowRender={(record) => <MileStoneDiv className="myGoalMilestoneOuter"><MileStoneComponent goalInfo={record} /></MileStoneDiv>}
                          locale={{
                            emptyText: (<div className="empty-data-table-container-show">
                              <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                              <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                            </div>),
                          }}
                          pagination={false}
                        />
                      </>
                    )}
            {plannedGoals?.length > 0
                    && (
                      <>
                        <h2 className="planned-status-style">Planned Goals</h2>
                        <Table
                          className={props.className}
                          columns={columns}
                          dataSource={plannedGoals}
                          onChange={handleChange}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandedRowRender={(record) => <MileStoneDiv className="myGoalMilestoneOuter"><MileStoneComponent goalInfo={record} /></MileStoneDiv>}
                          locale={{
                            emptyText: (<div className="empty-data-table-container-show">
                              <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                              <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                            </div>),
                          }}
                          pagination={false}
                        />
                      </>
                    )}
            {onHoldGoals?.length > 0
                    && (
                      <>
                        <h2 className="onHold-status-style">On-hold Goals</h2>
                        <Table
                          className={props.className}
                          columns={columns}
                          dataSource={onHoldGoals}
                          onChange={handleChange}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandedRowRender={(record) => <MileStoneDiv className="myGoalMilestoneOuter"><MileStoneComponent goalInfo={record} /></MileStoneDiv>}
                          locale={{
                            emptyText: (<div className="empty-data-table-container-show">
                              <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                              <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                            </div>),
                          }}
                          pagination={false}
                        />
                      </>
                    )}
            {cancelledGoals?.length > 0
                    && (
                      <>
                        <h2 className="cancelled-status-style">Cancelled Goals</h2>
                        <Table
                          className={props.className}
                          columns={columns}
                          dataSource={cancelledGoals}
                          onChange={handleChange}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandedRowRender={(record) => <MileStoneDiv className="myGoalMilestoneOuter"><MileStoneComponent goalInfo={record} /></MileStoneDiv>}
                          locale={{
                            emptyText: (<div className="empty-data-table-container-show">
                              <div className="empty-data-table-container-image"><img src={EmptyTableData} width="250" /></div>
                              <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
                            </div>),
                          }}
                          pagination={false}
                        />
                      </>
                    )}
          </div>
        )}
    </>
  );
};

export default MyGoalTableComponent;
