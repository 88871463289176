import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { searchPublishedVideoApi } from '../../api/video';

export default function useVideoSearch(searchTerm: string) {
  const PAGE_SIZE = 10;
  const defaultPagination = {
    current: 1,
    pageSize: PAGE_SIZE
  };
  const [pagination, setPagination] = useState(defaultPagination);

  useEffect(() => {
    setPagination(defaultPagination);
  }, [searchTerm]);

  const { data, isLoading, isFetching } = useQuery(
    ['video-search', searchTerm, pagination.current, pagination.pageSize],
    () => {
      const { current, pageSize } = pagination;
      const options = {
        skip: (current - 1) * pageSize,
        limit: pageSize,
        search: searchTerm
      };
      return searchPublishedVideoApi(options);
    },
    {
      keepPreviousData: true
    }
  );

  return {
    data,
    pagination,
    setPagination,
    isFetching
  };
}
