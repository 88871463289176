import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { userMemberDataType } from '../../../constant';
import { RootState } from '../../../redux/store';
import PRIVILEGES from '../../../utils/privilege';
import { Mode, Role } from '../../../utils/types/role';
import { ColDiv, EditViewIcon } from '../../common/styles';
import { ShortLevel } from './ShortLevelsComponent';

interface ShortLevelsTableProps {
  onOpenModal: (id: string, mode: Mode) => void;
  className?: string;
  shortLevels: ShortLevel[];
}

const ShortLevelsTable = ({ onOpenModal, shortLevels, className }: ShortLevelsTableProps) => {
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const canEdit = PRIVILEGES.ShortLevels.Edit?.includes(currentLoginUser.type as Role);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
      render: (text, record) => <ColDiv>{record.name}</ColDiv>
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      width: 250,
      sorter: (a, b) => a.rating && b.rating && a.rating.localeCompare(b.rating),
      render: (text, record) => <ColDiv>{record.rating}</ColDiv>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <span onClick={() => onOpenModal(record._id, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => onOpenModal(record._id, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <Table
      className={className}
      columns={columns}
      dataSource={shortLevels}
      pagination={false}
      scroll={{ x: 1000 }}
    />
  );
};

export default ShortLevelsTable;
