import { EditOutlined, EyeOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Checkbox, Image, Space, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getResourcesAPI, togglefavResourceAPI } from '../../api/resources';
import altThumbnailImage from '../../images/thumbnailAlt.png';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import {
  setFetchingFavResource,
  setIsOpenDrawer,
  setSelectedRowData,
  setShowFormData
} from '../../redux/actions/formAction';
import { getResizedImageName } from '../../utils/file';
import { Mode, Role } from '../../utils/types/role';
import DeepLink from '../common/DeepLink';
import { ColDiv, EditViewIcon, StarDiv } from '../common/styles';
import { formattedDate } from '../common/utils';

const ResourcesTableComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    resourcesList = [],
    resourceListMode,
    selectedRowData,
    resourcesId,
    onlyDisplayActiveResources,
    onChangeFavResource,
    checkboxChecked,
    currentLoginUser
  } = props;

  const [favResourceKey, setFavResourceKey] = useState<any>([]);

  const fetchResouceList = useCallback(
    (searchTerm: string = '', isActive) => {
      const data = {
        search: searchTerm,
        type: '',
        objective: '',
        owner: '',
        isFavourites: isActive
      };
      dispatch(setCustomerLoading(false));
      return getResourcesAPI(data, '');
    },
    [dispatch]
  );

  const StatusColorSwitch = (status) => {
    switch (status) {
      case 'Pending':
        return 'pending-color';
      case 'Active':
        return 'active-color';
      case 'Reject':
        return 'reject-color';
      case 'Approved':
        return 'approved-color';
    }
  };

  useEffect(() => {
    fetchResouceList('', true).then((res) => {
      const { data } = res;
      const favList = data && data.map((favItem) => favItem._id);
      setFavResourceKey(favList);
      dispatch(setFetchingFavResource(data));
      dispatch(setCustomerLoading(false));
      if (resourcesId) {
        const resourceData = {
          _id: resourcesId
        };
        openDrawar(resourceData, Mode.View);
      }
    });
  }, [dispatch, fetchResouceList]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Resources'
    };

    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onSelectRow = (record) => {
    if (record && record._id) {
      dispatch(setCustomerLoading(true));
      togglefavResourceAPI(record._id).then((res) => {
        const existingId = favResourceKey.filter((fav) => fav === record._id);
        if (existingId.length) {
          const favdata = favResourceKey.filter((fav) => fav !== record._id);
          setFavResourceKey(favdata);
          onChangeFavResource(checkboxChecked);
        } else {
          setFavResourceKey(favResourceKey.concat(record._id));
        }
        dispatch(setCustomerLoading(false));
      });
    }
  };

  const defaultSelected = (id) => {
    const activeList = favResourceKey.filter((fav) => fav === id);
    if (activeList.length && activeList[0] === id) {
      return 'active';
    }
    return '';
  };

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Checkbox
          onChange={() => onSelectChange(record._id, record)}
          checked={selectedRowData.keys.indexOf(record._id) !== -1}
        />
      )
    },
    {
      title: 'Favourites',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <StarDiv>
          {defaultSelected(record._id) === 'active' ? (
            <StarFilled
              onClick={() => onSelectRow(record)}
              className={`icon ${defaultSelected(record._id)}`}
            />
          ) : (
            <StarOutlined
              onClick={() => onSelectRow(record)}
              className={`icon ${defaultSelected(record._id)}`}
            />
          )}
        </StarDiv>
      )
    },
    {
      title: '',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (text, record) => (
        <ColDiv>
          {record?.thumbnail ? (
            <Image
              width="150px"
              height="100px"
              src={getResizedImageName(record.thumbnail, '200x200')}
            />
          ) : (
            <img width="150px" height="100px" src={altThumbnailImage} />
          )}
        </ColDiv>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
      render: (text, record) => <ColDiv>{record.name}</ColDiv>
    },
    {
      title: 'Share Link',
      dataIndex: 'externalDeepLink',
      key: 'externalDeepLink',
      sorter: (a, b) =>
        a.externalDeepLink &&
        b.externalDeepLink &&
        a.externalDeepLink.localeCompare(b.externalDeepLink),
      render: (text, record) => (
        <ColDiv>
          <DeepLink
            type="external"
            deepLink={record.externalDeepLink}
            hidden={record.status !== 'Active'}
          />
        </ColDiv>
      )
    },
    {
      title: 'Resource Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.name && b.type.name && a.type.name.localeCompare(b.type.name),
      render: (text, record) =>
        record.type && record.type.name ? <ColDiv>{record.type.name}</ColDiv> : '--'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) =>
        record.status ? (
          <ColDiv>
            <div className={StatusColorSwitch(record.status)}> {record.status}</div>
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Added At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) =>
        record.createdAt ? <ColDiv> {formattedDate(record.createdAt)} </ColDiv> : '--'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <DeepLink
              type="internal"
              deepLink={record.internalDeepLink}
              hidden={record.status !== 'Active'}
            />
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  const onSelectChange = (selectedkeys: any, selectedRows: any) => {
    const { keys: keysOrigin, rows: rowsOrigin } = selectedRowData;
    const keys = [...keysOrigin]; // * avoid mutation
    const rows = [...rowsOrigin]; // * avoid mutation
    if (keys.indexOf(selectedkeys) === -1) {
      keys.push(selectedkeys);
      rows.push(selectedRows);
    } else {
      keys.splice(keys.indexOf(selectedkeys), 1);
      rows.splice(
        rows.findIndex((a) => a._id === selectedRows._id),
        1
      );
    }
    dispatch(setSelectedRowData({ keys, rows }));
  };

  const modifyData =
    resourcesList.map((itemData) => {
      if (itemData.isDeleted !== true) {
        return { ...itemData, key: itemData._id };
      }
      return [];
    }) || [];

  const modifyColumn =
    resourceListMode === ''
      ? columns.filter((item, index) => index !== 0)
      : columns.filter((elem) => elem.title !== 'Favourites' && elem.title !== 'Action');

  return (
    <Table
      className={props.className + (resourceListMode === '' ? ' hide' : '')}
      columns={modifyColumn}
      dataSource={
        onlyDisplayActiveResources
          ? modifyData.filter((item) => item.status === 'Active')
          : modifyData
      }
      pagination={false}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer,
    resourcesList: state.formReducer.resourcesList,
    favResourcesList: state.formReducer.favResourcesList,
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(ResourcesTableComponent);
