import React from 'react';
import { Progress, Card, Empty } from 'antd';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ChartDiv } from '../common/styles';
import GradientSVG from './GradientSVG';
import ThreeDotsIcon from '../../images/icons/3dotsIcon.svg';
import PurpleEllipse from '../../images/icons/purpleEllipse.svg';
import PinkEllipse from '../../images/icons/pinkEllipse.svg';
import TransparentEllipse from '../../images/icons/transparentEllipse.svg';
import BlueDotsIcon from '../../images/icons/3bluedotsIcon.svg';

interface PropsTypes {
    title?: string;
    percentage?: number;
    textPrefix?: string;
    subTitle?: string;
    style?: any;
    hoverable?: boolean;
    className?: string;
    lowColor?: string;
    highColor?: string;
    progressId?: string;
    colorClass?: string;
    showInfo?: boolean;
    centerText?: string
}

const ProgressChart: React.FC<PropsTypes> = (props: any) => {
  const percentageValue = Math.round(props.percentage);
  return (
    <ChartDiv style={props.style} className={`${props.className} ${props.hoverable ? 'hoverable-card' : ''}`}>
      <Card title={props.title ? props.title : 'Progress Chart'}>
        <div className="sub-title">{props.subTitle ? props.subTitle : ''}</div>
        <div className="sub-icon">
          <img src={ThreeDotsIcon} className="sub-dots-black-icon" />
          <img src={BlueDotsIcon} className="sub-dots-icon" />
        </div>
        <div className="progress-chart" style={{ maxWidth: 200, width: '100%', height: 200 }}>
          <GradientSVG
            startColor={props.lowColor}
            endColor={props.highColor}
            idCSS={props.progressId}
            rotation={90}
          />
          {props.percentage
            ? (
              <div className="progress-container">
                <Progress
                  type="circle"
                  strokeColor={{
                    '40%': `${props.lowColor}`,
                    '60%': `${props.highColor}`,
                  }}
                  strokeWidth={9}
                  className={props.colorClass}
                  percent={percentageValue}
                  status="active"
                />
                <div className="progress-sub-list">{props.centerText || props.title || 'Progress Chart'}</div>
              </div>
            )
            : <Empty description="No Item" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
        {/* <div className="progress-menu">
                    <div className="sub-menu">
                        <img src={PurpleEllipse} />
                        <span>Active</span>
                    </div>
                    <div className="sub-menu-center">
                        <img src={PinkEllipse} />
                        <span>Completed</span>
                    </div>
                    <div className="sub-menu">
                        <img src={TransparentEllipse} />
                        <span>In Que</span>
                    </div>
                </div> */}
      </Card>
    </ChartDiv>
  );
};

export default ProgressChart;
