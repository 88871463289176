import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { getAuthor } from '../../api/author';
import { getTags } from '../../api/user';
import { deleteVideo, patchVideo, postVideo, searchVideoApi, videoDetails } from '../../api/video';
import { CountryCodeProps, StatusProps } from '../../constant';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import { setAuthorList, setVideoList } from '../../redux/actions/userMemberAction';
import useVideoPlayerModal from '../../utils/hooks/useVideoPlayerModal';
import { Mode, Role } from '../../utils/types/role';
import FileUploader from '../common/FileUploader';
import { DeleteTabDiv, DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;
interface VideoFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  resourceID?: string;
  videoID?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  status: StatusProps[];
  countryCode: CountryCodeProps[];
  showFormData: any;
  resourcesList: any;
  customerDataList: any;
  authorList: any;
  state: any;
  videoList: [];
  videoCategoryList: any[];
  searchTerm?: string;
  PerPageRecords?: number;
  pagination?: number;
  objectiveList?: any;
}

const VideoForm: React.FC<VideoFormProps> = (props) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const {
    dispatch,
    state,
    objectiveList,
    pagination,
    PerPageRecords,
    showFormData,
    videoID,
    resourcesList,
    visible,
    videoList,
    videoCategoryList,
    customerDataList,
    authorList,
    searchTerm
  } = props;
  const [activeForm, setActiveForm] = useState<any>({
    // createdByAgency: false,
    thumbnail: '',
    status: 'Draft'
  });
  const [isloading, setisloading] = useState<boolean>(false);
  const [addtags, setaddedTags] = useState<any>([]);
  const [filterResources, setFilterResources] = useState<any>();
  const [fileError, setFileError] = useState<string>('');

  const { component: videoPlayerComponent, open: openVideoPlayer } = useVideoPlayerModal(
    activeForm.video
  );

  const domainPathwaysListing = [
    'School',
    'Foundation Courses / Autism',
    'Social Skills',
    'Receptive Language Domain',
    'Expressive Language Domain',
    'Participation Domain',
    'Perspective-Taking Domain',
    'Social Regulation Domain'
  ];

  const fetchVideoList = React.useCallback((option, searchTerm: any) => {
    const options = {
      skip: option?.current * option?.pageSize - option?.pageSize,
      limit: option?.pageSize,
      search: searchTerm
    };
    return searchVideoApi(options);
  }, []);

  useEffect(() => {
    const activeFormLength = Object.keys(activeForm).length;
    getAuthor().then((res) => {
      const { data } = res;
      dispatch(setAuthorList(data));
    });
    getTags()
      .then((res) => {
        const { data } = res;
        setaddedTags(data);
      })
      .catch(() => {
        setaddedTags([]);
      });

    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          title: '',
          domainPathway: '',
          domainSubCategory: '',
          externalCopyright: true,
          description: '',
          categories: [],
          tags: [],
          video: '',
          author: '',
          relatedResources: [],
          thumbnail: '',
          status: 'Draft',
          archiveReason: '',
          transcript: ''
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
      }
    }
    if (videoID && visible) {
      setisloading(true);
      videoDetails(videoID)
        .then((res) => {
          const videodata = res.data;
          const stateData = {
            title: videodata.title,
            domainPathway: videodata.domainPathway,
            domainSubCategory: videodata?.domainSubCategory?._id,
            externalCopyright: true,
            description: videodata.description,
            transcript: videodata.transcript,
            relatedResources: videodata?.relatedResources.map((res) => res._id),
            categories: videodata.categories?.map((item) => item._id),
            tags: videodata.tags?.map((item) => item.name),
            video: videodata.video,
            author: videodata.author ? videodata.author._id : '',
            thumbnail: videodata.thumbnail,
            status: videodata.status,
            archiveReason: videodata.archiveReason
          };
          setActiveForm(stateData);
          form.setFieldsValue(stateData);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
        });
    }
  }, [form, videoID, showFormData, visible, props.userRole]);

  useEffect(() => {
    setFilterResources(resourcesList.filter((item) => item.status === 'Active'));
  }, []);

  const onReset = () => {
    setActiveForm({});
    form.resetFields();
    props.cancel();
    props.close();
  };
  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleMultiSelectChange = (key: string, value: any) => {
    setActiveForm({ ...activeForm, [key]: value });
  };

  const handleresourceChange = (value: any) => {
    setActiveForm({ ...activeForm, relatedResources: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleLinkClick = (text) => {
    openVideoPlayer();
  };

  const onSubmit = () => {
    setisloading(true);
    console.log(activeForm, '***8');
    if (!videoID) {
      postVideo(activeForm)
        .then((res: any) => {
          const { data } = res;
          setisloading(false);
          setActiveForm({});
          form.resetFields();
          showNotificationDrawer('Success', 'Video added successfully!');
          dispatch(setCustomerLoading(true));
          fetchVideoList(pagination, searchTerm)
            .then((res) => {
              const { data, count } = res;
              dispatch(setVideoList(data));
              dispatch(setCustomerLoading(false));
            })
            .catch((err) => {
              dispatch(setCustomerLoading(false));
            });
          onReset();
        })
        .catch((err) => {
          setisloading(false);
          setActiveForm({});
          form.resetFields();
          dispatch(setCustomerLoading(false));
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }

    // Edit
    if (videoID) {
      patchVideo(videoID, activeForm)
        .then((res) => {
          setisloading(false);
          setActiveForm({});
          form.resetFields();
          showNotificationDrawer('Success', 'Video Updated successfully!');
          dispatch(setIsOpenDrawer(false));
          dispatch(setCustomerLoading(true));
          fetchVideoList(pagination, searchTerm)
            .then((res) => {
              const { data, count } = res;
              dispatch(setVideoList(data));
              dispatch(setCustomerLoading(false));
            })
            .catch((err) => {
              dispatch(setCustomerLoading(false));
            });
          onReset();
        })
        .catch(() => {
          setisloading(false);
          onReset();
          setActiveForm({});
          form?.resetFields();
          props.cancel();
        });
    }
  };

  const onChangeFile = (url: string, name: string, videoLength: string) => {
    if (videoLength) {
      setActiveForm({
        ...activeForm,
        video: url,
        fileName: name,
        duration: videoLength
      });
    } else {
      setActiveForm({ ...activeForm, video: url, fileName: name });
    }
  };

  const onChangeThumbnailFile = (url, name) => {
    setActiveForm({ ...activeForm, thumbnail: url, thumbnailFileName: name });
  };

  const handleDeleteVideo = (id: string) => {
    setisloading(true);
    deleteVideo(id)
      .then((res) => {
        form?.resetFields();
        setActiveForm({});
        onReset();
        setisloading(false);
        dispatch(setCustomerLoading(true));
        showNotificationDrawer('Success', 'Video deleted successfully!');
        searchVideoApi({ skip: pagination, limit: PerPageRecords, search: searchTerm })
          .then((res) => {
            const { data, length } = res;
            dispatch(setVideoList(data));
            dispatch(setCustomerLoading(false));
          })
          .catch((err) => {
            dispatch(setCustomerLoading(false));
          });
      })
      .catch((err) => {
        setisloading(false);
        showNotificationDrawer('Error', err?.response?.data?.message);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this Video?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteVideo(id);
      },
      onCancel() {}
    });
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input your Title!', whitespace: true }]}
            >
              <Input
                name="title"
                onChange={(e) => onHandleChange(e, 'title')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Domain Pathway"
              name="domainPathway"
              rules={[
                {
                  required: true,
                  message: 'Please select domain pathway!'
                }
              ]}
              className="time-zone-container-s"
            >
              <Select
                defaultValue=""
                onChange={(value: any) => handleChange('domainPathway', value)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                className="thirdPartyAppStyle"
              >
                {domainPathwaysListing.map((data: any, index: number) => (
                  <Option key={index} value={data}>
                    {data}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Domain Sub Category"
              name="domainSubCategory"
              rules={[{ required: true, message: 'Please select sub category!' }]}
            >
              {objectiveList && objectiveList.length ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  // mode="multiple"
                  className="thirdPartyAppStyle"
                  onChange={(value: any) => handleChange('domainSubCategory', value)}
                  placeholder="Please select category"
                  filterOption={(input, option) => {
                    if (option) {
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return true;
                  }}
                >
                  {objectiveList.map((data, index: number) => (
                    <Option key={index} value={data._id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select />
              )}
            </Form.Item>
            <Form.Item
              label="Categories"
              name="categories"
              className="time-zone-container-s"
              rules={[{ required: true, message: 'Please select category!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue={[]}
                showSearch
                mode="tags"
                onChange={(value) => handleMultiSelectChange('categories', value)}
              >
                {videoCategoryList.map((data: any) => (
                  <Option key={data._id} value={data._id}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Add Tags"
              name="tags"
              className="time-zone-container-s"
              rules={[{ required: true, message: 'Please enter tags' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue={[]}
                showSearch
                mode="tags"
                onChange={(value) => handleMultiSelectChange('tags', value)}
              >
                {[...addtags].map((data: any, index: number) => (
                  <Option key={index} value={data.name}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Context/Description..."
              name="description"
              className="no-boder"
              rules={[
                { required: true, message: 'Please input your note or comments!', whitespace: true }
              ]}
            >
              <Input.TextArea
                name="description"
                onChange={(e) => onHandleChange(e, 'description')}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>

            <Form.Item
              label="Author"
              name="author"
              rules={[{ required: true, message: 'Please input your author!' }]}
              className="no-boder time-zone-container-s"
            >
              <Select
                onChange={(value: any) => handleChange('author', value)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue=""
              >
                {authorList?.map((data, index: number) => (
                  <Option key={index} value={data._id}>
                    {data?.firstName} {data?.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Related Resources"
              name="relatedResources"
              rules={[
                {
                  required: false,
                  message: 'Please select related resources!'
                }
              ]}
              className="time-zone-container-s"
            >
              <Select
                disabled={props.viewMode === 'View'}
                mode="multiple"
                onChange={handleresourceChange}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                filterOption={(input, option) => {
                  if (option) {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return true;
                }}
                style={{ pointerEvents: 'auto' }}
              >
                {filterResources?.map((data, index: number) => (
                  <Option key={index} value={data._id}>
                    {data?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              className="no-boder"
              rules={[{ required: true, message: 'Please select your User Status!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue="Draft"
                onChange={(value) => handleChange('status', value)}
              >
                {props.status.map((data: StatusProps, index: number) => (
                  <Option key={index} value={data.label}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {form.getFieldValue('status') === 'Archived' && (
              <Form.Item
                label="Archive Reason"
                name="archiveReason"
                rules={[{ required: true, message: 'Please input your reason!', whitespace: true }]}
              >
                <Input
                  name="archiveReason"
                  onChange={(e) => onHandleChange(e, 'archiveReason')}
                  style={{ borderBottom: '1px solid #E9E9E9' }}
                />
              </Form.Item>
            )}

            <Form.Item label="Transcript" name="transcript" className="no-boder">
              <Input.TextArea
                name="transcript"
                onChange={(e) => onHandleChange(e, 'transcript')}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>

            <Form.Item
              label="Video Link"
              name="video"
              className="no-boder"
              rules={[{ required: true, message: 'field is required' }]}
              style={{ pointerEvents: 'auto' }}
            >
              {props.viewMode === 'View' ? (
                <span
                  onClick={() => handleLinkClick(activeForm.video)}
                  style={{ pointerEvents: 'auto', cursor: 'pointer', color: '#2a8ff7' }}
                >
                  {activeForm.video}
                </span>
              ) : (
                <Input
                  name="document"
                  placeholder="https://example.com"
                  pattern="https://.*"
                  onChange={(e) => onHandleChange(e, 'video')}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Upload Thumbnail"
              name="thumbnail"
              className="no-boder"
              rules={[{ required: true, message: 'Please upload thumbnail' }]}
              style={{ pointerEvents: 'auto' }}
            >
              <FileUploader
                mode={props.viewMode}
                onChange={onChangeThumbnailFile}
                avatarImage={activeForm?.thumbnail}
                errorMessage={fileError}
                onlyImageAccept
                previewVideo
                limit={1024 * 1024 * 2} // limit file size to 2 MB
                resize
              />
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          )}
          {props.viewMode === 'Edit'
            ? props.visible && (
                <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                  Save
                </SubmitButton>
              )
            : props.viewMode === 'Create'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                    Submit
                  </SubmitButton>
                )
              : null}

          {props.userRole === Role.Admin && videoID && props.viewMode === 'Edit' ? (
            <DeleteTabDiv
              style={{ marginLeft: '20px', marginTop: '10px' }}
              className=""
              onClick={() => showDeleteConfirm(videoID)}
            >
              <DeleteOutlined />
            </DeleteTabDiv>
          ) : null}
        </Form.Item>
      </DrawerFooter>
      {videoPlayerComponent}
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    resourcesList: state.formReducer.resourcesList,
    objectiveList: state.formReducer.resourceObjectivelist,
    customerDataList: state.notificationReducer.customerDataList,
    authorList: state.userMemberData.authorList,
    videoList: state.userMemberData.videoList,
    videoCategoryList: state.videoCategoryReducer.categoryList
  };
}

export default connect(mapStateToProps)(VideoForm);
