import {
  DeleteOutlined, DownloadOutlined, LoadingOutlined, RollbackOutlined,
} from '@ant-design/icons';
import { Button, Spin, Table } from 'antd';
import { ColumnType, TableRowSelection } from 'antd/lib/table/interface';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadClientFile, getClientFiles } from '../../api/clientFiles';
import { CustomerState } from '../../redux/reducers/customerRuducer';
import { RootState } from '../../redux/store';

export interface IClientFilesTableComponentProps {
  fileList: ClientFile[];
  onDelete?: (selectedIds: string[]) => void;
  onRestore?: (selectedIds: string[]) => void;
  onDownload?: (fileId: string) => Promise<void>;
}

function humanFileSize(size: number) {
  const i: number = size <= 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${Number((size / 1024 ** i).toFixed(2))} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export default function ClientFilesTableComponent(props: IClientFilesTableComponentProps) {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [downloadingIds, setDownloadingIds] = useState<string[]>([]);
  const { fileList } = props;

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: (a, b) => a?.fileName?.localeCompare(b?.fileName),
    },
    {
      title: 'Member',
      dataIndex: 'member',
      key: 'member',
      render: (value, record) => value && `${value?.firstName} ${value?.lastName}`,
    },
    {
      title: 'Uploaded by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (value, record) => value && `${value?.firstName} ${value?.lastName}`,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (value, record) => record.fileName.split('.').reverse()[0].toUpperCase(),
    },
    {
      title: 'Date added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      render: (value) => moment(new Date(value)).format('D MMM y hh:mm'),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => a.size - b.size,
      render: (value) => humanFileSize(value),
    },
    {
      title: 'Download',
      key: 'url',
      align: 'center' as any,
      render: (value, record) => {
        if (downloadingIds.includes(record._id)) return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
        return (
          <a onClick={() => download(record._id)}>
            <DownloadOutlined style={{ fontSize: 24 }} />
          </a>
        );
      },
    },
  ];

  const download = async (id: string) => {
    setDownloadingIds((ids) => [...ids, id]);
    await props.onDownload?.call(null, id);
    setDownloadingIds((ids) => {
      ids.splice(ids.indexOf(id), 1);
      return [...ids];
    });
  };

  const rowSelection: TableRowSelection<ClientFile> = {
    selectedRowKeys: selectedIds,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedIds(selectedRows.map((r) => r._id));
    },
  };

  const onDelete = async () => {
    await props.onDelete?.call(null, selectedIds);
    setSelectedIds([]);
  };
  const onRestore = async () => {
    await props.onRestore?.call(null, selectedIds);
    setSelectedIds([]);
  };
  return (
    <div>
      {selectedIds.length > 0 && (
        <div className="p-3 bg-gray-100">
          {props.onDelete && (
            <Button icon={<DeleteOutlined />} type="text" onClick={onDelete}>
              <span className="text-blue-400 underline">Delete</span>
            </Button>
          )}
          {props.onRestore && (
            <Button icon={<RollbackOutlined />} type="text" onClick={onRestore}>
              <span className="text-blue-400 underline">Restore</span>
            </Button>
          )}
        </div>
      )}
      <Table
        rowSelection={{
          ...rowSelection,
          type: 'checkbox',
        }}
        dataSource={fileList}
        columns={columns}
        rowKey="_id"
      />
    </div>
  );
}
