import { Modal } from 'antd';
import React, { useState } from 'react';
import WizardScreen from '../../../common/WelcomeScreen/WizardScreen';

interface WizardModalProps {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
  step?: number;
}

const WizardModal: React.FC<WizardModalProps> = ({ visible, setVisibility, step }) => {
  const [wizardopen] = useState<boolean>(false);
  const handleOk = () => {
    setVisibility(false);
  };

  return (
    <Modal
      title=""
      visible={visible}
      onOk={handleOk}
      style={{ zIndex: 99999999, width: '100%' }}
      className={wizardopen ? 'welcome-modal-full-width ' : 'welcome-modal-full-width'}
      onCancel={handleOk}
    >
      {/* <WizardUploader /> */}
      {/* <WelcomeMainScreen setWizardOpen={setWizardOpen} /> */}
      {wizardopen ? (
        <WizardScreen handleOk={handleOk} step={step} />
      ) : (
        <WizardScreen handleOk={handleOk} step={step} />
      )}
    </Modal>
  );
};

export default WizardModal;
