import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { setDataInLocalStorage } from '../../components/common/utils';

export interface ICustomerRedirectDashboardProps {
}

export default function CustomerRedirectDashboard(props: ICustomerRedirectDashboardProps) {
  const { customerId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectUri = params.get('redirectUri') || '/dashboard';

  const gotoCustomerDashboard = (customerId: string) => {
    const data = {
      role: 'BusinessAccesCustomer',
      id: customerId,
    };
    setDataInLocalStorage('bussness access customer', data);
    window.location.href = redirectUri;
  };
  useEffect(() => {
    gotoCustomerDashboard(customerId);
  }, [customerId]);
  return (
    <div />
  );
}
