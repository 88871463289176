import { List } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

const NotificationList = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const { list, onClick } = props;
  return (
    <List
      className="notification-outer-container"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item: any) => (
        <List.Item>
          <List.Item.Meta
            description={
              <div
                className={`flex items-center ${!item.read ? 'active-message' : 'inactive-message'}`}
                onClick={() => onClick(item)}
              >
                <div>{item.message}</div>
                <div className="ml-auto mt-1 mr-2">
                  <small>{moment(item.createdAt).format('DD-MM-YYYY')}</small>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};
export default NotificationList;
