import React, { useState } from 'react';
import { Drawer } from 'antd';
import { showNotificationDrawer } from '../../common/utils';

interface SideDrawerProps {
    title: string,
    visible: false,
}

const SideDrawer: React.FC<SideDrawerProps> = ({ title, visible, children }) => {
  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  return (
    <Drawer
      title={`${title} Form`}
      placement="right"
      closable={false}
      visible={visible}
      width="520"
      className="user-drawer notificationDrawerModal"
      maskClosable={false}
    >
      {children}
    </Drawer>
  );
};

export default SideDrawer;
