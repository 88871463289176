import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import { Col, Form, Image, Input, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { getAuthor } from '../../api/author';
import { deleteBlog, getBlogCategory, getCurrentBlog, patchBlog, postBlog } from '../../api/blog';
import FileUploader from '../common/FileUploader';
import { DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;

interface BlogFormProps {
  permission: 'view' | 'edit' | 'create';
  onClose: () => void;
  fetchData: () => void;
  currentBlogId?: string;
}

const BlogForm: React.FC<BlogFormProps> = ({ permission, onClose, fetchData, currentBlogId }) => {
  const [form] = Form.useForm();
  const defaultData: BlogFormState = {
    title: '',
    categories: '',
    author: '',
    thumbnail: '',
    body: '',
    status: 'Draft'
  };
  const [activeForm, setActiveForm] = useState<BlogFormState>(defaultData);
  const [currentBlog, setCurrentBlog] = useState<BlogFormState>(defaultData);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string>('');
  const [categoryList, setCategoryList] = useState<any>([]);
  const [authorList, setAuthorList] = useState<any>([]);
  const { confirm } = Modal;

  useEffect(() => {
    getBlogCategory().then((res) => {
      const { data } = res;
      setCategoryList(data);
    });
    getAuthor().then((res) => {
      const { data } = res;
      setAuthorList(data);
    });
  }, []);

  useEffect(() => {
    if (currentBlogId) {
      getCurrentBlog(currentBlogId).then((res) => {
        const { data } = res;
        const {
          title,
          categories: { _id: categories },
          author: { _id: author },
          thumbnail,
          body,
          status
        } = data;
        const blogData = {
          title,
          categories,
          author,
          thumbnail,
          body,
          status
        };
        const formData = { ...blogData };
        setCurrentBlog(blogData);
        setActiveForm(formData);
        form.setFieldsValue(formData);
      });
    } else {
      setActiveForm(defaultData);
    }
    if (permission === 'view') {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [currentBlogId, permission]);

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleInput = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleEditor = (value: any, editor: any) => {
    setActiveForm({ ...activeForm, body: value });
  };

  const formStyle: CSSProperties = {};

  const onSubmit = () => {
    if (permission === 'create') {
      setIsloading(true);
      const createData = {
        ...activeForm
      };
      postBlog(createData)
        .then((res: any) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Success', 'Blog created successfully!');
          onClose();
          fetchData();
        })
        .catch((err) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    } else if (permission === 'edit') {
      setIsloading(true);
      if (JSON.stringify(activeForm) === JSON.stringify(currentBlog)) {
        showNotificationDrawer('Warning', 'Nothing changed');
        setIsloading(false);
        onClose();
      } else {
        patchBlog(currentBlogId, activeForm)
          .then((res) => {
            setIsloading(false);
            form?.resetFields();
            showNotificationDrawer('Success', 'Blog updated successfully!');
            onClose();
            fetchData();
          })
          .catch((err) => {
            setIsloading(false);
            form?.resetFields();
            showNotificationDrawer('Error', err?.response?.data?.message);
          });
      }
    }
  };

  const showDeleteConfirm = async () => {
    confirm({
      title: 'Do you really want to delete this Blog?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await deleteBlog(currentBlogId);
          fetchData();
          onClose();
        } catch (err) {
          console.error('Error deleting Blog:', err);
        }
      },
      onCancel() {}
    });
  };

  const onChangeThumbnailFile = (url) => {
    setActiveForm({ ...activeForm, thumbnail: url });
  };

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form author-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: 'Please input Title!', whitespace: true },
                { max: 120, message: 'Title must be at most 120 characters long' } // Add this rule
              ]}
            >
              <Input
                name="title"
                onChange={(e) => onHandleInput(e, 'title')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
                readOnly={isReadOnly}
              />
            </Form.Item>
            <Form.Item
              name="categories"
              fieldKey="categories"
              label="Category"
              rules={[{ required: true, message: 'Please select Category!', whitespace: true }]}
              className="category-selector"
            >
              <Select
                onChange={(value) => handleChange('categories', value)}
                placeholder="Please select category"
                style={{ borderBottom: '1px solid #E9E9E9' }}
                disabled={isReadOnly}
              >
                {categoryList?.map((data) => (
                  <Option key={data._id} value={data._id}>
                    <div className="flex items-center gap-3">
                      <Image width="50px" height="50px" src={data?.iconUrl} preview={false} />
                      {data?.name}
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Author"
              name="author"
              rules={[{ required: true, message: 'Please select Author!', whitespace: true }]}
              className=""
            >
              <Select
                onChange={(value: any) => handleChange('author', value)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                placeholder="Please select Author"
                style={{ borderBottom: '1px solid #E9E9E9' }}
                disabled={isReadOnly}
              >
                {authorList?.map((data) => (
                  <Option key={data._id} value={data._id}>
                    {data?.firstName} {data?.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Body"
              required
              help={activeForm.body ? '' : 'Please input your body!'}
              validateStatus={activeForm.body ? 'success' : 'error'}
              className="no-boder time-zone-container-s"
            >
              {/* @ts-ignore */}
              <Editor
                disabled={isReadOnly}
                onEditorChange={onHandleEditor}
                init={{
                  height: 500,
                  width: '100%',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | blocks | ' +
                    'bold italic forecolor backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help'
                }}
                value={activeForm.body ?? ''}
              />
            </Form.Item>
            <Form.Item
              label="Upload Thumbnail"
              name="thumbnail"
              className="no-boder"
              rules={[{ required: true, message: 'Please upload thumbnail' }]}
              style={{ pointerEvents: 'auto' }}
            >
              {isReadOnly ? (
                // Render a disabled state or placeholder if read-only
                <Image
                  width="104px"
                  height="104px"
                  src={activeForm?.thumbnail}
                  preview={false}
                  style={{ objectFit: 'cover' }}
                />
              ) : (
                // Render the FileUploader component when not read-only
                <FileUploader
                  mode={permission}
                  onChange={onChangeThumbnailFile}
                  avatarImage={activeForm?.thumbnail}
                  errorMessage={fileError}
                  onlyImageAccept
                  limit={1024 * 1024 * 2} // limit file size to 2 MB
                  resize
                />
              )}
            </Form.Item>
            <Form.Item
              name="status"
              fieldKey="status"
              label="Status"
              style={{ borderBottom: '1px solid #E9E9E9' }}
              className="no-boder time-zone-container-s"
            >
              <Select onChange={(value) => handleChange('status', value)} disabled={isReadOnly}>
                <Option value="Draft">Draft</Option>
                <Option value="Published">Published</Option>
                <Option value="Archived">Archived</Option>
              </Select>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton blog-footer">
        {permission !== 'view' && (
          <Form.Item className="full-row no-border">
            <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
              Submit
            </SubmitButton>
            {permission === 'edit' && (
              <SubmitButton
                htmlType="button"
                type="primary"
                onClick={() => {
                  showDeleteConfirm();
                }}
              >
                <DeleteOutlined key="delete" /> Delete
              </SubmitButton>
            )}
          </Form.Item>
        )}
      </DrawerFooter>
    </Form>
  );
};

export default BlogForm;
