import React from 'react';
import { useWindowSize } from '@react-hook/window-size';
import Confetti from 'react-confetti';
import { connect } from 'react-redux';

const PartyPopper: React.FC<any> = (props) => {
  const [width, height] = useWindowSize();
  return (
    <Confetti
      width={520}
      height={212}
      numberOfPieces={60}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    openWelcomeModal: state.customerRuducer.openWelcomeModal,
  };
}

export default connect(mapStateToProps)(PartyPopper);
