import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { checkUserAuth } from '../../utils/api';

const PrivateRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  if (!component) {
    return null;
  }
  const Component: any = component;
  return (
    <Route
      {...rest}
      render={(props) => (checkUserAuth() ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />)}
    />
  );
};

export default PrivateRoute;
