import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { RoleTypeProps, TaskFormType, userMemberDataType } from '../../constant';
import { Mode, Role } from '../../utils/types/role';
import {
  DeleteTabDiv,
  DrawerFooter,
  MultipleFileDiv,
  SubmitButton,
  UserLastRow
} from '../common/styles';
// import { ProgramRole } from '../MyGoalsComponent';
import { postNotificationApi } from '../../api/notification';
import {
  addTaskApi,
  getGoalsApi,
  getMilestoneApi,
  getTaskDetailsApi,
  updateTaskApi
} from '../../api/user-program';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setSelectedRowData } from '../../redux/actions/formAction';
import { addNotificationAlert, setNotificationAlert } from '../../redux/actions/notificationAction';
import { setContentLinkList, setFileLinkList } from '../../redux/actions/userProgramAction';
import Comments from '../common/Comments/Comments';
import RichTextComponent from '../common/RichTextComponent';
import { dateFormat } from '../common/utils';
import FieldBox from './FieldBox';
import ResourceFiles from './ResourceFiles';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const { Option } = Select;
const { Title, Text } = Typography;
const { confirm } = Modal;
const thridPartyAppLis = [
  'Gemiini',

  'Centervention',

  'BoardMaker',

  'Education.Com',

  'TeachTown',

  'Emotional ABC',

  'GoalBook',

  'Proloquo2Go',

  'Others'
];

interface GoalFormProps {
  isCheckedLabel?: string;
  mileStoneTypes?: any;
  description?: string;
  commentBox?: any;
  roleType: RoleTypeProps[];
  userType: RoleTypeProps[];
  userRole: Role;
  close: any;
  cancel: any;
  userId?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  userMemberData: userMemberDataType;
  goalFormType?: string;
  taskTypesList?: any;
  strategyTypes?: any;
  taskId?: string;
  taskData?: any;
  assigneeList?: Array<any>;
  mileStoneData?: any;
  customerSelectedMember?: any;
  customerDataList?: any;
  targetSkillList?: any;
  contentLinkList?: any;
  fileLinkList?: any;
  getGoalsData?: any;
  resourcesList?: any;
  memberGoals?: any;
  createdByOther?: string;
  isMobile?: any;
  isSession?: any;
}

const TaskForm: React.FC<GoalFormProps> = (props) => {
  const {
    dispatch,
    taskData,
    taskId,
    assigneeList,
    mileStoneData,
    strategyTypes,
    contentLinkList,
    memberGoals,
    fileLinkList,
    getGoalsData,
    visible,
    viewMode,
    customerSelectedMember,
    userMemberData,
    isSession,
    resourcesList,
    createdByOther,
    customerDataList,
    isMobile
  } = props;

  const [activeForm, setActiveForm] = useState<TaskFormType>({
    milestone: props.mileStoneData?._id,
    dueDate: moment().add(1, 'days'),
    fileLink: [],
    contentLink: [],
    commentBox: ''
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [addstrategyTypes, setaddStrategyTypes] = useState<any>('');
  const [addNameofThirdPartyApp, setaddNameofThirdPartyApp] = useState<any>('');
  const [Programs, setPrograms] = useState<any>([]);
  const [Milestones, setmileStones] = useState<any>([]);
  const [addedValueOfThirdPartyApp, setValueOfThirdPartyApp] = useState<any>([]);
  const [addedstrategy, setaddedStrategy] = useState<any>([]);
  const [form] = Form.useForm();

  const { currentLoginUser } = userMemberData;
  // check to prevent app from crashing:
  //! taskData.assignee.some((x: any) => !x)
  const defaultAssignee = taskData
    ? taskData.assignee &&
      !taskData.assignee.some((x: any) => !x) &&
      taskData.assignee.map((res) => res._id)
    : [];
  const defaultTaskType = taskData ? taskData.taskType && taskData.taskType.name : null;
  const SelectedMember = customerSelectedMember;

  const index = 0;
  useEffect(() => {
    setLoading(true);
    if (customerSelectedMember[0]?._id) {
      getGoalsApi(customerSelectedMember[0]?._id).then((res) => {
        setPrograms(res.data);
        setLoading(false);
      });
    }

    const activeFormLength = Object.keys(activeForm).length;

    if (!activeFormLength) {
      if (viewMode === 'Create' && !taskId) {
        const createData = {
          milestone: mileStoneData?._id,
          name: '',
          dueDate: moment().add(1, 'days'),
          fileLink: [],
          contentLink: [],
          isCompleted: false,
          description: '',
          commentBox: '',
          strategy: [],
          taskType: '',
          assignee: [],
          thridPartyAppName: [],
          thridPartyAppDesc: '',
          notes: '',
          comment: ''
        };
        dispatch(setFileLinkList(createData.fileLink));
        dispatch(setSelectedRowData({ keys: [], rows: [] }));
        dispatch(setContentLinkList(createData.contentLink));
        form.setFieldsValue(createData);
      }
    }

    if (taskId && visible) {
      getTaskDetailsApi(taskId).then((res) => {
        const { data } = res;
        const taskInfoData = {
          ...data,
          assignee: data.assignee.map((res) => res._id),
          strategy: data.strategy?.length === 0 ? [] : data.strategy?.split(','),
          thridPartyAppName:
            data.thridPartyAppName?.length === 0 ? [] : data.thridPartyAppName?.split(','),
          taskType: data.taskType ? data.taskType._id : null,
          dueDate: moment(data.dueDate).isValid() ? moment(data.dueDate) : null,
          program: data.milestone?.goal,
          milestone: data.milestone && data.milestone._id
        };
        getMilestoneApi(data.milestone?.goal).then((res) => {
          setmileStones(res.data);
        });
        const keys = taskInfoData.fileLink.map((item) => item._id);
        dispatch(setFileLinkList(keys));
        dispatch(setSelectedRowData({ keys, rows: taskInfoData.fileLink }));
        dispatch(setContentLinkList(data.contentLink));
        form.setFieldsValue(taskInfoData);
        setActiveForm({ ...activeForm, description: data.description });
        setLoading(false);
      });
    }
  }, [dispatch, taskId, form, mileStoneData?._id, visible, viewMode]);

  const onReset = (e: any) => {
    e.stopPropagation();
    setActiveForm({});
    form.resetFields();
    setLoading(false);
    props.cancel();
    dispatch(setFileLinkList([]));
    dispatch(setContentLinkList([]));
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onNameChange = (event) => {
    setaddStrategyTypes(event.target.value);
  };

  const onNameChangeofThirdPartyApp = (event) => {
    setaddNameofThirdPartyApp(event.target.value);
  };
  const thirdPartyAddItem = () => {
    setValueOfThirdPartyApp([...addedValueOfThirdPartyApp, addNameofThirdPartyApp]);
  };
  const addItem = () => {
    setaddedStrategy([...addedstrategy, addstrategyTypes]);
  };
  const onHandleChange = (e) => {
    const name = e.target.name || e.target.props?.name;
    const value = e.target.value || e.target.props?.value;
    setActiveForm({ ...activeForm, [name]: value });
    setaddStrategyTypes(e.target.value);
  };

  const onHandleRichTxt = (value: any) => {
    setActiveForm({ ...activeForm, description: value });
  };

  // const handleOnCheck = (e: any) => {
  //     const { checked } = e.target
  //     setActiveForm({ ...activeForm, isCompleted: checked });
  // }

  const onChangeDate = (date, dateString) => {
    setActiveForm({ ...activeForm, dueDate: moment(dateString) });
  };

  const multiSelectHandleChange = (value: any) => {
    setActiveForm({ ...activeForm, assignee: value });
  };

  const onSubmit = () => {
    const contentLinkString = contentLinkList;
    const fileLinkString = fileLinkList;
    setLoading(true);
    if (props.taskId) {
      updateTaskApi(props.taskId, {
        ...activeForm,
        contentLink: contentLinkString,
        fileLink: fileLinkString
      })
        .then((res) => {
          getGoalsData();
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          dispatch(setFileLinkList([]));
          dispatch(setContentLinkList([]));
          if (!isLoading) {
            props.close('Success', 'Task information updated successfully!', res);
          }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();

          dispatch(setFileLinkList([]));
          dispatch(setContentLinkList([]));
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    } else {
      const createTaskData = {
        name: activeForm.name,
        description: activeForm.description,
        commentBox: activeForm.commentBox,
        milestone: activeForm.milestone ? activeForm.milestone : props.mileStoneData._id,
        taskType: activeForm.taskType,
        isCompleted: activeForm.isCompleted,
        assignee: activeForm.assignee,
        member: SelectedMember.length && SelectedMember[0]._id,
        dueDate: activeForm.dueDate
      };

      addTaskApi(
        currentLoginUser.type === Role.Customer
          ? createTaskData
          : {
              ...activeForm,
              member: SelectedMember.length && SelectedMember[0]._id,
              milestone: activeForm.milestone ? activeForm.milestone : props.mileStoneData._id,
              contentLink: contentLinkString,
              fileLink: fileLinkString
            }
      )
        .then((res) => {
          getGoalsData();
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Success', 'Task created successfully!', res);
          }
          const filterAssign = assigneeList?.filter(
            (data) => data._id === (activeForm.assignee && activeForm.assignee[0])
          );
          const notificationGoal = {
            member: `${
              customerSelectedMember.length
                ? customerSelectedMember[0]._id
                : customerSelectedMember._id
            }`,
            businessUser: `${userMemberData.loginUserData._id}`,
            message: `New Task added and assigned to ${
              filterAssign && filterAssign[0].firstName
            } ${filterAssign && filterAssign[0].lastName}`,
            user: `${customerSelectedMember[0].parent}`,
            task: res.data._id,
            type: 'TASK_ADDED'
          };
          fileLinkList.forEach((element) => {
            const filterResource = resourcesList?.find((data) => data._id === element);
            const resource = {
              member: `${
                customerSelectedMember.length
                  ? customerSelectedMember[0]._id
                  : customerSelectedMember._id
              }`,
              businessUser: `${userMemberData.loginUserData._id}`,
              message: `Resource ${filterResource.name} has been added to  ${
                customerSelectedMember.length
                  ? customerSelectedMember[0].firstName
                  : customerSelectedMember.firstName
              }`,
              user: `${customerSelectedMember[0].parent}`,
              resource: element,
              type: 'RESOURCE'
            };
            return postNotificationApi(resource).then((res) => {
              dispatch(setNotificationAlert(res.data));
            });
          });

          postNotificationApi(notificationGoal).then((res) => {
            dispatch(addNotificationAlert(res.data));
          });
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    }
  };

  const deleteTask = async (taskId: any) => {
    if (taskId) {
      setLoading(true);
      try {
        const res = await updateTaskApi(taskId, {
          ...activeForm,
          isDeleted: true
        });
        getGoalsData();
        setLoading(false);
        if (!isLoading) {
          props.close('Success', 'Task removed successfully!', res);
        }
      } catch (err: any) {
        setLoading(false);
        if (!isLoading) {
          props.close('Error', err.response.data.message);
        }
      }
    }
  };

  const addContentSkill = (data: any) => {
    dispatch(setContentLinkList(data));
  };

  const addNeedSkill = (data: any) => {
    dispatch(setFileLinkList(data));
  };

  const getContentLinkSpecificFields = () => {
    const { contentLinkList = [] } = props;
    return (
      <>
        {contentLinkList.length > 0 && props.viewMode === 'View' ? (
          <MultipleFileDiv>
            <Text>Content Link</Text>
            <FieldBox
              fieldMsg="Please input some content link"
              SkillList={contentLinkList}
              addData={addContentSkill}
              buttonLabel="Add more content link"
              dataId={taskId}
              view={viewMode}
              taskcontentLink="taskcontentLink"
              viewMode={currentLoginUser.type !== Role.Business}
            />
          </MultipleFileDiv>
        ) : props.viewMode === 'Edit' || props.viewMode === 'Create' ? (
          <MultipleFileDiv>
            <Text>Content Link</Text>
            <FieldBox
              fieldMsg="Please input some content link"
              SkillList={contentLinkList}
              addData={addContentSkill}
              buttonLabel="Add more content link"
              dataId={taskId}
              view={viewMode}
              taskcontentLink="taskcontentLink"
              viewMode={currentLoginUser.type !== Role.Business}
            />
          </MultipleFileDiv>
        ) : null}
      </>
    );
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTask(id);
      },
      onCancel() {}
    });
  };

  const getFileLinkSpecificFields = () => {
    const { fileLinkList = [] } = props;

    return (
      <>
        {fileLinkList.length > 0 && props.viewMode === 'View' ? (
          <MultipleFileDiv>
            <Text>Resource Link</Text>
            <ResourceFiles
              fieldMsg="Please input some file link"
              itemList={fileLinkList}
              addData={addNeedSkill}
              buttonLabel="Add More Resource"
              dataId={taskId}
              view={viewMode}
              viewMode={currentLoginUser.type !== Role.Business}
            />
          </MultipleFileDiv>
        ) : props.viewMode === 'Edit' || props.viewMode === 'Create' ? (
          <MultipleFileDiv>
            <Text>Resource Link</Text>
            <ResourceFiles
              fieldMsg="Please input some file link"
              itemList={fileLinkList}
              addData={addNeedSkill}
              buttonLabel="Add More Resource"
              dataId={taskId}
              view={viewMode}
              viewMode={currentLoginUser.type !== Role.Business}
            />
          </MultipleFileDiv>
        ) : null}
      </>
    );
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  const disabledDate = (current) =>
    // Can not select days before today and today
    current && current < moment().endOf('day');
  const disableField = !!createdByOther;

  const checkstrategy = (data) => {
    const children = [] as any;
    for (let i = 10; i < 36; i++) {
      children.push(
        <Option key={i.toString(36) + i} value={data}>
          {data}
        </Option>
      );
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style task-form-style"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
      onClick={(e) => e.stopPropagation()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          {isSession && (
            <Row>
              <Col xs={24} sm={12} className="right-select">
                <Form.Item
                  label="Select the Outcome"
                  name="program"
                  rules={[{ required: true, message: 'Please select The Program!' }]}
                  className="no-boder time-zone-container-s"
                >
                  <Select
                    onChange={(value) => {
                      getMilestoneApi(value).then((res) => {
                        setmileStones(res.data);
                      });
                    }}
                    disabled={viewMode === 'Edit'}
                  >
                    {Programs.map((data: any, index: number) => (
                      <Option key={index} value={data._id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {form.getFieldValue('program') && (
                <Col xs={24} sm={12} className="right-select">
                  <Form.Item
                    label="Select the Skill"
                    name="milestone"
                    rules={[{ required: true, message: 'Please select The MileStone!' }]}
                    className="no-boder time-zone-container-s"
                  >
                    <Select
                      onChange={(value) => (value ? handleChange('milestone', value) : null)}
                      disabled={viewMode === 'Edit'}
                    >
                      {Milestones.map((data: any, index: number) => (
                        <Option key={index} value={data._id}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          )}
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Task Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please add name of task name!',
                  whitespace: true
                }
              ]}
            >
              <Input
                name="name"
                value={activeForm.name}
                onChange={onHandleChange}
                disabled={props.viewMode === 'View'}
                style={{
                  pointerEvents: 'auto',
                  background: 'white',
                  borderBottom: '1px solid lightgrey'
                }}
              />
            </Form.Item>

            {/* {props.goalFormType !== ProgramRole.Milestone ?
                                <Form.Item
                                    name="isCompleted"
                                    label=""
                                    rules={[{ required: false, message: 'Please check as the box!' }]}
                                >
                                 className="Check-goal-as-primary" checked={activeForm.isCompleted} onChange={handleOnCheck}>{"Complete"}>
                                </Form.Item>
                                : null
                            } */}

            <Row>
              {!isSession && (
                <Col xs={24} sm={12} className="left-select">
                  <Form.Item
                    label="What Skill it belongs to?"
                    name="milestone"
                    rules={[{ required: true, message: 'Please select your skill!' }]}
                    className="time-zone-container-s"
                  >
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      defaultValue={mileStoneData?.name}
                      onChange={(value) => handleChange('milestone', value)}
                      disabled={props.viewMode === 'View'}
                      style={{ pointerEvents: 'auto', background: 'white' }}
                    >
                      <Option
                        value={mileStoneData?._id}
                        disabled={props.viewMode === 'View'}
                        style={{ pointerEvents: 'auto', background: 'white' }}
                      >
                        {mileStoneData?.name}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} sm={12} className="right-select">
                <Form.Item
                  label="Task Type"
                  name="taskType"
                  rules={[{ required: true, message: 'Please select Task type!' }]}
                  className="no-boder time-zone-container-s"
                >
                  <Select
                    defaultValue={defaultTaskType}
                    onChange={(value) => handleChange('taskType', value)}
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  >
                    {props.taskTypesList.map((data: any, index: number) => (
                      <Option
                        key={index}
                        value={data._id}
                        style={{ pointerEvents: 'auto', background: 'white' }}
                        disabled={props.viewMode === 'View'}
                      >
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="left-select">
                <Form.Item
                  label="Assignee"
                  name="assignee"
                  rules={[{ required: true, message: 'Please select your Assignee!' }]}
                  className="time-zone-container-s"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    mode="multiple"
                    defaultValue={defaultAssignee}
                    onChange={multiSelectHandleChange}
                    disabled={!!(disableField || props.viewMode === 'View')}
                    style={{ pointerEvents: 'auto' }}
                  >
                    {isSession
                      ? assigneeList?.map((data: any, index: number) => (
                          <Option key={index} value={data._id}>
                            {`${data.firstName} ${data.lastName}`}
                          </Option>
                        ))
                      : assigneeList && (props.viewMode === 'Create' || props.viewMode === 'Edit')
                        ? assigneeList?.map((data: any, index: number) => (
                            <Option key={index} value={data._id}>
                              {`${data?.firstName} ${data?.lastName}`}
                            </Option>
                          ))
                        : taskData &&
                          taskData.assignee &&
                          taskData.assignee.map((data: any, index: number) => (
                            <Option key={index} value={data._id}>
                              {`${data?.firstName} ${data?.lastName}`}
                            </Option>
                          ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="right-select">
                <Form.Item
                  name="dueDate"
                  fieldKey="dueDate"
                  label="Due Date"
                  rules={[{ required: false, message: 'Please input due date' }]}
                >
                  {/* @ts-ignore */}
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode}
                    name="dueDate"
                    style={{ width: '100%' }}
                    format={dateFormat}
                    onChange={onChangeDate}
                    disabledDate={disabledDate}
                    disabled={disableField}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Task Description"
              name="description"
              rules={[{ required: true, message: 'Please add description!' }]}
            >
              <RichTextComponent
                className="rich-text-div"
                onChange={onHandleRichTxt}
                data={activeForm.description ? activeForm.description : ''}
                disabled={!!(customerDataList.type === 'Customer' && props.viewMode === 'View')}
                style={{ pointerEvents: 'auto', background: 'white' }}
              />
            </Form.Item>

            {(currentLoginUser.type === Role.Business || activeForm?.strategy) && (
              <>
                <Title level={5} className="titleStyle">
                  If task type is strategy
                </Title>

                <Form.Item
                  label="Strategy Description"
                  name="strategy"
                  className="time-zone-container-s"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue={[]}
                    showSearch
                    mode="tags"
                    onChange={(value: any) => handleChange('strategy', value.join(','))}
                    disabled={customerDataList.type === 'Customer' && disableField}
                  >
                    {[...props.strategyTypes, ...addedstrategy].map((data: any, index: number) => (
                      <Option key={index} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {currentLoginUser.type === Role.Business || props?.contentLinkList?.length
              ? getContentLinkSpecificFields()
              : null}
            {currentLoginUser.type === Role.Business || props?.fileLinkList?.length
              ? getFileLinkSpecificFields()
              : null}
            {currentLoginUser.type === Role.Business || activeForm?.thridPartyAppName?.length ? (
              <>
                <Title level={5} className="titleStyle">
                  If task type is 3rd party app
                </Title>
                <Row>
                  <Col xs={24} sm={12} className="left-select">
                    <Form.Item
                      label="Name of App"
                      name="thridPartyAppName"
                      rules={[
                        {
                          required: false,
                          message: 'Please select your app name!'
                        }
                      ]}
                      className="time-zone-container-s"
                    >
                      <Select
                        defaultValue={[]}
                        onChange={(value: any) =>
                          handleChange('thridPartyAppName', value.join(','))
                        }
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        mode="tags"
                        className="thirdPartyAppStyle"
                      >
                        {[...thridPartyAppLis, ...addedValueOfThirdPartyApp].map(
                          (data: any, index: number) => (
                            <Option key={index} value={data}>
                              {data}
                            </Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null}

            {currentLoginUser.type === Role.Business || activeForm?.thridPartyAppDesc?.length ? (
              <Form.Item
                label="Details of the Task in the App"
                name="thridPartyAppDesc"
                className="no-boder"
                rules={[
                  {
                    required: false,
                    message: 'Please input your app details!',
                    whitespace: true
                  }
                ]}
              >
                <Input.TextArea
                  name="thridPartyAppDesc"
                  value={activeForm.name}
                  onChange={onHandleChange}
                />
              </Form.Item>
            ) : null}
          </Col>

          {props.viewMode === 'View' && <Comments contentId={taskId} />}
        </UserLastRow>
        <DrawerFooter
          className={
            isMobile
              ? 'drawer-footer-style drawerButton'
              : 'drawer-footer-style drawerButton taskformfooter'
          }
        >
          <Form.Item className="no-boder">
            {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            )}
            {props.viewMode === 'Edit'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary">
                    Save
                  </SubmitButton>
                )
              : props.viewMode === 'Create'
                ? props.visible && (
                    <SubmitButton htmlType="submit" type="primary">
                      Submit
                    </SubmitButton>
                  )
                : null}

            {props.viewMode !== Mode.View && customerDataList.type === 'BusinessUser' && taskId ? (
              <DeleteTabDiv
                className="delete-task-controller-div"
                onClick={() => showDeleteConfirm(taskId)}
              >
                <DeleteOutlined />
              </DeleteTabDiv>
            ) : null}
          </Form.Item>
        </DrawerFooter>
      </Spin>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    assigneeList: state.programRuducer.assigneeList,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    targetSkillList: state.programRuducer.targetSkillList,
    contentLinkList: state.programRuducer.contentLinkList,
    fileLinkList: state.programRuducer.fileLinkList,
    resourcesList: state.formReducer.resourcesList,
    memberGoals: state.programRuducer.memberGoals,
    customerDataList: state.notificationReducer.customerDataList,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(TaskForm);
