import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getHomePromptsAPI = (options?) => apiGet(`${API_URL}home/prompts`, options);

export const getHomePromptByIdAPI = (id) => apiGet(`${API_URL}home/prompts/${id}`);

export const postHomePromptAPI = (data) => apiPost(`${API_URL}home/prompts`, data);

export const patchHomePromptAPI = (id, data) => apiPatch(`${API_URL}home/prompts/${id}`, data);

export const deleteHomePromptAPI = (id) => apiDelete(`${API_URL}home/prompts`, { id });

export const getHomeCategories = (pagination?) => apiGet(`${API_URL}home/categories`, pagination);

export const getHomeCategoryById = (id: string) => apiGet(`${API_URL}home/categories/${id}`);

export const addHomeCategory = (data) => apiPost(`${API_URL}home/categories`, data);

export const updateHomeCategory = (id, data) => apiPatch(`${API_URL}home/categories/${id}`, data);

export const deleteHomeCategory = (id: string) => apiDelete(`${API_URL}home/categories`, { id });
