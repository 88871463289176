import React, { ReactChild, useEffect, useState } from 'react';
import { Drawer, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AdminForm from '../AdminForm';
import country from '../../country.json';
import timeZone from '../../timeZone.json';
import countryCode from '../../countryCode.json';
import {
  RoleType, UserStatus, UserType, ChoosePlan, ListingType,
} from '../../constant';
import { AddButton } from '../common/styles';
import { Role } from '../../utils/types/role';
import { showNotificationDrawer } from '../common/utils';

interface AddUserProps {
    user: Role;
    icon?: ReactChild;
    id?: string;
    edit?: boolean;
    chooseForm?: string;
    formLabel?: string;
    onCloseDrawer?: () => void;
    isOpenVisible?: (boolean) => void;
    closeProfileInfo?: (boolean) => void;
    handleOutSideClick?: () => void;
}

const AddUserComponent: React.FC<AddUserProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [role, setRole] = useState<Role>(props.user);

  useEffect(() => {
    setRole(props.user);
  }, [props.user]);

  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  const closeDrawer = (title, content) => {
    openNotification(title, content);
    if (props.onCloseDrawer) {
      props.onCloseDrawer();
    }
  };

  const onClose = () => {
    setVisible(false);
    if (props.handleOutSideClick) {
      props.handleOutSideClick();
    }
  };

  const onCancel = () => {
    setVisible(false);

    if (props.closeProfileInfo && props.isOpenVisible) {
      props.closeProfileInfo(false);
      props.isOpenVisible(false);
    }
  };

  let showTitle = '';

  switch (props.chooseForm) {
  case 'Edit':
  case 'View':
    showTitle = props.chooseForm;
    break;
  default:
    showTitle = 'Create';
  }

  const openDrawar = (e) => {
    setVisible(true);
    e.stopPropagation();
    if (props.isOpenVisible) {
      props.isOpenVisible(true);
    }
  };

  return (
    <>
      { props.icon
        ? <span onClick={openDrawar}>{props.icon}</span>
        : (
          <AddButton type="primary" onClick={openDrawar}>
            <PlusOutlined />
          </AddButton>
        )}
      <Drawer
        title={`${showTitle} Profile`}
        placement="right"
        closable={false}
        visible={visible}
        width="88%"
        className="user-drawer edit-user-profile-main-drawer-container"
        onClose={onClose}
        maskClosable={false}
      >
        {visible && (
          <AdminForm
            countryList={country}
            timeZone={timeZone}
            roleType={RoleType}
            userStatus={UserStatus}
            userType={UserType}
            listingType={ListingType}
            countryCode={countryCode}
            choosePlan={ChoosePlan}
            close={closeDrawer}
            cancel={onCancel}
            userRole={role}
            userId={props.id}
            editMode={props.edit}
            viewMode={showTitle}
            visible={visible}
          />
        )}
      </Drawer>
    </>
  );
};

export default AddUserComponent;
