import { cardItemType, memberItemType } from '../../constant';
import {
  SET_AUTHOR_CURRENT_PAGE,
  SET_AUTHOR_LIST,
  SET_AUTHOR_PAGE_COUNT,
  SET_COURSE_CURRENT_PAGE,
  SET_COURSE_LIST,
  SET_COURSE_PAGE_COUNT,
  SET_CURRENT_LOGIN_USER,
  SET_LOGIN_USER_DATA,
  SET_MEMBER_CURRENT_PAGE,
  SET_MEMBER_DATA,
  SET_MEMBER_LIST,
  SET_MEMBER_LOADING,
  SET_TOTAL_MEMBER_COUNT,
  SET_USER_LIST,
  SET_VIDEO_CURRENT_PAGE,
  SET_VIDEO_LIST,
  SET_VIDEO_PAGE_COUNT,
  SET_WORKSHOP_CURRENT_PAGE,
  SET_WORKSHOP_LIST,
  SET_WORKSHOP_PAGE_COUNT
} from '../types/types';

export function setUserList(data: Array<cardItemType>) {
  return {
    type: SET_USER_LIST,
    payload: data
  };
}
export function setAuthorList(data: Array<any>) {
  return {
    type: SET_AUTHOR_LIST,
    payload: data
  };
}
export function setWorkShopList(data: Array<any>) {
  return {
    type: SET_WORKSHOP_LIST,
    payload: data
  };
}

export function setVideoList(data: Array<any>) {
  return {
    type: SET_VIDEO_LIST,
    payload: data
  };
}

export function setMemberList(data: Array<memberItemType>) {
  return {
    type: SET_MEMBER_LIST,
    payload: data
  };
}

export function setMemberData(data: Array<any>) {
  return {
    type: SET_MEMBER_DATA,
    payload: data
  };
}

export function setMemberLoading(data: boolean) {
  return {
    type: SET_MEMBER_LOADING,
    payload: data
  };
}

export function setTotalMemberCount(data: number) {
  return {
    type: SET_TOTAL_MEMBER_COUNT,
    payload: data
  };
}
export function setAuthorPageCount(data: number) {
  return {
    type: SET_AUTHOR_PAGE_COUNT,
    payload: data
  };
}
export function setWorkShopPageCount(data: number) {
  return {
    type: SET_WORKSHOP_PAGE_COUNT,
    payload: data
  };
}
export function setVideoPageCount(data: number) {
  return {
    type: SET_VIDEO_PAGE_COUNT,
    payload: data
  };
}

export function setMembertCurrentPage(data: number) {
  return {
    type: SET_MEMBER_CURRENT_PAGE,
    payload: data
  };
}
export function setAuthorCurrentPage(data: number) {
  return {
    type: SET_AUTHOR_CURRENT_PAGE,
    payload: data
  };
}
export function setVideoCurrentPage(data: number) {
  return {
    type: SET_VIDEO_CURRENT_PAGE,
    payload: data
  };
}

export function setCurrentLoginUser(data: any) {
  return {
    type: SET_CURRENT_LOGIN_USER,
    payload: data
  };
}

export function setLoginUserData(data: any) {
  return {
    type: SET_LOGIN_USER_DATA,
    payload: data
  };
}
export function setWorkShopCurrentPage(data: number) {
  return {
    type: SET_WORKSHOP_CURRENT_PAGE,
    payload: data
  };
}
export function setCourseCurrentPage(data: number) {
  return {
    type: SET_COURSE_CURRENT_PAGE,
    payload: data
  };
}
export function setCourseList(data: Array<any>) {
  return {
    type: SET_COURSE_LIST,
    payload: data
  };
}
export function setCoursePageCount(data: number) {
  return {
    type: SET_COURSE_PAGE_COUNT,
    payload: data
  };
}
