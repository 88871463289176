import React from 'react';

import { FormOutlined } from '@ant-design/icons';
import './PostInteractions.scss';

interface PostInteractionProps {
  interactions: PostInteraction[];
  onEdit: (interaction: PostInteraction) => void;
}

const PostInteractions = ({ interactions, onEdit }: PostInteractionProps) => {
  const getInteractionsWithPoster = () => {
    let interactionsWithPoster: PostInteraction[] = [];
    const topTherapistInteraction = interactions.find(
      (interaction: PostInteraction) => interaction.type === 'Response' && !interaction.parentId
    );
    if (!topTherapistInteraction) return interactionsWithPoster;

    interactionsWithPoster = interactions.filter(
      (one) => one.parentId === topTherapistInteraction._id
    );
    return [topTherapistInteraction, ...interactionsWithPoster];
  };

  const interactionsWithPoster = getInteractionsWithPoster();

  return (
    <div className="interactions-container">
      {interactionsWithPoster.map((interaction, index) => {
        const userType = interaction.type === 'Response' ? 'THERAPIST' : 'POSTER';
        return (
          <div
            key={index}
            className={`interaction-message ${userType === 'POSTER' ? 'ml-auto' : ''}`}
          >
            <div className="flex items-center">
              <span className={`user-type-tag ${userType.toLowerCase()}`}>{userType}</span>
              {userType === 'THERAPIST' && (
                <FormOutlined
                  className="ml-auto cursor-pointer"
                  onClick={() => onEdit(interaction)}
                />
              )}
            </div>
            <div className="pl-2">
              <p className="mt-2 mb-0">{interaction.body}</p>
              <div>
                {interaction.referralLinks?.map((one) => (
                  <div className="internal-deeplink" key={one.title}>
                    <span>{one.title}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PostInteractions;
