import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';
import {
  createShortAPI,
  deleteShortAPI,
  getPublishedShortsAPI,
  getShortByIdAPI,
  getShortCategoriesAPI,
  updateShortAPI
} from '../../../api/short';
import { ShortStatus, StatusProps } from '../../../constant';
import CrossFormButton from '../../../images/icons/crossFormButton.svg';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { RootState } from '../../../redux/store';
import useVideoPlayerModal from '../../../utils/hooks/useVideoPlayerModal';
import { Mode, Role } from '../../../utils/types/role';
import FileUploader from '../../common/FileUploader';
import { DeleteTabDiv, DrawerFooter, SubmitButton, UserLastRow } from '../../common/styles';
import { showNotificationDrawer } from '../../common/utils';
import { ShortCategory } from '../ShortCategory/ShortCategoriesComponent';
import { Short } from '../ShortsComponent';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;
interface ShortsFormProps {
  userRole: Role;
  onClose: any;
  shortId?: string;
  visible?: boolean;
  viewMode: string;
  onSubmit: () => void;
}

const ShortsForm = (props: ShortsFormProps) => {
  const { shortId, visible, onSubmit } = props;
  const { showFormData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);

  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [activeForm, setActiveForm] = useState<any>({
    thumbnail: '',
    status: 'Draft'
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shortCategories, setShortCategories] = useState<ShortCategory[]>([]);
  const [relatedShorts, setRelatedShorts] = useState<Short[]>([]);
  const [fileError, setFileError] = useState<string>('');

  const { component: videoPlayerComponent, open: openVideoPlayer } = useVideoPlayerModal(
    activeForm.videoLink
  );

  useEffect(() => {
    (async () => {
      const [shortCategories, relatedShorts] = await Promise.all([
        getShortCategoriesAPI(),
        getPublishedShortsAPI()
      ]);

      setShortCategories(shortCategories.data);
      setRelatedShorts(relatedShorts.data);
    })();
  }, []);

  useEffect(() => {
    const activeFormLength = Object.keys(activeForm).length;

    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          title: '',
          videoLink: '',
          thumbnail: '',
          categories: [],
          relatedShorts: [],
          status: 'Draft'
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
      }
    }
    if (shortId && visible) {
      setIsLoading(true);
      getShortByIdAPI(shortId)
        .then((res) => {
          const shortData = res.data;
          const stateData = {
            title: shortData.title,
            videoLink: shortData.videoLink,
            thumbnail: shortData.thumbnail,
            categories: shortData.categories?.map((item) => item._id),
            relatedShorts: shortData?.relatedShorts.map((item) => item._id),
            status: shortData.status
          };
          setActiveForm(stateData);
          form.setFieldsValue(stateData);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [form, shortId, showFormData, visible]);

  const onReset = () => {
    setActiveForm({});
    form.resetFields();
    props.onClose();
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleMultiSelectChange = (key: string, value: any) => {
    setActiveForm({ ...activeForm, [key]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleLinkClick = (text) => {
    openVideoPlayer();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (!shortId) {
      createShortAPI(activeForm)
        .then((res: any) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Short added successfully!');

          onSubmit();
          onReset();
        })
        .catch((err) => {
          setIsLoading(false);
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }

    // Edit
    if (shortId) {
      updateShortAPI(shortId, activeForm)
        .then((res) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Short updated successfully!');

          onSubmit();
          onReset();
        })
        .catch((err) => {
          setIsLoading(false);
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }
  };

  const onChangeThumbnailFile = (url, name) => {
    setActiveForm({ ...activeForm, thumbnail: url, thumbnailFileName: name });
  };

  const handleDeleteShort = (id: string) => {
    setIsLoading(true);
    deleteShortAPI(id)
      .then((res) => {
        setIsLoading(false);
        showNotificationDrawer('Success', 'Short deleted successfully!');

        onSubmit();
        onReset();
      })
      .catch((err) => {
        setIsLoading(false);
        showNotificationDrawer('Error', err?.response?.data?.message);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this short?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteShort(id);
      },
      onCancel() {}
    });
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={handleSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input your title!', whitespace: true }]}
            >
              <Input
                name="title"
                onChange={(e) => onHandleChange(e, 'title')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Categories"
              name="categories"
              className="time-zone-container-s"
              rules={[{ required: true, message: 'Please select category!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue={[]}
                showSearch
                mode="tags"
                onChange={(value) => handleMultiSelectChange('categories', value)}
              >
                {shortCategories?.map((data: any) => (
                  <Option key={data._id} value={data._id}>
                    {data.name} - {data.level.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Related Shorts"
              name="relatedShorts"
              rules={[
                {
                  required: false,
                  message: 'Please select related shorts!'
                }
              ]}
              className="time-zone-container-s"
            >
              <Select
                disabled={props.viewMode === 'View'}
                mode="multiple"
                onChange={(value) => handleMultiSelectChange('relatedShorts', value)}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                filterOption={(input, option) => {
                  if (option) {
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }
                  return true;
                }}
                style={{ pointerEvents: 'auto' }}
              >
                {relatedShorts?.map((one) => (
                  <Option key={one._id} value={one._id}>
                    {one?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              className="no-boder"
              rules={[{ required: true, message: 'Please select your User Status!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue="Draft"
                onChange={(value) => handleChange('status', value)}
              >
                {ShortStatus.map((data: StatusProps, index: number) => (
                  <Option key={index} value={data.label}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Video Link"
              name="videoLink"
              className="no-boder"
              rules={[{ required: true, message: 'field is required' }]}
              style={{ pointerEvents: 'auto' }}
            >
              {props.viewMode === 'View' ? (
                <span
                  onClick={() => handleLinkClick(activeForm.video)}
                  style={{ pointerEvents: 'auto', cursor: 'pointer', color: '#2a8ff7' }}
                >
                  {activeForm.video}
                </span>
              ) : (
                <Input
                  name="videoLink"
                  placeholder="https://example.com"
                  pattern="https://.*"
                  onChange={(e) => onHandleChange(e, 'videoLink')}
                />
              )}
            </Form.Item>

            <Form.Item
              label="Upload Thumbnail"
              name="thumbnail"
              className="no-boder"
              rules={[{ required: true, message: 'Please upload thumbnail' }]}
              style={{ pointerEvents: 'auto' }}
            >
              <FileUploader
                mode={props.viewMode}
                onChange={onChangeThumbnailFile}
                avatarImage={activeForm?.thumbnail}
                errorMessage={fileError}
                onlyImageAccept
                previewVideo
                limit={1024 * 1024 * 2} // limit file size to 2 MB
                resize
              />
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isLoading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isLoading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          )}
          {props.viewMode === 'Edit'
            ? props.visible && (
                <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
                  Save
                </SubmitButton>
              )
            : props.viewMode === 'Create'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
                    Submit
                  </SubmitButton>
                )
              : null}

          {props.userRole === Role.Admin && shortId && props.viewMode === 'Edit' ? (
            <DeleteTabDiv
              style={{ marginLeft: '20px', marginTop: '10px' }}
              className=""
              onClick={() => showDeleteConfirm(shortId)}
            >
              <DeleteOutlined />
            </DeleteTabDiv>
          ) : null}
        </Form.Item>
      </DrawerFooter>
      {videoPlayerComponent}
    </Form>
  );
};

export default ShortsForm;
