import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Modal, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import {
  deleteBroadcastMessage,
  patchBroadcastMessage,
  postBroadcastMessage
} from '../../api/broadcastMessage';
import { DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { dateFormat, showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;

interface BroadcastMessageFormProps {
  permission: 'view' | 'edit' | 'create';
  onClose: () => void;
  fetchData: () => void;
  currentMessage?: BroadcastMessage;
}

const BroadcastMessageForm: React.FC<BroadcastMessageFormProps> = ({
  permission,
  onClose,
  fetchData,
  currentMessage
}) => {
  const [form] = Form.useForm();
  const [activeForm, setActiveForm] = useState<BroadcastMessageFormState>({
    title: '',
    description: '',
    link: '',
    priority: 1,
    endDate: moment(),
    status: 'Draft'
  });
  const [isloading, setIsloading] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [minAllowedDate, setMinAllowedDate] = useState(moment());
  const { confirm } = Modal;

  useEffect(() => {
    setMinAllowedDate(moment());
    if (currentMessage) {
      const updatedCurrentMessage: BroadcastMessageFormState = {
        ...currentMessage,
        endDate: moment(currentMessage.endDate)
      };
      setActiveForm(updatedCurrentMessage);
      form.setFieldsValue(updatedCurrentMessage);
    }
    if (permission === 'view') {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [currentMessage, permission]);

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleInput = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onChangeDate = (date) => {
    setActiveForm({ ...activeForm, endDate: date });
  };

  const formStyle: CSSProperties = {};

  const onSubmit = () => {
    if (permission === 'create') {
      setIsloading(true);
      const createData = {
        ...activeForm
      };
      postBroadcastMessage(createData)
        .then((res: any) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Success', 'Broadcast message created successfully!');
          onClose();
          fetchData();
        })
        .catch((err) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    } else if (permission === 'edit') {
      setIsloading(true);
      if (JSON.stringify(activeForm) === JSON.stringify(currentMessage)) {
        showNotificationDrawer('Warning', 'Nothing changed');
        onClose();
      } else {
        patchBroadcastMessage(currentMessage?._id, activeForm)
          .then((res) => {
            setIsloading(false);
            form?.resetFields();
            showNotificationDrawer('Success', 'Broadcast message updated successfully!');
            onClose();
            fetchData();
          })
          .catch((err) => {
            setIsloading(false);
            form?.resetFields();
            showNotificationDrawer('Error', err?.response?.data?.message);
          });
      }
    }
  };

  const showDeleteConfirm = async () => {
    confirm({
      title: 'Do you really want to delete this Broadcast Message?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        try {
          await deleteBroadcastMessage(currentMessage?._id);
          fetchData();
          onClose();
        } catch (err) {
          console.error('Error deleting broadcast message:', err);
        }
      },
      onCancel() {}
    });
  };

  const disabledDate = (current) => current && current < moment().endOf('day');

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form author-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input Title!', whitespace: true }]}
            >
              <Input
                name="title"
                onChange={(e) => onHandleInput(e, 'title')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
                readOnly={isReadOnly}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: 'Please input Description!', whitespace: true }]}
            >
              <Input.TextArea
                name="description"
                onChange={(e) => onHandleInput(e, 'description')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
                readOnly={isReadOnly}
              />
            </Form.Item>
            <Form.Item
              label="Link"
              name="link"
              rules={[
                { required: true, message: 'Please input your link!' },
                { pattern: /^viaapp:\/\/(.){1,}/, message: 'The link must start with viaapp://' }
              ]}
              className="no-boder time-zone-container-s"
            >
              <Input
                name="link"
                placeholder="viaapp://"
                onChange={(e) => onHandleInput(e, 'link')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
                readOnly={isReadOnly}
              />
            </Form.Item>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[
                { pattern: /^\d+$/, message: 'Priority must contain numbers' }
                // { required: true, message: 'Please input Priority!', whitespace: true }
              ]}
            >
              <Input
                name="priority"
                onChange={(e) => onHandleInput(e, 'priority')}
                type="number"
                style={{ borderBottom: '1px solid #E9E9E9' }}
                readOnly={isReadOnly}
              />
            </Form.Item>
            <Form.Item name="endDate" fieldKey="endDate" label="End Date">
              {/* @ts-ignore */}
              <DatePicker
                getPopupContainer={(triggerNode) => triggerNode}
                name="endDate"
                style={{ width: '100%' }}
                format={dateFormat}
                onChange={onChangeDate}
                disabled={isReadOnly}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item name="status" fieldKey="status" label="Status">
              <Select
                onChange={(value) => handleChange('status', value)}
                defaultValue="Draft"
                disabled={isReadOnly}
              >
                <Option value="Draft">Draft</Option>
                <Option value="Published">Published</Option>
                <Option value="Archived">Archived</Option>
              </Select>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        {permission !== 'view' && (
          <Form.Item className="full-row no-border">
            <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
              Submit
            </SubmitButton>
            {permission === 'edit' && (
              <SubmitButton
                htmlType="button"
                type="primary"
                onClick={() => {
                  showDeleteConfirm();
                }}
              >
                <DeleteOutlined key="delete" /> Delete
              </SubmitButton>
            )}
          </Form.Item>
        )}
      </DrawerFooter>
    </Form>
  );
};

export default BroadcastMessageForm;
