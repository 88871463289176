import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { resetPassword } from '../../api/login';
import { ResetPasswordProps } from '../../constant';
import { passwordRegex } from './formValidator';
import {
  ErrorContainer,
  ForgetButtonContainer,
  InputFieldsContainer,
  LoginButton,
  LoginContainer,
  LogoContainer
} from './styles';
// import SiteBlackLogo from "../../images/autism_logo_black.svg";
import SiteLogo from '../../images/autism_360_white_new_logo.png';
import SiteBlackLogo from '../../images/icons/logo-text.png';
import { checkUserAuth } from '../../utils/api';

const ResetPassword = () => {
  const { token } = useParams();

  const [resetDetail, setResetDetail] = useState<ResetPasswordProps>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const history = useHistory();

  const handlescreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handlescreenSize);
    if (checkUserAuth()) {
      history.push('/dashboard');
    }
  }, [history]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setResetDetail({ ...resetDetail, [name]: value });
    setErrorMessage('');
  };

  const onSubmitClick = () => {
    setErrorMessage('');
    setLoading(true);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    resetPassword({ password: resetDetail.password }, headers)
      .then((res) => {
        setLoading(false);
        history.push('/password-confirmed');
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <LoginContainer>
        <LogoContainer>
          <div className="Signin-container">
            <div className="Background-rectangle-image">
              <div className="RectangleImage" />
            </div>
            <div className="sideContainer">
              <div className="sideMenu">
                <div className="Signin-image">
                  <div className="LogoIcons">
                    <img
                      src={screenSize < 992 ? SiteLogo : SiteBlackLogo}
                      style={{ width: '150px' }}
                    />
                  </div>
                  <div className="welcomeText">
                    <div className="SignInText"> Reset Your </div>
                    <div className="autismText"> Password </div>
                    <div className="credentialsText">
                      {' '}
                      Enter and confirm your new password <br /> to re-set access to your account.{' '}
                    </div>
                  </div>
                </div>
                <Spin spinning={isLoading}>
                  <Form id="loginForm" size="large" layout="vertical" onFinish={onSubmitClick}>
                    <InputFieldsContainer style={{ marginTop: '17px' }}>
                      <Form.Item
                        label="New Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input new password!',
                            whitespace: true
                          },
                          {
                            pattern: new RegExp(passwordRegex),
                            message:
                              'Minimum 8 characters with at least 1 digit and 1 uppercase letter!'
                          }
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter new Password"
                          name="password"
                          onChange={onChange}
                          className="InputFieldsContainer"
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          disabled={!!isLoading}
                        />
                      </Form.Item>
                    </InputFieldsContainer>
                    <InputFieldsContainer>
                      <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please input confirm password!'
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                'Password & confirmation password does not match!'
                              );
                            }
                          })
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter confirm Password"
                          name="confirmPassword"
                          className="InputFieldsContainer"
                          onChange={onChange}
                          iconRender={(visible) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                          }
                          disabled={!!isLoading}
                        />
                      </Form.Item>
                    </InputFieldsContainer>
                    {errorMessage !== '' ? <ErrorContainer>{errorMessage}</ErrorContainer> : null}
                    <ForgetButtonContainer className="MainLoginButton">
                      <LoginButton
                        className="LoginButton"
                        type="primary"
                        htmlType="submit"
                        disabled={!!isLoading}
                      >
                        Submit
                      </LoginButton>
                    </ForgetButtonContainer>
                  </Form>
                </Spin>
              </div>
            </div>
          </div>
        </LogoContainer>
      </LoginContainer>
    </div>
  );
};

export default ResetPassword;
