import { Spin, Switch, Typography } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getClientBinFiles, restoreClientFiles } from '../api/clientFiles';
import { useCustomerId, useMemberId } from '../utils/hooks/useCustomerId';
import ClientFilesTableComponent from './TableData/ClientFilesTableComponent';

export interface IClientFilesBinComponentProps {}

export default function ClientFilesBinComponent(props: IClientFilesBinComponentProps) {
  const queryClient = useQueryClient();
  const clientId = useCustomerId();
  const memberId = useMemberId();
  const [showAll, setShowAll] = useState(false);
  // const { userLocalData } = useSelector<RootState, CustomerState>((state) => state.customerRuducer);
  // const clientId = loginUser?.type === Role.Customer ? loginUser?._id : (userLocalData as any)._id;
  // const [loginUser, setLoginUser] = React.useState<any>();

  const fetchBinFilesQuery = useQuery<{ data: ClientFile[]; count: number }>(
    ['client-bin-files', clientId, memberId, showAll],
    () => getClientBinFiles(clientId, !showAll ? memberId : null),
    {
      enabled: Boolean(clientId && memberId)
    }
  );
  const restoreMutation = useMutation(['client-bin-files-restore', clientId], (idArray: string[]) =>
    restoreClientFiles(clientId, idArray)
  );

  const restore = async (selectedIds: string[]) => {
    const res = await restoreMutation.mutateAsync(selectedIds);
    // await fetchFileList();
    queryClient.invalidateQueries(['client-bin-files', clientId]);
  };
  const files = fetchBinFilesQuery.data?.data || [];
  return (
    <div className="resource-mobile-view-container">
      <Spin spinning={fetchBinFilesQuery.isLoading}>
        <div className="site-layout-background">
          <div className="space-y-5 py-12">
            <div className="flex items-center mb-3">
              <Typography.Title level={2} className="px-3 !mb-0">
                Bin
              </Typography.Title>
              <div className="rounded-full p-1 bg-gray-200 mx-3">
                <Switch checked={showAll} onChange={(checked) => setShowAll(checked)} />
                <span className="mx-2">Show All Deleted Files</span>
              </div>
            </div>
            <ClientFilesTableComponent fileList={files} onRestore={restore} />
          </div>
        </div>
      </Spin>
    </div>
  );
}
