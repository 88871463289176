import {
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  OpenAIOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin,
  TimePicker,
  Typography
} from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import _ReactPlayer, { ReactPlayerProps } from 'react-player';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import { getSelectedMemberDashboardApi } from '../../api/customer';
import { postNotificationApi } from '../../api/notification';
import {
  addSessionNoteApi,
  deletedSessionNoteApi,
  generateAISessionNoteApi,
  getSessionApi,
  getSessionNoteDetailsApi,
  getSessionRecordingURLForPlayer,
  updateSessionNote
} from '../../api/session';
import { listUserApi } from '../../api/user';
import { getGoalsApi } from '../../api/user-program';
import { dashBoardFilterByDateType, userMemberDataType } from '../../constant';
import BlackStarIcon from '../../images/icons/BlackStar.svg';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import EditIcon from '../../images/icons/EditIcon.svg';
import SparklesIcon from '../../images/icons/sparkles.svg';
import StarIcon from '../../images/icons/Star.svg';
import { setCustomerInfo, setCustomerLoading } from '../../redux/actions/customerAction';
import { setNotificationAlert } from '../../redux/actions/notificationAction';
import { setSessionData } from '../../redux/actions/sessionAction';
import { setAssigneeList, setMemberGoals } from '../../redux/actions/userProgramAction';
import { Role } from '../../utils/types/role';
import { getUniqueUsersByMerge } from '../../utils/users';
import AddTasks from '../AddUser/AddTasks';
import RichTextComponent from '../common/RichTextComponent';
import {
  DeleteTabDiv,
  DrawerFooter,
  MultipleFileDiv,
  StarRatingDiv,
  SubmitButton,
  UserLastRow
} from '../common/styles';
import { dateFormat, formattedDate, formattedTime, showNotificationDrawer } from '../common/utils';
import { ProgramRole } from '../MyGoalsComponent';
import FieldBox from './FieldBox';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const { Option } = Select;
const { Title, Text } = Typography;
// const DateTimeFormat = `YYYY-MM-DD HH:mm a`;

const CancellationTypeList = ['Yes', 'No', 'Not sure'];
const rating = [1, 2, 3, 4, 5];

interface GoalFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  sessionId?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  userMemberData: userMemberDataType;
  customerSelectedMember?: any;
  memberGoals?: any;
  programSkills?: any;
  programComlexityList?: any;
  targetSkillList?: any;
  customerDataList?: any;
  loginUserData?: any;
  currentLoginUser?: any;
  visibly?: any;
  form?: any;
  refreshSessionNotes?: boolean;
  activeForm?: any;
  setActiveForm?: any;
  relatedTasks?: any;
  setrelatedTasks?: any;
  setisTouched?: any;
  isTouched?: boolean;
}

const SessionForm: React.FC<GoalFormProps> = (props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [addTaskModal, setaddTaskModal] = useState<boolean>(false);
  const [editTaskOpen, setEditTaskOpen] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string>('');
  const [chooseKey, setKey] = useState<string>('Active');
  const [viewTaskForm, setViewTaskForm] = useState<string>('');
  const [taskSelected, setTaskSelected] = useState<any>({});
  const [dateTime, setDateTime] = useState<any>({
    start_Date: moment(),
    start_Time: moment(),
    end_Date: moment().add(1, 'hours'),
    end_Time: moment().add(1, 'hours')
  });
  const [showSessionVideo, setShowSessionVideo] = useState(false);
  const [sessionRecordingURL, setSessionRecordingURL] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const showAI = searchParams.get('AI') === 'true';
  const [showAIDraftData, setShowAIDraftData] = useState(showAI);
  const [aiDraftData, setAIDraftData] = useState<any>(null);
  const [isAiAdded, setIsAiAdded] = useState(false);
  const [isAiGenerating, setIsAiGenerating] = useState(false);
  const [sessionNoteUpdated, setSessionNoteUpdated] = useState({});

  const reloadSessionNote = () => setSessionNoteUpdated({});

  const { form } = props;
  const {
    dispatch,
    visibly,
    refreshSessionNotes,
    setisTouched,
    isTouched,
    memberGoals,
    loginUserData,
    currentLoginUser,
    sessionId,
    userRole,
    visible,
    customerSelectedMember,
    viewMode,
    userMemberData,
    customerDataList,
    activeForm,
    setActiveForm,
    relatedTasks,
    setrelatedTasks
  } = props;
  const getSessionFromLocalStorage = (sessionId: string) => {
    let sessions = localStorage.getItem('sessionData') as any;
    try {
      sessions = JSON.parse(sessions);
      return sessions.find((x) => x.id === Number(sessionId));
    } catch (err) {
      return null;
    }
  };

  const selectedGoal = memberGoals.filter((goal) => goal._id === sessionId);
  const selectedGoalInfo = selectedGoal && selectedGoal[0];

  const defaultDate = dashBoardFilterByDateType[dashBoardFilterByDateType.length - 1];
  const [currentSelectedTaskChecked, setCurrentSelectedTaskChecked] = useState(false);
  const PAGE_SIZE: number = 50;

  const role = Role.Business;
  const show = 'Active';
  const fetchList = useCallback((searchTerm: string = '', skip: number = 0) => {
    const options = {
      filter: show,
      search: searchTerm.toLowerCase(),
      skip: skip || 0,
      limit: PAGE_SIZE
    };
    return listUserApi(role, options);
  }, []);

  useEffect(() => {
    let dataList = [loginUserData, currentLoginUser];
    // In case of customer, loginUserData and currentLogainUser are same, remove duplication...
    if (currentLoginUser.type === Role.Customer) dataList = [currentLoginUser];

    fetchList('').then((res) => {
      // setTeamMember(res.data)
      dataList = getUniqueUsersByMerge(dataList, res.data);
      dispatch(setAssigneeList(dataList));
    });
    if (viewMode === 'Create' && !sessionId) {
      const createData = {
        type: userRole,
        endtimeCancellation: false,
        sessionRecorded: false,
        isDeleted: false,
        rateSession: 0,
        clientEngagement: 0,
        topWins: [''],
        challenges: [''],
        about: '',
        title: '',
        videoFile: '',
        internalNotes: '',
        start_Date: moment(),
        start_Time: moment(),
        end_Date: moment().add(1, 'hours'),
        end_Time: moment().add(1, 'hours'),
        _id: Math.random()
      };
      form.setFieldsValue(createData);
      setActiveForm(createData);
    }
    if (sessionId && visible) {
      setIsAiAdded(false);
      if (sessionId?.startsWith('draft')) {
        setLoading(true);
        const data = getSessionFromLocalStorage(sessionId.split('-')[1]);

        if (!data) {
          setLoading(false);
          return;
        }
        const sessionNote = {
          ...data,
          sessionRecorded: data.sessionRecorded,
          // to prevent app from crashing (date.clone error),
          // we wrap these values in moment and save in form
          start_Date: moment(data.startDate).isValid() ? moment(data.startDate) : null,
          start_Time: moment(data.startTime).isValid() ? moment(data.startTime) : null,
          end_Date: moment(data.endDate).isValid() ? moment(data.endDate) : null,
          end_Time: moment(data.endTime).isValid() ? moment(data.endTime) : null
        };

        const dateTime = {
          start_Date: moment(data.startDate).isValid() ? moment(data.startDate) : null,
          start_Time: moment(data.startTime).isValid() ? moment(data.startTime) : null,
          end_Date: moment(data.endDate).isValid() ? moment(data.endDate) : null,
          end_Time: moment(data.endTime).isValid() ? moment(data.endTime) : null
        };

        // form.setFieldsValue(dateTime)
        form.setFieldsValue(sessionNote);
        setDateTime(dateTime);
        setActiveForm(sessionNote);
        setrelatedTasks(data.relatedTasks);
        setLoading(false);
      } else {
        setLoading(true);
        getSessionNoteDetailsApi(sessionId)
          .then((res) => {
            const { data } = res;
            if (!relatedTasks?.length) {
              setrelatedTasks(data.relatedTasks);
            }
            if (data.relatedTasks?.length > relatedTasks?.length) {
              setrelatedTasks(data.relatedTasks);
            }
            const { draftData, status } = data;
            const sessionNote = {
              ...data,
              sessionRecorded: data.sessionRecorded,
              // startTime: moment(data.startTime),
              // endTime: moment(data.endTime),
              start_Date: moment(data.startTime).isValid() ? moment(data.startTime) : null,
              start_Time: moment(data.startTime).isValid() ? moment(data.startTime) : null,
              end_Date: moment(data.endTime).isValid() ? moment(data.endTime) : null,
              end_Time: moment(data.endTime).isValid() ? moment(data.endTime) : null
            };

            if (viewMode === 'Edit' && draftData) {
              try {
                const { about, topWins, challenges } = JSON.parse(draftData);
                setAIDraftData({
                  about,
                  topWins,
                  challenges
                });
              } catch (e) {
                console.log('Error parsing draft data');
              }
            }

            const dateTime = {
              ...data,
              start_Date: moment(data.startTime),
              start_Time: moment(data.startTime),
              end_Date: moment(data.endTime),
              end_Time: moment(data.endTime)
            };

            form.setFieldsValue(dateTime);
            setDateTime(dateTime);
            form.setFieldsValue(sessionNote);
            setActiveForm(sessionNote);
            // setrelatedTasks(sessionNote)
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [dispatch, sessionId, userRole, selectedGoalInfo, viewMode, visible, sessionNoteUpdated]);

  const onCloseDrawer = useCallback(
    (newTask: any) => {
      if (newTask?.data) {
        const taskIndex = relatedTasks.findIndex((a) => a._id === newTask.data._id);
        if (taskIndex === -1) {
          setrelatedTasks([...relatedTasks, newTask.data]);
        } else if (newTask.data.isDeleted) {
          relatedTasks.splice(taskIndex, 1);
          setrelatedTasks(relatedTasks);
        } else {
          relatedTasks.splice(taskIndex, 1, newTask.data);
          setrelatedTasks(relatedTasks);
        }
      }
      setaddTaskModal(false);
    },
    [relatedTasks]
  );
  const onReset = (e: any) => {
    e.stopPropagation();
    // form.resetFields();
    if (props.cancel()) {
      setLoading(false);
      setActiveForm({});
      setrelatedTasks([]);
      setisTouched(false);
    }
  };

  const handleChange = (name: string, value: string) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleChangeRating = (name: string, value: number) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleRichTxt = (value: any) => {
    setActiveForm({ ...activeForm, about: value });
  };

  const onCheckCancellation = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, endtimeCancellation: checked });
  };

  const onCheckRecording = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, sessionRecorded: checked });
  };

  const onChangeDate = (date, dateString) => {
    if (date != null) {
      const data = {
        ...dateTime,
        start_Date: moment(date),
        start_Time: moment(date),
        end_Date: moment(date).add(1, 'hour'),
        end_Time: moment(date).add(1, 'hour')
      };
      setDateTime(data);
      form.setFieldsValue(data);
      setisTouched(true);
    }
  };

  const onChangeLastDate = (date, dateString) => {
    setDateTime({ ...dateTime, end_Date: date });
    // const data = {
    //   end_Time: moment(date),
    // };
    // form.setFieldsValue(data);
    setisTouched(true);
  };

  const onChangeTime = (time) => {
    if (time != null) {
      const data = {
        ...dateTime,
        start_Time: time,
        // end_Date: moment(time).add(1, 'hour'),
        end_Time: moment(time).add(1, 'hour')
      };
      form.setFieldsValue(data);
      setDateTime(data);
      setisTouched(true);
    }
  };

  const onChangeLastTime = (time) => {
    setDateTime({ ...dateTime, end_Time: time });
  };
  const onSubmit = () => {
    const { customerSelectedMember } = props;
    const { start_Date, start_Time, end_Date, end_Time } = dateTime;
    const modify_start_Date = formattedDate(start_Date);
    const modify_start_Time = formattedTime(start_Time);
    const modify_end_Date = formattedDate(end_Date);
    const modify_end_Time = formattedTime(end_Time);

    const startDateTime = moment(`${modify_start_Date} ${modify_start_Time}`);
    const endDateTime = moment(`${modify_end_Date} ${modify_end_Time}`);
    const addData = {
      ...activeForm,
      member: customerSelectedMember[0]._id,
      startTime: startDateTime,
      endTime: endDateTime,
      relatedTasks: []
    };

    const updateData = {
      ...activeForm,
      startTime: startDateTime,
      endTime: endDateTime,
      relatedTasks: relatedTasks.map((item) => item._id)
    };

    const onCancelData = {
      member: customerSelectedMember[0]._id,
      startTime: startDateTime,
      endTime: endDateTime,
      endtimeCancellation: activeForm.endtimeCancellation,
      rescheduleOffered: activeForm.rescheduleOffered,
      relatedTasks: [],
      sessionRecorded: activeForm.sessionRecorded,
      recordingLink: activeForm.recordingLink,
      rateSession: 0,
      clientEngagement: 0,
      topWins: [''],
      challenges: [''],
      about: '',
      title: '',
      videoFile: '',
      internalNotes: ''
    };

    const sendingAddData = activeForm.endtimeCancellation ? onCancelData : addData;
    const sendingUpdateData = activeForm.endtimeCancellation ? onCancelData : updateData;
    sendingAddData.relatedTasks = relatedTasks.map((item) => item._id);
    setLoading(true);
    if (props.sessionId) {
      if (props.sessionId.startsWith('draft')) {
        createNewSessionNote(sendingAddData);
        removeDraftFromLocalStorage(props.sessionId.split('-')[1]);
        return;
      }
      updateSessionNote(props.sessionId, sendingUpdateData)
        .then((res) => {
          if (customerSelectedMember[0]._id) {
            getSessionApi(customerSelectedMember[0]._id).then((res) => {
              dispatch(setSessionData(res.data));
              setLoading(false);
            });
          }
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close(
              'Success',
              `Thank you. Your session is now published and available to ${loginUserData.firstName}`,
              true
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Error', err.response.data.message, true);
          }
        });
    } else {
      createNewSessionNote(sendingAddData);
    }
  };

  const createNewSessionNote = (sendingAddData) => {
    delete sendingAddData.id;
    delete sendingAddData._id;
    addSessionNoteApi(sendingAddData)
      .then((res) => {
        const { data } = res;
        if (customerSelectedMember[0]._id) {
          getSessionApi(customerSelectedMember[0]._id).then((res) => {
            dispatch(setSessionData(res.data));
            setLoading(false);
          });
        }
        const notification = {
          member: `${
            customerSelectedMember.length
              ? customerSelectedMember[0]._id
              : customerSelectedMember._id
          }`,
          businessUser: `${userMemberData.loginUserData._id}`,
          message: `${
            customerDataList && customerDataList.firstName
          }  just shared Session Notes for ${formattedDate(data.createdAt)}`,
          user: `${customerSelectedMember[0].parent}`,
          session: res.data._id,
          type: 'SESSION_ADDED'
        };

        postNotificationApi(notification).then((res) => {
          dispatch(setNotificationAlert(res.data));
        });
        setActiveForm({});
        setrelatedTasks([]);
        form.resetFields();
        if (!isLoading) {
          props.close('Success', 'Session note created successfully!');
        }
      })
      .catch((err) => {
        setLoading(false);
        setActiveForm({});
        form.resetFields();
        if (!isLoading) {
          props.close('Error', err.response.data.message);
        }
      });
  };

  const removeDraftFromLocalStorage = (id) => {
    let drafts = localStorage.getItem('sessionData') as any;
    try {
      drafts = JSON.parse(drafts);
      drafts = drafts.filter((x) => x.id != id);
      localStorage.setItem('sessionData', JSON.stringify(drafts));
    } catch (err) {
      console.log(err);
    }
  };

  const addWinTops = (data: any) => {
    setActiveForm({ ...activeForm, topWins: data });
  };

  const addChallenges = (data: any) => {
    setActiveForm({ ...activeForm, challenges: data });
  };

  const deleteSession = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this session?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setLoading(true);
        deletedSessionNoteApi(id)
          .then((res) => {
            getSessionApi(customerSelectedMember[0]._id).then((res) => {
              dispatch(setSessionData(res.data));
              setLoading(false);
            });
            if (!isLoading) {
              props.close('Success', `${res.message}!`);
            }
          })
          .catch((err) => {
            setLoading(false);
            if (!isLoading) {
              props.close('Error', err.response.data.message);
            }
          });
      },
      onCancel() {}
    });
  };

  const getWinSpecificFields = () => {
    const { topWins = [] } = activeForm;
    return (
      <MultipleFileDiv>
        <Text style={{}}>Enter Top wins this week (if none, put NA)</Text>
        <FieldBox
          fieldLabel=""
          fieldName="topWins"
          fieldMsg="Please input some needed skills"
          SkillList={topWins}
          addData={addWinTops}
          buttonLabel="Add another win"
          viewMode={props.viewMode === 'View'}
        />
      </MultipleFileDiv>
    );
  };

  const getChallengeSpecificFields = () => {
    const { challenges = [] } = activeForm;
    return (
      <MultipleFileDiv>
        <Text style={{}}>Any key challenges to be shared with carer?</Text>
        <FieldBox
          fieldLabel=""
          fieldName="challenges"
          fieldMsg="Please input some needed skills"
          SkillList={challenges}
          addData={addChallenges}
          buttonLabel="Add another challenge"
          viewMode={props.viewMode === 'View'}
          disabled={props.viewMode === 'View'}
        />
      </MultipleFileDiv>
    );
  };
  const getAddTaskButtonInSession = () => {
    const { challenges = [] } = activeForm;
    return (
      <MultipleFileDiv>
        <Text style={{}}>Tasks for this session</Text>
        {relatedTasks?.map((item) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '6px',
              borderBottom: '1px solid rgb(0 0 0 / 8%)',
              lineHeight: '35px'
            }}
          >
            <p
              onClick={() => {
                setaddTaskModal(true);
                setViewTaskForm('View');
                setTaskSelected(item);
              }}
              className="item-name-in-session"
            >
              {item?.name || ''}{' '}
            </p>
            {props.viewMode != 'View' && (
              <p style={{ marginBottom: 0 }}>
                <img
                  src={EditIcon}
                  className="session-edit-icon-overlap"
                  onClick={(e) => {
                    e.stopPropagation(), setaddTaskModal(true);
                    setViewTaskForm('Edit');
                    setTaskSelected(item);
                  }}
                />
              </p>
            )}
          </div>
        ))}
        <div style={{ marginTop: '15px' }}>
          {props.viewMode != 'View' && (
            <Button
              type="dashed"
              onClick={(e) => {
                e.preventDefault();
                close();
                setaddTaskModal(true);
                setTaskSelected({});
                setViewTaskForm('Create');
              }}
              className="add-more-content"
            >
              <PlusOutlined /> Add Task
            </Button>
          )}
        </div>
        {/* <button className="add-task-button-in-session" onClick={(e) => { e.preventDefault(); close(); setaddTaskModal(true); setTaskSelected({}); setViewTaskForm("Create")}}> Add Task </button> */}
      </MultipleFileDiv>
    );
  };
  const getGoalsData = () => {
    if (customerSelectedMember && customerSelectedMember.length && customerSelectedMember[0]._id) {
      getGoalsApi(customerSelectedMember[0]._id).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
      const data = {
        startDate: defaultDate.startDate,
        endDate: defaultDate.endDate
      };
      getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
        const { data } = res;
        dispatch(setCustomerInfo(data));
      });
    }
  };

  // TODO: play in video element rather than download
  const handleRecordingLink = async () => {
    const recordingLink = activeForm?.recordingLink;

    if (props.viewMode === 'View' && recordingLink) {
      try {
        const videoUrl = await getSessionRecordingURLForPlayer(recordingLink);
        setSessionRecordingURL(videoUrl);
        setShowSessionVideo(true);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleSessionRecordingError = (e) => {
    showNotificationDrawer('error', 'Recording not exist');
    setShowSessionVideo(false);
  };

  const toggleAIDraftData = () => {
    setShowAIDraftData((show) => !show);
  };

  const applyAIDraftData = () => {
    setActiveForm({
      ...activeForm,
      about: (activeForm.about ? activeForm.about + '\n' : '') + aiDraftData.about ?? '',
      topWins: [...activeForm.topWins, ...aiDraftData.topWins],
      challenges: [...activeForm.challenges, ...aiDraftData.challenges]
    });
    setIsAiAdded(true);
    // toggleAIDraftData();
  };

  const canGenerateAI =
    props.viewMode === 'Edit' &&
    activeForm.transcript &&
    (activeForm.aiStatus === 'Failed' ||
      (activeForm.aiStatus === 'Pending' &&
        !aiDraftData &&
        Date.now() - new Date(activeForm.updatedAt).getTime() > 24 * 60 * 60 * 1000));
  const handleGenerateAI = async () => {
    try {
      if (isAiGenerating) return;
      setIsAiGenerating(true);
      notification['info']({
        message: 'Generating AI Session Note',
        description:
          'This may take a few minutes. We will email you once it is ready. You can continue working on the form in the meantime.'
      });
      await generateAISessionNoteApi(sessionId);

      notification['success']({
        message: 'AI Session Note generated',
        description:
          'AI Session Note has been generated successfully. You can now add it to the form.'
      });

      // refresh the session note to get ai generated
      reloadSessionNote();
      setShowAIDraftData(true);

      setIsAiGenerating(false);
    } catch (e) {
      console.error(e);
      setIsAiGenerating(false);
      notification['error']({
        message: 'Failed to generate AI Session Note',
        description: 'Please try again later.'
      });
    }
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }
  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
      onClick={(e) => e.stopPropagation()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Title level={5} className="titleStyle">
              SESSION NOTE
            </Title>
            <Row>
              <Col xs={24} sm={12} className="left-select">
                <Text className="form-date-time-text">Start Date & Time</Text>
                <Row className="form-date-time-row">
                  <Col xs={24} sm={13} className="left-select">
                    <Form.Item
                      name="start_Date"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your start date!'
                        }
                      ]}
                      className="time-zone-container-s"
                    >
                      {/* @ts-ignore */}
                      <DatePicker
                        getPopupContainer={(triggerNode) => triggerNode}
                        className="autismag-picker"
                        name="start_Date"
                        style={{ width: '100%' }}
                        format={dateFormat}
                        value={dateTime.start_Date}
                        onChange={onChangeDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11} className="left-select">
                    <Form.Item
                      name="start_Time"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your start time!'
                        }
                      ]}
                      className="session-notes time-zone-container-s"
                    >
                      <TimePicker
                        getPopupContainer={(triggerNode) => triggerNode}
                        showNow={false}
                        className="autismag-picker"
                        allowClear={false}
                        name="start_Time"
                        format="hh:mm A"
                        onChange={onChangeTime}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} className="right-select">
                <Text className="form-date-time-text">End Date & Time</Text>
                <Row className="form-date-time-row">
                  <Col xs={24} sm={13} className="left-select">
                    <Form.Item
                      label=""
                      name="end_Date"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your end date!'
                        }
                      ]}
                      className="session-notes time-zone-container-s"
                    >
                      {/* @ts-ignore */}
                      <DatePicker
                        getPopupContainer={(triggerNode) => triggerNode}
                        className="autismag-picker"
                        name="end_Date"
                        style={{ width: '100%' }}
                        format={dateFormat}
                        allowClear={false}
                        value={dateTime.end_Date}
                        onChange={onChangeLastDate}
                        disabledDate={(value) => {
                          // Can not select days before start time
                          const start =
                            dateTime != null
                              ? dateTime.start_Time != null
                                ? dateTime.start_Time.format('HH.mm')
                                : ''
                              : '';
                          const end =
                            dateTime != null
                              ? dateTime.end_Time != null
                                ? dateTime.end_Time.format('HH.mm')
                                : ''
                              : '';

                          const limitDate = moment(dateTime.start_Date).set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                          });
                          if (start >= end) limitDate.add(1, 'days');
                          return value <= limitDate;
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11} className="left-select">
                    <Form.Item
                      name="end_Time"
                      rules={[
                        {
                          required: true,
                          message: 'Please select your end time!'
                        }
                      ]}
                      className="time-zone-container-s"
                    >
                      <TimePicker
                        getPopupContainer={(triggerNode) => triggerNode}
                        className="autismag-picker"
                        name="end_Time"
                        allowClear={false}
                        format="hh:mm A"
                        onChange={onChangeLastTime}
                        showNow={false}
                        disabledDate={(value) => {
                          // Can not select days before start Date
                          const start =
                            dateTime != null
                              ? dateTime.start_Date != null
                                ? dateTime.start_Date.format('YYYY-MM-DD')
                                : ''
                              : '1';
                          const end =
                            dateTime != null
                              ? dateTime.end_Date != null
                                ? dateTime.end_Date.format('YYYY-MM-DD')
                                : ''
                              : '2';

                          if (start === end) {
                            return (
                              moment(value).format('HH.mm') <
                              moment(dateTime.start_Time).format('HH.mm')
                            );
                          }
                          return false;
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="endtimeCancellation"
              label=""
              className="no-boder"
              rules={[{ required: false, message: 'Please input the skill address!' }]}
            >
              <Checkbox
                className="Check-goal-as-primary"
                checked={activeForm.endtimeCancellation}
                onChange={onCheckCancellation}
              >
                <label>Is this a No Show or last minute client cancellation</label>
              </Checkbox>
            </Form.Item>

            {!activeForm.endtimeCancellation && (
              <>
                <Form.Item
                  label={"What was today's session title"}
                  name="title"
                  className="no-boder"
                  rules={[
                    {
                      required: false,
                      message: 'Please input add title today session!',
                      whitespace: true
                    }
                  ]}
                >
                  <Input
                    name="title"
                    value={activeForm.title}
                    style={{
                      borderBottom: '1px solid #E9E9E9',
                      pointerEvents: 'auto',
                      color: '#1D1D2D'
                    }}
                    onChange={onHandleChange}
                    disabled={viewMode === 'View'}
                  />
                </Form.Item>
                <div className="flex gap-6 flex-col md:flex-row mb-4">
                  {aiDraftData && showAIDraftData && (
                    <div className="grow basis-1/2 p-4 border border-solid border-purple-300 shadow-lg rounded-lg">
                      <h4 className="flex items-center text-xl font-bold">
                        <OpenAIOutlined className="mr-1" /> AI Generated:
                        <Button
                          type="text"
                          shape="round"
                          className="ml-auto !text-purple-400"
                          onClick={applyAIDraftData}
                          icon={<PlusOutlined />}
                          disabled={isAiAdded}
                        >
                          Add
                        </Button>
                        <Button
                          type="text"
                          shape="circle"
                          className=""
                          onClick={toggleAIDraftData}
                          icon={<CloseCircleOutlined />}
                        />
                      </h4>
                      <div>
                        <p
                          dangerouslySetInnerHTML={{ __html: aiDraftData.about || 'Empty Notes' }}
                        />
                        <hr className="border-0 border-b border-solid border-gray-100" />
                        <div>
                          {aiDraftData.topWins?.length > 0 && (
                            <>
                              <b>Top Wins:</b>
                              <ul>
                                {aiDraftData.topWins.map((win) => (
                                  <li>{win}</li>
                                ))}
                              </ul>
                            </>
                          )}
                          {aiDraftData.challenges?.length > 0 && (
                            <>
                              <b>Challenges:</b>
                              <ul>
                                {aiDraftData.challenges.map((challenge) => (
                                  <li>{challenge}</li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="grow basis-1/2">
                    <Form.Item
                      label={
                        <>
                          {!showAIDraftData && aiDraftData && activeForm.aiStatus !== 'Failed' && (
                            <Button
                              type="text"
                              shape="circle"
                              className="mr-2 !text-purple-400"
                              onClick={toggleAIDraftData}
                              icon={<OpenAIOutlined />}
                            />
                          )}
                          <div>
                            What was today's session about
                            {canGenerateAI && (
                              <>
                                <br />
                                <small className="text-gray-400 font-normal">
                                  Click the AI icon on the right to generate AI session note.
                                </small>
                              </>
                            )}
                          </div>
                          {canGenerateAI && (
                            <Button
                              type="text"
                              shape="circle"
                              loading={isAiGenerating}
                              icon={<img width="24" src={SparklesIcon} />}
                              onClick={handleGenerateAI}
                              className="ml-1 ai-session-button border-none"
                            />
                          )}
                        </>
                      }
                      name="about"
                      className="no-boder"
                      rules={[
                        {
                          required: false,
                          message: 'Please input add about today session!',
                          whitespace: true
                        }
                      ]}
                    >
                      <RichTextComponent
                        className="rich-text-div session-form-rich-text"
                        onChange={onHandleRichTxt}
                        data={activeForm.about ?? ''}
                        disabled={viewMode === 'View'}
                      />
                    </Form.Item>

                    {getWinSpecificFields()}
                    {getChallengeSpecificFields()}
                  </div>
                </div>
                {getAddTaskButtonInSession()}
                {customerDataList.type !== 'Customer' && (
                  <>
                    <Form.Item
                      name="rateSession"
                      label="Overall Session Rating provided by Coach?"
                      rules={[
                        {
                          required: false,
                          message: 'Please rate the session!'
                        }
                      ]}
                    >
                      <StarRatingDiv>
                        {rating.map((rate) => {
                          const activeStar = rate <= (activeForm.rateSession || 0) ? 'active' : '';
                          return (
                            <span
                              className={`rating-icon ${activeStar}`}
                              onClick={() => handleChangeRating('rateSession', rate)}
                            >
                              {activeStar ? <img src={StarIcon} /> : <img src={BlackStarIcon} />}{' '}
                            </span>
                          );
                        })}
                      </StarRatingDiv>
                    </Form.Item>

                    <Form.Item
                      name="clientEngagement"
                      label="Overall Session Rating provided by Client"
                      rules={[
                        {
                          required: false,
                          message: 'Please rate the Engaged of the client!'
                        }
                      ]}
                    >
                      <StarRatingDiv>
                        {rating.map((rate) => {
                          const activeStar =
                            rate <= (activeForm.clientEngagement || 0) ? 'active' : '';
                          return (
                            <span className={`rating-icon ${activeStar}`}>
                              {activeStar ? <img src={StarIcon} /> : <img src={BlackStarIcon} />}
                            </span>
                          );
                        })}
                      </StarRatingDiv>
                    </Form.Item>
                  </>
                )}
              </>
            )}

            <Form.Item
              name="sessionRecorded"
              label=""
              className="no-boder"
              rules={[
                {
                  required: false,
                  message: 'Please input the skill address!'
                }
              ]}
            >
              <Checkbox
                className="Check-goal-as-primary"
                checked={activeForm.sessionRecorded}
                onChange={onCheckRecording}
              >
                This session was recorded
              </Checkbox>
            </Form.Item>

            {activeForm.sessionRecorded && (
              <Form.Item
                label="Record Link"
                name="recordingLink"
                rules={[
                  {
                    required: true,
                    message: 'Please add recording link!',
                    whitespace: true
                  }
                ]}
              >
                <Input
                  name="recordingLink"
                  value={activeForm?.recordingLink}
                  readOnly={viewMode === 'View'}
                  onChange={onHandleChange}
                  style={{
                    pointerEvents: 'auto',
                    cursor: 'pointer',
                    borderBottom: '1px solid #E9E9E9'
                  }}
                  placeholder="https://example.com"
                  pattern="https://.*"
                  onClick={handleRecordingLink}
                />
              </Form.Item>
            )}

            {!activeForm.endtimeCancellation && currentLoginUser.type === 'BusinessUser' && (
              <Form.Item label="Internal Notes" name="internalNotes" className="no-boder">
                <Input.TextArea
                  name="internalNotes"
                  rows={4}
                  value={activeForm.internalNotes}
                  onChange={onHandleChange}
                />
              </Form.Item>
            )}

            {activeForm.endtimeCancellation && (
              <Form.Item
                label="Reschedule"
                name="rescheduleOffered"
                rules={[{ required: true, message: 'Please select if resceduled!' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue=""
                  onChange={(value) => handleChange('rescheduleOffered', value)}
                >
                  {CancellationTypeList &&
                    CancellationTypeList.map((data: any, index: number) => (
                      <Option key={index} value={data}>
                        {data}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </UserLastRow>
        <DrawerFooter className="drawer-footer-style drawerButton">
          <Form.Item className="no-boder">
            {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            )}
            {props.viewMode === 'Edit'
              ? props.visible && (
                  <>
                    <SubmitButton className="form1-submit-buttton" htmlType="submit" type="primary">
                      Publish
                    </SubmitButton>
                  </>
                )
              : props.viewMode === 'Create'
                ? props.visible && (
                    <SubmitButton htmlType="submit" className="form1-submit-buttton" type="primary">
                      Publish
                    </SubmitButton>
                  )
                : null}

            {currentLoginUser.type === Role.Admin && sessionId ? (
              <DeleteTabDiv onClick={() => deleteSession(sessionId)}>
                <DeleteOutlined style={{ color: 'red' }} />
              </DeleteTabDiv>
            ) : null}
          </Form.Item>
        </DrawerFooter>
        <AddTasks
          getGoalsData={getGoalsData}
          onCloseDrawer={onCloseDrawer}
          user={Role.Customer}
          goalFormType={ProgramRole.MyTasks}
          chooseForm={viewTaskForm}
          propVisible={addTaskModal}
          formTypeLabel="Tasks"
          notifyVisible
          addButtonLabel="Add Task"
          isSession
          taskId={taskSelected._id}
          taskData={taskSelected}
        />
        {activeForm?.recordingLink && (
          <Modal
            visible={showSessionVideo}
            onCancel={() => {
              setShowSessionVideo(false);
            }}
            width={1024}
            centered
            bodyStyle={{
              padding: 0
            }}
            destroyOnClose
            footer={null}
            closeIcon={<></>}
          >
            <ReactPlayer
              url={sessionRecordingURL}
              controls
              width="100%"
              height="100%"
              className="video-player"
            />
          </Modal>
        )}
      </Spin>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    memberGoals: state.programRuducer.memberGoals,
    programSkills: state.programRuducer.programSkills,
    programComlexityList: state.programRuducer.programComlexityList,
    targetSkillList: state.programRuducer.targetSkillList,
    customerDataList: state.notificationReducer.customerDataList,
    loginUserData: state.userMemberData.loginUserData,
    currentLoginUser: state.userMemberData.currentLoginUser,
    visibly: state.notificationReducer.visible,
    refreshSessionNotes: state.sessionReducer.refreshSessionNotes
  };
}

export default connect(mapStateToProps)(SessionForm);
