import { Input, Modal, Select, Table, TableColumnType } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAssets } from '../../../api/search';
import useDebounce from '../../../utils/hooks/useDebounce';

import { ShareAltOutlined } from '@ant-design/icons';
import { ColDiv, SubmitButton } from '../../common/styles';
import './PostReferralLinkModal.scss';

interface PostReferralLinkModalProps {
  isOpened: boolean;
  onClose: () => void;
  onAddReferralLink: (referralLinks: PostFormState['referralLinks']) => void;
}

interface Asset {
  _id: string;
  title: string;
  internalDeepLink: string;
  category: string;
}

const dailyDiaryInternalDeepLink = 'viaapp://diary';

const PostReferralLinkModal = ({
  isOpened,
  onClose,
  onAddReferralLink
}: PostReferralLinkModalProps) => {
  const [category, setCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [assets, setAssets] = useState([]);
  const [selectedRows, setSelectedRows] = useState<Array<Partial<Asset>>>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const columns: TableColumnType<any>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 400
    },
    {
      title: 'Deep Link',
      dataIndex: 'internalDeepLink',
      key: 'internalDeepLink',
      width: 200
    },
    {
      title: 'Category',
      key: 'category',
      width: 100,
      render: (_, record) => {
        return <ColDiv className="capitalize">{record.category}</ColDiv>;
      }
    }
  ];

  useEffect(() => {
    setSelectedRows([]);
  }, [isOpened]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(/[^\w\s]/g, '');
    setSearchTerm(value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);

    if (value === 'dailydiary')
      setSelectedRows([{ title: 'Daily Diary', internalDeepLink: dailyDiaryInternalDeepLink }]);
    else {
      setSelectedRows([]);
      setPagination({ ...pagination, current: 1 });
    }
  };

  const handlePaginationChange = (pagination) => {
    setPagination(pagination);

    fetchAssets(pagination);
  };

  const fetchAssets = (pagination) => {
    getAssets({
      category,
      search: debouncedSearchTerm,
      page: pagination.current,
      pageSize: pagination.pageSize
    }).then((res) => {
      setAssets(res.data);
      setPagination({ ...pagination, total: res.count });
    });
  };
  useEffect(() => {
    fetchAssets(pagination);
  }, [debouncedSearchTerm, category]);

  const onSelectChange = (selectedKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleAddReferralLink = () => {
    onAddReferralLink(
      selectedRows.map((row: any) => ({
        title: row.title,
        internalDeepLink: row.internalDeepLink
      }))
    );
  };

  return (
    <Modal
      visible={isOpened}
      onCancel={onClose}
      width="80%"
      className="post-referral-link-modal-container"
      footer={null}
    >
      <div className="w-full flex mt-8 gap-8 items-center internal-deeplink-container">
        <Select onChange={handleCategoryChange} value={category} className="w-[400px]">
          <Select.Option value="">All</Select.Option>
          <Select.Option value="course">Course</Select.Option>
          <Select.Option value="video">Video</Select.Option>
          <Select.Option value="resource">Resource</Select.Option>
          <Select.Option value="workshop">Workshop</Select.Option>
          <Select.Option value="blog">Blog</Select.Option>
          <Select.Option value="dailydiary">Daily Diary</Select.Option>
        </Select>
        {category !== 'dailydiary' ? (
          <Input
            name="search"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Search Referral Link"
          />
        ) : (
          <span className="internal-deeplink">{dailyDiaryInternalDeepLink}</span>
        )}
      </div>
      {category !== 'dailydiary' && (
        <div className="mt-6">
          <Table
            columns={columns}
            dataSource={assets}
            onChange={handlePaginationChange}
            pagination={pagination}
            rowSelection={{
              onChange: onSelectChange,
              selectedRowKeys: selectedRows.map((row: any) => row._id)
            }}
            rowKey="_id"
          />
        </div>
      )}
      <div className="flex justify-center">
        <SubmitButton
          onClick={handleAddReferralLink}
          type="primary"
          icon={<ShareAltOutlined />}
          disabled={!selectedRows.length}
        >
          Add
        </SubmitButton>
      </div>
    </Modal>
  );
};

export default PostReferralLinkModal;
