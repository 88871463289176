import { PlusOutlined } from '@ant-design/icons';
import { Drawer, Form, Select, Spin } from 'antd';
import moment from 'moment';
import React, { CSSProperties, ReactChild, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  createMemberApi,
  listMemberApi,
  membersPaginationApi,
  updateMemberApi
} from '../../api/member';
import { Role } from '../../utils/types/role';
import { AddButton, DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { MemberList } from '../MemberList/MemberList';

import CrossFormButton from '../../images/icons/crossFormButton.svg';
import {
  setMemberData,
  setMemberLoading,
  setMembertCurrentPage,
  setTotalMemberCount
} from '../../redux/actions/userMemberAction';
import { showNotificationDrawer } from '../common/utils';

interface AddUserProps {
  user: Role;
  icon?: ReactChild;
  memberId?: string;
  editMode?: boolean;
  chooseForm?: string;
  onCloseDrawer?: () => void;
  memberData?: any;
  dispatch?: any;
  member?: any;
  customerList?: any;
  visible?: boolean;
  setVisible?: any;
  activekey?: any;
}

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;

const AddMemberComponent: React.FC<AddUserProps> = (props) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [memberItem, setMemberItem] = useState<any>([]);
  const [parentData, setParentData] = useState<any>({});
  const { dispatch, chooseForm, memberId, member, customerList, visible, setVisible, activekey } =
    props;

  const [form] = Form.useForm();

  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  const closeDrawer = (title, content) => {
    setVisible(false);
    openNotification(title, content);

    if (props.onCloseDrawer) {
      props.onCloseDrawer();
    }
  };

  const fetchMemberList = useCallback((pageSize: number = 0, skip: number = 0, filter: string) => {
    const options = {
      skip: skip || 0,
      limit: pageSize,
      filter
    };

    return membersPaginationApi(options);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      parent: member?.parent
        ? `${member?.parent?.firstName || ''} ${member?.parent?.lastName || ''}`
        : ''
    });
    if (!chooseForm) {
      setInitialMember();
    }
    if (memberId) {
      setParentData({});
      setInitialMember();
      if (member) {
        const membersData = {
          ...member,
          dob: moment(member.dob).isValid() ? moment(member.dob) : null,
          memberShipStart: moment(member.memberShipStart).isValid()
            ? moment(member.memberShipStart)
            : null,
          memberShipEnd: moment(member.memberShipEnd).isValid()
            ? moment(member.memberShipEnd)
            : null
        };
        setParentData(membersData.parent);
        setMemberItem([membersData]);
        setLoading(false);
      } else {
        listMemberApi(memberId)
          .then((res) => {
            const data = res;
            const membersData = {
              ...data,
              dob: moment(data.dob).isValid() ? moment(data.dob) : null,
              memberShipStart: moment(data.memberShipStart).isValid()
                ? moment(data.memberShipStart)
                : null,
              memberShipEnd: moment(data.memberShipEnd).isValid()
                ? moment(data.memberShipEnd)
                : null
            };
            setParentData(membersData.parent);
            setMemberItem([membersData]);
          })
          .catch((err) => {
            console.log('Error caught!', err.message);
          })
          .finally(() => setLoading(false));
      }
    }
  }, [memberId, chooseForm, member?.updatedAt]);

  const getMemberList = () => {
    fetchMemberList(10, 0, activekey).then((res) => {
      const { data, count } = res;
      dispatch(setMembertCurrentPage(1));
      dispatch(setMemberData(data));
      dispatch(setTotalMemberCount(count));
      dispatch(setMemberLoading(false));
      setMemberItem([
        {
          parent: null,
          firstName: '',
          lastName: '',
          dob: '',
          memberShipStart: moment(),
          memberShipEnd: '',
          listingType: '',
          listingIdentifier: '',
          formRef: null
        }
      ]);
    });
  };

  const setInitialMember = () => {
    setMemberItem([
      {
        parent: null,
        firstName: '',
        lastName: '',
        dob: '',
        memberShipStart: moment(),
        memberShipEnd: '',
        listingType: '',
        listingIdentifier: '',
        formRef: null
      }
    ]);
  };

  let showTitle = '';

  switch (chooseForm) {
    case 'Edit':
    case 'View':
      showTitle = chooseForm;
      break;
    default:
      showTitle = 'Create';
  }

  const handleChange = (value: any) => {
    const newMember = customerList && customerList.find((data) => data._id === value);
    setParentData(newMember);
  };
  const fetchList = useCallback(
    (pageSize: number = 0, skip: number = 0, filter: string = activekey) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        filter
      };
      return membersPaginationApi(options);
    },
    []
  );
  const onSubmit = () => {
    let membersToAdd;
    const validationPromises =
      memberItem.map((member) => member?.formRef?.validateFields()) || (memberId as any);
    Promise.all(validationPromises).then(() => {
      setLoading(true);
      const memberitem = memberItem.map((member) => ({
        ...member.formRef.getFieldsValue(),
        parent: parentData._id
      }));
      memberitem[0].listingType === ''
        ? (membersToAdd = memberitem.map(({ listingType, ...member }) => member))
        : (membersToAdd = memberitem.map((member) => member));
      // Object.keys(memberitem[0]).map((item,key)=>{
      //     if(memberitem[0][item]==""){
      //         delete memberitem[0][item]
      //     }
      // })
      // membersToAdd=memberitem;
      setLoading(true);
      dispatch(setMemberLoading(true));
      if (memberId) {
        const updateMemberPromises = membersToAdd.map((member) =>
          updateMemberApi(memberId, member)
        );
        Promise.all(updateMemberPromises)
          .then((res) => {
            getMemberList();
            closeDrawer('Success', 'Member information updated successfully!');
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            closeDrawer('Error', err.response.data.message);
          });

        return;
      }
      const memberPromises = membersToAdd.map((member) => createMemberApi(member));
      Promise.all(memberPromises)
        .then((res) => {
          getMemberList();
          memberItem[0].formRef.resetFields();
          form.resetFields();
          closeDrawer('Success', 'Member created successfully!');
          setLoading(false);
          setInitialMember();
          dispatch(setMemberLoading(false));
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
          closeDrawer('Error', err.response.data.message);
          setLoading(false);
          setInitialMember();
          dispatch(setMemberLoading(false));
        })
        .finally(() => {
          form.resetFields();
        });
    });
  };

  const onReset = () => {
    const membersToReset = memberItem.map((member) => ({
      ...member.formRef.resetFields()
    }));
    if (!memberId) {
      form.resetFields();
    }
    setLoading(false);
    setVisible(false);
  };

  const formStyle: CSSProperties = {};

  if (props.editMode === false) {
    formStyle.pointerEvents = 'none';
  }
  return (
    <>
      {props.icon ? (
        <span onClick={() => setVisible(true)}>{props.icon}</span>
      ) : (
        <AddButton type="primary" onClick={() => setVisible(true)}>
          <PlusOutlined />
        </AddButton>
      )}
      <Drawer
        title={`${showTitle} Member Form`}
        placement="right"
        closable={false}
        visible={visible}
        width="520"
        className="user-drawer"
        maskClosable={false}
        onClose={() => setVisible(false)}
      >
        <Form
          {...layout}
          form={form}
          className="admin-form"
          name="member"
          onFinish={onSubmit}
          onClick={(e) => e.stopPropagation()}
        >
          <Spin spinning={isLoading}>
            <UserLastRow style={formStyle}>
              <Form.Item
                label="Parent"
                name="parent"
                className="time-zone-container-s"
                // required={true}
                rules={[{ required: true, message: 'Please select your Parent!' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  defaultValue={
                    chooseForm && memberId
                      ? `${parentData?.firstName ? parentData.firstName : ''} ${parentData?.lastName ? parentData.lastName : ''}`
                      : ''
                  }
                  onChange={handleChange}
                  disabled={!!memberId}
                  filterOption={(input, option) => {
                    if (option) {
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }

                    return true;
                  }}
                >
                  {memberId ? (
                    <Option
                      value={`${parentData?.firstName ? parentData.firstName : ''} ${parentData?.lastName ? parentData.lastName : ''}`}
                    >
                      {`${parentData?.firstName ? parentData.firstName : ''} ${parentData?.lastName ? parentData.lastName : ''}`}
                    </Option>
                  ) : (
                    customerList?.map((data: any, index: number) => (
                      <Option
                        key={index}
                        value={data._id}
                      >{`${data.firstName} ${data.lastName ? data.lastName : ''}`}</Option>
                    ))
                  )}
                </Select>
              </Form.Item>
              <MemberList
                memberList={memberItem || []}
                onChange={setMemberItem}
                hideAdd={showTitle !== 'No-Create'}
                disabled={props.editMode ? !props.editMode : showTitle !== 'Create'}
                noRemoveTab
              />
            </UserLastRow>
            <DrawerFooter className="drawer-footer-style drawerButton">
              <Form.Item className="no-boder">
                {showTitle === 'Create' || showTitle === 'Edit' ? (
                  <SubmitButton
                    onClick={onReset}
                    className={
                      visible
                        ? 'close-drawer-button member-close-button-border'
                        : 'close-drawer-button hide-close-drawer-button member-close-button-border'
                    }
                  >
                    <img src={CrossFormButton} />
                  </SubmitButton>
                ) : (
                  <SubmitButton
                    onClick={onReset}
                    className={
                      visible
                        ? 'close-drawer-button member-close-button-border'
                        : 'close-drawer-button hide-close-drawer-button member-close-button-border'
                    }
                  >
                    <img src={CrossFormButton} />
                  </SubmitButton>
                )}
                {showTitle === 'Edit'
                  ? visible && (
                      <SubmitButton
                        className="edit-member-submit-button"
                        htmlType="submit"
                        type="primary"
                      >
                        Save
                      </SubmitButton>
                    )
                  : showTitle === 'Create'
                    ? visible && (
                        <SubmitButton
                          className="edit-member-submit-button"
                          htmlType="submit"
                          type="primary"
                          onClick={() => {
                            const memberitem = memberItem.map((member) => {
                              member?.formRef?.submit();
                              return member?.formRef?.validateFields();
                            });
                          }}
                        >
                          Submit
                        </SubmitButton>
                      )
                    : null}
              </Form.Item>
            </DrawerFooter>
          </Spin>
        </Form>
      </Drawer>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    memberData: state.userMemberData.memberData
  };
}

export default connect(mapStateToProps)(AddMemberComponent);
