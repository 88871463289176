import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Space, Table, Tag } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchCourseApi } from '../../api/course';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import { setShowFormData } from '../../redux/actions/formAction';
import { setCourseList } from '../../redux/actions/userMemberAction';
import { getResizedImageName } from '../../utils/file';
import PRIVILEGES from '../../utils/privilege';
import { Mode, Role } from '../../utils/types/role';
import DeepLink from '../common/DeepLink';
import { ColDiv, EditViewIcon } from '../common/styles';

const colors = ['tag1-style', 'tag2-style', 'tag3-style', 'tag4-style', 'tag5-style'];

const CourseTableComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    courseData = [],
    resourceListMode,
    perPageRecords,
    setVisible,
    currentLoginUser
  } = props;

  const [current, setCurrent] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isvisible, setisvisible] = useState(false);
  const [formUrl, setformUrl] = useState('');
  const [videotitle, setvideotitle] = useState('');
  // const { customerLoading } = userMemberData;
  const fetchCourseList = useCallback(
    (pageSize: number = 0, skip: number = 0, searchTerm: string) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        search: searchTerm
      };
      dispatch(setCustomerLoading(false));
      return searchCourseApi(options);
    },
    [dispatch]
  );
  // const fetchAuthorList = useCallback((searchTerm: string = "", isActive) => {
  //     dispatch(setCustomerLoading(false))
  //     return getAuthor()
  // }, [dispatch]);

  useEffect(() => {
    fetchCourseList(perPageRecords, 0, props.searchTerm)
      .then((res) => {
        const { data, count } = res;
        dispatch(setCourseList(data));
        setCurrent(1);
        setTotalCount(count);
      })
      .catch((err) => {});
  }, [dispatch, fetchCourseList, perPageRecords]);

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Course'
    };
    dispatch(setShowFormData(createFormData));
    setVisible(true);
    // dispatch(setIsOpenDrawer(true))
  };

  const handleCancel = () => {
    setisvisible(false);
    setformUrl('');
    document.getElementById('hidePointerInput')?.setAttribute('src', '');
  };

  const handlethumbnailclick = (video: any, title: string) => {
    setformUrl(video);
    setvideotitle(title);
    document.getElementById('hidePointerInput')?.setAttribute('src', video);
    setisvisible(true);
  };

  const canEdit = PRIVILEGES.Course.Edit?.includes(currentLoginUser.type as Role);

  const columns: ColumnType<any>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 300,
      render: (text, record) => {
        const thumbnail = record.previewThumbnail ? (
          <img
            style={{ cursor: 'pointer' }}
            src={getResizedImageName(record.previewThumbnail, '400x400')}
            alt="thumbnail"
            width="180px"
            height="120px"
          />
        ) : (
          ''
        );

        return (
          <ColDiv>
            {record.order ? (
              <Badge.Ribbon text={record.order} placement="start" color="blue">
                {thumbnail}
              </Badge.Ribbon>
            ) : (
              thumbnail
            )}
          </ColDiv>
        );
      }
    },
    {
      title: 'Share Link',
      dataIndex: 'externalDeepLink',
      key: 'externalDeepLink',
      width: 200,
      sorter: (a, b) =>
        a.externalDeepLink &&
        b.externalDeepLink &&
        a.externalDeepLink.localeCompare(b.externalDeepLink),
      render: (text, record) => (
        <ColDiv>
          <DeepLink type="external" deepLink={record.externalDeepLink} />
        </ColDiv>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 250,
      sorter: (a, b) => a.title && b.title && a.title.localeCompare(b.title),
      render: (text, record) => <ColDiv>{record.title}</ColDiv>
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      render: (text, record) => (
        <ColDiv>
          {record.tags?.map((i, index) => (
            <Tag className={`${colors[index % 5]} video-tag-style`}>{i.name}</Tag>
          ))}
        </ColDiv>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <DeepLink type="internal" deepLink={record.internalDeepLink} />
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];
  return (
    <>
      <Table
        className={props.className + (resourceListMode === '' ? ' hide' : '')}
        columns={columns}
        dataSource={courseData}
        pagination={false}
      />

      {/* <Modal maskClosable={false} className={`onboardFormsModal videoPlayModal`} title={<div className="add-task-title-container"> {videotitle}  <div onClick={handleCancel} className={props.isMobile ? 'add-task-close-button' : `add-task-close-button`}><img src={ModalCloser} /></div></div>} visible={isvisible} closable={false} onCancel={handleCancel}>
                <video controls className="video-play-modal" id="hidePointerInput" width="100%" height="100%" src={formUrl}></video>
            </Modal> */}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer,
    resourcesList: state.formReducer.resourcesList,
    favResourcesList: state.formReducer.favResourcesList,
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    courseList: state.userMemberData.courseList,
    customerLoading: state.customerRuducer.customerLoading,
    isMobile: state.trackReducer.isMobile,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(CourseTableComponent);
