import { DeleteFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Pagination, Select, Spin, Tooltip } from 'antd';
import React, { createContext, useCallback, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { getUserMemberResourcesAPI } from '../../api/resources';
import { setFetchingResource, setSelectedRowData } from '../../redux/actions/formAction';
import { useMemberId } from '../../utils/hooks/useCustomerId';
import { CustomerContainer } from '../common/styles';
import MyResourcesTableComponent from '../TableData/MyResourcesTableComponent';
import SearchBar from './SearchBar';
import useShareResourcesMutation from './useShareResourcesMutation';

const { Option } = Select;
const MYRESOURCES_TOOLTIP_INFO =
  'Search resources by title, then click Add to make them available in My Resources';
export const MyResourcesContext = createContext({ pagination: null });

const MyResourcesComponent: React.FC<any> = (props) => {
  const { dispatch, selectedRowData } = props;
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 5,
    total: null,
    allResources: []
  });

  useLayoutEffect(() => {
    dispatch(setSelectedRowData({ keys: [], rows: [] }));
  }, []);
  const memberId = useMemberId();
  const { data: resourcesList = [], isLoading: customerLoading } = useQuery(
    ['sharedResources', memberId, pagination.current],
    () => {
      const options = {
        skip: pagination.current * pagination.pageSize - pagination.pageSize,
        limit: pagination.pageSize
      };
      getUserMemberResourcesAPI(memberId, options).then((res) => {
        const { data, allResources, count } = res;
        setPagination({
          ...pagination,
          total: count,
          allResources
        });
        dispatch(setFetchingResource(data));
      });
    },
    { enabled: Boolean(memberId) }
  );

  const {
    onUnshare,
    unShareResourceMutation: { isLoading: unshareLoading }
  } = useShareResourcesMutation(memberId, pagination.current);

  const onUnshareClick = async () => {
    onUnshare(selectedRowData.keys);
    dispatch(setSelectedRowData({ keys: [], rows: [] }));
  };
  const onPageChange = useCallback(
    (page: number) => {
      const option = {
        ...pagination,
        current: page
      };
      setPagination(option);
    },
    [pagination]
  );

  return (
    <MyResourcesContext.Provider value={{ pagination }}>
      <div className="resource-mobile-view-container">
        <Spin spinning={customerLoading}>
          <div className="site-layout-background">
            <div className="flex items-center gap-x-8 gap-y-4 flex-wrap px-4 my-8 lg:my-0">
              <Button
                icon={<DeleteFilled />}
                onClick={onUnshareClick}
                type="link"
                shape="round"
                className="bg-slate-700 hover:bg-slate-600 border-slate-700 text-white hover:text-white disabled:border-slate-300 w-full lg:w-auto"
                disabled={selectedRowData.keys.length <= 0 || unshareLoading}
              >
                Remove Selected
              </Button>
              <SearchBar />
              <span className="shrink-0">
                <div className="text-lg font-medium leading-none">
                  A place for my resources &ensp;
                  <Tooltip
                    title={MYRESOURCES_TOOLTIP_INFO}
                    arrowPointAtCenter={false}
                    placement="topRight"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </div>
                <div>Search and add resources</div>
              </span>
            </div>
            <div className="site-card-wrapper">
              <CustomerContainer>
                <MyResourcesTableComponent className="resources-table" />
                <Pagination
                  // hideOnSinglePage={true}
                  current={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={onPageChange}
                  className="my-pagination"
                />
              </CustomerContainer>
            </div>
          </div>
        </Spin>
      </div>
    </MyResourcesContext.Provider>
  );
};

function mapStateToProps(state: any) {
  return {
    sessionData: state.sessionReducer.sessionData,
    selectedRowData: state.formReducer.selectedRowData
  };
}

export default connect(mapStateToProps)(MyResourcesComponent);
