import {
  SET_MEMBER_GOALS, SET_SKILL_LIST, SET_COMPLEXITY_LIST, SET_ASSIGNEE_LIST, SET_TARGET_SKILL_LIST, SET_FILE_LINK_LIST, SET_CONTENT_LINK_LIST, SET_ADD_TARGET_ACHIEVED,
} from '../types/types';

const INITIAL_STATE = {
  memberGoals: [],
  programSkills: [],
  programComlexityList: [],
  assigneeList: [],
  targetSkillList: [],
  fileLinkList: [],
  contentLinkList: [],
  addTargetList: [],
};

const userProgramRuducer = (state = INITIAL_STATE, action: { type: any, payload: any }) => {
  switch (action.type) {
  case SET_MEMBER_GOALS:
    return {
      ...state, memberGoals: action.payload,
    };
  case SET_SKILL_LIST:
    return {
      ...state, programSkills: action.payload,
    };
  case SET_COMPLEXITY_LIST:
    return {
      ...state, programComlexityList: action.payload,
    };
  case SET_ASSIGNEE_LIST:
    return {
      ...state, assigneeList: action.payload,
    };
  case SET_TARGET_SKILL_LIST:
    return {
      ...state, targetSkillList: action.payload,
    };
  case SET_FILE_LINK_LIST:
    return {
      ...state, fileLinkList: action.payload,
    };
  case SET_CONTENT_LINK_LIST:
    return {
      ...state, contentLinkList: action.payload,
    };
  case SET_ADD_TARGET_ACHIEVED:
    return {
      ...state, addTargetList: action.payload,
    };
  default:
    return state;
  }
};

export default userProgramRuducer;
