import { Mode, Role } from '../../utils/types/role';
import {
  SET_FETCH_ALL_RESOURCES,
  SET_FETCH_FAV_RESOURCES,
  SET_IS_OPEN_DRAWER,
  SET_OBJECTIVE_LIST,
  SET_RESOURCES_RELATED_TASKS,
  SET_RESOURCE_LIST_MODE,
  SET_RESOURCE_OBJECTIVE_LIST,
  SET_RESOURCE_TYPES,
  SET_SELECTED_ROW_DATA,
  SET_SHOW_FORM_DATA,
  SET_USER_MEMBER_RESOURCES
} from '../types/types';

export interface FormReduxState {
  showFormData: {
    id: string;
    userType: Role;
    formMode: Mode | '';
    formLabel: string;
  };
  isOpenDrawer: boolean;
  resourcesList: any[];
  favResourcesList: any[];
  objectiveList: any[];
  resourceTypes: any[];
  resourceListMode: Mode | '';
  selectedRowData: {
    keys: string[];
    rows: any[];
  };
  resourcesRelatedTasks: any[];
  userMemberResources: any[];
  resourceObjectivelist: any[];
}

const INITIAL_STATE: FormReduxState = {
  showFormData: {
    id: '',
    userType: Role.Customer,
    formMode: '',
    formLabel: ''
  },
  isOpenDrawer: false,
  resourcesList: [],
  favResourcesList: [],
  objectiveList: [],
  resourceTypes: [],
  resourceListMode: '',
  selectedRowData: { keys: [], rows: [] },
  resourcesRelatedTasks: [],
  userMemberResources: [],
  resourceObjectivelist: []
};

const formReducer = (state = INITIAL_STATE, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_SHOW_FORM_DATA:
      return {
        ...state,
        showFormData: action.payload
      };
    case SET_IS_OPEN_DRAWER:
      return {
        ...state,
        isOpenDrawer: action.payload
      };
    case SET_FETCH_ALL_RESOURCES:
      return {
        ...state,
        resourcesList: action.payload
      };
    case SET_FETCH_FAV_RESOURCES:
      return {
        ...state,
        favResourcesList: action.payload
      };
    case SET_OBJECTIVE_LIST:
      return {
        ...state,
        objectiveList: action.payload
      };
    case SET_RESOURCE_TYPES:
      return {
        ...state,
        resourceTypes: action.payload
      };
    case SET_RESOURCE_LIST_MODE:
      return {
        ...state,
        resourceListMode: action.payload
      };
    case SET_SELECTED_ROW_DATA:
      return {
        ...state,
        selectedRowData: action.payload
      };
    case SET_RESOURCES_RELATED_TASKS:
      return {
        ...state,
        resourcesRelatedTasks: action.payload
      };
    case SET_USER_MEMBER_RESOURCES:
      return {
        ...state,
        userMemberResources: action.payload
      };
    case SET_RESOURCE_OBJECTIVE_LIST:
      return {
        ...state,
        resourceObjectivelist: action.payload
      };
    default:
      return state;
  }
};

export default formReducer;
