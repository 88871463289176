import { PlusOutlined } from '@ant-design/icons';
import { Drawer, Form } from 'antd';
import moment from 'moment';
import React, { ReactChild, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { SessionNoteFormType } from '../../constant';
import EditIcon from '../../images/icons/EditIcon.svg';
import EyeViewIcon from '../../images/icons/showPasswordBlack.png';
import { setRefreshSessionData } from '../../redux/actions/sessionAction';
import { Role } from '../../utils/types/role';
import { ProgramAddButton } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';
import SessionForm from '../FormComponent/SessionForm';

interface AddUserProps {
  user: Role;
  icon?: ReactChild | null;
  sessionId?: string;
  edit?: boolean;
  chooseForm?: string;
  onCloseDrawer?: () => void;
  formTypeLabel?: string;
  addButtonLabel?: string;
  notifyVisible?: boolean;
  propVisible?: boolean;
  isMobile?: any;
  directlyCallComponent?: boolean;
  viewIconDisable?: boolean;
  refreshSessionNotes?: boolean;
  userLocalData?: any;
  customerSelectedMember?: any;
  currentLoginUser?: any;
}

export interface goalTypeListType {
  title: string;
  value: string;
}

const AddSessionNoteComponent: React.FC<AddUserProps> = (props) => {
  const {
    propVisible,
    chooseForm,
    isMobile,
    refreshSessionNotes,
    userLocalData,
    customerSelectedMember,
    currentLoginUser
  } = props;
  const { icon = <img src={EditIcon} /> } = props;

  const [visible, setVisible] = useState<boolean>(!!props.directlyCallComponent);
  const [role, setRole] = useState<Role>(props.user);
  const [isTouched, setisTouched] = useState<boolean>(false);
  const [relatedTasks, setrelatedTasks] = useState<any>([]);
  const [activeForm, setActiveForm] = useState<SessionNoteFormType>({
    type: role,
    endtimeCancellation: false,
    sessionRecorded: false,
    isDeleted: false,
    rateSession: 0,
    clientEngagement: 0,
    topWins: [''],
    challenges: [''],
    about: '',
    title: '',
    internalNotes: ''
  });
  const [sessionId, setSessionId] = useState<string>();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const unloadListenerRef = useRef<(ev) => void>();
  if (unloadListenerRef.current) {
    window.removeEventListener('beforeunload', unloadListenerRef.current);
  }
  unloadListenerRef.current = (ev) => {
    if (visible) {
      saveDraftSessionNote();
      setTimeout(() => dispatch(setRefreshSessionData(!refreshSessionNotes)));
      ev.returnValue = null;
    }
  };
  window.addEventListener('beforeunload', unloadListenerRef.current);
  useEffect(
    () => () => {
      if (unloadListenerRef.current) {
        window.removeEventListener('beforeunload', unloadListenerRef.current);
      }
    },
    []
  );

  useEffect(() => {
    if (!visible) return;
    const timeoutId = setTimeout(() => {
      saveDraftSessionNote();
      setTimeout(() => dispatch(setRefreshSessionData(!refreshSessionNotes)));
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [form, activeForm]);

  useEffect(() => {
    setRole(props.user);
  }, [props.user]);

  useEffect(() => {
    setSessionId(props.sessionId);
  }, [props.sessionId]);

  useEffect(() => {
    if (props.directlyCallComponent === true) {
      setVisible(true);
    }
  }, [props.directlyCallComponent]);

  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  const closeDrawer = (title, content, forceClose = false) => {
    if (
      form.isFieldsTouched() &&
      !sessionId?.startsWith('draft') &&
      chooseForm === 'Edit' &&
      !forceClose &&
      !confirm('Are you sure? The changes you made may not be saved!')
    ) {
      return false;
    }
    saveDraftSessionNote();
    setVisible(false);
    if (title) {
      openNotification(title, content);
    }
    if (props.directlyCallComponent === true) {
      setVisible(false);
    }
    if (props.onCloseDrawer) {
      props.onCloseDrawer();
    }
    dispatch(setRefreshSessionData(!refreshSessionNotes));
    return true;
  };

  const saveDraftSessionNote = () => {
    if (form.isFieldsTouched() && chooseForm !== 'View') {
      let sessionData = localStorage.getItem('sessionData') as any;
      try {
        if (chooseForm === 'Edit' && !sessionId?.startsWith('draft')) {
        } else if (sessionData) {
          sessionData = JSON.parse(sessionData);
          if (sessionData.length >= 0) {
            if (sessionId?.startsWith('draft')) {
              const id = sessionId?.split('-')[1];
              sessionData = sessionData.map((x) =>
                x.id === id
                  ? {
                      ...form.getFieldsValue(),
                      ...activeForm,
                      relatedTasks,
                      isDraft: true,
                      loginBy: currentLoginUser?._id,
                      memberId: customerSelectedMember[0]?._id,
                      startTime: moment(form.getFieldsValue()?.start_Time),
                      startDate: moment(form.getFieldsValue()?.start_Date),
                      endDate: moment(form.getFieldsValue()?.end_Date),
                      endTime: moment(form.getFieldsValue()?.end_Time),
                      createdBy: `${currentLoginUser?.firstName} ${currentLoginUser?.lastName}`,
                      id
                    }
                  : x
              );
            } else {
              const sessionDataExistLength = {
                ...form.getFieldsValue(),
                ...activeForm,
                relatedTasks,
                isDraft: true,
                loginBy: currentLoginUser?._id,
                memberId: customerSelectedMember[0]?._id,
                startTime: moment(form.getFieldsValue()?.start_Time),
                endTime: moment(form.getFieldsValue()?.end_Time),
                startDate: moment(form.getFieldsValue()?.start_Date),
                endDate: moment(form.getFieldsValue()?.end_Date),
                createdBy: `${currentLoginUser?.firstName} ${currentLoginUser?.lastName}`,
                id: sessionData.reduce((acc, curr) => (acc > curr.id ? acc : curr.id + 1), 0)
              };
              if (
                sessionDataExistLength.about ||
                sessionDataExistLength.title ||
                sessionDataExistLength.challenges.filter((i) => i).length > 0 ||
                isTouched === true ||
                sessionDataExistLength.relatedTasks.length > 0 ||
                sessionDataExistLength.topWins.filter((i) => i).length > 0 ||
                sessionDataExistLength.internalNotes ||
                sessionDataExistLength.sessionRecorded != false ||
                sessionDataExistLength.endtimeCancellation != false
              ) {
                const id = sessionData.reduce(
                  (acc, curr) => (acc > curr.id ? acc : curr.id + 1),
                  0
                );
                sessionData.push({
                  ...form.getFieldsValue(),
                  ...activeForm,
                  relatedTasks,
                  isDraft: true,
                  loginBy: currentLoginUser?._id,
                  memberId: customerSelectedMember[0]?._id,
                  startTime: moment(form.getFieldsValue()?.start_Time),
                  endTime: moment(form.getFieldsValue()?.end_Time),
                  startDate: moment(form.getFieldsValue()?.start_Date),
                  endDate: moment(form.getFieldsValue()?.end_Date),
                  createdBy: `${currentLoginUser?.firstName} ${currentLoginUser?.lastName}`,
                  id
                });
                setSessionId(`draft-${id}`);
              }
            }
            localStorage.setItem('sessionData', JSON.stringify(sessionData));
          }
        } else {
          const newData = [
            {
              ...form.getFieldsValue(),
              ...activeForm,
              relatedTasks,
              isDraft: true,
              loginBy: currentLoginUser?._id,
              memberId: customerSelectedMember[0]?._id,
              startTime: moment(form.getFieldsValue()?.start_Time),
              endTime: moment(form.getFieldsValue()?.end_Time),
              startDate: moment(form.getFieldsValue()?.start_Date),
              endDate: moment(form.getFieldsValue()?.end_Date),
              createdBy: `${currentLoginUser?.firstName} ${currentLoginUser?.lastName}`,
              id: 0
            }
          ];

          if (
            newData[0]?.about ||
            newData[0]?.title ||
            newData[0]?.challenges.filter((i) => i).length > 0 ||
            isTouched === true ||
            newData[0]?.relatedTasks.length > 0 ||
            newData[0]?.topWins.filter((i) => i).length > 0 ||
            newData[0]?.internalNote ||
            newData[0].sessionRecorded != false ||
            newData[0].endtimeCancellation != false
          ) {
            setSessionId('draft-0');
            localStorage.setItem('sessionData', JSON.stringify(newData));
          }
        }
      } catch (err) {}
    }
  };

  let showTitle = '';

  switch (props.chooseForm) {
    case 'Edit':
    case 'View':
      showTitle = props.chooseForm;
      break;
    default:
      showTitle = 'Create';
  }

  const openDrawar = (e: any) => {
    e.stopPropagation();

    setSessionId(props.sessionId);
    setVisible(true);
  };
  const openSessionDrawer = (e: any) => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <>
      {!props.notifyVisible ? (
        <ProgramAddButton icon={<PlusOutlined />} onClick={openDrawar}>
          {props.addButtonLabel}
        </ProgramAddButton>
      ) : chooseForm === 'Edit' ? (
        icon && <span onClick={openSessionDrawer}>{icon}</span>
      ) : isMobile && props.viewIconDisable ? (
        ''
      ) : (
        isMobile && <img src={EyeViewIcon} className="show-eye-icon" onClick={openSessionDrawer} />
      )}
      <div onClick={(e) => e.stopPropagation()}>
        <Drawer
          title={`${`${showTitle} ${props.formTypeLabel}`} Form`}
          placement="right"
          closable={false}
          maskClosable={false}
          visible={visible || propVisible}
          width={window.innerWidth}
          className={
            props.propVisible
              ? 'user-drawer session-drawer notificationDrawerModal'
              : 'user-drawer session-drawer'
          }
          onClose={() => closeDrawer('', '')}
        >
          <SessionForm
            close={closeDrawer}
            cancel={() => closeDrawer('', '')}
            userRole={role}
            sessionId={sessionId}
            setisTouched={setisTouched}
            isTouched={isTouched}
            editMode={props.edit}
            viewMode={showTitle}
            visible={visible || propVisible}
            form={form}
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            relatedTasks={relatedTasks}
            setrelatedTasks={setrelatedTasks}
          />
        </Drawer>
      </div>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    visiblePopup: state.customerRuducer.visiblePopup,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    isMobile: state.trackReducer.isMobile,
    refreshSessionNotes: state.sessionReducer.refreshSessionNotes,
    customerDataList: state.notificationReducer.customerDataList,
    userLocalData: state.customerRuducer.userLocalData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(AddSessionNoteComponent);
