import styled from 'styled-components';
import { Button, Input } from 'antd';

const { TextArea } = Input;

export const SubmitButton = styled(Button)`
    margin-top: 15px;
    float: right;
`;

export const TextAreaField = styled(TextArea)`
    min-height: 200px !important;
    z-index: 0;
`;
