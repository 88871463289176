import {
  SET_IS_OPEN_DRAWER, SET_SHOW_FORM_DATA, SET_FETCH_ALL_RESOURCES, SET_FETCH_FAV_RESOURCES, SET_OBJECTIVE_LIST, SET_RESOURCE_TYPES, SET_RESOURCE_LIST_MODE, SET_SELECTED_ROW_DATA, SET_RESOURCES_RELATED_TASKS, SET_USER_MEMBER_RESOURCES, SET_RESOURCE_OBJECTIVE_LIST,
} from '../types/types';

export function setShowFormData(data) {
  return {
    type: SET_SHOW_FORM_DATA,
    payload: data,
  };
}

export function setIsOpenDrawer(data) {
  return {
    type: SET_IS_OPEN_DRAWER,
    payload: data,
  };
}

export function setFetchingResource(data) {
  return {
    type: SET_FETCH_ALL_RESOURCES,
    payload: data,
  };
}

export function setFetchingFavResource(data) {
  return {
    type: SET_FETCH_FAV_RESOURCES,
    payload: data,
  };
}

export function setObjectiveList(data) {
  return {
    type: SET_OBJECTIVE_LIST,
    payload: data,
  };
}

export function setResourceTypes(data) {
  return {
    type: SET_RESOURCE_TYPES,
    payload: data,
  };
}

export function setResourceListMode(data) {
  return {
    type: SET_RESOURCE_LIST_MODE,
    payload: data,
  };
}

export function setSelectedRowData(data) {
  return {
    type: SET_SELECTED_ROW_DATA,
    payload: data,
  };
}

export function setResourcesRelatedTasks(data) {
  return {
    type: SET_RESOURCES_RELATED_TASKS,
    payload: data,
  };
}

export function setUserMemberResources(data) {
  return {
    type: SET_USER_MEMBER_RESOURCES,
    payload: data,
  };
}

export function setResourceObjective(data) {
  return {
    type: SET_RESOURCE_OBJECTIVE_LIST,
    payload: data,
  };
}
