import { Tabs } from 'antd';
import React, { useState } from 'react';
import HomeCategoriesComponent from './components/HomeCategoriesComponent';
import HomePromptsComponent from './components/HomePromptsComponent';

const HomeComponent = () => {
  const [chooseKey, setKey] = useState<string>('prompts');

  const handleTabChange = (key: string) => {
    setKey(key);
  };

  return (
    <div className="site-layout-background mt-8">
      <Tabs activeKey={chooseKey} type="card" onChange={handleTabChange}>
        <Tabs.TabPane tab="Prompts" key="prompts">
          {chooseKey === 'prompts' && <HomePromptsComponent />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Categories" key="categories">
          {chooseKey === 'categories' && <HomeCategoriesComponent />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default HomeComponent;
