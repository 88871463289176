import { customerHierarchy } from '../../components/SelectData/constant';
import {
  SET_CUSTOMER_INFO, SET_CUSTOMER_LOADING, SET_SELECTED_CUSTOMER, SET_SELECTED_MEMBER_DATA, SET_CUSTOMER_FORM_DETAILS, SET_CUSTOMER_ACCESS_DATA, SET_USER_LOCAL_DATA, SET_WELCOME_MODAL, SET_NOTIFICATION, SET_VISIBLE_POPUP,
} from '../types/types';

export type CustomerState = typeof INITIAL_STATE;

const INITIAL_STATE = {
  customerInfo: [],
  selectCustomer: [customerHierarchy[0]] || [],
  customerLoading: false,
  customerSelectedMember: [] as any[],
  customerFormDetails: {},
  customerAccessData: {},
  userLocalData: {},
  openWelcomeModal: false,
  notificationData: [],
  visiblePopup: false,
};

const customerRuducer = (state = INITIAL_STATE, action: { type: any, payload: any }) => {
  switch (action.type) {
  case SET_CUSTOMER_INFO:
    return {
      ...state, customerInfo: action.payload,
    };
  case SET_SELECTED_CUSTOMER:
    return {
      ...state, selectCustomer: action.payload,
    };
  case SET_CUSTOMER_LOADING:
    return {
      ...state, customerLoading: action.payload,
    };
  case SET_SELECTED_MEMBER_DATA:
    return {
      ...state, customerSelectedMember: action.payload,
    };
  case SET_CUSTOMER_FORM_DETAILS:
    return {
      ...state, customerFormDetails: action.payload,
    };
  case SET_CUSTOMER_ACCESS_DATA:
    return {
      ...state, customerAccessData: action.payload,
    };
  case SET_USER_LOCAL_DATA:
    return {
      ...state, userLocalData: action.payload,
    };
  case SET_WELCOME_MODAL:
    return {
      ...state, openWelcomeModal: action.payload,
    };
  case SET_NOTIFICATION:
    return {
      ...state, notificationData: action.payload,
    };
  case SET_VISIBLE_POPUP: {
    return {
      ...state, visiblePopup: action.payload,
    };
  }
  default:
    return state;
  }
};
export default customerRuducer;
