import React, { useCallback, useState } from 'react';
import { Tabs } from 'antd';
import ListingBox from './ListingComponent/ListingBox';
import AddUserComponent from './AddUser/AddUserComponent';
import { UserContainer } from './common/styles';
import { Role } from '../utils/types/role';

const { TabPane } = Tabs;

const BusinessComponent: React.FC = () => {
  const [chooseKey, setKey] = useState<string>('Active');

  const callback = (key: string) => {
    setKey(key);
  };

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  return (
    <div>
      <UserContainer>
        <AddUserComponent onCloseDrawer={onCloseDrawer} user={Role.Business} formLabel="Business" />
      </UserContainer>
      <div className="site-layout-background">
        <Tabs activeKey={chooseKey} type="card" onChange={callback}>
          <TabPane tab="Active" key="Active">
            <ListingBox activeKey={chooseKey} show="Active" role={Role.Business} />
          </TabPane>
          <TabPane tab="Inactive" key="Inactive">
            <ListingBox activeKey={chooseKey} show="Inactive" role={Role.Business} />
          </TabPane>
          <TabPane tab="On hold" key="OnHold">
            <ListingBox activeKey={chooseKey} show="OnHold" role={Role.Business} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default BusinessComponent;
