import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { postNotificationApi } from '../../api/notification';
import { addMilestoneApi, deleteMilestoneApi, updateMilestoneApi } from '../../api/user-program';
import { MilestoneFormType, StatusProps, userMemberDataType } from '../../constant';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setNotificationAlert } from '../../redux/actions/notificationAction';
import { setAddTarget } from '../../redux/actions/userProgramAction';
import { Role } from '../../utils/types/role';
import {
  DeleteTabDiv,
  DrawerFooter,
  MultipleFileDiv,
  SubmitButton,
  UserLastRow
} from '../common/styles';
import FieldBox from './FieldBox';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const needHelpLabel = 'I need help from other Team member/Coaches to meet this skill';
const { Option } = Select;
const { Title, Text } = Typography;

interface GoalFormProps {
  mileStoneTypes?: any;
  userRole: Role;
  cancel: any;
  close: any;
  milestoneId?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  userMemberData: userMemberDataType;
  goalFormType?: string;
  memberGoals?: any;
  goalData: any;
  mileStoneData?: any;
  customerSelectedMember?: any;
  teamMember?: any;
  getGoalsData?: any;
  customerDataList?: any;
  addTargetList?: any;
  isMobile?: any;
  status?: StatusProps[];
  comment?: string;
}

const MilestoneForm: React.FC<GoalFormProps> = (props) => {
  const [activeForm, setActiveForm] = useState<MilestoneFormType>({
    goal: props.goalData._id,
    needHelp: false,
    status: 'Active'
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [targetsToBeAchieved, settargetsToBeAchieved] = useState<any>([]);
  const [skillStatus, setSkillStatus] = useState<any>([]);
  const [form] = Form.useForm();
  const {
    dispatch,
    goalData,
    mileStoneData,
    milestoneId,
    teamMember,
    getGoalsData,
    customerSelectedMember,
    userMemberData,
    memberGoals,
    customerDataList,
    addTargetList,
    isMobile,
    status
  } = props;
  const defaultMilestoneType =
    mileStoneData && mileStoneData.milestoneType?.name ? mileStoneData.milestoneType.name : '';
  const defaultMember =
    milestoneId && milestoneId
      ? mileStoneData && mileStoneData.teamMember.length
        ? `${mileStoneData.teamMember[0].firstName} ${mileStoneData.teamMember[0].lastName}`
        : `${mileStoneData.teamMember.firstName} ${mileStoneData.teamMember.lastName}`
      : [];

  // const defaultMember = milestoneId && milestoneId ? mileStoneData && mileStoneData.teamMember.map(res => res._id): [];

  const targetSkillList = (goalData && goalData.neededSkills) || [];
  const SelectedMember = customerSelectedMember;

  useEffect(() => {
    if (props.viewMode === 'Create') {
      const createData = {
        name: '',
        milestoneType: '',
        targetSkills: [],
        needHelp: false,
        teamMember: [],
        notes: '',
        comment: '',
        status: 'Active'
      };
      settargetsToBeAchieved([]);
      dispatch(setAddTarget([]));
      form.setFieldsValue(createData);
      setActiveForm(createData);
    }

    if (milestoneId) {
      const data = {
        ...mileStoneData,
        milestoneType: mileStoneData && mileStoneData.milestoneType?._id,
        teamMember: mileStoneData && mileStoneData.teamMember?.map((res) => res._id),
        status:
          (mileStoneData && mileStoneData?.status === 'Pending') ||
          mileStoneData?.status === 'Complete' ||
          mileStoneData?.status === 'In-progress'
            ? 'Active'
            : mileStoneData?.status
      };
      form.setFieldsValue(data);
      setActiveForm(data);
      settargetsToBeAchieved(data.targetsToBeAchieved);
    }
  }, [milestoneId, mileStoneData, form, goalData._id, props.visible]);

  const onReset = (e: any) => {
    e.stopPropagation();
    setActiveForm({ ...activeForm });
    form.setFieldsValue({
      ...activeForm,
      neededSkills: ['']
    });
    form.resetFields();
    setLoading(false);
    props.cancel();
  };

  const handleChange = (name: string, value: string) => {
    setActiveForm({ ...activeForm, [name]: value });
    setSkillStatus({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onCheck = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, needHelp: checked });
  };

  const onSubmit = () => {
    setLoading(true);
    if (milestoneId) {
      updateMilestoneApi(milestoneId, {
        ...activeForm,
        targetsToBeAchieved
      })
        .then((res) => {
          getGoalsData();
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          settargetsToBeAchieved([]);
          dispatch(setAddTarget([]));
          if (!isLoading) {
            props.close('Success', 'Skill information updated successfully!');
          }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          dispatch(setAddTarget([]));
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    } else {
      addMilestoneApi({
        ...activeForm,
        member: SelectedMember[0]._id,
        goal: activeForm.goal ? activeForm.goal : goalData._id,
        targetsToBeAchieved
      })
        .then((res) => {
          getGoalsData();
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          settargetsToBeAchieved([]);
          if (!isLoading) {
            props.close('Success', 'Skill created successfully!');
          }
          const goalsConcat = goalData.name.slice(0, 20);
          const notification = {
            member: `${customerSelectedMember.length ? customerSelectedMember[0]._id : customerSelectedMember._id}`,
            businessUser: `${userMemberData.loginUserData._id}`,
            message: `${customerDataList && customerDataList.firstName}  has just added a new Skill for ${goalsConcat}`,
            user: `${SelectedMember[0].parent}`,
            milestone: res.data._id,
            type: 'MILESTONE_ADDED'
          };

          postNotificationApi(notification).then((res) => {
            dispatch(setNotificationAlert(res.data));
          });
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading && err.response) {
            props.close('Error', err.response.data?.message);
          }
        });
    }
  };

  const multiSelectHandleChange = (value: any) => {
    setActiveForm({ ...activeForm, targetSkills: value });
  };

  const teamMemberOnChange = (value: any) => {
    setActiveForm({ ...activeForm, teamMember: value });
  };

  const deleteMilestone = (id) => {
    if (milestoneId) {
      deleteMilestoneApi(id, {
        isdeleted: true
      })
        .then((res) => {
          getGoalsData();
          if (!isLoading) {
            props.close('Success', `${res.message}!`);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    }
  };
  const addContentSkill = (data: any) => {
    settargetsToBeAchieved(data);
    dispatch(setAddTarget(data));
  };
  const getContentLinkSpecificFields = () => (
    <MultipleFileDiv>
      <Text>What targets need to be achieved?</Text>
      <FieldBox
        fieldMsg="Please input target"
        SkillList={targetsToBeAchieved}
        addData={addContentSkill}
        buttonLabel="Add new Targets"
        viewMode={userMemberData.currentLoginUser.type !== 'BusinessUser'}
      />
    </MultipleFileDiv>
  );
  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
      onClick={(e) => e.stopPropagation()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          {/* <Row> */}
          <Col xs={24} sm={24} md={24}>
            <Title level={5} className="titleStyle" />
            <Form.Item
              label="Skill Definition"
              name="name"
              className="no-boder"
              rules={[{ required: true, message: 'Please add name of skill!', whitespace: true }]}
            >
              <Input.TextArea
                name="name"
                value={activeForm.name}
                onChange={(e) => onHandleChange(e, 'name')}
              />
            </Form.Item>
            <Row>
              <Col xs={24} sm={12} className="left-select mobile-view-skill-type">
                <Form.Item
                  label="Which Outcome does it belong to?"
                  name="goal"
                  rules={[{ required: false, message: 'Please select Outcome belong to' }]}
                  className="time-zone-container-s"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue={goalData.name}
                    onChange={(value) => handleChange('goal', value)}
                    disabled
                  >
                    <Option value={goalData._id}>{goalData.name}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                sm={12}
                className={
                  isMobile
                    ? 'right-select mobile-view-skill-type'
                    : 'right-select desktop-view-skill-type'
                }
              >
                <Form.Item
                  label="Skill Type"
                  name="milestoneType"
                  // rules={[{ required: true, message: 'Please select your Skill Type!' }]}
                  className="time-zone-container-s"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue={defaultMilestoneType}
                    onChange={(value) => handleChange('milestoneType', value)}
                  >
                    {props.mileStoneTypes.map((data: any, index: number) => (
                      <Option key={index} value={data._id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="What Specific needs are met by this skill?"
              name="targetSkills"
              rules={[{ required: true, message: 'Please add target Skills!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={[]}
                onChange={multiSelectHandleChange}
              >
                {targetSkillList.map((skills: string, index: number) => (
                  <Option key={index} value={skills}>
                    {skills}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
                            label={"What targets need to be achieved?"}
                            name="targetsAchieved"
                            className="no-boder"
                            rules={[{ required: false, message: 'Please add targets achieved!', whitespace: true }]}
                        >
                            <Input.TextArea name="targetsAchieved" value={activeForm.name} onChange={(e) => onHandleChange(e, 'targetsAchieved')} />
                        </Form.Item> */}
            {(userMemberData.currentLoginUser.type === 'BusinessUser' ||
              userMemberData.currentLoginUser.type === 'Customer' ||
              props.addTargetList?.length) &&
            ((userMemberData.currentLoginUser.type !== 'BusinessUser' &&
              targetsToBeAchieved.length) ||
              userMemberData.currentLoginUser.type === 'BusinessUser')
              ? getContentLinkSpecificFields()
              : null}
            <Form.Item
              name="needHelp"
              label=""
              className="no-boder"
              rules={[{ required: false, message: 'Please input the skill address!' }]}
            >
              <Checkbox
                className="Check-goal-as-primary"
                checked={activeForm.needHelp}
                onChange={onCheck}
              >
                {needHelpLabel}
              </Checkbox>
            </Form.Item>
            {activeForm?.needHelp ? (
              <Form.Item
                label="Team Member"
                name="teamMember"
                rules={[{ required: true, message: 'Please add target team member!' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue={defaultMember}
                  onChange={teamMemberOnChange}
                  value={form.getFieldValue('teamMember')}
                >
                  {teamMember &&
                    teamMember.map((data: any, index: number) => (
                      <Option
                        key={index}
                        value={data._id}
                      >{`${data?.firstName} ${data?.lastName}`}</Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item
              label="Notes / Comments..."
              name="notes"
              className="no-boder"
              // rules={[{ required: true, message: 'Please input your note or comments!', whitespace: true }]}
            >
              <Input.TextArea
                name="notes"
                onChange={(e) => onHandleChange(e, 'notes')}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
          <Col span={16} className="edit-profile-user-status-field">
            <Form.Item
              label="Status"
              name="status"
              className="no-boder"
              rules={[{ required: true, message: 'Please select your User Status!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue="Active"
                onChange={(value) => handleChange('status', value)}
              >
                {props?.status?.map((data: StatusProps, index: number) => (
                  <Option key={index} value={data.label}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {skillStatus?.status === 'Cancelled' || skillStatus?.status === 'On-hold' ? (
            <Form.Item
              label="Comments"
              name="comment"
              className="no-boder"
              rules={[{ required: true, message: 'leave your comment!', whitespace: true }]}
            >
              <Input.TextArea
                name="comment"
                value={activeForm?.comment}
                onChange={(e) => onHandleChange(e, 'comment')}
              />
            </Form.Item>
          ) : null}
        </UserLastRow>

        <DrawerFooter className="drawer-footer-style drawerButton">
          <Form.Item className="no-boder">
            {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            )}
            {props.visible &&
              (props.viewMode === 'Edit' ? (
                <SubmitButton htmlType="submit" type="primary">
                  Save
                </SubmitButton>
              ) : props.viewMode === 'Create' ? (
                <SubmitButton htmlType="submit" type="primary">
                  Submit
                </SubmitButton>
              ) : null)}

            {props.userRole === Role.Business && milestoneId ? (
              <DeleteTabDiv onClick={() => deleteMilestone(milestoneId)}>
                <DeleteOutlined />
              </DeleteTabDiv>
            ) : null}
          </Form.Item>
        </DrawerFooter>
      </Spin>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    memberGoals: state.programRuducer.memberGoals,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerDataList: state.notificationReducer.customerDataList,
    addTargetList: state.programRuducer.addTargetList,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(MilestoneForm);
