import React from 'react';
// import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import {
  LoginContainer, LogoContainer, Logo, VerifyText,
} from './styles';
import SiteLogo from '../../images/autism_360_white_new_logo.png';
// import SiteBlackLogo from "../../images/autism_logo_black.svg";
import SiteBlackLogo from '../../images/icons/logo-text.png';

const VerificationScreen = (props) => {
  const { isMobile } = props;

  return (
    <div>
      <LoginContainer>
        <LogoContainer>
          <div className="Signin-container">
            <div className="Background-rectangle-image">
              <div className="RectangleImage" />
            </div>

            <div className="sideContainer">
              <div className="sideMenu">
                <div className="Signin-image">
                  <div className="LogoIcons">
                    <img
                      src={isMobile ? SiteLogo : SiteBlackLogo}
                      style={{ width: '150px' }}
                    />
                  </div>
                  <div className="welcomeText">
                    <div className="SignInText"> Verification </div>
                    <div className="autismText">
                      {' '}
                      autism
                      <span className="autism360Text">360</span>
                      {' '}
                    </div>
                    <div className="verificationCredentialsText">
                      {' '}
                      Please check a verification email
                      <br />
                      {' '}
                      has been sent.
                      {' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LogoContainer>
      </LoginContainer>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(VerificationScreen);
