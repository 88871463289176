import { EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Space, Spin, Table, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { membersPaginationApi } from '../api/member';
import { searchUserApi } from '../api/user';
import { userMemberDataType } from '../constant';
import {
  setMemberData,
  setMemberLoading,
  setMembertCurrentPage,
  setTotalMemberCount
} from '../redux/actions/userMemberAction';
import { Role } from '../utils/types/role';
import AddMemberComponent from './AddUser/AddMemberComponent';
import { UserContainer } from './common/styles';
import { SearchContainer } from './ListingComponent/styles';

interface MembersComponentType {
  dispatch: any;
  userMemberData: userMemberDataType;
  customerDataList?: any;
  activeKey?: any;
  show?: any;
  role?: any;
}

const MemberFilteringComponent: React.FC<MembersComponentType> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const [isAddingMember, setisAddingMember] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { userMemberData, customerDataList } = props;
  const { currentLoginUser } = userMemberData;
  const [customerList, setCustomerList] = useState<any>([]);
  const { TabPane } = Tabs;
  const callback = (key: string) => {
    setKey(key);
    dispatch(setMemberLoading(true));
    dispatch(setMemberData([]));
    fetchList(50, 0, key)
      .then((res) => {
        const { data, count } = res;
        dispatch(setMemberData(data));
        dispatch(setMemberLoading(false));
        dispatch(setTotalMemberCount(count));
        dispatch(setMembertCurrentPage(1));
      })
      .catch((err) => {
        console.log('Error caught!', err.message);

        dispatch(setMemberLoading(false));
      });
  };
  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const fetchList = useCallback(
    (pageSize: number = 0, skip: number = 0, filter: string = 'Active') => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        filter
      };
      return membersPaginationApi(options);
    },
    []
  );
  const fetchUserList = useCallback((searchTerm: string = '', skip: number = 0) => {
    const options = {
      skip: skip || 0,
      limit: '',
      filter: props.activeKey
    };

    return searchUserApi(Role.Customer, options);
  }, []);
  useEffect(() => {
    dispatch(setMemberLoading(true));
    dispatch(setMemberData([]));
    fetchUserList('')
      .then((res) => {
        const { data } = res;
        setCustomerList(data);
      })
      .catch((err) => {
        console.log('Error caught!', err.message);
      });
  }, []);
  return (
    <div>
      {currentLoginUser.type != 'BusinessUser' ? (
        <UserContainer>
          <AddMemberComponent
            onCloseDrawer={onCloseDrawer}
            customerList={customerList}
            user={Role.Customer}
            setVisible={setisAddingMember}
            visible={isAddingMember}
            activekey={chooseKey}
          />
        </UserContainer>
      ) : null}

      <div
        className={
          customerDataList.type != 'Administrator'
            ? 'site-layout-background member-layout-in-business'
            : 'site-layout-background member-layout'
        }
      >
        <Tabs activeKey={chooseKey} type="card" onChange={callback}>
          <TabPane tab="Active" key="Active">
            <MembersComponent {...props} activeKey={chooseKey} show="Active" role={Role.Admin} />
          </TabPane>
          <TabPane tab="Expiring" key="Expiring">
            <MembersComponent {...props} activeKey={chooseKey} show="Expiring" role={Role.Admin} />
          </TabPane>
          <TabPane tab="Expired" key="Expired">
            <MembersComponent {...props} activeKey={chooseKey} show="Expired" role={Role.Admin} />
          </TabPane>
          <TabPane tab="On hold" key="OnHold">
            <MembersComponent {...props} activeKey={chooseKey} show="OnHold" role={Role.Admin} />
          </TabPane>
          <TabPane tab="Inactive" key="Inactive">
            <MembersComponent {...props} activeKey={chooseKey} show="Inactive" role={Role.Admin} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const MembersComponent: React.FC<MembersComponentType> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [perPageRecords, setperPageRecords] = useState<number>(10);
  const [visible, setVisible] = useState<boolean>(false);
  const [isAddingMember, setisAddingMember] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<string>('');
  const [recordSelected, setRecordSelected] = useState<any>();
  const [customerList, setCustomerList] = useState<any>([]);
  const { dispatch, userMemberData } = props;
  const {
    currentLoginUser,
    memberData = [],
    memberLoading,
    totalMemberCount,
    memberCurrentPage
  } = userMemberData;

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const fetchList = useCallback(
    (pageSize: number = 0, skip: number = 0, filter: string = props.activeKey, searchTerm) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        filter,
        search: searchTerm
      };
      return membersPaginationApi(options);
    },
    [perPageRecords]
  );

  const fetchUserList = useCallback(
    (searchTerm: string = '', skip: number = 0, filter: string = props.activeKey) => {
      const options = {
        skip: skip || 0,
        limit: '',
        filter
      };

      return searchUserApi(Role.Customer, options);
    },
    []
  );

  useEffect(() => {
    setSearchTerm('');
  }, [props.activeKey]);

  useEffect(() => {
    dispatch(setMemberLoading(true));
    dispatch(setMemberData([]));
    fetchList(perPageRecords, 0, props.activeKey, '')
      .then((res) => {
        const { data, count } = res;
        dispatch(setMemberData(data));
        dispatch(setMemberLoading(false));
        dispatch(setTotalMemberCount(count));
        dispatch(setMembertCurrentPage(1));
      })
      .catch((err) => {
        console.log('Error caught!', err.message);

        dispatch(setMemberLoading(false));
      });
    fetchUserList('')
      .then((res) => {
        const { data } = res;
        setCustomerList(data);
      })
      .catch((err) => {
        console.log('Error caught!', err.message);
      });
  }, [fetchList, dispatch, perPageRecords]);

  const onPageChange = useCallback(
    (page: number) => {
      const pageCount = perPageRecords;
      const skipPageCount = page === 1 ? 0 : perPageRecords * (page - 1);

      dispatch(setMemberLoading(true));
      dispatch(setMembertCurrentPage(page));

      fetchList(pageCount, skipPageCount, props.activeKey, searchTerm)
        .then((res) => {
          const { data, count } = res;
          dispatch(setMemberData(data));
          dispatch(setMemberLoading(false));
          dispatch(setTotalMemberCount(count));
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
          dispatch(setMemberLoading(false));
        });
      fetchUserList('')
        .then((res) => {
          const { data } = res;
          setCustomerList(data);
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
        });
    },
    [fetchList, dispatch, perPageRecords, searchTerm]
  );

  const onSearch = useCallback(
    (searchTerm) => {
      dispatch(setMemberLoading(true));
      fetchList(perPageRecords, 0, props.activeKey, searchTerm)
        .then((res) => {
          const { data, count } = res;
          dispatch(setMemberData(data));
          dispatch(setMemberLoading(false));
          dispatch(setTotalMemberCount(count));
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
          dispatch(setMemberLoading(false));
        });
    },
    [dispatch, searchTerm]
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (!value) {
      onSearch(value);
    }
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && searchTerm != '') {
        onSearch(searchTerm);
      }
    },
    [searchTerm, onSearch]
  );

  const memberCalculatedAge = () =>
    // No-longer warn on membership ending/ended as membership just on-going now.
    '';

  const columns = [
    {
      title: '',
      dataIndex: 'warning',
      key: 'warning',
      render: (text, record) => <>{memberCalculatedAge()}</>
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => `${record.firstName} ${record.lastName ? record.lastName : ''}`
    },
    {
      title: 'Parent Name',
      dataIndex: 'parentName',
      key: 'parentName',
      render: (text, record) =>
        `${record.parent?.firstName ? record.parent?.firstName : '-'}${' '}${record.parent?.lastName ? record.parent?.lastName : ''}` ||
        '-'
    },
    {
      title: 'Parent Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => record.parent?.email || '-'
    },
    {
      title: 'Primary Clinician',
      dataIndex: 'primaryContact',
      key: 'primaryContact',
      render: (text, record) =>
        record.primaryContact
          ? `${record.primaryContact?.firstName} ${record.primaryContact?.lastName}`
          : '-'
    },
    {
      title: 'Listing Type',
      key: 'listingType',
      dataIndex: 'listingType',
      render: (text, record) =>
        record.listingType ? record.listingType.split('_').join(' ') : '--'
    },
    {
      title: 'Listing Identifier',
      key: 'listingIdentifier',
      dataIndex: 'listingIdentifier',
      render: (text, record) => (record.listingIdentifier ? record.listingIdentifier : '--')
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setRecordSelected(record);
              setViewMode('View');
              setVisible(true);
            }}
          >
            <EyeOutlined />
          </Button>
          {currentLoginUser.type === 'Administrator' && (
            <Button
              type="link"
              onClick={() => {
                setRecordSelected(record);
                setViewMode('Edit');
                setVisible(true);
              }}
            >
              <EditOutlined />
            </Button>
          )}
        </Space>
      )
    }
  ];

  return (
    <div>
      <div className="site-layout-background member-container">
        <SearchContainer className="search-box search-member">
          <Button
            onClick={() => (searchTerm != '' ? onSearch(searchTerm) : null)}
            className="SearchButton"
          >
            <SearchOutlined />
          </Button>
          <Input
            onKeyPress={handleKeyPress}
            name="search"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Search member..."
          />
        </SearchContainer>
        <Spin spinning={memberLoading}>
          <Table
            columns={columns}
            dataSource={memberData}
            className="member-pagination"
            pagination={{
              defaultPageSize: perPageRecords,
              pageSize: perPageRecords,
              position: ['bottomLeft']
            }}
          />
        </Spin>
        <Pagination
          // hideOnSinglePage={true}
          current={memberCurrentPage}
          total={totalMemberCount}
          pageSize={perPageRecords}
          onChange={onPageChange}
          className="my-pagination"
          onShowSizeChange={(current, pagesize) => setperPageRecords(pagesize)}
        />
      </div>
      <AddMemberComponent
        user={Role.Customer}
        chooseForm={viewMode}
        editMode={viewMode === 'Edit'}
        customerList={customerList}
        memberId={recordSelected?._id}
        setVisible={setVisible}
        visible={visible}
        activekey={props.activeKey}
        member={recordSelected}
        icon={<div />}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    customerDataList: state.notificationReducer.customerDataList
  };
}
export default connect(mapStateToProps)(MemberFilteringComponent);
// export default connect(mapStateToProps)(MembersComponent);
