import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Popover } from 'antd';
import { SearchContainer } from '../ListingComponent/styles';
import useDebounce from '../../utils/hooks/useDebounce';
import ResourcesSearchResult from './ResourcesSearchResult';

export default function SearchBar(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(/[^\w\s]/g, '');
    setSearchTerm(value);
  };

  const searchResult = (
    <div
      style={{
        width: '64rem',
        maxWidth: 'calc(100vw - 6rem)',
      }}
      className="px-2"
    >
      <ResourcesSearchResult searchTerm={debouncedSearchTerm} />
    </div>
  );

  return (
    <SearchContainer className="search-box grow">
      <Button onClick={() => {}} className="SearchButton">
        <SearchOutlined />
      </Button>
      <Popover
        content={searchResult}
        placement="bottom"
        trigger="click"
        visible={popoverOpen}
        onVisibleChange={(visible) => setPopoverOpen(visible)}
      >
        <div className="w-full">
          <Input
            name="search"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Search resource"
            onClick={(e) => {
              e.stopPropagation();
              setPopoverOpen(true);
            }}
          />
        </div>
      </Popover>
    </SearchContainer>
  );
}
