import {
  SET_BANNER_HEADER_LABEL, SET_MENU_INDEX, SET_SUB_MENU_INDEX, SET_TRACKING_CODE, SET_TRACKING_LOADING, SET_IS_MOBILE,
} from '../types/types';

export function setTrackingCode(data: Array<any>) {
  return {
    type: SET_TRACKING_CODE,
    payload: data,
  };
}

export function setBannerHeaderLabel(data: string) {
  return {
    type: SET_BANNER_HEADER_LABEL,
    payload: data,
  };
}

export function setSubMenuIndex(data: string) {
  return {
    type: SET_SUB_MENU_INDEX,
    payload: data,
  };
}

export function setMenuIndex(data: string) {
  return {
    type: SET_MENU_INDEX,
    payload: data,
  };
}

export function setTrackingLoading(data: boolean) {
  return {
    type: SET_TRACKING_LOADING,
    payload: data,
  };
}
export function setIsMobile(data: boolean) {
  return {
    type: SET_IS_MOBILE,
    payload: data,
  };
}
