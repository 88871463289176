import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { SubmitButton, TextAreaField } from './styles';
import { addTrackingCode, getTrack } from '../../api/tracking';
import { showNotificationDrawer } from '../common/utils';
import { setTrackingCode, setTrackingLoading } from '../../redux/actions/trackingAction';

const TrackingComponent: React.FC = (props: any) => {
  const [text, setText] = useState<string>('');

  const { dispatch, trackReducer } = props;
  const { trackingCode, trackingLoading } = trackReducer;

  useEffect(() => {
    if (trackingCode) {
      const decodeData = decodeURIComponent(trackingCode.code) || '';
      setText(decodeData);
    }
  }, [trackingCode]);

  const onChangeText = (event: any) => {
    setText(event.target.value);
  };

  const handleSubmit = () => {
    if (!trackingCode) {
      addTracking(text, 'add');
      return;
    }
    addTracking(text, 'update');
  };

  const addTracking = (code: string, msg: string) => {
    const data = encodeURIComponent(code);
    dispatch(setTrackingLoading(true));
    addTrackingCode({
      code: data,
    })
      .then((res) => {
        showNotificationDrawer('Success', `${msg === 'add' ? 'Tracking added successfully!' : 'Tracking updated successfully!'}`);
        getTrack().then((res) => {
          const { data } = res;
          const trackCode = data.length ? data[0] : {};
          const decodeData = decodeURIComponent(trackCode.code) || '';
          setText(decodeData);
          dispatch(setTrackingCode(trackCode));
          dispatch(setTrackingLoading(false));
        }).catch((error) => {
          dispatch(setTrackingLoading(false));
        });
      })
      .catch((err) => {
        showNotificationDrawer('Error', 'Something went wrong!');
        dispatch(setTrackingLoading(false));
      });
  };

  return (
    <div className="common-container">
      <Spin spinning={trackingLoading}>
        <div className="site-layout-background">
          <TextAreaField rows={10} value={text} onChange={onChangeText} />
          <SubmitButton type="primary" htmlType="submit" onClick={handleSubmit}>
            {trackingCode
              ? 'Update Tracking Code'
              : 'Add Tracking Code'}
          </SubmitButton>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    trackReducer: state.trackReducer,
  };
}

export default connect(mapStateToProps)(TrackingComponent);
