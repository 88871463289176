export enum Role {
    Admin = 'Administrator',
    Business = 'BusinessUser',
    Customer = 'Customer',
    Member = 'Member',
    BusinessAccesCustomer = 'BusinessAccesCustomer',
}

export enum Mode {
    Create = 'Create',
    Edit = 'Edit',
    View = 'View',
}
