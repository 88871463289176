import { SET_RESOURCE_OBJECTIVE_LIST, SET_RESOURCE_OBJECTIVE_PAGINATION } from '../types/types';

export function setResourceObjectiveList(data) {
  return {
    type: SET_RESOURCE_OBJECTIVE_LIST,
    payload: data
  };
}

export function setResourceObjectivePagination(data) {
  return {
    type: SET_RESOURCE_OBJECTIVE_PAGINATION,
    payload: data
  };
}
