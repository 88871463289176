import { Checkbox, Collapse, Form, Input, List, Modal, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { postNotificationApi } from '../api/notification';
import { listUserApi } from '../api/user';
import { getGoalsApi, updateTaskApi, updateTaskStatusApi } from '../api/user-program';
import DownExpandArrow from '../images/icons/ArrowDown.svg';
import EditIcon from '../images/icons/EditIcon.svg';
import LeftExpandArrow from '../images/icons/leftArrow.svg';
import ShowPasswordBlack from '../images/icons/showPasswordBlack.png';
import { setCustomerLoading } from '../redux/actions/customerAction';
import { setNotificationAlert } from '../redux/actions/notificationAction';
import { setAssigneeList, setMemberGoals } from '../redux/actions/userProgramAction';
import { Role } from '../utils/types/role';
import { getUniqueUsersByMerge } from '../utils/users';
import AddMilestone from './AddUser/AddMilestone';
import AddTasks from './AddUser/AddTasks';
import {
  DashedMessageDiv,
  EditViewIcon,
  ListBoxDiv,
  MileStoneTitle,
  TitleDiv,
  UserContainer
} from './common/styles';
import { ProgramRole } from './MyGoalsComponent';

const { Panel } = Collapse;
const { Title, Text } = Typography;

function callback(key: string | string[]) {
  // console.log(key);
}

const PAGE_SIZE: number = 50;

const role = Role.Business;
const show = 'Active';

const MileStoneComponent: React.FC<any> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const [teamMember, setTeamMember] = useState<any>([]);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [taskSelected, setTaskSelected] = useState<any>({});
  const {
    dispatch,
    goalInfo,
    loginUserData,
    currentLoginUser,
    customerSelectedMember,
    customerDataList,
    state,
    memberGoals
  } = props;

  const fetchList = useCallback((searchTerm: string = '', skip: number = 0) => {
    const options = {
      filter: show,
      search: searchTerm.toLowerCase(),
      skip: skip || 0,
      limit: PAGE_SIZE
    };
    return listUserApi(role, options);
  }, []);

  useEffect(() => {
    let dataList = [loginUserData, currentLoginUser];
    // In case of customer, loginUserData and currentLogainUser are same, remove duplication...
    if (currentLoginUser.type === Role.Customer) dataList = [currentLoginUser];

    fetchList('').then((res) => {
      setTeamMember(res.data);
      dataList = getUniqueUsersByMerge(dataList, res.data);
      dispatch(setAssigneeList(dataList));
    });

    dispatch(setAssigneeList(dataList));
  }, [dispatch, currentLoginUser, fetchList]);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
    setModalVisible(false);
  }, [chooseKey]);

  const getGoalsData = () => {
    if (goalInfo && goalInfo.member) {
      getGoalsApi(goalInfo.member).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
    }
  };
  const StatusSkillColorSwitch = (status_color) => {
    switch (status_color) {
      case 'Mastered':
        return 'achieved-color';
      case 'Active':
        return 'inProgress-color';
      case 'Complete':
        return 'inProgress-color';
      case 'In-progress':
        return 'inProgress-color';
      case 'On-hold':
        return 'on-hold-color';
      case 'Cancelled':
        return 'cancelled-color';
      default:
        return 'mileStone-panel-text';
    }
  };

  const customClass =
    currentLoginUser.type === Role.Business ? 'milestone-label' : 'milestone-label';
  const modifyMilestone =
    goalInfo.milestones && goalInfo.milestones.length
      ? goalInfo.milestones.filter((itemData) => itemData.isDeleted !== true)
      : [];

  return (
    <div className="mileStone-container">
      <UserContainer className="add-milestone">
        <TitleDiv className={customClass}>
          <Title level={5} className="titleStyle">
            Skills
          </Title>
        </TitleDiv>
        {/* {currentLoginUser.type === Role.Business ? <AddMilestone getGoalsData={getGoalsData} onCloseDrawer={onCloseDrawer} user={Role.Customer} formTypeLabel="Skill" goalFormType={ProgramRole.Milestone} addButtonLabel="Add Skill" goalData={goalInfo} teamMember={teamMember} /> : null} */}
        {currentLoginUser.type === Role.Business &&
        goalInfo?.status != 'Cancelled' &&
        goalInfo?.status != 'Achieved' &&
        goalInfo?.status != 'On-hold' ? (
          <AddMilestone
            getGoalsData={getGoalsData}
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            formTypeLabel="Skill"
            goalFormType={ProgramRole.Milestone}
            addButtonLabel="Add Skill"
            goalData={goalInfo}
            teamMember={teamMember}
          />
        ) : null}
      </UserContainer>
      {modifyMilestone.length ? (
        modifyMilestone.map((skill, index) => {
          const modifyTasks =
            skill.tasks && skill.tasks.length
              ? skill.tasks.filter((itemData) => itemData.isDeleted !== true)
              : [];
          const customeIndex = index + 1;
          return (
            <Collapse
              defaultActiveKey={['0']}
              onChange={callback}
              expandIcon={({ isActive }) =>
                isActive ? <img src={DownExpandArrow} /> : <img src={LeftExpandArrow} />
              }
            >
              <div className="addtaskComp">
                <span className="add-milestone-task-tab">
                  <EditViewIcon className="milestone-view-edit">
                    <AddMilestone
                      getGoalsData={getGoalsData}
                      onCloseDrawer={onCloseDrawer}
                      user={Role.Customer}
                      formTypeLabel="Skill"
                      goalFormType={ProgramRole.Milestone}
                      chooseForm="View"
                      addButtonLabel="View Skill"
                      icon={
                        <img
                          src={ShowPasswordBlack}
                          className={
                            goalInfo?.status === 'Achieved' ||
                            goalInfo?.status === 'Cancelled' ||
                            (currentLoginUser.type === Role.Admin &&
                              goalInfo?.status === 'Planned') ||
                            skill?.status === 'Cancelled' ||
                            skill?.status === 'Mastered'
                              ? 'view-icon-styling'
                              : ''
                          }
                        />
                      }
                      goalData={goalInfo}
                      mileStoneData={skill}
                      milestoneId={skill._id}
                      teamMember={teamMember}
                    />
                    {currentLoginUser.type === Role.Business &&
                    goalInfo?.status != 'Cancelled' &&
                    goalInfo?.status != 'Achieved' &&
                    skill?.status != 'Cancelled' &&
                    skill?.status != 'Mastered' ? (
                      <AddMilestone
                        getGoalsData={getGoalsData}
                        onCloseDrawer={onCloseDrawer}
                        user={Role.Customer}
                        formTypeLabel="Skill"
                        goalFormType={ProgramRole.Milestone}
                        chooseForm="Edit"
                        addButtonLabel="Edit Skill"
                        icon={<img className="edit-button-bussiness" src={EditIcon} />}
                        goalData={goalInfo}
                        mileStoneData={skill}
                        milestoneId={skill._id}
                        teamMember={teamMember}
                      />
                    ) : null}
                  </EditViewIcon>

                  {/* {currentLoginUser.type !== Role.Admin  ? <AddTasks getGoalsData={getGoalsData} onCloseDrawer={onCloseDrawer} user={Role.Customer} goalFormType={ProgramRole.MyTasks} formTypeLabel="Tasks" addButtonLabel="Add Task" mileStoneData={skill} /> : null} */}
                  {currentLoginUser.type !== Role.Admin &&
                  goalInfo?.status != 'Cancelled' &&
                  goalInfo?.status != 'Achieved' &&
                  skill?.status != 'Cancelled' &&
                  skill?.status != 'Mastered' ? (
                    <AddTasks
                      getGoalsData={getGoalsData}
                      onCloseDrawer={onCloseDrawer}
                      user={Role.Customer}
                      goalFormType={ProgramRole.MyTasks}
                      formTypeLabel="Tasks"
                      addButtonLabel="Add Task"
                      mileStoneData={skill}
                    />
                  ) : null}
                </span>
              </div>
              <Panel
                key={customeIndex}
                className="custom-panel"
                disabled={!!(goalInfo?.status === 'Cancelled' || skill?.status === 'Cancelled')}
                header={
                  <div style={{ display: 'flex' }}>
                    <MileStoneTitle className={StatusSkillColorSwitch(skill?.status)}>
                      {skill.name}
                    </MileStoneTitle>
                    <span
                      className={StatusSkillColorSwitch(skill?.status)}
                      style={{ marginLeft: '200px' }}
                    >
                      {skill?.status === 'Complete' ||
                      skill?.status === 'In-progress' ||
                      skill?.status === 'Pending'
                        ? 'Active'
                        : skill?.status}
                    </span>
                  </div>
                }
              >
                <ListBoxDiv>
                  <Text className="task-label">Tasks</Text>
                  <List
                    className="task-list-div"
                    dataSource={modifyTasks}
                    renderItem={(item: any) => (
                      <List.Item>
                        <IsCompletedBox
                          skill={skill}
                          taskData={item}
                          loginUserType={currentLoginUser.type}
                          loginUserDetails={currentLoginUser}
                          getGoalsData={getGoalsData}
                          dispatch={dispatch}
                          customerDataList={customerDataList}
                          customerSelectedMember={customerSelectedMember}
                          currentLoginUser={currentLoginUser}
                          setModalVisible={(x) => {
                            setModalVisible(x);
                            setTaskSelected(item);
                          }}
                        />
                        {skill?.status !== 'Mastered' && (
                          <EditViewIcon className="task-view-edit-icon">
                            {currentLoginUser.type === Role.Business ||
                            item.createdBy === currentLoginUser._id ? (
                              <AddTasks
                                getGoalsData={getGoalsData}
                                onCloseDrawer={onCloseDrawer}
                                user={Role.Customer}
                                goalFormType={ProgramRole.MyTasks}
                                formTypeLabel="Tasks"
                                addButtonLabel="Edit Task"
                                icon={<img src={EditIcon} />}
                                chooseForm="Edit"
                                taskId={item._id}
                                taskData={item}
                                mileStoneData={skill}
                                createdByOther={
                                  item.createdBy !== currentLoginUser._id ? 'createdByOther' : ''
                                }
                              />
                            ) : null}
                          </EditViewIcon>
                        )}
                      </List.Item>
                    )}
                  />
                  <AddTasks
                    getGoalsData={getGoalsData}
                    onCloseDrawer={onCloseDrawer}
                    user={Role.Customer}
                    goalFormType={ProgramRole.MyTasks}
                    formTypeLabel="Tasks"
                    addButtonLabel="View Task"
                    chooseForm="View"
                    propVisible={isModalVisible}
                    taskId={taskSelected._id}
                    taskData={taskSelected}
                    mileStoneData={skill}
                    notifyVisible
                  />

                  {!modifyTasks.length ? (
                    <DashedMessageDiv>
                      {currentLoginUser.type === Role.Business && skill?.status !== 'Mastered' ? (
                        <span>Add Task by click Add Task Button</span>
                      ) : (
                        <span>No Tasks</span>
                      )}
                    </DashedMessageDiv>
                  ) : null}
                </ListBoxDiv>
              </Panel>
            </Collapse>
          );
        })
      ) : (
        <Collapse
          defaultActiveKey={['0']}
          collapsible={
            goalInfo?.status != 'Cancelled' &&
            goalInfo?.status != 'Achieved' &&
            goalInfo?.status != 'On-hold'
              ? 'header'
              : 'disabled'
          }
          onChange={callback}
          expandIcon={({ isActive }) =>
            isActive ? <img src={DownExpandArrow} /> : <img src={LeftExpandArrow} />
          }
        >
          <DashedMessageDiv>
            {currentLoginUser.type !== Role.Business ? (
              <span>Coach to Add Skills</span>
            ) : (
              <span>Add Skill by clicking Add Skill Button</span>
            )}
          </DashedMessageDiv>
        </Collapse>
      )}
    </div>
  );
};

const IsCompletedBox: React.FC<any> = (props) => {
  const {
    taskData,
    loginUserType,
    getGoalsData,
    memberGoals,
    dispatch,
    currentLoginUser,
    customerSelectedMember,
    customerDataList,
    setViewMode,
    setModalVisible,
    loginUserDetails,
    skill
  } = props;
  const [check, setCheck] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    if (taskData) {
      setCheck(taskData.isCompleted);
    }
    if (skill?.status === 'Mastered') {
      setCheck(skill?.isCompleted);
    }
  }, [taskData]);

  const handleCheckBox = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    const assigneeIdList = taskData.assignee.map((value) => value._id);
    const updateData = {
      ...taskData,
      isCompleted: checked,
      notes,
      assignee: assigneeIdList
      // taskType: taskData._id
    };
    let updateTaskDataCheck = false;
    if (loginUserType === 'Customer' && assigneeIdList.includes(currentLoginUser._id)) {
      updateTaskDataCheck = true;
    }
    if (loginUserType === 'BusinessUser') {
      updateTaskDataCheck = true;
    }
    if (checked && updateTaskDataCheck) {
      setCheck(checked);
      showModal();
    } else if (taskData._id) {
      if (updateTaskDataCheck) {
        dispatch(setCustomerLoading(true));
        updateTaskApi(taskData._id, updateData)
          .then((res) => {
            getGoalsData();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const onHandleChange = (e) => {
    const { value } = e.target;
    setNotes(value);
  };

  const handleOk = () => {
    const assignIdList = taskData.assignee.map((value) => value._id);
    const updateData = {
      ...taskData,
      isCompleted: check,
      notes,
      assignee: assignIdList,
      taskType: taskData._id
    };

    if (taskData._id) {
      dispatch(setCustomerLoading(true));
      updateTaskStatusApi(taskData._id, updateData)
        .then((res) => {
          getGoalsData();
          closeModal();

          const notification = {
            member: `${customerSelectedMember.length ? customerSelectedMember[0]._id : customerSelectedMember._id}`,
            businessUser: `${currentLoginUser && currentLoginUser._id}`,
            message: `${customerDataList && customerDataList.firstName} just Marked a Task as Complete`,
            user: `${customerSelectedMember.length ? customerSelectedMember[0].parent : customerSelectedMember.parent}`,
            task: `${taskData._id}`,
            type: 'TASK_MARKED'
          };

          postNotificationApi(notification).then((res) => {
            dispatch(setNotificationAlert(res.data));
          });
        })
        .catch((error) => {
          console.error(error);
          closeModal();
        });
    }
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    setCheck(false);
    setNotes('');
  };

  const closeModal = () => {
    setIsOpen(false);
    setNotes('');
  };

  return (
    <>
      <Checkbox
        checked={check}
        style={{ pointerEvents: loginUserType === Role.Admin ? 'none' : 'auto' }}
        onChange={handleCheckBox}
        className={`checkBoxWiddth ${check === true ? 'is-complete-task' : 'not-completed-task'}`}
        disabled={skill?.status === 'Mastered'}
      />
      <span
        className={`checkBoxWiddth ${check === true ? 'is-complete-task taskData-text-isCompleted' : 'not-completed-task taskData-text-notCompleted'} ${loginUserType === Role.Business || loginUserDetails?._id === taskData?.createdBy ? 'margin-text-in-task' : ''}`}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {taskData.name}
      </span>
      <Modal
        title={
          <div className="add-task-title-container">
            Add Task Note
            <div onClick={hideModal} className="add-task-close-button">
              Close
            </div>
          </div>
        }
        visible={isOpen}
        onOk={handleOk}
        onCancel={hideModal}
        closable={false}
        className="Add-task-modal-container"
        footer={[<button onClick={handleOk}>Ok</button>]}
      >
        <Form.Item
          name="notes"
          className="no-boder"
          rules={[{ required: false, message: 'Please input your app details!' }]}
        >
          <Input.TextArea rows={4} name="notes" onChange={onHandleChange} />
        </Form.Item>
      </Modal>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    currentLoginUser: state.userMemberData.currentLoginUser,
    loginUserData: state.userMemberData.loginUserData,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerDataList: state.notificationReducer.customerDataList,
    visibly: state.notificationReducer.visible,
    memberGoals: state.programRuducer.memberGoals
  };
}

export default connect(mapStateToProps)(MileStoneComponent);
