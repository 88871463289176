import { API_URL } from '../constant';
import { apiGet, apiPatch } from '../utils/api';

export const getWorkShop = () => apiGet(`${API_URL}workshop`);

export const patchWorkShop = (id, data) => apiPatch(`${API_URL}workshop/${id}`, data);

export const searchWorkShopApi = (options) => apiGet(`${API_URL}workshop`, options);

export const workShopDetailsApi = (id) => apiGet(`${API_URL}workshop/details/${id}`);
