import { SET_SESSION_DATA, REFRESH_SESSION_NOTES } from '../types/types';

const INITIAL_STATE = {
  sessionData: [],
  refreshSessionNotes: false,
};

const sessionReducers = (state = INITIAL_STATE, action: { type: any, payload: any }) => {
  switch (action.type) {
  case SET_SESSION_DATA:
    return {
      ...state, sessionData: action.payload,
    };
  case REFRESH_SESSION_NOTES:
    return {
      ...state, refreshSessionNotes: action.payload,
    };

  default:
    return state;
  }
};

export default sessionReducers;
