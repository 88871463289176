import React from 'react';
import logoText from '../images/autism_360_white_new_logo.png';

const SiteUnderMaintainence: React.FC = () => (
  <div className="bgimg">
    <div className="background-bubble-effect" />
    <div className="topleft">
      <img src={logoText} className="site-logo-for-maintainence" />
    </div>
    <div className="middle">
      <h1>Site Under Maintainence</h1>
      <hr />
    </div>
    <div className="bottomleft">
      <p>COMING SOON</p>
    </div>
  </div>

);

export default SiteUnderMaintainence;
