import { SET_RESOURCE_OBJECTIVE_LIST, SET_RESOURCE_OBJECTIVE_PAGINATION } from '../types/types';

export interface ResourceObjectiveReduxState {
  objectiveList: any[];
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
}

const INITIAL_STATE: ResourceObjectiveReduxState = {
  objectiveList: [],
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0
  }
};

const resourceObjectiveReducer = (state = INITIAL_STATE, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_RESOURCE_OBJECTIVE_LIST:
      return {
        ...state,
        objectiveList: action.payload
      };
    case SET_RESOURCE_OBJECTIVE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default resourceObjectiveReducer;
