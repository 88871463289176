import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchAuthorApi } from '../api/author';
import { UserContainer } from '../pages/dashboard/styles';
import { setCustomerLoading } from '../redux/actions/customerAction';
import { setIsOpenDrawer, setShowFormData } from '../redux/actions/formAction';
import { Mode, Role } from '../utils/types/role';
import AddAuthor from './AddUser/AddAuthor';
import { AddButton, CustomerContainer } from './common/styles';
import { SearchContainer } from './ListingComponent/styles';
import AuthorTableComponent from './TableData/AuthorTableComponent';

import {
  setAuthorCurrentPage,
  setAuthorList,
  setAuthorPageCount
} from '../redux/actions/userMemberAction';

const AuthorComponent: React.FC<any> = (props) => {
  const { dispatch, authorList, userMemberData, onlyDisplayActiveResources, currentLoginUser } =
    props;

  const [perPageRecords, setperPageRecords] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isloading, setLoading] = useState<boolean>(false);
  const { totalAuthorCount, authorCurrentPage } = userMemberData;
  const fetchAuthorList = useCallback(
    (pageSize: number = 0, skip: number = 0, searchTerm: string) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        search: searchTerm
      };

      dispatch(setCustomerLoading(false));
      return searchAuthorApi(options);
    },
    []
  );

  useEffect(() => {
    // fetchAuthorList(perPageRecords, 0,'').then((res)=>{
    //     const { data, count } = res;
    //     dispatch(setAuthorList(data))
    //     dispatch(setCustomerLoading(false))
    //     dispatch(setAuthorPageCount(count));
    //     // dispatch(setAuthorCurrentPage(1));
    // }).catch((err)=>{
    //     dispatch(setCustomerLoading(false))
    // })
  }, [dispatch, fetchAuthorList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = useCallback(
    (searchTerm) => {
      setLoading(true);

      fetchAuthorList(perPageRecords, 0, searchTerm)
        .then((res) => {
          const { data, count } = res;
          dispatch(setAuthorList(data));
          dispatch(setAuthorCurrentPage(1));
          dispatch(setAuthorPageCount(count));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [dispatch, searchTerm, perPageRecords]
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (!value) {
      onSearch(value);
    }
  };
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && searchTerm != '') {
        onSearch(searchTerm);
      }
    },
    [searchTerm, onSearch]
  );

  const openDrawar = (e: any) => {
    e.stopPropagation();
    const createFormData = {
      id: '',
      userType: currentLoginUser.type,
      formMode: Mode.Create,
      formLabel: 'Author'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onPageChange = (page: number, pageSize?: number) => {
    const pageCount = pageSize || perPageRecords;
    const skipPageCount = page <= 1 ? 0 : pageCount * (page - 1);

    dispatch(setAuthorCurrentPage(page));
    setLoading(true);
    fetchAuthorList(pageCount, skipPageCount, searchTerm)
      .then((res) => {
        const { data, count } = res;

        setLoading(false);
        dispatch(setAuthorList(data));
        dispatch(setAuthorPageCount(count));
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Spin spinning={isloading}>
        {currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin ? (
          <UserContainer>
            <AddButton type="primary" onClick={openDrawar}>
              <PlusOutlined />
            </AddButton>
            <AddAuthor
              searchTerm={searchTerm}
              pagination={(authorCurrentPage - 1) * perPageRecords}
              PerPageRecords={perPageRecords}
              changePageToOne={() => dispatch(setAuthorCurrentPage(1))}
            />
          </UserContainer>
        ) : null}

        <div className="site-layout-background">
          <SearchContainer className="search-box search-video-box">
            <Button
              onClick={() => (searchTerm != '' ? onSearch(searchTerm) : null)}
              className="SearchButton"
            >
              <SearchOutlined />
            </Button>
            <Input
              onKeyPress={handleKeyPress}
              name="search"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search author"
            />
          </SearchContainer>
          <div className="site-card-wrapper">
            <CustomerContainer>
              <AuthorTableComponent
                searchTerm={searchTerm}
                className="resources-table "
                PerPageRecords={perPageRecords}
                pagination={{
                  defaultPageSize: perPageRecords,
                  pageSize: perPageRecords,
                  position: ['bottomLeft']
                }}
                authorData={authorList}
                onlyDisplayActiveResources={onlyDisplayActiveResources}
              />
              <Pagination
                current={authorCurrentPage}
                total={totalAuthorCount}
                pageSize={perPageRecords}
                onChange={onPageChange}
                className="my-pagination"
                onShowSizeChange={(current, pagesize) => {
                  setperPageRecords(pagesize);
                }}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    customerLoading: state.customerRuducer.customerLoading,
    authorList: state.userMemberData.authorList,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(AuthorComponent);
