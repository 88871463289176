import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getBroadcastMessage, searchBroadcastMessageApi } from '../api/broadcastMessage';
import { UserContainer } from '../pages/dashboard/styles';
import { Role } from '../utils/types/role';
import { AddButton, CustomerContainer } from './common/styles';
import BroadcastMessageForm from './FormComponent/BroadcastMessageForm';
import { SearchContainer } from './ListingComponent/styles';
import BroadcastMessageTableComponent from './TableData/BroadcastMessageTableComponent';

export interface BroadcastMessageComponentProps {
  currentLoginUser: any;
}

const BroadcastMessageComponent: React.FC<any> = (props: BroadcastMessageComponentProps) => {
  const { currentLoginUser } = props;

  const [isloading, setLoading] = useState<boolean>(false);
  const [broadcastMessageList, setBroadcastMessageList] = useState<BroadcastMessage[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [perPageRecords, setPerPageRecords] = useState<number>(10);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    try {
      if (searchTerm === '') {
        const response = await getBroadcastMessage();
        setBroadcastMessageList(response?.data);
      } else {
        const options = {
          skip: 0,
          limit: perPageRecords,
          search: searchTerm
        };
        const response = await searchBroadcastMessageApi(options);
        setBroadcastMessageList(response?.data);
      }
    } catch (error) {
      console.error('Error fetching broadcast messages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') fetchData();
    },
    [searchTerm]
  );

  const showDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <div>
      <Spin spinning={isloading}>
        <UserContainer>
          {currentLoginUser.type === Role.Admin && (
            <>
              <AddButton type="primary" onClick={showDrawer}>
                <PlusOutlined />
              </AddButton>
              <Drawer
                title="Create Broadcast Message Form"
                placement="right"
                onClose={closeDrawer}
                visible={open}
                width="520"
                className="user-drawer"
              >
                {open && (
                  <BroadcastMessageForm
                    fetchData={fetchData}
                    permission="create"
                    onClose={closeDrawer}
                  />
                )}
              </Drawer>
            </>
          )}
        </UserContainer>

        <div className="site-layout-background">
          <SearchContainer className="search-box search-video-box">
            <Button
              onClick={() => (searchTerm != '' ? fetchData() : null)}
              className="SearchButton"
            >
              <SearchOutlined />
            </Button>
            <Input
              onKeyPress={handleKeyPress}
              name="search"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search Broadcast Message"
            />
          </SearchContainer>
          <div className="site-card-wrapper">
            <CustomerContainer>
              <BroadcastMessageTableComponent
                className="resources-table "
                broadcastMessageData={broadcastMessageList}
                fetchData={fetchData}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.userMemberData.currentLoginUser
});
export default connect(mapStateToProps)(BroadcastMessageComponent);
