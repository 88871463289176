import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Drawer, Image, Space, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import altThumbnailImage from '../../images/thumbnailAlt.png';
import { Role } from '../../utils/types/role';
import DeepLink from '../common/DeepLink';
import { ColDiv, EditViewIcon } from '../common/styles';
import BlogForm from '../FormComponent/BlogForm';

export interface BlogTableComponentProps {
  currentLoginUser: any;
  className: string;
  blogData: Blog[];
  onlyDisplayActiveResources?: boolean;
  fetchData: () => void;
}
const BlogTableComponent: React.FC<BlogTableComponentProps> = (props: BlogTableComponentProps) => {
  const { currentLoginUser, blogData, className, onlyDisplayActiveResources, fetchData } = props;

  const [isloading, setIsloading] = useState<boolean>(false);
  const [permission, setPermission] = useState<'view' | 'edit' | 'create'>('view');
  const [currentBlogId, setCurrentBlogId] = useState<string | undefined>();

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const onView = () => {
    setPermission('view');
    showDrawer();
  };

  const onEdit = () => {
    setPermission('edit');
    showDrawer();
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (text, record) => (
        <ColDiv>
          {record?.thumbnail ? (
            <Image
              width="150px"
              height="100px"
              src={record.thumbnail}
              style={{ objectFit: 'cover' }}
            />
          ) : (
            <img width="150px" height="100px" src={altThumbnailImage} />
          )}
        </ColDiv>
      ),
      width: 200
    },
    {
      title: 'Share Link',
      dataIndex: 'externalDeepLink',
      key: 'externalDeepLink',
      sorter: (a, b) =>
        a.externalDeepLink &&
        b.externalDeepLink &&
        a.externalDeepLink.localeCompare(b.externalDeepLink),
      render: (text, record) => (
        <ColDiv>
          <DeepLink
            type="external"
            deepLink={record.externalDeepLink}
            hidden={record.status !== 'Published'}
          />
        </ColDiv>
      ),
      width: 300
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title && b.title && a.title.localeCompare(b.title),
      render: (text, record) => <ColDiv>{record?.title}</ColDiv>,
      width: 300
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text, record) => <ColDiv>{record?.categories?.name}</ColDiv>,
      width: 200
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <ColDiv>{record?.status}</ColDiv>,
      width: 200
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <DeepLink
              type="internal"
              deepLink={record.internalDeepLink}
              hidden={record.status !== 'Published'}
            />
            <span
              onClick={() => {
                setCurrentBlogId(record._id);
                onView();
              }}
            >
              <EyeOutlined key="eye" />
            </span>
            {currentLoginUser.type === Role.Admin ? (
              <span
                onClick={() => {
                  setCurrentBlogId(record._id);
                  onEdit();
                }}
              >
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <Spin spinning={isloading}>
      <Table
        className={`${className} ${onlyDisplayActiveResources === true ? 'hide' : ''}`}
        columns={columns}
        dataSource={blogData}
        rowKey="_id"
      />
      <Drawer
        title={`${permission === 'edit' ? 'Edit' : 'View'} Blog Form`}
        placement="right"
        onClose={closeDrawer}
        maskClosable={false}
        visible={open}
        width="1024"
        className="user-drawer"
      >
        {open && (
          <BlogForm
            fetchData={fetchData}
            currentBlogId={currentBlogId}
            permission={permission}
            onClose={closeDrawer}
          />
        )}
      </Drawer>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.userMemberData.currentLoginUser
});
export default connect(mapStateToProps)(BlogTableComponent);
