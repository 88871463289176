import React, { ReactChild, useEffect, useState } from 'react';
import { Drawer, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { ProgramAddButton } from '../common/styles';
import { Role } from '../../utils/types/role';
import MilestoneForm from '../FormComponent/MilestoneForm';
import { getMilestoneTypesApi } from '../../api/user-program';
import { showNotificationDrawer } from '../common/utils';
import Plus from '../../images/Plus_White.svg';
import { skillStatus } from '../../constant';

interface AddMilestoneProps {
    user: Role;
    icon?: ReactChild;
    edit?: boolean;
    chooseForm?: string;
    onCloseDrawer?: () => void;
    goalFormType?: string;
    formTypeLabel?: string;
    addButtonLabel?: string;
    mileStoneTypes?: any;
    goalData: any;
    mileStoneData?: any;
    milestoneId?: any;
    teamMember?: any;
    getGoalsData?: any;
    notifyVisible?:boolean;
    propVisible?: boolean;
    isMobile?:any;
}

export interface goalTypeListType {
    title: string;
    value: string;
}

const AddMilestone: React.FC<AddMilestoneProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [role, setRole] = useState<Role>(props.user);
  const [mileStoneTypes, setMileStoneTypes] = useState<any>([]);
  const {
    user, mileStoneData, getGoalsData, propVisible, isMobile,
  } = props;

  useEffect(() => {
    setRole(user);

    getMilestoneTypesApi().then((res) => {
      setMileStoneTypes(res.data);
    });
  }, [user]);

  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  const closeDrawer = (title, content) => {
    setVisible(false);
    if (title) {
      openNotification(title, content);
    }

    if (props.onCloseDrawer) {
      props.onCloseDrawer();
    }
  };

  let showTitle = '';

  switch (props.chooseForm) {
  case 'Edit':
  case 'View':
    showTitle = props.chooseForm;
    break;
  default:
    showTitle = 'Create';
  }

  const openDrawar = (e: any) => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <>
      { props.icon
        ? <span onClick={openDrawar}>{props.icon}</span>
        : !props.notifyVisible
          ? (
            <ProgramAddButton className="add-milestone-button" icon={<img src={Plus} className={isMobile ? 'Plus-Icon-mobile-view ' : 'Plus-Icon-Desktop-View'} />} onClick={openDrawar}>
              {props.addButtonLabel}
            </ProgramAddButton>
          )
          : null}
      <Drawer
        maskClosable={false}
        title={`${`${showTitle} ${props.formTypeLabel}`} Form`}
        placement="right"
        closable={false}
        visible={visible || propVisible}
        width="520"
        className={props.propVisible ? 'user-drawer notificationDrawerModal' : 'user-drawer'}
        onClose={() => closeDrawer('', '')}

      >
        <MilestoneForm
          mileStoneTypes={mileStoneTypes}
          cancel={() => closeDrawer('', '')}
          close={closeDrawer}
          userRole={role}
          status={skillStatus}
          milestoneId={props.milestoneId}
          editMode={props.edit}
          viewMode={showTitle}
          visible={visible || propVisible}
          goalFormType={props.goalFormType}
          goalData={props.goalData}
          mileStoneData={mileStoneData}
          teamMember={props.teamMember}
          getGoalsData={getGoalsData}
        />
      </Drawer>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(AddMilestone);
