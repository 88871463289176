import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Input, Modal, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import {
  courseDetailsApi,
  deleteCourse,
  patchCourse,
  postCourse,
  searchCourseApi
} from '../../api/course';
import { getTags } from '../../api/user';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import { setIsOpenDrawer, setSelectedRowData } from '../../redux/actions/formAction';
import { setCourseList } from '../../redux/actions/userMemberAction';
import { setFileLinkList } from '../../redux/actions/userProgramAction';
import { Mode, Role } from '../../utils/types/role';
import FileUploader from '../common/FileUploader';
import {
  DeleteTabDiv,
  DrawerFooter,
  MultipleFileDiv,
  SubmitButton,
  UserLastRow
} from '../common/styles';
import { showNotificationDrawer } from '../common/utils';
import VideoFiles from './VideoFIles';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 24 }
};
const colors = ['tag1-style', 'tag2-style', 'tag3-style', 'tag4-style', 'tag5-style'];
const { Option } = Select;
interface CourseFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  courseID?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  showFormData: any;
  state: any;
  customerDataList: any;
  courseList?: any;
  videoList?: any;
  pagination?: any;
  PerPageRecords?: any;
  changePageToOne?: () => void;
  searchTerm?: string;
  fileLinkList?: any;
  objectiveList: any;
}

const CourseForm: React.FC<CourseFormProps> = (props) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const {
    dispatch,
    courseID,
    showFormData,
    visible,
    videoList,
    fileLinkList,
    pagination,
    PerPageRecords,
    changePageToOne,
    searchTerm,
    courseList,
    viewMode,
    objectiveList
  } = props;
  const [activeForm, setActiveForm] = useState<any>({});
  const [fileError, setFileError] = useState<string>('');
  const [videosExistList, setVideoExistsList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [addtags, setaddedTags] = useState<any>([]);
  const [isloading, setisloading] = useState<boolean>(false);
  const { Title, Text } = Typography;

  useEffect(() => {
    const filterVideos: any = [];
    getTags()
      .then((res) => {
        setaddedTags(res.data);
      })
      .catch(() => {
        setaddedTags([]);
      });
    const activeFormLength = Object.keys(activeForm).length;
    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          title: '',
          tags: [],
          foundational: false,
          pathway: '',
          videos: [],
          previewThumbnail: ''
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
        dispatch(setFileLinkList(createData.videos));
        dispatch(setSelectedRowData({ keys: [], rows: [] }));
        courseList.map(
          (item: any) =>
            item.videos && item.videos.map((subitem: any) => filterVideos.push(subitem._id))
        );
      }
    }
    if (courseID && visible) {
      setisloading(true);
      courseList.map(
        (item: any) =>
          item.videos &&
          item._id !== courseID &&
          item.videos.map((subitem: any) => filterVideos.push(subitem._id))
      );
      courseDetailsApi(courseID)
        .then((res) => {
          const courseData = res.data;
          const stateData = {
            title: courseData.title,
            tags: courseData.tags?.map((tagItem) => tagItem.name),
            order: courseData.order,
            foundational: courseData.foundational ?? false,
            pathway: courseData.pathway,
            videos: courseData.videos ? courseData.videos.map((item) => item._id) : [],
            previewThumbnail: courseData.previewThumbnail
          };
          const keys = courseData.videos.map((item) => item._id);
          dispatch(setFileLinkList(keys));
          dispatch(setSelectedRowData({ keys, rows: courseData.videos }));
          setActiveForm(stateData);
          form.setFieldsValue(stateData);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
          console.log(err);
        });
    }
    const updatedFilterVideos =
      videoList &&
      videoList.filter((data: any, index: number) => !filterVideos?.includes(data._id));
    const activevideo =
      updatedFilterVideos &&
      updatedFilterVideos.filter((dataItem) => dataItem.status === 'Published');
    setVideoExistsList(activevideo);
  }, [form, courseID, showFormData, visible, props.userRole]);
  const onReset = () => {
    form.resetFields();
    setActiveForm({});
    props.close();
    props.cancel();
    dispatch(setFileLinkList([]));
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };
  const onSubmit = () => {
    setisloading(true);
    const updateData = {
      ...activeForm,
      videos: fileLinkList
    };
    if (courseID) {
      patchCourse(courseID, updateData)
        .then((res) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          showNotificationDrawer('Success', 'Course Updated successfully!');
          dispatch(setIsOpenDrawer(false));
          dispatch(setFileLinkList([]));
          dispatch(setCustomerLoading(true));
          searchCourseApi({ skip: pagination, limit: PerPageRecords, search: searchTerm })
            .then((res) => {
              const { data } = res;
              dispatch(setCourseList(data));
              dispatch(setCustomerLoading(false));
            })
            .catch((err) => {
              console.log(err);
              dispatch(setCustomerLoading(false));
            });
          onReset();
        })
        .catch((err) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          dispatch(setFileLinkList([]));
          props.cancel();
        });
    }

    const createData = {
      ...activeForm,
      videos: fileLinkList
    };
    if (!courseID) {
      postCourse(createData)
        .then((res: any) => {
          setisloading(false);
          const { data } = res;
          onReset();
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          dispatch(setFileLinkList([]));

          showNotificationDrawer('Success', 'Course created successfully!');
          dispatch(setCustomerLoading(true));
          searchCourseApi({ skip: 0, limit: PerPageRecords, search: searchTerm })
            .then((res) => {
              const { data } = res;
              dispatch(setCourseList(data));
              dispatch(setCustomerLoading(false));
            })
            .catch((err) => {
              console.log(err);
              dispatch(setCustomerLoading(false));
            });
          changePageToOne?.();
        })
        .catch((err) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          dispatch(setFileLinkList([]));
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }
  };
  const multiSelectHandleChange = (value: any) => {
    setActiveForm({ ...activeForm, tags: value });
  };
  const addNeedSkill = (data: any) => {
    dispatch(setFileLinkList([...data]));
  };

  const handleDeleteCourse = (id: string) => {
    setisloading(true);
    deleteCourse(id)
      .then((res) => {
        form?.resetFields();
        setActiveForm({});
        onReset();
        setisloading(false);
        dispatch(setCustomerLoading(true));
        showNotificationDrawer('Success', 'Course deleted successfully!');
        searchCourseApi({ skip: pagination, limit: PerPageRecords, search: searchTerm })
          .then((res) => {
            const { data } = res;
            dispatch(setCourseList(data));
            dispatch(setCustomerLoading(false));
          })
          .catch((err) => {
            dispatch(setCustomerLoading(false));
          });
      })
      .catch((err) => {
        setisloading(false);
        console.log(err);
        showNotificationDrawer('Error', err?.response?.data?.message);
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this Course?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteCourse(id);
      },
      onCancel() {}
    });
  };

  const getFileLinkSpecificFields = () => {
    const { fileLinkList = [] } = props;

    return (
      <>
        {fileLinkList.length > 0 && props.viewMode === 'View' ? (
          <MultipleFileDiv style={{ pointerEvents: 'auto' }}>
            <Text>Videos</Text>
            <VideoFiles
              fieldMsg="Please input some file link"
              itemList={fileLinkList}
              addData={addNeedSkill}
              buttonLabel="Add More Videos"
              dataId={courseID}
              view={viewMode}
              viewMode
              filterVideoList={videosExistList}
            />
          </MultipleFileDiv>
        ) : props.viewMode === 'Edit' || props.viewMode === 'Create' ? (
          <MultipleFileDiv>
            <Text>Videos</Text>
            <VideoFiles
              fieldMsg="Please input some file link"
              itemList={fileLinkList}
              addData={addNeedSkill}
              buttonLabel={fileLinkList.length ? 'Add More Videos' : 'Add Videos'}
              dataId={courseID}
              view={viewMode}
              viewMode={false}
              filterVideoList={videosExistList}
            />
          </MultipleFileDiv>
        ) : null}
      </>
    );
  };
  const relatedVideoSelectHandleChange = (value: any) => {
    setActiveForm({ ...activeForm, videos: value });
  };

  const onChangeFile = (url, name) => {
    setActiveForm({ ...activeForm, previewThumbnail: url });
  };
  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }
  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  pattern: /^(?!\s)[a-zA-Z\s]+$/,
                  message: 'Title must contain alphabets'
                },
                {
                  required: true,
                  message: 'Please input title!',
                  whitespace: true
                }
              ]}
            >
              <Input
                name="title"
                onChange={(e) => onHandleChange(e, 'title')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Add Tags"
              name="tags"
              className="time-zone-container-s"
              rules={[{ required: true, message: 'Please enter tags' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue={[]}
                showSearch
                mode="tags"
                onChange={multiSelectHandleChange}
              >
                {[...addtags].map((data: any, index: number) => (
                  <Option key={index} value={data.name}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Foundational" name="foundational" valuePropName="checked">
              <Checkbox onChange={(e) => handleChange('foundational', e.target.checked)}>
                Foundational
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Pathway"
              name="pathway"
              rules={[{ required: true, message: 'Please select a pathway!' }]}
            >
              {objectiveList?.length ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  onChange={(value: any) => handleChange('pathway', value)}
                  placeholder="Please select pathway"
                  filterOption={(input, option) => {
                    if (option) {
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return true;
                  }}
                >
                  {objectiveList.map((data, index: number) => (
                    <Option key={index} value={data._id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select />
              )}
            </Form.Item>
            <Form.Item name="order" label="Course Order" rules={[{ required: false }]}>
              <Input
                name="order"
                type="number"
                onChange={(e) => onHandleChange(e, 'order')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            {getFileLinkSpecificFields()}
            <Form.Item
              label="Upload Thumbnail (max size 2mb)"
              name="previewThumbnail"
              className="no-boder"
              rules={[{ required: true, message: 'Please upload Thumbnail' }]}
              style={{ pointerEvents: 'auto' }}
            >
              <FileUploader
                limit={2097152}
                onlyImageAccept
                mode={props.viewMode}
                onChange={onChangeFile}
                avatarImage={activeForm.previewThumbnail}
                type="user-form"
                previewVideo
                resize
              />
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          )}
          {props.viewMode === 'Edit'
            ? props.visible && (
                <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                  Save
                </SubmitButton>
              )
            : props.viewMode === 'Create'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                    Submit
                  </SubmitButton>
                )
              : null}

          {props.userRole === Role.Admin && courseID && props.viewMode === 'Edit' ? (
            <DeleteTabDiv
              style={{ marginLeft: '20px', marginTop: '10px' }}
              className=""
              onClick={() => showDeleteConfirm(courseID)}
            >
              <DeleteOutlined />
            </DeleteTabDiv>
          ) : null}
        </Form.Item>
      </DrawerFooter>
      <Modal
        title={<div className="add-task-title-container">Order Videos</div>}
        closable={false}
        visible={isOpen}
        onOk={() => setIsOpen(false)}
        className="wide-modal resource-detail-modal-container"
        footer={[
          <button className="close-resource-task" onClick={() => setIsOpen(false)}>
            Ok
          </button>
        ]}
      />
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    customerDataList: state.notificationReducer.customerDataList,
    videoList: state.userMemberData.videoList,
    courseList: state.userMemberData.courseList,
    fileLinkList: state.programRuducer.fileLinkList,
    objectiveList: state.formReducer.resourceObjectivelist
  };
}

export default connect(mapStateToProps)(CourseForm);
