import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchCourseApi } from '../api/course';
import { getResourceObjective } from '../api/resources';
import { searchVideoApi } from '../api/video';
import { UserContainer } from '../pages/dashboard/styles';
import { setCustomerLoading } from '../redux/actions/customerAction';
import { setResourceObjective, setShowFormData } from '../redux/actions/formAction';
import {
  setCourseCurrentPage,
  setCourseList,
  setCoursePageCount,
  setVideoList
} from '../redux/actions/userMemberAction';
import PRIVILEGES from '../utils/privilege';
import { Mode, Role } from '../utils/types/role';
import AddCourse from './AddUser/AddCourse';
import { AddButton, CustomerContainer } from './common/styles';
import { SearchContainer } from './ListingComponent/styles';
import CourseTableComponent from './TableData/CourseTableComponent';

const CourseComponent: React.FC<any> = (props) => {
  const { dispatch, courseList, userMemberData, onlyDisplayActiveResources, customerLoading } =
    props;
  const { currentLoginUser } = userMemberData;

  const [visible, setVisible] = useState<boolean>(false);
  const [perPageRecords, setperPageRecords] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { totalCourseCount, courseCurrentPage } = userMemberData;
  const fetchCourseList = useCallback(
    (pageSize: number = 0, skip: number = 0, searchTerm: string) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        search: searchTerm
      };

      dispatch(setCustomerLoading(true));
      return searchCourseApi(options);
    },
    []
  );

  const fetchVideoList = useCallback(
    (pageSize: number = 0, skip: number = 0, searchTerm: string) => {
      const options = {
        skip: skip || 0,
        pageSize,
        search: searchTerm
      };
      return searchVideoApi(options);
    },
    [perPageRecords]
  );

  useEffect(() => {
    getResourceObjective().then((res) => {
      if (res && res.data) {
        dispatch(setResourceObjective(res.data));
      }
    });

    fetchCourseList(perPageRecords, 0, '')
      .then((res) => {
        const { data, count } = res;
        dispatch(setCourseList(data));
        dispatch(setCustomerLoading(false));
        dispatch(setCoursePageCount(count));
        // dispatch(setCourseCurrentPage(1));
      })
      .catch((err) => {
        dispatch(setCustomerLoading(false));
      });
    fetchVideoList(perPageRecords, 0, '')
      .then((res) => {
        const { data, count } = res;
        dispatch(setVideoList(data));
        dispatch(setCustomerLoading(false));
        // dispatch(setVideoCurrentPage(1));
      })
      .catch((err) => {
        dispatch(setCustomerLoading(false));
      });
  }, [dispatch, fetchCourseList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = useCallback(
    (searchTerm) => {
      dispatch(setCustomerLoading(true));

      fetchCourseList(perPageRecords, 0, searchTerm)
        .then((res) => {
          const { data, count } = res;
          dispatch(setCourseList(data));
          dispatch(setCourseCurrentPage(1));
          dispatch(setCoursePageCount(count));
          dispatch(setCustomerLoading(false));
        })
        .catch(() => {
          dispatch(setCustomerLoading(false));
        });
    },
    [dispatch, searchTerm, perPageRecords]
  );
  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (!value) {
      onSearch(value);
    }
  };
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && searchTerm != '') {
        onSearch(searchTerm);
      }
    },
    [searchTerm, onSearch]
  );

  const openDrawar = (e: any) => {
    e.stopPropagation();
    const createFormData = {
      id: '',
      userType: currentLoginUser.type,
      formMode: Mode.Create,
      formLabel: 'Course'
    };
    dispatch(setShowFormData(createFormData));
    setVisible(true);
    // dispatch(setIsOpenDrawer(true))
  };

  const onPageChange = (page: number, pageSize?: number) => {
    const pageCount = pageSize || perPageRecords;
    const skipPageCount = page <= 1 ? 0 : pageCount * (page - 1);

    dispatch(setCourseCurrentPage(page));
    dispatch(setCustomerLoading(true));
    fetchCourseList(pageCount, skipPageCount, searchTerm)
      .then((res) => {
        const { data, count } = res;
        dispatch(setCustomerLoading(true));
        dispatch(setCourseList(data));
        dispatch(setCoursePageCount(count));
      })
      .catch((error) => {
        dispatch(setCustomerLoading(true));
      });
  };
  const canCreate = PRIVILEGES.Videos.Create?.includes(currentLoginUser.type as Role);
  return (
    <div>
      <Spin spinning={customerLoading}>
        {currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin ? (
          <UserContainer>
            {canCreate && (
              <AddButton type="primary" onClick={openDrawar}>
                <PlusOutlined />
              </AddButton>
            )}
            <AddCourse
              setVisible={setVisible}
              visible={visible}
              searchTerm={searchTerm}
              pagination={(courseCurrentPage - 1) * perPageRecords}
              PerPageRecords={perPageRecords}
              changePageToOne={() => dispatch(setCourseCurrentPage(1))}
            />
          </UserContainer>
        ) : null}

        <div className="site-layout-background">
          <SearchContainer className="search-box search-video-box">
            <Button
              onClick={() => (searchTerm != '' ? onSearch(searchTerm) : null)}
              className="SearchButton"
            >
              <SearchOutlined />
            </Button>
            <Input
              onKeyPress={handleKeyPress}
              name="search"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search course"
            />
          </SearchContainer>
          <div className="site-card-wrapper">
            <CustomerContainer>
              <CourseTableComponent
                searchTerm={searchTerm}
                className="resources-table "
                PerPageRecords={perPageRecords}
                pagination={{
                  defaultPageSize: perPageRecords,
                  pageSize: perPageRecords,
                  position: ['bottomLeft']
                }}
                courseData={courseList}
                onlyDisplayActiveResources={onlyDisplayActiveResources}
                setVisible={setVisible}
              />
              <Pagination
                current={courseCurrentPage}
                total={totalCourseCount}
                pageSize={perPageRecords}
                onChange={onPageChange}
                className="my-pagination"
                onShowSizeChange={(current, pagesize) => {
                  setperPageRecords(pagesize);
                }}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    customerLoading: state.customerRuducer.customerLoading,
    courseList: state.userMemberData.courseList
  };
}

export default connect(mapStateToProps)(CourseComponent);
