import { API_URL } from '../constant';
import { apiGet, apiPost } from '../utils/api';

export const uploadFileAPI = (data) => apiPost(`${API_URL}upload`, data);

export const uploadFileWithResizeAPI = (data) => apiPost(`${API_URL}upload-with-resize`, data);

export const downloadFileAPI = (data) => apiPost(`${API_URL}download`, data);

export const getPublicFile = (fileName) => apiGet(`${API_URL}static/${fileName}`);
