import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getComplexityApi, getSkillsApi } from '../api/user-program';
import { UserContainer } from '../pages/dashboard/styles';
import { setCustomerLoading } from '../redux/actions/customerAction';
import { setComplexityList, setSkillList } from '../redux/actions/userProgramAction';
import { Role } from '../utils/types/role';
import AddGoalComponent from './AddUser/AddGoalComponent';
import { CustomerContainer } from './common/styles';
import MyGoalTableComponent from './TableData/MyGoalTableComponent';

interface customerInfoType {
  title: string;
  subTitle: string;
  mileStone: number;
  percentage: number;
  link: string;
}

export enum ProgramRole {
  MyGoals = 'MyGoals',
  Milestone = 'Skill',
  MyTasks = 'MyTasks'
}

const MyGoalsComponent: React.FC<any> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const {
    dispatch,
    memberGoals,
    programSkills,
    programComlexityList,
    customerLoading,
    currentLoginUser
  } = props;

  useEffect(() => {
    dispatch(setCustomerLoading(true));
    getSkillsApi().then((res) => {
      dispatch(setSkillList(res.data));
      dispatch(setCustomerLoading(false));
    });
    getComplexityApi().then((res) => {
      dispatch(setComplexityList(res.data));
      dispatch(setCustomerLoading(false));
    });
  }, [dispatch]);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  return (
    <div style={{ marginTop: currentLoginUser.type !== Role.Business ? '57px' : 0 }}>
      <Spin spinning={customerLoading}>
        {currentLoginUser.type === Role.Business ? (
          <UserContainer>
            <AddGoalComponent
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              formTypeLabel="Outcome"
              addButtonLabel="Add Outcome"
            />
          </UserContainer>
        ) : null}
        <div className="site-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer>
              <MyGoalTableComponent
                className="my-goal-table"
                goalData={memberGoals}
                programSkills={programSkills}
                programComlexityList={programComlexityList}
                loginUserType={currentLoginUser.type}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerInfo: state.customerRuducer.customerInfo,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    memberGoals: state.programRuducer.memberGoals,
    programSkills: state.programRuducer.programSkills,
    programComlexityList: state.programRuducer.programComlexityList,
    customerLoading: state.customerRuducer.customerLoading,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(MyGoalsComponent);
