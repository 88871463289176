import { CloseSquareFilled } from '@ant-design/icons';
import { Button, Checkbox, Tabs } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ChoosePlan } from '../constant';
import { setCustomerAccessData, setCustomerLoading } from '../redux/actions/customerAction';
import useDebounce from '../utils/hooks/useDebounce';
import { Role } from '../utils/types/role';
import AddUserComponent from './AddUser/AddUserComponent';
import { UserContainer } from './common/styles';
import { setDataInLocalStorage } from './common/utils';
import GoogleCalendarToggle from './GoogleCalendarToggle';
import ListingBox from './ListingComponent/ListingBox';

const { TabPane } = Tabs;

interface CustomerFilterByPlanTypeProps {
  filterPlanTypes: string[];
  onFilterChange: (filter: string[]) => void;
}
const CustomerFilterByPlanType = (props: CustomerFilterByPlanTypeProps) => {
  const planTypes = ChoosePlan;
  const { filterPlanTypes } = props;
  const onFilterChange = (type: string) => (e: CheckboxChangeEvent) => {
    const newPlanTypes = [...filterPlanTypes];
    if (!newPlanTypes.includes(type)) {
      newPlanTypes.push(type);
    } else {
      newPlanTypes.splice(newPlanTypes.indexOf(type), 1);
    }
    props.onFilterChange?.call(null, newPlanTypes);
  };
  const onFilterReset = () => {
    props.onFilterChange?.call(null, []);
  };
  return (
    <div className="flex flex-col gap-2 items-start md:flex-row" data-testid="plan-type-filter">
      <div>Plan Type&ensp;</div>
      <div className="grid grid-cols-2 md:flex">
        {planTypes.map((type, i) => (
          <Checkbox
            key={i}
            checked={filterPlanTypes.includes(type.label)}
            onChange={onFilterChange(type.label)}
            className="ml-2"
          >
            {type.label}
          </Checkbox>
        ))}
      </div>

      {filterPlanTypes.length > 0 && (
        <Button icon={<CloseSquareFilled />} type="text" size="small" onClick={onFilterReset}>
          Clear all
        </Button>
      )}
    </div>
  );
};

const CustomerComponent: React.FC<any> = (props) => {
  const { dispatch, currentLoginUser } = props;
  const history = useHistory();

  const [chooseKey, setKey] = useState<string>('Active');
  const [planTypeFilter, setPlanTypeFilter] = useState([] as string[]);
  const debouncedPlanTypeFilter = useDebounce(planTypeFilter, 500);

  const callback = (key: string) => {
    setKey(key);
  };

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const onPlanTypeFilterChange = (filter: string[]) => {
    setPlanTypeFilter([...filter]);
  };

  const gotoCustomerDashboard = (customerData: any) => {
    const data = {
      role: 'BusinessAccesCustomer',
      id: customerData._id
    };
    setDataInLocalStorage('bussness access customer', data);
    dispatch(setCustomerAccessData(data));
    dispatch(setCustomerLoading(true));
    history.push('/dashboard');
    window.location.reload();
  };

  const mainStyle = {
    marginTop: currentLoginUser.type !== Role.Business ? 0 : 46
  };

  return (
    <div>
      {currentLoginUser.type !== Role.Business ? (
        <UserContainer>
          <AddUserComponent
            onCloseDrawer={onCloseDrawer}
            user={Role.Customer}
            formLabel="Customer"
          />
        </UserContainer>
      ) : null}
      <div className="site-layout-background space-y-5" style={mainStyle}>
        {currentLoginUser.type === 'BusinessUser' && (
          <div className="mx-5">
            <GoogleCalendarToggle />
          </div>
        )}
        <div className="mx-5 p-3 bg-gray-200">
          <CustomerFilterByPlanType
            filterPlanTypes={planTypeFilter}
            onFilterChange={onPlanTypeFilterChange}
          />
        </div>
        <Tabs activeKey={chooseKey} type="card" onChange={callback}>
          <TabPane tab="Active" key="Active">
            <ListingBox
              activeKey={chooseKey}
              show="Active"
              role={Role.Customer}
              cardClass="customer-view-card"
              onIconClick={gotoCustomerDashboard}
              loginUserType={currentLoginUser.type}
              planTypeFilter={debouncedPlanTypeFilter}
            />
          </TabPane>
          <TabPane tab="Inactive" key="Inactive">
            <ListingBox
              activeKey={chooseKey}
              show="Inactive"
              role={Role.Customer}
              cardClass="customer-view-card"
              onIconClick={gotoCustomerDashboard}
              loginUserType={currentLoginUser.type}
              planTypeFilter={planTypeFilter}
            />
          </TabPane>
          <TabPane tab="On hold" key="OnHold">
            <ListingBox
              activeKey={chooseKey}
              show="OnHold"
              role={Role.Customer}
              cardClass="customer-view-card"
              onIconClick={gotoCustomerDashboard}
              loginUserType={currentLoginUser.type}
              planTypeFilter={planTypeFilter}
            />
          </TabPane>
          {currentLoginUser.type === Role.Admin && (
            <TabPane tab="Awaiting" key="Awaiting">
              <ListingBox
                activeKey={chooseKey}
                show="Awaiting"
                role={Role.Customer}
                cardClass="customer-view-card"
                onIconClick={gotoCustomerDashboard}
                loginUserType={Role.Admin}
                planTypeFilter={planTypeFilter}
              />
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerInfo: state.customerRuducer.customerInfo,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(CustomerComponent);
