import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FixedButton } from '../../pages/dashboard/styles';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import { Role } from '../../utils/types/role';
import { removeDataInLocalStorage } from './utils';

const CustomerAccessBackButton: React.FC = (props: any) => {
  const history = useHistory();
  const { dispatch, customerAccessData, currentLoginUser } = props;

  const customerAccessUserType = (customerAccessData && customerAccessData.role) || '';

  const backToBusiness = () => {
    dispatch(setCustomerLoading(true));
    removeDataInLocalStorage('bussness access customer');
    removeDataInLocalStorage('selected member');
    history.push('/dashboard');
    window.location.reload();
  };

  return customerAccessUserType === Role.BusinessAccesCustomer ? (
    <FixedButton style={{ marginRight: '65px' }}>
      <Button type="primary" shape="round" onClick={backToBusiness} icon={<ArrowLeftOutlined />}>
        {currentLoginUser.type === 'Administrator' ? 'Back to Admin user' : 'Back to Business user'}
      </Button>
    </FixedButton>
  ) : null;
};

function mapStateToProps(state: any) {
  return {
    customerAccessData: state.customerRuducer.customerAccessData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(CustomerAccessBackButton);
