import { CaretDownOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Collapse, Dropdown, Popover, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { getSelectedMemberDashboardApi } from '../../api/customer';
import { createMemberFavUrlApi, updateMemberFavUrlApi } from '../../api/member';
import { dashBoardFilterByDateType } from '../../constant';
import ThreeDotsIcon from '../../images/icons/3dotsverticalicon.svg';
import EditProfile from '../../images/icons/editProfile.svg';
import FavoriteIcon from '../../images/icons/favourites.svg';
import HelpIcon from '../../images/icons/help.svg';
import SignOut from '../../images/icons/logout.svg';
import SearchIcon from '../../images/icons/search.svg';
import { setCustomerInfo, setCustomerLoading } from '../../redux/actions/customerAction';
import { logOut } from '../../utils/api';
import { Role } from '../../utils/types/role';
import AddUserComponent from '../AddUser/AddUserComponent';
import WizardModal from '../refactored/Modal/Wizard/Wizard';
import NotificationPopup from './NotificationComponent/NotificationPopup';
import {
  AddButton,
  ProfileButton,
  ProfileHeader,
  ProfileName,
  SettingContainer,
  UserProfileContainer
} from './styles';
import { getRouteLabel, showNotificationDrawer } from './utils';

const SearchAndMenuItem = (props) => {
  const {
    dispatch,
    customerSelectedMember,
    customerAccessData,
    customerInfo,
    notificationList,
    customerDataList,
    currentLoginUser,
    handleChange,
    setCollapse,
    collapse,
    isMobile
  } = props;

  const match = useRouteMatch();
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [visiblePopup, setVisiblePopup] = useState<boolean>(false);
  const [showProfile, setProfile] = useState<boolean>(false);
  const [showName, setName] = useState<string>('');
  const [profileImage, setProfileImage] = useState('');
  const [routeLabel, setRouteLabel] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [chooseKey, setKey] = useState<string>('Active');
  const [isActive, setIsActive] = useState<any>(false);
  const [visible, setVisible] = useState(false);
  const [visibility, setVisibility] = useState<boolean>(false);
  const defaultDate = dashBoardFilterByDateType[7];

  const { Title } = Typography;
  const { Panel } = Collapse;

  const handleScreenSize = () => {
    setScreenSize(window.innerWidth);
  };

  const filterNotificationTaskList =
    (notificationList &&
      notificationList.length &&
      notificationList
        .filter((item) => (item?.task ? !item.task.isDeleted : true))
        .sort((a, b) => {
          const x = new Date(b.createdAt) as any;
          const y = new Date(a.createdAt) as any;
          return x - y;
        })) ||
    [];

  // const notificationfileter = notificationList && notificationList.length && notificationList.filter(x => x.task === {} || (x.task && x.task.isDeleted === false));
  useEffect(() => {
    setName(
      `${currentLoginUser.firstName} ${currentLoginUser.lastName ? currentLoginUser.lastName : ''}`
    );
    setProfileImage(customerDataList && customerDataList.avatar);
    const headerPath = getRouteLabel(
      currentLoginUser.type,
      window.location.pathname,
      customerAccessData
    );
    setRouteLabel(headerPath);

    history.listen((location, action) => {
      const headerPathName = getRouteLabel(
        currentLoginUser.type,
        window.location.pathname,
        customerAccessData
      );
      setRouteLabel(headerPathName);
    });
  }, [
    currentLoginUser._id,
    currentLoginUser && currentLoginUser.firstName,
    currentLoginUser && currentLoginUser.lastName,
    customerDataList && customerDataList.avatar
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener('resize', handleScreenSize);
  }, []);

  const gotoSetting = () => {
    handleClick(4);
    history.push('/dashboard/settings/tracking');
  };

  const handleClick = (value) => {
    setIsActive(value);
  };

  function callback(key) {
    if (collapse === false) {
      handleChange();
    }
    setIsActive(true);
  }
  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const getDashboardInfo = () => {
    const data = {
      startDate: defaultDate.startDate,
      endDate: defaultDate.endDate
    };

    if (customerSelectedMember && customerSelectedMember.length && customerSelectedMember[0]._id) {
      getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
        const { data } = res;
        dispatch(setCustomerInfo(data));
      });
    }
  };

  const bookMarkPage = (urlPath) => {
    const customerLabel = getRouteLabel(
      currentLoginUser.type,
      window.location.pathname,
      customerAccessData
    );

    const favoriteData = customerInfo && customerInfo.favourites;

    const favData =
      (favoriteData && favoriteData.filter((item) => item.name === customerLabel && item)) || [];

    const updatedata = {
      name: customerLabel,
      url: urlPath
    };

    if (favData && favData.length) {
      dispatch(setCustomerLoading(true));
      updateMemberFavUrlApi(favData[0]._id, updatedata)
        .then((res) => {
          dispatch(setCustomerLoading(false));
          getDashboardInfo();
          showNotificationDrawer('Success', 'Favorites page updated successfully!');
        })
        .catch((err) => {
          dispatch(setCustomerLoading(false));
          showNotificationDrawer('Error', err.response.data.message);
        });
      return;
    }

    if (customerSelectedMember && customerSelectedMember.length) {
      const data = {
        name: customerLabel,
        member: customerSelectedMember[0]._id,
        url: urlPath
      };
      createMemberFavUrlApi(data)
        .then((res) => {
          dispatch(setCustomerLoading(false));
          getDashboardInfo();
          showNotificationDrawer('Success', 'Page added to favorites successfully!');
        })
        .catch((err) => {
          dispatch(setCustomerLoading(false));
          showNotificationDrawer('Error', 'Something went wrong!');
        });
    }
  };

  const closeProfileInfo = (value: boolean) => {
    setProfile(value);
  };

  const gettingStartedWizardOpen = () => {
    setVisible(false);
    setVisibility(true);
  };

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
    setIsEdit(false);
  }, [chooseKey]);

  const openEditModal = (value) => {
    setIsEdit(value);
  };

  const handleOutSideClick = () => {
    if (!isEdit) {
      closeProfileInfo(false);
    }
    onCloseDrawer();
  };

  const onClickSearch = () => {
    if (setCollapse) {
      setCollapse(true);
    }
    handleClick(1);
  };

  const onLogOut = () => {
    logOut();
    history.push('/login');
  };

  const HeaderPanel = () => (
    <SettingContainer className="setting-right setting-filter">
      {currentLoginUser.type === Role.Customer ? (
        <Tooltip title="Favorites">
          <AddButton className={isActive === 3 ? 'active' : ''}>
            <Link
              to={`${match.path}/myfavorites`}
              onClick={() => {
                handleClick(3);
                bookMarkPage(window.location.pathname);
              }}
            >
              <img src={FavoriteIcon} />
            </Link>
          </AddButton>
        </Tooltip>
      ) : null}
      {currentLoginUser.type === Role.Customer ? (
        <NotificationPopup
          notify={filterNotificationTaskList}
          customerDataList={customerDataList}
          visible={visiblePopup}
          setVisible={setVisiblePopup}
        />
      ) : null}

      <Popover
        content={
          <div className="popoverViewStyle">
            {currentLoginUser.type === Role.Customer && (
              <Link to="#" className="popGettingStart" onClick={gettingStartedWizardOpen}>
                Getting Started
              </Link>
            )}
            {/* {currentLoginUser.type === Role.Customer &&
              <a onClick={gettingStartedWizardOpen} className="popGettingStart">
                Getting Started
              </a>
            } */}
            <a href="https://help.autism360.com/" className="popOverText" target="blank">
              Knowledge Base{' '}
            </a>
            <a href="https://go.autism360.com/support" className="popContactSupport" target="blank">
              Contact Support
            </a>
            <WizardModal visible={visibility} setVisibility={setVisibility} />
          </div>
        }
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        overlayClassName="popoverHelpStyle"
      >
        <Tooltip title="">
          <AddButton className={isActive === 4 ? 'active' : ''} onClick={() => handleClick(3)}>
            <img src={HelpIcon} />
          </AddButton>
        </Tooltip>
      </Popover>
      {!customerAccessData && currentLoginUser.type === Role.Admin ? (
        <Tooltip title="Setting">
          <AddButton className={isActive === 5 ? 'active' : ''} onClick={gotoSetting}>
            <SettingOutlined />
          </AddButton>
        </Tooltip>
      ) : null}
      <span className="outSideClick-div">
        <div className="dashboard-header-user">
          <Dropdown
            overlay={
              <UserProfileContainer>
                <ProfileHeader>
                  {currentLoginUser.type === Role.Customer && (
                    <div className="form-icon">
                      <Link to={`${match.path}/myfavorites`}>
                        <img src={FavoriteIcon} className="signout-header-image" />
                        <span className="header-edit-sign-out"> My Favorite</span>
                      </Link>
                    </div>
                  )}
                  <AddUserComponent
                    onCloseDrawer={onCloseDrawer}
                    user={currentLoginUser.type}
                    id={currentLoginUser._id}
                    formLabel={currentLoginUser.type}
                    chooseForm="Edit"
                    edit
                    icon={
                      <div className="form-icon">
                        <img src={EditProfile} />
                        <span className="header-edit-edit">Edit Profile</span>
                        <div onClick={onLogOut}>
                          <img src={SignOut} className="signout-header-image" />
                          <span className="header-edit-sign-out">Sign Out</span>
                        </div>
                      </div>
                    }
                    isOpenVisible={openEditModal}
                    closeProfileInfo={closeProfileInfo}
                    handleOutSideClick={handleOutSideClick}
                  />
                </ProfileHeader>
              </UserProfileContainer>
            }
            placement="bottomRight"
            overlayStyle={{
              left: '955px !important',
              zIndex: 1000,
              minWidth: 500
            }}
            trigger={['click']}
            overlayClassName="profile-view-header-style"
          >
            <ProfileButton onClick={() => closeProfileInfo(!showProfile)}>
              <Avatar src={profileImage} className="header-avatar" />
              <ProfileName className="dashboard-header-user-profile">{showName}</ProfileName>
              <CaretDownOutlined
                style={{
                  color: '#C4C4C4',
                  verticalAlign: 'middle',
                  margin: '0px 5px'
                }}
              />
            </ProfileButton>
          </Dropdown>
          {/* {showProfile ? (
            <UserProfileContainer>
              <ProfileHeader>
                {
                  userData.type === Role.Customer &&

                    <div className="form-icon">
                      <Link to={`${match.path}/myfavorites`}>
                        <img src={FavoriteIcon} className="signout-header-image" />
                        <span className="header-edit-sign-out"> My Favorite</span>
                      </Link>
                    </div>
                }
                <AddUserComponent
                  onCloseDrawer={onCloseDrawer}
                  user={userData.type}
                  id={userData._id}
                  formLabel={userData.type}
                  chooseForm="Edit"
                  edit={true}
                  icon={
                    <div className="form-icon">
                      <img src={EditProfile} />
                      <span className="header-edit-edit">Edit Profile</span>
                      <div onClick={onLogOut}>
                        <img src={SignOut} className="signout-header-image" />
                        <span className="header-edit-sign-out">Sign Out</span>
                      </div>
                    </div>
                  }
                  isOpenVisible={openEditModal}
                  closeProfileInfo={closeProfileInfo}
                  handleOutSideClick={handleOutSideClick}
                />
              </ProfileHeader>
            </UserProfileContainer>
          ) : null} */}
        </div>
      </span>
    </SettingContainer>
  );
  return (
    <>
      {screenSize >= 1042 ? (
        HeaderPanel()
      ) : (
        <>
          <Tooltip title="Search">
            <AddButton
              className={
                isActive === 1 ? 'active search-mobile-response' : 'search-mobile-response'
              }
              onClick={() => onClickSearch()}
            >
              <img src={SearchIcon} />
            </AddButton>
          </Tooltip>
          <Collapse
            defaultActiveKey={['0']}
            onChange={callback}
            expandIcon={({ isActive }) =>
              isActive ? <img src={ThreeDotsIcon} /> : <img src={ThreeDotsIcon} />
            }
            className="collapseHeader"
          >
            <Panel header="" key="1">
              {' '}
              {HeaderPanel()}
            </Panel>
          </Collapse>

          {/* <SettingContainer className="setting-right setting-filter">
          <Tooltip title={"Filters"}>
            <Title level={3} style={{ display: "inline" }}>
              {" "}
              Filters:
            </Title>
            <SelectDataByType inline={true} />
          </Tooltip>
          </SettingContainer> */}
        </>
      )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerInfo: state.customerRuducer.customerInfo,
    customerAccessData: state.customerRuducer.customerAccessData,
    notificationList: state.notificationReducer.notificationList,
    customerDataList: state.notificationReducer.customerDataList,
    currentLoginUser: state.userMemberData.currentLoginUser,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(SearchAndMenuItem);
