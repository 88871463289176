import React, { useState } from 'react';
import { Modal, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { setWelcomeModal } from '../../redux/actions/customerAction';
import { GetStartedButtonDiv, WelcomeImg, WelcomeText } from './styles';
import welcomeImg from '../../images/party.png';
import PartyPopper from '../../components/PartyPopper';
import WizardModal from '../../components/refactored/Modal/Wizard/Wizard';

const WelcomeModal: React.FC<any> = (props) => {
  const { Title } = Typography;
  const { openWelcomeModal, dispatch, loginUserData } = props;
  const [visibleModal, setvisibleModal] = useState<boolean>(false);

  const handleCancel = () => {
    dispatch(setWelcomeModal(false));
  };

  const openModal = (e) => {
    e.preventDefault();
    setvisibleModal(true);
    dispatch(setWelcomeModal(false));
  };

  return (
    <div>
      <Modal centered visible={openWelcomeModal} footer={null} onOk={handleCancel} onCancel={handleCancel}>
        <PartyPopper />
        <GetStartedButtonDiv>
          <WelcomeImg src={welcomeImg} />
        </GetStartedButtonDiv>
        <GetStartedButtonDiv>
          <Title level={2}>Welcome</Title>
        </GetStartedButtonDiv>
        <WelcomeText className="">
          Yay !
          {loginUserData.firstName}
          {' '}
          {loginUserData.lastName}
          {' '}
          Welcome to Autism 360™ Portal. Let's get started.
        </WelcomeText>
        <GetStartedButtonDiv>
          <Button type="primary" onClick={openModal}>Continue To Dashboard</Button>
        </GetStartedButtonDiv>

      </Modal>
      <WizardModal
        visible={visibleModal}
        setVisibility={setvisibleModal}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    openWelcomeModal: state.customerRuducer.openWelcomeModal,
    loginUserData: state.userMemberData.loginUserData,
  };
}

export default connect(mapStateToProps)(WelcomeModal);
