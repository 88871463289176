import { Card, message, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSocialAssessmentQuestion, updateUsersApi } from '../../../api/user';
import PrevButtonIcon from '../../../images/icons/Arrow_Prev.svg';
import NextButtonIcon from '../../../images/icons/nextIcon.svg';

const SocialSkill: React.FC<any> = (props) => {
  // console.log('props', props)

  const [currentQuestion, setCurrentQuestion] = useState<any>(0);
  const [Questions, setQuestions] = useState<any>([]);

  const [display, setDisplay] = useState(!!props.isModal);

  const Answers = () => (
    <Radio.Group name="radiogroup" defaultValue={1}>
      <Radio value={1}>Fine</Radio>
      <Radio value={2}>Going well</Radio>
    </Radio.Group>
  );

  const next = () => {
    const answer = Questions[currentQuestion].selectedResponse;
    if (!answer) {
      return alert('please select answer');
    }
    const latestValue = currentQuestion + 1;
    setCurrentQuestion(latestValue);
    const { nextQuestionRef } = Questions[currentQuestion].response.find((x) => x._id === answer);

    getSocialAssessmentQuestion(nextQuestionRef).then((res) => {
      setQuestions([...Questions, res.data]);
    });
  };
  const prev = () => {
    const newQuestion = Questions.slice(0, Questions.length - 1);
    const latestValue = currentQuestion - 1;
    setCurrentQuestion(latestValue);
    setQuestions(newQuestion);
  };

  useEffect(() => {
    getSocialAssessmentQuestion('S1.Q1').then((res) => {
      setQuestions([res.data]);
    });
  }, []);

  const renderQuestion = (question: any, index: number) => (
    <div className="social-skill-container">
      <div className="site-card-border-less-wrapper">
        <Card title={question?.question} bordered={false} className="questionaire-card-style">
          <div className="ans-layer-design">
            <Radio.Group
              name={`radiogroup-${index}`}
              defaultValue={question?.selectedResponse}
              value={question?.selectedResponse}
              onChange={(e) => {
                setQuestions(
                  Questions.map((x) =>
                    x._id === question._id ? { ...x, selectedResponse: e.target.value } : x
                  )
                );
              }}
            >
              {question?.response.map((data, i) => (
                <Radio value={data._id} checked={question.selectedResponse === data._id}>
                  {data.response}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </Card>
      </div>
    </div>
  );
  const success = () => {
    updateUsersApi(props.user?._id, { socialSkillAssesment: JSON.stringify(Questions) })
      .then((res) => {
        // console.log('res', res)
        message.success('Social Skill Assessment submitted successfully');
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const socialSkillAssesment = props.user?.socialSkillAssesment
    ? JSON.parse(props.user?.socialSkillAssesment)
    : [];
  if (!display && socialSkillAssesment?.length) {
    return (
      <>
        <div className="socialSkillsAssesment">Social Skill Assessment</div>
        <button className="wizard-modal-button" onClick={() => setDisplay(true)}>
          Social Skills
        </button>
      </>
    );
  }
  return (
    <div>
      <div className="socialSkillsHeading">{!props?.showFormType && 'Social Skill Assessment'}</div>
      {socialSkillAssesment && socialSkillAssesment?.length ? (
        <div
          className={
            props?.showFormType && !props?.isMobile
              ? 'social-form-styling'
              : !props?.showFormType
                ? 'social-form-in-wizard'
                : 'social-form-mobile-view'
          }
        >
          {socialSkillAssesment.map((x, i) => (
            <div>{renderQuestion(x, i)}</div>
          ))}
        </div>
      ) : (
        <div style={{ marginTop: 10 }}>
          {renderQuestion(Questions[currentQuestion], currentQuestion)}
          <div style={{ position: 'relative', marginBottom: '100px' }}>
            {currentQuestion > 0 && (
              <div className="click-prev-ques" onClick={prev}>
                <img src={PrevButtonIcon} />
                Prev
              </div>
            )}

            {Questions[currentQuestion]?.selectedResponse ? (
              Questions[currentQuestion]?.response.find(
                (x) => x._id === Questions[currentQuestion]?.selectedResponse
              )?.nextQuestionRef === null ? (
                <div className="click-next-ques" onClick={success}>
                  {' '}
                  Submit
                  <img src={NextButtonIcon} />
                </div>
              ) : (
                <div className="click-next-ques" onClick={next}>
                  {' '}
                  Next
                  <img src={NextButtonIcon} />
                </div>
              )
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    user: state?.notificationReducer?.customerDataList,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(SocialSkill);
