import { Button, Checkbox, Col, Form, Input, Select, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';

import { PlusOutlined } from '@ant-design/icons';
import {
  createAssetAPI,
  getAssetByIdAPI,
  getAssetEventsAPI,
  updateAssetAPI
} from '../../../api/asset';
import { AvatarAssetTypes } from '../../../constant';
import CrossFormButton from '../../../images/icons/crossFormButton.svg';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { RootState } from '../../../redux/store';
import { svgToBase64DataUrl } from '../../../utils/file';
import { Mode, Role } from '../../../utils/types/role';
import ColorInput from '../../common/ColorInput';
import { DrawerFooter, SubmitButton, UserLastRow } from '../../common/styles';
import { showNotificationDrawer } from '../../common/utils';
import { AssetEvent } from '../AssetEvent/AssetEventsComponent';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;
interface AssetsFormProps {
  userRole: Role;
  onClose: any;
  assetId?: string;
  visible?: boolean;
  viewMode: string;
  onSubmit: () => void;
}

const AssetsForm = (props: AssetsFormProps) => {
  const { assetId, visible, onSubmit } = props;
  const { showFormData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);

  const [form] = Form.useForm();
  const [activeForm, setActiveForm] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetEvents, setAssetEvents] = useState<AssetEvent[]>([]);
  const svgRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      const res = await getAssetEventsAPI();
      setAssetEvents(res.data);
    })();
  }, []);

  useEffect(() => {
    const activeFormLength = Object.keys(activeForm).length;

    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          type: '',
          name: '',
          svg: '',
          fixColour: false,
          colour: '#00FF00', // default color: green
          event: null,
          status: 'Draft'
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
      }
    }
    if (assetId && visible) {
      setIsLoading(true);
      getAssetByIdAPI(assetId)
        .then((res) => {
          const assetData = res.data;
          setActiveForm(assetData);
          form.setFieldsValue(assetData);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [form, assetId, showFormData, visible]);

  const onReset = () => {
    setActiveForm({});
    form.resetFields();
    props.onClose();
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleSvgChange = (e) => {
    const files = e.target.files;

    if (!files.length) {
      return;
    }

    const file = files[0];
    if (file.type !== 'image/svg+xml') {
      alert('Please upload an SVG file.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const svg = e.target?.result ?? '';

      setActiveForm({ ...activeForm, svg });
      form.setFields([{ name: 'svg', value: svg }]);
      form.validateFields(['svg']);
    };

    reader.readAsText(file);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (!assetId) {
      createAssetAPI(activeForm)
        .then((res: any) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Asset added successfully!');

          onSubmit();
          onReset();
        })
        .catch((err) => {
          setIsLoading(false);
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }

    // Edit
    if (assetId) {
      updateAssetAPI(assetId, activeForm)
        .then((res) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Asset updated successfully!');

          onSubmit();
          onReset();
        })
        .catch((err) => {
          setIsLoading(false);
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={handleSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Type"
              name="type"
              className="time-zone-container-s"
              rules={[{ required: true, message: 'Please select type!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(value) => handleChange('type', value)}
              >
                {AvatarAssetTypes?.map((one: any) => (
                  <Option key={one} value={one}>
                    {one}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!', whitespace: true }]}
            >
              <Input
                name="name"
                onChange={(e) => onHandleChange(e, 'name')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Svg"
              name="svg"
              rules={[{ required: true, message: 'Please upload your svg!' }]}
            >
              <input ref={svgRef} type="file" onChange={handleSvgChange} accept=".svg" hidden />
              {!activeForm.svg && (
                <Button
                  className="btn-outlined-orange w-[100px]"
                  onClick={() => svgRef.current?.click()}
                >
                  <PlusOutlined />
                </Button>
              )}
              {activeForm.svg && (
                <img
                  src={svgToBase64DataUrl(activeForm.svg)}
                  width="50"
                  height="50"
                  onClick={() => svgRef.current?.click()}
                />
              )}
            </Form.Item>
            <Form.Item label="Fix Colour" name="fixColour">
              <Checkbox
                checked={activeForm.fixColour}
                onChange={(e) => handleChange('fixColour', e.target.checked)}
              >
                Fix Colour
              </Checkbox>
            </Form.Item>
            {!activeForm.fixColour && (
              <Form.Item label="colour" name="colour">
                <ColorInput
                  value={activeForm.colour}
                  onChange={(color) => handleChange('colour', color)}
                />
              </Form.Item>
            )}
            <Form.Item label="Event" name="event" className="no-border">
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(value) => handleChange('event', !value ? null : value)}
              >
                <Option value="">None</Option>
                {assetEvents?.map((one: any) => (
                  <Option key={one._id} value={one._id}>
                    {one.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Status"
              name="status"
              className="no-boder"
              rules={[{ required: true, message: 'Please select your User Status!' }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue="Draft"
                onChange={(value) => handleChange('status', value)}
              >
                <Select.Option value="Draft">Draft</Select.Option>
                <Select.Option value="Published">Published</Select.Option>
                <Select.Option value="Hidden">Hidden</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isLoading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isLoading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          )}
          {props.viewMode === 'Edit'
            ? props.visible && (
                <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
                  Save
                </SubmitButton>
              )
            : props.viewMode === 'Create'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
                    Submit
                  </SubmitButton>
                )
              : null}
        </Form.Item>
      </DrawerFooter>
    </Form>
  );
};

export default AssetsForm;
