import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getBlog, searchBlogApi } from '../api/blog';
import { UserContainer } from '../pages/dashboard/styles';
import { Role } from '../utils/types/role';
import { AddButton, CustomerContainer } from './common/styles';
import BlogForm from './FormComponent/BlogForm';
import { SearchContainer } from './ListingComponent/styles';
import BlogTableComponent from './TableData/BlogTableComponent';

export interface BlogComponentProps {
  currentLoginUser: any;
}

const BlogComponent: React.FC<any> = (props: BlogComponentProps) => {
  const { currentLoginUser } = props;

  const [isloading, setLoading] = useState<boolean>(false);
  const [blogList, setBlogList] = useState<Blog[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [perPageRecords, setPerPageRecords] = useState<number>(10);
  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    try {
      if (searchTerm === '') {
        const response = await getBlog();
        setBlogList(response?.data);
      } else {
        const options = {
          skip: 0,
          limit: perPageRecords,
          search: searchTerm
        };
        const response = await searchBlogApi(options);
        setBlogList(response?.data);
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') fetchData();
    },
    [searchTerm]
  );

  const showDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <div>
      <Spin spinning={isloading}>
        {currentLoginUser.type === Role.Admin ? (
          <UserContainer>
            <AddButton type="primary" onClick={showDrawer}>
              <PlusOutlined />
            </AddButton>
            <Drawer
              title="Create Blog Form"
              placement="right"
              onClose={closeDrawer}
              visible={open}
              width="1024"
              maskClosable={false}
              className="user-drawer"
            >
              {open && <BlogForm fetchData={fetchData} permission="create" onClose={closeDrawer} />}
            </Drawer>
          </UserContainer>
        ) : null}

        <div className="site-layout-background">
          <SearchContainer className="search-box search-video-box">
            <Button
              onClick={() => (searchTerm != '' ? fetchData() : null)}
              className="SearchButton"
            >
              <SearchOutlined />
            </Button>
            <Input
              onKeyPress={handleKeyPress}
              name="search"
              value={searchTerm}
              onChange={handleChange}
              placeholder="Search Blog"
            />
          </SearchContainer>
          <div className="site-card-wrapper">
            <CustomerContainer>
              <BlogTableComponent
                className="resources-table"
                blogData={blogList}
                fetchData={fetchData}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(BlogComponent);
