import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Table } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { userMemberDataType } from '../../../constant';
import { RootState } from '../../../redux/store';
import PRIVILEGES from '../../../utils/privilege';
import { Mode, Role } from '../../../utils/types/role';
import { ColDiv, EditViewIcon } from '../../common/styles';
import { ShortCategory } from './ShortCategoriesComponent';

interface ShortCategoriesTableProps {
  onOpenModal: (id: string, mode: Mode) => void;
  className?: string;
  shortCategories: ShortCategory[];
}

const ShortCategoriesTable = ({
  onOpenModal,
  shortCategories,
  className
}: ShortCategoriesTableProps) => {
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const canEdit = PRIVILEGES.ShortCategories.Edit?.includes(currentLoginUser.type as Role);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
      render: (text, record) => <ColDiv>{record.name}</ColDiv>
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      width: 250,
      sorter: (a, b) => a.level && b.level && a.level.localeCompare(b.level),
      render: (text, record) => <ColDiv>{record.level?.name}</ColDiv>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <span onClick={() => onOpenModal(record._id, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => onOpenModal(record._id, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <Table
      className={className}
      columns={columns}
      dataSource={shortCategories}
      pagination={false}
      scroll={{ x: 1000 }}
    />
  );
};

export default ShortCategoriesTable;
