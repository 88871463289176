import styled from 'styled-components';
import { Button, Input } from 'antd';

export const LoginContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 0px;
  text-align: right;
  background-color: #f9fcfd;
`;

export const LoginTitle = styled.div`
  height: 31px;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px;
  text-align: center;
  margin-top: 50px;
  color: #1890ff;
`;

export const WelcomeBack = styled.div`
  height: 21px;
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left;
  font-weight: 500;
`;

export const InputFieldsContainer = styled.div`
  height: auto;
  width: 100%;
`;

export const SubmitButtonContainer = styled.div`
  width: 96%;
  height: 46px;
  margin: auto;
  margin-top: 20px;
  box-sizing: border-box;
`;

export const LoginButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 46px;
  font-size: 17px;
`;

export const ForgetButtonContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 46px;
  font-size: 16px;
`;

export const LoginButton = styled(Button)`
  width: 100%;
`;
export const DownloadAppButton = styled(Button)``;
export const RememberMe = styled.div`
  margin-top: 20px;
  text-align: left;
`;
export const ForgotPassword = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 10px;
  color: black !important;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;
export const WelcomeScreen = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 10px;
  color: black !important;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`;
// @ts-ignore
export const InputField = styled(Input)`
  background-color: rgba(17, 206, 239, 0.2);
  height: 48px;
  width: 100%;
  border-color: rgb(204, 204, 204);
  border-width: 1px;
  padding: 0px 10px;

  &:hover {
    background-color: rgb(215, 215, 215);
    border-color: #11ceef;
    border-width: 2px;
  }

  &:focus {
    border-color: #11ceef;
  }
`;

export const Logo = styled.img`
  width: 170px;
  height: auto;
`;

export const LogoContainer = styled.div`
  text-align: center;
  margin-top: 0px;
`;

export const ErrorContainer = styled.div`
  text-align: center;
  padding-top: 10px;
  color: red;

  &.file-error {
    text-align: left;
    padding-top: 5px;
  }
`;

export const BackContainer = styled.div`
  display: flex;
  border: none;
  box-shadow: none;
  padding: 0px;
  margin-top: 70px;
`;

export const BackButton = styled(Button)`
  border: none;
  box-shadow: none;
  padding: 0px;
  cursor: pointer;
  padding-top: 1px;
  margin-right: 15px;
`;

export const VerifyText = styled.p`
  text-align: center;
`;
