import { API_URL } from '../constant';
import { apiGet, apiPatch } from '../utils/api';

export const getPost = () => apiGet(`${API_URL}userPost?includeDeleted=true`);

export const getCurrentPost = (id) => apiGet(`${API_URL}userPost/${id}`);

export const patchAnswer = (id, data) => apiPatch(`${API_URL}userPost/${id}/answer`, data);

export const searchPostApi = (options) => apiGet(`${API_URL}userPost?includeDeleted=true`, options);
