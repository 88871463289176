import { Checkbox, Col, DatePicker, Form, Input, Row, Select, Spin, TimePicker, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { patchWorkShop, searchWorkShopApi, workShopDetailsApi } from '../../api/workshop';
import { CountryCodeProps, StatusProps, WorkShopStatus } from '../../constant';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import { setWorkShopList } from '../../redux/actions/userMemberAction';
import useVideoPlayerModal from '../../utils/hooks/useVideoPlayerModal';
import { Mode, Role } from '../../utils/types/role';
import FileUploader from '../common/FileUploader';
import { DrawerFooter, SubmitButton, UserLastRow } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};
const { Title, Text } = Typography;
const { Option } = Select;
interface WorkShopFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  workShopId?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  countryCode: CountryCodeProps[];
  showFormData: any;
  state: any;
  customerDataList: any;
  authorList?: any;
  pagination?: any;
  PerPageRecords?: any;
  changePageToOne?: () => void;
  searchTerm?: string;
}

const WorkShopForm: React.FC<WorkShopFormProps> = (props) => {
  const [form] = Form.useForm();
  const {
    dispatch,
    workShopId,
    showFormData,
    visible,
    pagination,
    PerPageRecords,
    changePageToOne,
    searchTerm
  } = props;
  const [activeForm, setActiveForm] = useState<any>({
    phone: ''
  });
  const [isloading, setisloading] = useState<boolean>(true);
  const [fileError, setFileError] = useState<string>('');
  const { open: openVideoPlayer, component: videoPlayerComponent } = useVideoPlayerModal(
    activeForm.video
  );

  useEffect(() => {
    setisloading(true);
    const activeFormLength = Object.keys(activeForm).length;
    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          webinarTopic: '',
          isPromoted: false,
          webinarType: '',
          webinarDescription: '',
          webinarDate: '',
          webinarTime: '',
          presenter1: '',
          presenter2: '',
          zoomLink: '',
          transcript: '',
          zoomMeeting: '',
          video: '',
          thumbnail: ''
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
      }
    }
    if (workShopId && visible) {
      workShopDetailsApi(workShopId).then((res) => {
        const { data } = res;
        // const { year, month, day } = data.webinarDate;
        const stateData = {
          webinarTopic: data.webinarTopic,
          isPromoted: data.isPromoted,
          webinarType: data.webinarType,
          webinarDescription: data.webinarDescription,
          webinarDate: data.webinarDate ? moment(new Date(data.startTime)) : moment(),
          webinarTime: data.webinarTime ? moment(new Date(data.startTime)) : moment(),
          presenter1: `${data.presenter1 ? data.presenter1 : ''}`,
          presenter2: `${data.presenter2 ? data.presenter2 : ''}`,
          zoomLink: data.zoomLink,
          transcript: data.transcript,
          video: data.video,
          thumbnail: data.thumbnail,
          zoomMeeting: data.zoomMeeting,
          status: data.status ? data.status : 'Pending'
        };
        setActiveForm(stateData);
        setisloading(false);
        form.setFieldsValue(stateData);
      });
    }
  }, [form, workShopId, showFormData, visible, props.userRole]);

  const onReset = () => {
    form.resetFields();
    setActiveForm({});
    props.close();
    props.cancel();
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onChangeFile = (url, name) => {
    setActiveForm({ ...activeForm, video: url });
  };
  const onChangeDate = (date, dateString) => {
    setActiveForm({ ...activeForm, webinarDate: date });
  };

  const onChangeTime = (time, timeString) => {
    setActiveForm({ ...activeForm, webinarTime: time });
  };

  const onSubmit = () => {
    setisloading(true);
    const year = moment(activeForm.webinarDate).year();
    const month = moment(activeForm.webinarDate).month() + 1;
    const day = moment(activeForm.webinarDate).date();
    const minute = moment(activeForm.webinarTime).minutes();
    const hour = moment(activeForm.webinarTime).hours();
    const startTime = new Date(year, month - 1, day, hour, minute);
    const updateData = {
      ...activeForm,
      webinarDate: {
        year,
        month,
        day
      },
      webinarTime: {
        minuteSelect: minute,
        hourSelect: hour
      },
      startTime: startTime.toISOString()
    };
    if (workShopId) {
      patchWorkShop(workShopId, updateData)
        .then((res) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          showNotificationDrawer('Success', 'Workshop Updated successfully!');
          dispatch(setIsOpenDrawer(false));
          searchWorkShopApi({
            skip: pagination,
            limit: PerPageRecords,
            search: searchTerm
          })
            .then((res) => {
              const { data } = res;
              dispatch(setWorkShopList(data));
            })
            .catch((err) => {
              throw err;
            });
          onReset();
        })
        .catch((err) => {
          if (err.response?.data) {
            showNotificationDrawer('Error', err.response.data.message);
          }
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
        });
    }
  };
  const handleLinkClick = (text) => {
    openVideoPlayer();
  };

  const onChangeThumbnailFile = (url, name) => {
    setActiveForm({ ...activeForm, thumbnail: url });
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }
  return (
    <Form
      {...layout}
      form={form}
      className="admin-form author-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Topic"
              name="webinarTopic"
              rules={[
                {
                  required: true,
                  message: 'Please input webinar Topic!',
                  whitespace: true
                }
              ]}
            >
              <Input
                name="webinarTopic"
                onChange={(e) => onHandleChange(e, 'webinarTopic')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item name="isPromoted">
              <Checkbox
                onChange={(e) => handleChange('isPromoted', e.target.checked)}
                checked={activeForm.isPromoted}
              >
                Promote in Mobile App
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Type"
              name="webinarType"
              rules={[
                {
                  required: true,
                  message: 'Please input Webinar Type!'
                }
              ]}
              className="no-boder time-zone-container-s"
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={(value) => handleChange('webinarType', value)}
                placeholder="Please select type"
              >
                <Option key={1} value="Mindset Session by Renae">
                  Mindset Session by Renae
                </Option>
                <Option key={2} value="Group Session by Coach">
                  Group Session by Coach
                </Option>
              </Select>
            </Form.Item>
            <Text className="form-date-time-text">Start Date & Time</Text>
            <Row className="form-date-time-row">
              <Col xs={24} sm={13} className="left-select">
                <Form.Item
                  name="webinarDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your start date!'
                    }
                  ]}
                  className="time-zone-container-s"
                >
                  {/* @ts-ignore */}
                  <DatePicker
                    getPopupContainer={(triggerNode) => triggerNode}
                    className="autismag-picker"
                    name="webinarDate"
                    style={{ width: '100%' }}
                    format="DD-MM-YYYY"
                    // value={activeForm.webinarDate}
                    onChange={onChangeDate}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col xs={22} sm={10} style={{ marginLeft: 'auto' }} className="left-select">
                <Form.Item
                  name="webinarTime"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your start time!'
                    }
                  ]}
                  className="session-notes time-zone-container-s"
                >
                  <TimePicker
                    getPopupContainer={(triggerNode) => triggerNode}
                    showNow={false}
                    value={activeForm.webinarTime}
                    className="autismag-picker"
                    allowClear={false}
                    // name="webinarTime"
                    use12Hours
                    format="hh:mm a"
                    onChange={onChangeTime}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Presenter#1" name="presenter1" className="contact-pno">
              <Input
                name="presenter1"
                onChange={(e) => onHandleChange(e, 'presenter1')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item label="Presenter#2" name="presenter2" className="contact-pno">
              <Input
                name="presenter2"
                onChange={(e) => onHandleChange(e, 'presenter2')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>

            <Form.Item label="Transcript" name="transcript" className="no-boder">
              <Input.TextArea
                name="transcript"
                onChange={(e) => onHandleChange(e, 'transcript')}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>

            <Form.Item
              label="Video Link"
              name="video"
              className="no-boder"
              // rules={[{ required: true, message: "field is required" }]}
              style={{ pointerEvents: 'auto' }}
            >
              {props.viewMode === 'View' ? (
                <span
                  onClick={() => handleLinkClick(activeForm.video)}
                  style={{
                    pointerEvents: 'auto',
                    cursor: 'pointer',
                    color: '#2a8ff7'
                  }}
                >
                  {activeForm.video}
                </span>
              ) : (
                <Input
                  style={{
                    borderBottom: '1px solid #E9E9E9'
                  }}
                  name="document"
                  placeholder="https://example.com"
                  pattern="https://.*"
                  onChange={(e) => onHandleChange(e, 'video')}
                />
              )}
            </Form.Item>

            {/* <Form.Item
                            label="Upload Video"
                            name="video"
                            className="no-boder"
                            // rules = {[{required:true,message:'Please upload video'}]}
                            style={{pointerEvents : "auto"}}
                        >
                            <FileUploader mode={props.viewMode} limit={1073741824} onChange={onChangeFile} avatarImage={activeForm?.video} errorMessage={fileError} onlyVideoAccept previewVideo={true}/>
                        </Form.Item> */}
            <Form.Item
              label="Upload Thumbnail"
              name="thumbnail"
              className="no-boder"
              rules={[
                {
                  required: false,
                  message: 'Please upload thumbnail'
                }
              ]}
              style={{ pointerEvents: 'auto' }}
            >
              <FileUploader
                mode={props.viewMode}
                onChange={onChangeThumbnailFile}
                avatarImage={activeForm?.thumbnail}
                errorMessage={fileError}
                onlyImageAccept
                previewVideo
                resize
              />
            </Form.Item>

            <Form.Item
              label="Description"
              name="webinarDescription"
              rules={[
                {
                  required: false,
                  message: 'Please input Description!'
                }
              ]}
              className="no-boder time-zone-container-s"
            >
              <Input.TextArea
                rows={5}
                name="webinarDescription"
                onChange={(e) => onHandleChange(e, 'webinarDescription')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Zoom Link"
              name="zoomLink"
              rules={[
                {
                  pattern:
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                  message: 'Please input Valid Url'
                },
                {
                  required: true,
                  message: 'Please input zoom Link!',
                  whitespace: true
                }
              ]}
            >
              {props.viewMode === 'View' ? (
                <span
                  onClick={() => handleLinkClick(activeForm.zoomLink)}
                  style={{
                    pointerEvents: 'auto',
                    cursor: 'pointer',
                    color: '#2a8ff7',
                    paddingLeft: '0.7rem'
                  }}
                >
                  {activeForm.zoomLink}
                </span>
              ) : (
                <Input
                  name="zoomLink"
                  placeholder="https://example.com"
                  pattern="https://.*"
                  onChange={(e) => onHandleChange(e, 'zoomLink')}
                  style={{
                    borderBottom: '1px solid #E9E9E9'
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Zoom Meeting"
              name="zoomMeeting"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: 'Zoom Meeting must contain Numeric Values'
                }
              ]}
            >
              <Input
                name="zoomMeeting"
                onChange={(e) => onHandleChange(e, 'zoomMeeting')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              className="no-boder"
              rules={[
                {
                  required: true,
                  message: 'Please select your User Status!'
                }
              ]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                defaultValue="Pending"
                onChange={(value) => handleChange('status', value)}
              >
                {WorkShopStatus.map((data: StatusProps, index: number) => (
                  <Option key={index} value={data.label}>
                    {data.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          )}
          {props.viewMode === 'Edit'
            ? props.visible && (
                <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                  Save
                </SubmitButton>
              )
            : props.viewMode === 'Create'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                    Submit
                  </SubmitButton>
                )
              : null}

          {/* {((props.userRole === Role.Admin) && workShopId) ?
                        <DeleteTabDiv style={{ marginLeft: "20px", marginTop: "10px" }} className="" onClick={() => deleteResource(workShopId)}>
                            <DeleteOutlined />
                        </DeleteTabDiv >
                        : null
                    } */}
        </Form.Item>
      </DrawerFooter>
      {videoPlayerComponent}
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    customerDataList: state.notificationReducer.customerDataList,
    authorList: state.userMemberData.authorList
  };
}

export default connect(mapStateToProps)(WorkShopForm);
