import React, { useCallback } from 'react';
import { Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CSSProperties } from 'styled-components';
import { MemberItem } from './MemberItem';
import { memberItemType } from '../../constant';

interface MemberListProps {
  memberList: any[];
  onChange: (members: memberItemType[]) => void;
  hideAdd: boolean;
  disabled: boolean;
  noRemoveTab?: boolean;
  viewMode?: boolean;
}

export const MemberList: React.FC<MemberListProps> = ({
  memberList,
  onChange,
  hideAdd,
  disabled,
  noRemoveTab,
  viewMode,
}: MemberListProps) => {
  const onAdd = useCallback(
    (memberList) => {
      onChange([
        ...memberList,
        {
          parent: null,
          firstName: '',
          lastName: '',
          dob: '',
          memberShipStart: moment(),
          memberShipEnd: '',
          listingType: '',
          listingIdentifier: '',
          formRef: null,
        },
      ]);
    },
    [onChange],
  );

  const onRemove = useCallback(
    (memberList, index: number) => {
      const copyMemberList = [...memberList];
      copyMemberList.splice(index, 1);
      onChange([...copyMemberList]);
    },
    [onChange],
  );

  const onRefUpdate = useCallback(
    (formRef: any, memberList: any[], index: number) => {
      let copyMemberList = [...memberList];
      copyMemberList = copyMemberList.map((member, memberIndex) => ({
        ...member,
        formRef: index === memberIndex ? formRef : member.formRef,
      }));
      onChange([...copyMemberList]);
    },
    [onChange],
  );

  const formStyle: CSSProperties = {};

  if (disabled) {
    formStyle.pointerEvents = 'none';
  }

  return (
    <div style={formStyle}>
      {memberList.map((member, index) => {
        const { formRef, ...restMember } = member;
        return (
          <MemberItem
            hideadd={hideAdd}
            key={index}
            member={restMember}
            existingFormRef={formRef}
            onRefUpdate={(formRef) => onRefUpdate(formRef, memberList, index)}
            onRemove={() => onRemove(memberList, index)}
            noRemoveTab={noRemoveTab || viewMode}
          />
        );
      })}
      {hideAdd ? null : (
        <Form.Item className="add-children no-border">
          <Button type="dashed" onClick={() => onAdd(memberList)} block>
            <PlusOutlined />
            {' '}
            Add Member
          </Button>
        </Form.Item>
      )}
    </div>
  );
};

export default MemberList;
