import { SET_VIDEO_CATEGORY_LIST, SET_VIDEO_CATEGORY_PAGINATION } from '../types/types';

export function setVideoCategoryList(data) {
  return {
    type: SET_VIDEO_CATEGORY_LIST,
    payload: data
  };
}

export function setVideoCategoryPagination(data) {
  return {
    type: SET_VIDEO_CATEGORY_PAGINATION,
    payload: data
  };
}
