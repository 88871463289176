import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Card,
  Checkbox,
  Col,
  Empty,
  Input,
  List,
  Modal,
  Progress,
  Row,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getSelectedMemberDashboardApi } from '../api/customer';
import { downloadFileAPI } from '../api/file';
import { getUserMemberResourcesAPI } from '../api/resources';
import { getPublicUserApi } from '../api/user';
import { API_URL, dashBoardFilterByDateType } from '../constant';
import BlueDotsIcon from '../images/icons/3bluedotsIcon.svg';
import ThreeDotsIcon from '../images/icons/3dotsIcon.svg';
import DeleteIcon from '../images/icons/Delete.svg';
import DownLoadIcon from '../images/icons/download.svg';
import EditIcon from '../images/icons/Edit.svg';
import FormsIcon from '../images/icons/forms.svg';
import LearningIcon from '../images/icons/learning.svg';
import { setCustomerInfo, setCustomerLoading } from '../redux/actions/customerAction';
import { setFetchingResource } from '../redux/actions/formAction';
import { setBannerHeaderLabel } from '../redux/actions/trackingAction';
import { Role } from '../utils/types/role';
import AddSessionNoteComponent from './AddUser/AddSessionNoteComponent';
import AddTasks from './AddUser/AddTasks';
import ProgressChart from './Charts/ProgressChart';
import {
  CardBottomLink,
  CompletedTaskDiv,
  CustomerContainer,
  IconTab,
  ListingDivContainer,
  SettingContainer,
  UserContainer
} from './common/styles';
import { customerHierarchy } from './SelectData/constant';
import SelectDataByType from './SelectData/SelectDataByType';

import { postNotificationApi } from '../api/notification';
import {
  getGoalsApi,
  getTaskDetailsApi,
  updateTaskApi,
  updateTaskStatusApi
} from '../api/user-program';
import {
  notificationVisible,
  sessionVisibleDrawer,
  setNotificationAlert
} from '../redux/actions/notificationAction';
import { setMemberGoals } from '../redux/actions/userProgramAction';
import { useLoginUser } from '../utils/hooks/useLoginUser';
import UserAvatar from './common/UserAvatar';
import { getRouteLabel } from './common/utils';
import { ProgramRole } from './MyGoalsComponent';

const { confirm } = Modal;
interface ParentOnboardProps {
  customerInfo: any;
  customerLoading: boolean;
  dispatch?: any;
  sessionData?: any;
  customerSelectedMember?: any;
  resourcesList?: any;
  customerAccessData: any;
  viewMode: string;
  visible?: boolean;
  customerDataList?: any;
  visibly?: any;
  sessionVisible?: any;
  currentLoginUser?: any;
  memberGoals?: any;
  userLocalData?: any;
}
const { Title } = Typography;
const ParentOnboardingComponent: React.FC<ParentOnboardProps> = (props) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [chooseKey, setKey] = useState<string>('Active');
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [routeLabel, setRouteLabel] = useState<string>('');
  const [milestoneData, setMilestoneData] = useState<any>();
  const [viewMode, setViewMode] = useState<string>('');
  const [taskData, setTaskData] = useState<any>();
  const [taskId, setTaskId] = useState<any>();
  const [taskNotesModalOpen, setTaskNotesModalOpen] = useState(false);
  const [sessionDrawerId, setSessionDrawerId] = useState('');
  const [currentSelectedTaskChecked, setCurrentSelectedTaskChecked] = useState(false);
  const [taskNotes, setTaskNotes] = useState('');
  const [selectedTask, setSelectedTask] = useState({} as any);
  const {
    dispatch,
    customerInfo,
    customerLoading,
    customerSelectedMember,
    resourcesList,
    sessionData,
    customerAccessData,
    customerDataList,
    visibly,
    sessionVisible,
    userLocalData,
    currentLoginUser
  } = props;

  const customerData: any =
    customerHierarchy.length && customerHierarchy[0].customerSelectionType[0].selectedData;
  // const checkedList = customerData && customerData.myTasks.filter((word:any) => defaultCheckedValue.includes(word.value));
  const defaultDate = dashBoardFilterByDateType[dashBoardFilterByDateType.length - 1];
  useEffect(() => {
    if (customerSelectedMember && customerSelectedMember.length) {
      const data = {
        startDate: defaultDate.startDate,
        endDate: defaultDate.endDate
      };
      if (customerSelectedMember[0]._id) {
        getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
          const { data } = res;
          dispatch(setCustomerInfo(data));
        });
      }

      if (customerSelectedMember[0]._id) {
        const options = {
          skip: 0,
          limit: 10
        };
        getUserMemberResourcesAPI(customerSelectedMember[0]._id, options).then((res) => {
          const { data } = res;
          dispatch(setFetchingResource(data));
        });
      }
    }
  }, [dispatch, customerSelectedMember, defaultDate]);
  useEffect(() => {
    const headerPath = getRouteLabel(
      currentLoginUser.type,
      window.location.pathname,
      customerAccessData
    );
    setRouteLabel(headerPath);

    history.listen((location, action) => {
      const headerPathName = getRouteLabel(
        currentLoginUser.type,
        window.location.pathname,
        customerAccessData
      );
      setRouteLabel(headerPathName);
    });
  }, []);

  let totalTask = customerInfo && customerInfo.pendingTasks;

  if (customerInfo.completedTasks && customerInfo.completedTasks.length) {
    totalTask = customerInfo.completedTasks.concat(totalTask);
  }

  let optionList = customerInfo && customerInfo.pendingTasks;

  if (customerInfo.completedTasks && customerInfo.completedTasks.length) {
    optionList = optionList.concat(customerInfo.completedTasks);
  }

  const optionTaskList =
    optionList &&
    optionList.length &&
    optionList?.filter((x) => !x.isDeleted)?.map((res: { name: any }) => res.name);

  const completedTasklist =
    customerInfo.completedTasks &&
    customerInfo.completedTasks.length &&
    customerInfo.completedTasks?.filter((x) => !x.isDeleted)?.map((res: { name: any }) => res.name);

  const onChange = (checkedValues: any) => {
    // const checkedTask =
    //   customerInfo &&
    //   customerInfo.pendingTasks
    //     .concat(customerInfo.completedTasks)
    //     .filter((elem: any) => checkedValues.includes(elem.name));
    // const modifyTaskList = checkedTask.map((res) => res.name);
    // setCheckedValue(modifyTaskList);
  };

  const gotoMoreTasks = () => {
    const path = '/dashboard/myprogram';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
  };

  const gotoMoreFiles = () => {
    const path = '/dashboard/myresources';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
  };
  const gotoMoreFavourites = () => {
    const path = '/dashboard/myfavorites';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
  };
  const gotoMoreSessions = () => {
    const path = '/dashboard/mysessions';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
  };
  const gotoMoreMileStone = () => {
    const path = '/dashboard/myprogram';
    history.push(path);
    dispatch(setBannerHeaderLabel(path));
  };

  const getClassName = (data: string | any[]) => {
    if (data && data.length) {
      return '';
    }
    return 'no-more-tab';
  };

  const getTaskClassName = (data: string | any[], text: string) => {
    let classConst = '';
    if (text) {
      classConst = 'task-list-card ';
    }
    if (data && data.length > 3) {
      return classConst;
    }
    return `${classConst}no-more-tab`;
  };

  const handlescreenSize = () => {
    setScreenSize(window.innerWidth);
  };
  const downloadFile = (fileUrl: string) => {
    if (fileUrl) {
      const data = {
        url: fileUrl
      };
      downloadFileAPI(data).then((res) => {
        const url = `${API_URL}static/${res.fileName}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(this.response);
          const tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = res.fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
        };
        xhr.send();
      });
    }
  };
  useEffect(() => {
    handlescreenSize();
    window.addEventListener('resize', handlescreenSize);
    return () => {
      window.removeEventListener('resize', handlescreenSize);
    };
  }, []);

  const completeTask = completedTasklist && completedTasklist.length;
  const TaskCount = optionTaskList ? `${`${completeTask}/${optionTaskList?.length}`}` : '';

  const optionMilestoneList =
    customerInfo &&
    customerInfo.completedMilestones?.length &&
    customerInfo.completedMilestones.map((res: { name: any }) => res.name);
  const mileStoneCompleteTask =
    customerInfo.completedMilestones && customerInfo.completedMilestones.length;

  const MileStoneCount = optionMilestoneList
    ? `${`${mileStoneCompleteTask}/${optionMilestoneList.length}`}`
    : '';
  const FavouriteCount = customerInfo && customerInfo.favourites;
  const MilestoneCount = customerInfo && customerInfo.completedMilestones;
  const viewTask =
    totalTask && totalTask.filter((item: { isDeleted: boolean }) => item.isDeleted === false);
  const viewResource = resourcesList && resourcesList.slice(0, 5);
  const viewMilestones =
    customerInfo &&
    customerInfo.completedMilestones &&
    customerInfo.completedMilestones.length &&
    customerInfo.completedMilestones.slice(0, 5);
  const viewFavourites =
    customerInfo &&
    customerInfo.favourites &&
    customerInfo.favourites.length &&
    customerInfo.favourites.slice(0, 5);
  const viewSessions =
    customerInfo &&
    customerInfo.sessionNotes &&
    customerInfo.sessionNotes.length &&
    customerInfo.sessionNotes.slice(0, 5);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
    dispatch(notificationVisible(false));
    dispatch(sessionVisibleDrawer(false));
  }, [chooseKey]);

  const openAddTaskDrawer = (id: any) => {
    setTaskId(id);
    dispatch(notificationVisible(true));
    getTaskDetailsApi(id).then((res) => {
      const { data } = res;
      setTaskData(data);
      setMilestoneData(data.milestone);
    });
  };
  const openSessionDrawer = (id) => {
    dispatch(sessionVisibleDrawer(true));
    setSessionDrawerId(id);
  };

  const getGoalsData = () => {
    if (customerSelectedMember && customerSelectedMember.length && customerSelectedMember[0]._id) {
      getGoalsApi(customerSelectedMember[0]._id).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
      const data = {
        startDate: defaultDate.startDate,
        endDate: defaultDate.endDate
      };
      getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
        const { data } = res;
        dispatch(setCustomerInfo(data));
      });
    }
  };

  const deleteTask = (id: any) => {
    if (id) {
      getTaskDetailsApi(id).then((res) => {
        const { data } = res;
        setTaskData(data);
        setMilestoneData(data.milestone);
        setLoading(true);
        updateTaskApi(id, {
          ...taskData,
          isDeleted: true
        }).then((res) => {
          getGoalsData();
          setLoading(false);
        });
      });
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTask(id);
      },
      onCancel() {
        console.log('Cancel');
      }
    });
  };
  const handleCheckBox = (e: CheckboxChangeEvent, task: { assignee: any[]; _id: any }) => {
    const { checked } = e.target;
    setCurrentSelectedTaskChecked(checked);
    // check to prevent app from crashing:
    if (task.assignee.some((x: any) => !x)) return;
    const updateData = {
      ...task,
      isCompleted: checked,
      notes: ''
    };
    setSelectedTask(updateData);
    let updateTaskCheck = false;

    if (
      task.assignee.includes(props.currentLoginUser._id) ||
      currentLoginUser.type === 'BusinessUser'
    ) {
      updateTaskCheck = true;
    }
    if (checked && updateTaskCheck) {
      setTaskNotesModalOpen(true);
    } else if (task._id && updateTaskCheck) {
      dispatch(setCustomerLoading(true));
      updateTaskStatusApi(task._id, updateData)
        .then((res) => {
          // console.log(res);
          getGoalsData();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleTaskNotesOk = () => {
    setTaskNotesModalOpen(false);
    // setTaskNotes("");
    if (selectedTask._id) {
      dispatch(setCustomerLoading(true));
      updateTaskStatusApi(selectedTask._id, { ...selectedTask, notes: taskNotes })
        .then((res) => {
          setTaskNotes('');
          getGoalsData();
          const notification = {
            member: `${
              customerSelectedMember.length
                ? customerSelectedMember[0]._id
                : customerSelectedMember._id
            }`,
            businessUser: `${props.currentLoginUser && props.currentLoginUser._id}`,
            message: `${
              customerDataList && customerDataList.firstName
            } just Marked a Task as Complete`,
            user: `${
              customerSelectedMember.length
                ? customerSelectedMember[0].parent
                : customerSelectedMember.parent
            }`,
            task: `${selectedTask._id}`,
            type: 'TASK_MARKED'
          };

          postNotificationApi(notification).then((res) => {
            dispatch(setNotificationAlert(res.data));
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  // const viewModifiedTask = new Date(Math.max(viewTask && viewTask.map(x => moment(x.createdAt))));

  const viewModifiedTask =
    viewTask &&
    viewTask.sort(
      (a: { updatedAt: moment.MomentInput }, b: { updatedAt: moment.MomentInput }) =>
        moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf()
    );

  const loginUser = useLoginUser();
  const { csm } = loginUser?.type === Role.Customer ? loginUser : userLocalData;

  const primaryContactId = customerSelectedMember[0]?.primaryContact;
  const primaryContactVisible = [Role.Business, Role.Admin, Role.Customer].includes(
    loginUser?.type
  );

  const customerManagerQuery = useQuery(
    ['customerManager', csm],
    () => getPublicUserApi(csm, 'BusinessUser'),
    {
      enabled: Boolean(csm)
    }
  );
  const primaryContactQuery = useQuery(
    ['primaryContact', primaryContactId],
    () => getPublicUserApi(primaryContactId, 'BusinessUser'),
    {
      enabled: Boolean(primaryContactId)
    }
  );

  const primaryContact = primaryContactQuery.data;
  const customerManager = customerManagerQuery.data;

  const usersArea = (
    <Row justify="space-around">
      {primaryContactVisible && (
        <Col lg={8} md={12} sm={18}>
          <Card
            className="autismag-extra-bottom !min-h-fit !min-w-max"
            type="inner"
            title={<div className="text-purple-500 text-lg">Primary Clinician</div>}
            bordered={false}
            bodyStyle={{ paddingTop: '.25rem', paddingBottom: 0 }}
          >
            <UserAvatar
              user={primaryContact}
              color="#67348e"
              loading={primaryContactQuery.isLoading}
            />
          </Card>
        </Col>
      )}
      <Col lg={8} md={12} sm={18}>
        <Card
          className="autismag-extra-bottom !min-h-fit !min-w-max"
          type="inner"
          title={<div className="text-pink-500 text-lg">Customer Success Manager</div>}
          bordered={false}
          bodyStyle={{ paddingTop: '.25rem', paddingBottom: 0 }}
        >
          <UserAvatar
            user={customerManager}
            color="#ea197a"
            loading={customerManagerQuery.isLoading}
          />
        </Card>
      </Col>
    </Row>
  );

  return (
    <div className="dashboard-main-layout">
      {/* <Title level={3} className="mobile-route-label">
        {routeLabel}
      </Title> */}
      {screenSize <= 1042 && (
        <UserContainer className="dashboard-inner-layout">
          {/* <SelectDataByType /> */}
          <SettingContainer className="setting-right setting-filter setting-filter-mobile">
            <Tooltip title="">
              <Title level={5} style={{ display: 'inline' }}>
                {' '}
                Filters
              </Title>
              <div className="setting-filter-drop-down">
                <SelectDataByType inline />
              </div>
            </Tooltip>
          </SettingContainer>
        </UserContainer>
      )}
      <div className="site-layout-background">
        <div className="site-card-wrapper">
          <CustomerContainer className="site-card-inner-layout">
            <Spin spinning={customerLoading}>
              {usersArea}
              <Row gutter={16}>
                <Col md={8}>
                  <ProgressChart
                    className="autismag-margin-bottom"
                    title="Outcome Progress"
                    centerText="Outcomes Completed"
                    // subTitle="Start from Mar 1-8,Mar,2021"
                    percentage={customerInfo && customerInfo.goalsProgress}
                    lowColor="#67348E"
                    highColor="#EA197A"
                    progressId="goalProgressBar"
                    colorClass="custom-progresBar"
                  />
                </Col>
                <Col md={8}>
                  <Card
                    className={
                      resourcesList && resourcesList.length >= 5
                        ? 'autismag-margin-bottom'
                        : 'autismag-extra-bottom'
                    }
                    type="inner"
                    title="Days in Program"
                    bordered={false}
                  >
                    <div className="sub-icon">
                      <img src={ThreeDotsIcon} className="sub-dots-black-icon" />
                      <img src={BlueDotsIcon} className="sub-dots-icon" />
                    </div>
                    <div className="days-in-program-container">
                      {customerInfo && (
                        <div>
                          <span className="days">{customerInfo.daysInProgram || 0}</span>
                          Days
                          <br />
                          <div className="progress-sub-list">Days in Program</div>
                        </div>
                      )}
                    </div>
                  </Card>
                  {/* <ProgressChart
                    className="autismag-margin-bottom"
                    title="Days in Program"
                    centerText="Programs Completed"
                    percentage={customerInfo && customerInfo.daysInProgram}
                    lowColor="#67348E"
                    highColor="#EA197A"
                    progressId="daysProgressBar"
                    colorClass="day-progresBar"
                  /> */}
                </Col>
                <Col md={8}>
                  <ProgressChart
                    className="autismag-margin-bottom"
                    title="Engagement Score"
                    percentage={customerInfo && customerInfo.userEngagementScorePer}
                    lowColor="#67348E"
                    highColor="#EA197A"
                    progressId="engagementProgressBar"
                    colorClass="engament-progresBar"
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={8}>
                  <Card
                    className={
                      resourcesList && resourcesList.length >= 5
                        ? 'autismag-margin-bottom'
                        : 'autismag-extra-bottom'
                    }
                    type="inner"
                    title="Resources"
                    bordered={false}
                  >
                    <div className="sub-icon">
                      <img src={ThreeDotsIcon} className="sub-dots-black-icon" />
                      <img src={BlueDotsIcon} className="sub-dots-icon" />
                    </div>
                    <ListingDivContainer className={getClassName(resourcesList)}>
                      <List
                        dataSource={viewResource || []}
                        renderItem={(item: any) => (
                          <List.Item>
                            <IconTab
                              className="icon-download-tab"
                              onClick={() => downloadFile(item.file)}
                            >
                              <img src={DownLoadIcon} />
                            </IconTab>
                            <div className="resource-card-container">
                              <div className="icon-tab-text"> {item.name} </div>
                              <div className="icon-date-resouce-text">
                                {moment(item.createdAt).format('DD-MM-YYYY')}
                              </div>
                            </div>
                            {item.status === 'Active' ? (
                              <button type="button" className="activeResourceButton">
                                Active
                              </button>
                            ) : (
                              <button type="button" className="pendingResourceButton">
                                Pending
                              </button>
                            )}
                          </List.Item>
                        )}
                      />
                    </ListingDivContainer>
                    {resourcesList && resourcesList.length >= 5 ? (
                      <CardBottomLink className="card-bootom-link">
                        <span className="right-link" onClick={gotoMoreFiles}>
                          More...
                        </span>
                      </CardBottomLink>
                    ) : (
                      ''
                    )}
                  </Card>
                </Col>
                <Col md={8}>
                  <Card
                    className={
                      sessionData && sessionData.length >= 5
                        ? 'autismag-margin-bottom'
                        : 'autismag-extra-bottom'
                    }
                    type="inner"
                    title="My Sessions"
                    bordered={false}
                  >
                    <div className="sub-icon">
                      <img src={ThreeDotsIcon} className="sub-dots-black-icon" />
                      <img src={BlueDotsIcon} className="sub-dots-icon" />
                    </div>
                    <ListingDivContainer>
                      <List
                        dataSource={viewSessions || []}
                        renderItem={(item: any) => (
                          <List.Item
                            className={`row-pointer ${item.endtimeCancellation ? 'noShow' : ''}`}
                          >
                            <IconTab className="icon-download-tab">
                              <img
                                src={
                                  item && item.sessionRecorded === true ? LearningIcon : FormsIcon
                                }
                                className="icons sessionImageMargin"
                              />
                            </IconTab>
                            <div style={{ flex: 1, flexDirection: 'column' }}>
                              <div
                                className="session-tab-text"
                                style={{ height: '22%', width: '70%' }}
                                onClick={() => openSessionDrawer(item._id)}
                              >
                                {item.title
                                  ? item.title
                                  : item.endtimeCancellation
                                    ? 'No Show'
                                    : item.about}
                              </div>
                              <br />
                              <div className="session-date-text">
                                {`${moment(item.createdAt).format('DD-MM-YYYY')} ${moment(
                                  item.endTime
                                ).diff(item.startTime, 'h')} hour`}
                              </div>
                            </div>
                          </List.Item>
                        )}
                      />
                      <AddSessionNoteComponent
                        directlyCallComponent={sessionVisible}
                        onCloseDrawer={onCloseDrawer}
                        user={Role.Customer}
                        formTypeLabel="Session"
                        notifyVisible
                        viewIconDisable
                        chooseForm="View"
                        edit={false}
                        icon={
                          <span className="view-session-details">
                            <EyeOutlined key="eye" />
                          </span>
                        }
                        sessionId={sessionDrawerId}
                      />
                    </ListingDivContainer>
                    {sessionData && sessionData.length >= 5 ? (
                      <CardBottomLink className="card-bootom-link">
                        {' '}
                        <span className="right-link" onClick={gotoMoreSessions}>
                          More...
                        </span>{' '}
                      </CardBottomLink>
                    ) : null}
                  </Card>
                </Col>
                <Col md={8}>
                  <Card
                    className={
                      MilestoneCount && MilestoneCount.length >= 5
                        ? 'autismag-margin-bottom'
                        : 'autismag-extra-bottom'
                    }
                    type="inner"
                    title="Skills Achieved"
                    bordered={false}
                  >
                    {/* {customerInfo && !customerInfo.completedMilestones ? (
                      <CompletedTaskDiv>{"No Target"}</CompletedTaskDiv>
                    ) : null} */}
                    {/* <CompletedTaskDiv className="sub-count">
                      ({MileStoneCount})
                    </CompletedTaskDiv> */}
                    <div className="sub-icon">
                      <img src={ThreeDotsIcon} className="sub-dots-black-icon" />
                      <img src={BlueDotsIcon} className="sub-dots-icon" />
                    </div>
                    {/* <Progress
                      className="progress-milestone"
                      percent={
                        (viewMilestones &&
                          viewMilestones?.filter((x) => x.isCompleted === true)
                            ?.length * 100) / viewMilestones?.length
                      }
                      showInfo={false}
                      strokeColor={"#F5B04A"}
                      strokeWidth={4}
                    /> */}
                    <ListingDivContainer>
                      <List
                        dataSource={viewMilestones || []}
                        renderItem={(item: any) => (
                          <List.Item>
                            <IconTab className="icon-download-tab">
                              {' '}
                              <img
                                src={
                                  item.isCompleted
                                    ? require('../images/icons/milestone_achieved_gray.svg')
                                    : require('../images/icons/lock.svg')
                                }
                              />
                            </IconTab>
                            <div className="icon-tab-text">{item.name}</div>

                            {item.isCompleted === true ? (
                              <button type="button" className="youdiditButton">
                                You did it!
                              </button>
                            ) : (
                              <button type="button" className="activeResourceButton">
                                Level Up
                              </button>
                            )}
                          </List.Item>
                        )}
                      />
                    </ListingDivContainer>
                    {MilestoneCount && MilestoneCount.length >= 5 ? (
                      <CardBottomLink className="card-bootom-link">
                        {' '}
                        <span className="right-link" onClick={gotoMoreMileStone}>
                          More...
                        </span>{' '}
                      </CardBottomLink>
                    ) : null}
                  </Card>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col md={16}>
                  <Card
                    className={
                      totalTask && totalTask.length >= 5
                        ? 'autismag-margin-bottom my-tasks'
                        : 'autismag-extra-bottom my-tasks'
                    }
                    type="inner"
                    title="My Tasks"
                    bordered={false}
                  >
                    <CompletedTaskDiv className="sub-count">
                      {TaskCount ? <span>({TaskCount})</span> : ''}
                    </CompletedTaskDiv>
                    {/* <div className="sub-icon-task">
                      <img src={ThreeDotsIcon} />
                    </div> */}
                    <div className="sub-icon">
                      <img src={ThreeDotsIcon} className="sub-dots-black-task-icon" />
                      <img src={BlueDotsIcon} className="sub-dots-task-icon" />
                    </div>
                    <Progress
                      className="progress-my-task"
                      percent={
                        // (viewTask?.filter((x: { isCompleted: boolean; }) => x.isCompleted === true)
                        //   ?.length *
                        //   100) /
                        // viewTask?.length
                        eval(TaskCount) * 100
                      }
                      showInfo={false}
                      strokeColor="#F5B04A"
                      strokeWidth={4}
                    />

                    {milestoneData && (
                      <AddTasks
                        getGoalsData={getGoalsData}
                        onCloseDrawer={onCloseDrawer}
                        user={Role.Business}
                        goalFormType={ProgramRole.MyTasks}
                        chooseForm={viewMode}
                        formTypeLabel="Tasks"
                        propVisible={visibly}
                        notifyVisible
                        addButtonLabel="Add Task"
                        mileStoneData={milestoneData}
                        taskData={taskData}
                        taskId={taskId}
                      />
                    )}
                    <ListingDivContainer className={getTaskClassName(totalTask, TaskCount)}>
                      {totalTask && totalTask.length ? (
                        <List
                          dataSource={viewModifiedTask || []}
                          renderItem={(item: any, index) => (
                            <List.Item
                              key={item.id}
                              className={index === 0 ? 'completed-task' : 'pending-task'}
                              onChange={onChange}
                            >
                              <Checkbox
                                checked={item.isCompleted}
                                style={{
                                  pointerEvents:
                                    props.customerDataList === Role.Admin ? 'none' : 'auto'
                                }}
                                onChange={(e) => handleCheckBox(e, item)}
                                className={`${
                                  item.isCompleted === true
                                    ? index === 0
                                      ? 'is-completed-viewTask'
                                      : 'is-completed-viewTask new-completed-task'
                                    : 'not-completed-task'
                                }`}
                              >
                                {/* {item.name} */}
                              </Checkbox>

                              <span
                                className={
                                  customerDataList.type != 'Customer'
                                    ? `${
                                        item.isCompleted === true
                                          ? index === 0
                                            ? 'is-completed-viewTask isCompletedItemName'
                                            : 'is-completed-viewTask new-completed-task isCompletedNewTask'
                                          : 'not-completed-task not-completed-itemName'
                                      }`
                                    : `${
                                        item.isCompleted === true
                                          ? index === 0
                                            ? 'is-completed-viewTask isCompletedItemNameforCustomer'
                                            : 'is-completed-viewTask new-completed-task isCompletedNewTaskforCustomer'
                                          : 'not-completed-task not-completed-itemNameforCustomer'
                                      }`
                                }
                                onClick={() => {
                                  openAddTaskDrawer(item._id);
                                  setViewMode('View');
                                }}
                              >
                                {item.name}
                              </span>

                              {customerDataList.type === 'BusinessUser' && (
                                <div className="icon-date-fav-text">
                                  <div className="task-container">
                                    <div
                                      className="edit-task-button"
                                      onClick={() => {
                                        openAddTaskDrawer(item._id);
                                        setViewMode('Edit');
                                      }}
                                    >
                                      <img src={EditIcon} />
                                    </div>
                                    <div
                                      className="delete-task-button"
                                      // onClick={() => deleteTask(item._id)}
                                      onClick={() => showDeleteConfirm(item._id)}
                                    >
                                      <img src={DeleteIcon} />
                                    </div>
                                  </div>
                                  {moment(item.createdAt).format('DD-MM-YYYY')}
                                </div>
                              )}
                            </List.Item>
                          )}
                        />
                      ) : (
                        <Empty description="No Data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </ListingDivContainer>
                    {totalTask && totalTask.length >= 5 && (
                      <CardBottomLink className="card-bootom-link">
                        <span className="right-link" onClick={gotoMoreTasks}>
                          More...
                        </span>
                      </CardBottomLink>
                    )}
                  </Card>
                </Col>
                <Col md={8}>
                  <Card
                    className={
                      FavouriteCount && FavouriteCount.length >= 5
                        ? 'autismag-margin-bottom My-fav'
                        : 'autismag-extra-bottom My-fav'
                    }
                    type="inner"
                    title="My Favorites"
                    bordered={false}
                  >
                    {customerInfo && !customerInfo.favourites ? (
                      <CompletedTaskDiv>No Favorite</CompletedTaskDiv>
                    ) : null}
                    <div className="sub-icon">
                      <img src={ThreeDotsIcon} className="sub-dots-black-icon" />
                      <img src={BlueDotsIcon} className="sub-dots-icon" />
                    </div>
                    <ListingDivContainer
                      className={getClassName(customerInfo && customerInfo.favourites)}
                    >
                      <List
                        dataSource={viewFavourites || []}
                        renderItem={(item: any) => (
                          <List.Item>
                            <Link to={item.url} className="link-fav-text">
                              {item.name}
                            </Link>

                            <div className="icon-date-fav-text">
                              {moment(item.createdAt).format('DD-MM-YYYY')}
                            </div>
                          </List.Item>
                        )}
                      />
                    </ListingDivContainer>
                    {FavouriteCount && FavouriteCount.length >= 5 ? (
                      <CardBottomLink className="card-bootom-link">
                        {' '}
                        <span className="right-link" onClick={gotoMoreFavourites}>
                          More...
                        </span>{' '}
                      </CardBottomLink>
                    ) : null}
                  </Card>
                </Col>
              </Row>
            </Spin>
          </CustomerContainer>
        </div>
      </div>

      <Modal
        title={
          <div className="add-task-title-container">
            Add Task Note{' '}
            <div onClick={() => setTaskNotesModalOpen(false)} className="add-task-close-button">
              Close
            </div>
          </div>
        }
        visible={taskNotesModalOpen}
        onOk={handleTaskNotesOk}
        closable={false}
        onCancel={() => setTaskNotesModalOpen(false)}
        className="Add-task-modal-container"
        footer={[<button onClick={handleTaskNotesOk}>Ok</button>]}
      >
        <div className="no-boder task-notes-modal ant-form-item">
          <div className="ant-form-item-control">
            <div className="ant-form-item-control-input-content">
              <Input.TextArea
                rows={4}
                name="notes"
                value={taskNotes}
                onChange={(e) => setTaskNotes(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerInfo: state.customerRuducer.customerInfo,
    customerLoading: state.customerRuducer.customerLoading,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerAccessData: state.customerRuducer.customerAccessData,
    userLocalData: state.customerRuducer.userLocalData,
    sessionData: state.sessionReducer.sessionData,
    resourcesList: state.formReducer.resourcesList,
    customerDataList: state.notificationReducer.customerDataList,
    visibly: state.notificationReducer.visible,
    sessionVisible: state.notificationReducer.sessionVisible,
    currentLoginUser: state.userMemberData.currentLoginUser,
    memberGoals: state.programRuducer.memberGoals
  };
}

export default connect(mapStateToProps)(ParentOnboardingComponent);
