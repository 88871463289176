import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Image, Input, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';

import {
  addHomeCategory,
  deleteHomeCategory,
  getHomeCategoryById,
  updateHomeCategory
} from '../../../api/home';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { HomeReduxState } from '../../../redux/reducers/homeReducer';
import { RootState } from '../../../redux/store';
import { Mode } from '../../../utils/types/role';
import FileUploader from '../../common/FileUploader';
import { DeleteTabDiv, SubmitButton, UserLastRow } from '../../common/styles';
import { showNotificationDrawer } from '../../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

interface HomeCategoryFormProps {
  onClose: () => void;
  onSubmit: (pagination) => void;
}

const HomeCategoryForm: React.FC<HomeCategoryFormProps> = ({ onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { showFormData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);
  const { id: objectiveId, formMode } = showFormData;
  const { pagination } = useSelector<RootState, HomeReduxState>((state) => state.homeReducer);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    if (objectiveId) {
      (async () => {
        try {
          const res = await getHomeCategoryById(objectiveId);
          form.setFieldsValue(res.data);

          setIsLoading(false);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      form.resetFields();
      setTimeout(() => setIsLoading(false), 0);
    }
  }, [showFormData]);

  const onReset = () => {
    form.resetFields();

    onClose();
  };

  const handleChange = (name: string, value: any) => {
    form.setFields([{ name, value }]);
  };

  const onChangeIcon = (url: string) => {
    form.setFields([{ name: 'icon', value: url }]);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = form.getFieldsValue();

    if (formMode === Mode.Create) {
      addHomeCategory(formData)
        .then((res: any) => {
          const { data } = res;
          setIsLoading(false);
          showNotificationDrawer('Success', 'Home category added successfully!');

          onReset();
          onSubmit(pagination);
        })
        .catch((err) => {
          setIsLoading(false);
          showNotificationDrawer('Error', err?.response?.data?.message);

          onReset();
        });
    }

    if (formMode === Mode.Edit) {
      updateHomeCategory(objectiveId, formData)
        .then((res) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Home category updated successfully!');

          onReset();
          onSubmit(pagination);
        })
        .catch((err) => {
          setIsLoading(false);
          showNotificationDrawer('Error', err?.response?.data?.message);

          onReset();
        });
    }
  };

  const handleResourceObjectiveDelete = (id: string) => {
    setIsLoading(true);
    deleteHomeCategory(id)
      .then((res) => {
        setIsLoading(false);
        showNotificationDrawer('Success', 'Home category deleted successfully!');

        onReset();
        onSubmit(pagination);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotificationDrawer('Error', err?.response?.data?.message);

        onReset();
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this category?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleResourceObjectiveDelete(id);
      },
      onCancel() {}
    });
  };

  const formStyle: CSSProperties = {};
  if (formMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={handleSubmit}
      // initialValues={form.getFieldsValue()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please input your category name!', whitespace: true }
              ]}
            >
              <Input
                name="name"
                onChange={(e) => handleChange('name', e.target.value)}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Icon"
              name="icon"
              rules={[
                {
                  pattern: new RegExp('^https?://.+'),
                  message: 'Icon must be a valid URL with HTTP or HTTPS.'
                }
              ]}
            >
              {formMode === Mode.View ? (
                // Render a disabled state or placeholder if read-only
                <Image
                  width="104px"
                  height="104px"
                  src={form.getFieldValue('icon')}
                  preview={false}
                  style={{ objectFit: 'cover' }}
                />
              ) : (
                // Render the FileUploader component when not read-only
                <FileUploader
                  mode={formMode}
                  onChange={onChangeIcon}
                  avatarImage={form.getFieldValue('icon')}
                  onlyImageAccept
                  limit={1024 * 1024 * 2} // limit file size to 2 MB
                  resize
                />
              )}
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <div className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {formMode === Mode.Edit ? (
            <div className="flex gap-x-4 items-center">
              <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
                Save
              </SubmitButton>
              <DeleteTabDiv className="ml-5 mt-2" onClick={() => showDeleteConfirm(objectiveId)}>
                <DeleteOutlined />
              </DeleteTabDiv>
            </div>
          ) : formMode === Mode.Create ? (
            <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
              Submit
            </SubmitButton>
          ) : null}
        </Form.Item>
      </div>
    </Form>
  );
};

export default HomeCategoryForm;
