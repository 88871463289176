import { CalendarOutlined } from '@ant-design/icons';
import { Space, Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { getSessionApi, patchSessionReadApi } from '../../api/session';
import { paginationConfig } from '../../constant';
import DeleteIcon from '../../images/DeleteBlackIcon.svg';
import EmptyTableData from '../../images/empty-table-data.svg';
import ExpandDownArrow from '../../images/icons/down arrrow.svg';
import EditIcon from '../../images/icons/EditIcon.svg';
import FormsIcon from '../../images/icons/forms.svg';
import LearningIcon from '../../images/icons/learning.svg';
import ExpandUpArrow from '../../images/icons/UpArrow.svg';
import { setVisiblePopup } from '../../redux/actions/customerAction';
import { setRefreshSessionData, setSessionData } from '../../redux/actions/sessionAction';
import { Role } from '../../utils/types/role';
import AddSessionNoteComponent from '../AddUser/AddSessionNoteComponent';
import { ColDiv, EditViewIcon, ProgramAddButton } from '../common/styles';
import {
  extractTextTransform,
  formattedDate,
  formattedDateWithDuration,
  isSameDate
} from '../common/utils';
import ViewEditPopOver from '../ViewEditPopOverComponent';
import MobileViewAccordian from './MobileViewAccordianComponent';

const MySessionTableComponent: React.FC<any> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const {
    sessionData,
    loginUserType,
    visiblePopup,
    dispatch,
    customerDataList,
    customerSelectedMember,
    refreshSessionNotes,
    isMobile,
    userLocalData,
    customerAccessData,
    todaySessionContainerEl,
    currentLoginUser
  } = props;
  const [onRow, setRow] = useState(false);
  const [onRecord, setRecord] = useState<any>({});
  const [pagination, setPagination] = useState<TablePaginationConfig>(paginationConfig);
  const [sorter, setSorter] = useState<SorterResult<any>>({});
  const [isTodaySessionHighlight, setIsTodaySessionHighlight] = useState(false);

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setRow(false);
    setKey(keyToUpdate);
    dispatch(setVisiblePopup(false));
  }, [chooseKey]);

  const handleChange = (pagination, filters, sorter, extra) => {
    setPagination(pagination);
    setSorter(sorter);

    // disable highlight when filters or sorter changes
    if (extra.action !== 'paginate') {
      setIsTodaySessionHighlight(false);
    }

    // console.log("Various parameters", pagination, filters, sorter, extra );
  };

  const handleDuration = (startTime: any, endTime: any) => {
    // var startingTime = moment(startTime, "HH:mm:ss a");
    // var endingTime = moment(endTime, "HH:mm:ss a");

    // calculate total duration
    if (startTime === null || endTime === null) {
      return 0;
    }
    const duration: moment.Duration = moment.duration(moment(endTime).diff(moment(startTime)));
    // @ts-ignore: Unreachable code error
    const hours = parseInt(duration.asHours());
    /// @ts-ignore: Unreachable code error
    const minutes = parseInt(duration.asMinutes()) % 60;
    if (hours === 0 && minutes === 0) {
      return 0;
    }
    if (hours === 0) {
      return `${minutes} minutes`;
    }
    if (minutes === 0) {
      return `${hours} hour`;
    }
    return `${hours} hour ${minutes} minutes`;
  };

  const SessionReadFun = (id) => {
    if (!id.startsWith('draft')) {
      if (id) {
        patchSessionReadApi(id).then((res) => {
          if (customerSelectedMember && customerSelectedMember.length) {
            getSessionApi(customerSelectedMember[0]._id).then((res) => {
              dispatch(setSessionData(res.data));
            });
          }
        });
      }
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <div className={record?.isDraft ? 'draft-text-style' : ''}>
          {record && record.sessionRecorded === true ? (
            <img src={LearningIcon} className="icons sessionImageMargin" />
          ) : (
            <img src={FormsIcon} className="icons sessionImageMargin" />
          )}{' '}
          {record.isDraft ? 'draft' : ''}
        </div>
      )
    },
    {
      title: 'Session Date',
      dataIndex: 'startTime',
      key: 'startTime',
      sorter: (a, b) => a.startTime && b.startTime && a.startTime.localeCompare(b.startTime),
      sortOrder: sorter.columnKey === 'startTime' ? sorter.order : null,
      render: (text, record) => (
        <ColDiv
          className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
            record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
          }`}
        >
          {formattedDate(record.startTime)}
        </ColDiv>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) =>
        a.title === null ? -1 : b.title === null ? 1 : a.title.toString().localeCompare(b.title),
      sortOrder: sorter.columnKey === 'title' ? sorter.order : null,
      render: (text, record) =>
        record.title || record.endtimeCancellation ? (
          <ColDiv
            className={`${
              record && record.read === true
                ? 'mySessionCol special-truncate-style-container'
                : 'sessionReadActive special-truncate-style-container'
            } ${record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'}`}
          >
            <span className="text-content">
              {record.endtimeCancellation ? 'Late Cancellation' : record.title}
            </span>
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Notes',
      dataIndex: 'about',
      key: 'about',
      sorter: (a, b) =>
        a.about === null ? -1 : b.about === null ? 1 : a.about.toString().localeCompare(b.about),
      sortOrder: sorter.columnKey === 'about' ? sorter.order : null,
      render: (text, record) => {
        const aboutVal = ReactHtmlParser(record.about, { transform: extractTextTransform });
        return record.about || record.endtimeCancellation ? (
          <ColDiv
            className={`${
              record && record.read === true
                ? 'mySessionCol special-truncate-style-container'
                : 'sessionReadActive special-truncate-style-container'
            } ${record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'}`}
          >
            <span className="text-content">
              {record.endtimeCancellation ? 'Late Cancellation' : aboutVal}
            </span>
          </ColDiv>
        ) : (
          '--'
        );
      }
    },
    {
      title: 'Duration',
      dataIndex: '',
      key: '',
      render: (text, record) =>
        handleDuration(record.startTime, record.endTime) !== 0 ? (
          <ColDiv
            className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
              record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
            }`}
          >
            {handleDuration(record.startTime, record.endTime)}
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) =>
        a.createdBy.firstName &&
        b.createdBy.firstName &&
        a.createdBy.firstName.localeCompare(b.createdBy.firstName),
      sortOrder: sorter.columnKey === 'createdBy' ? sorter.order : null,
      render: (text, record) =>
        record.createdBy?.firstName ? (
          <ColDiv
            className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
              record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
            }`}
          >
            <span className="text-content">
              {record.createdBy && `${record.createdBy?.firstName} ${record.createdBy?.lastName}`}
            </span>
          </ColDiv>
        ) : (
          '--'
        )
    },
    ...(loginUserType === Role.Admin
      ? [
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
            render: (value) => formattedDateWithDuration(value)
          }
        ]
      : [])
  ];

  const businessSessionColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <div className={record?.isDraft ? 'draft-text-style' : ''}>
          {record && record.sessionRecorded === true ? (
            <img src={LearningIcon} className="icons sessionImageMargin" />
          ) : (
            <img src={FormsIcon} className="icons sessionImageMargin" />
          )}{' '}
          {record.isDraft ? 'draft' : ''}
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.startTime && b.startTime && a.startTime.localeCompare(b.startTime),
      sortOrder: sorter.columnKey === 'createdAt' ? sorter.order : null,
      render: (text, record) => (
        <ColDiv
          className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
            record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
          }`}
        >
          {formattedDate(record.startTime)}
        </ColDiv>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) =>
        a.title === null ? -1 : b.title === null ? 1 : a.title.toString().localeCompare(b.title),
      sortOrder: sorter.columnKey === 'title' ? sorter.order : null,
      render: (text, record) =>
        record.title || record.endtimeCancellation ? (
          <ColDiv
            className={`${
              record && record.read === true
                ? 'mySessionCol special-truncate-style-container'
                : 'sessionReadActive special-truncate-style-container'
            } ${record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'}`}
          >
            <span className="text-content">
              {record.endtimeCancellation ? 'Late Cancellation' : record.title}
            </span>
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Notes',
      dataIndex: 'about',
      key: 'about',
      sorter: (a, b) => a.about && b.about && a.about.localeCompare(b.about),
      sortOrder: sorter.columnKey === 'about' ? sorter.order : null,
      render: (text, record) => {
        const aboutVal = ReactHtmlParser(record.about, { transform: extractTextTransform });
        return record.about || record.endtimeCancellation ? (
          <ColDiv
            className={`${
              record && record.read === true
                ? 'mySessionCol special-truncate-style-container'
                : 'sessionReadActive special-truncate-style-container'
            } ${aboutVal && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'}`}
          >
            <span className="text-content">
              {record.endtimeCancellation ? 'Late Cancellation' : aboutVal}
            </span>
          </ColDiv>
        ) : (
          '--'
        );
      }
    },
    {
      title: 'Duration',
      dataIndex: '',
      key: '',
      render: (text, record) =>
        handleDuration(record.startTime, record.endTime) !== 0 ? (
          <ColDiv
            className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
              record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
            }`}
          >
            {handleDuration(record.startTime, record.endTime)}
          </ColDiv>
        ) : (
          //  : loginUserType === Role.Business ? (
          //   <AddSessionNoteComponent
          //     onCloseDrawer={onCloseDrawer}
          //     user={Role.Customer}
          //     formTypeLabel={"Session"}
          //     chooseForm="Edit"
          //     edit={false}
          //     icon={<CheckTaskButton>Reschedule</CheckTaskButton>}
          //     sessionId={record._id}
          //   />
          // )
          '--'
        )
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => a.title && b.title && a.title.localeCompare(b.title),
      sortOrder: sorter.columnKey === 'createdBy' ? sorter.order : null,
      render: (text, record) =>
        record.createdBy?.firstName ? (
          <ColDiv
            className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
              record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
            }`}
          >
            <span className="text-content">
              {record.createdBy && `${record.createdBy?.firstName} ${record.createdBy?.lastName}`}
            </span>
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action" style={{ display: 'flex' }}>
            {loginUserType === Role.Business && (
              <>
                <AddSessionNoteComponent
                  onCloseDrawer={onCloseDrawer}
                  user={Role.Customer}
                  formTypeLabel="Session"
                  chooseForm="Edit"
                  edit={false}
                  icon={<img src={EditIcon} />}
                  sessionId={record?.isDraft ? `draft-${record.id}` : record._id}
                  notifyVisible
                />
                {record?.isDraft ? (
                  <div
                    className="remove-drafts"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteElement(record.id);
                    }}
                  >
                    <img src={DeleteIcon} style={{ width: '16px' }} />
                  </div>
                ) : null}
              </>
            )}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  const Mobilecolumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <div className={record?.isDraft ? 'draft-text-style' : ''}>
          {record && record.sessionRecorded === true ? (
            <img src={LearningIcon} className="icons sessionImageMargin" />
          ) : (
            <img src={FormsIcon} className="icons sessionImageMargin" />
          )}{' '}
          {record.isDraft ? 'draft' : ''}
        </div>
      )
    },

    {
      render: (text, record) => (
        <ColDiv
          className={`${record && record.read === true ? 'mySessionCol' : 'sessionReadActive'} ${
            record && record.endtimeCancellation ? 'sessionNoShow' : 'sessionReadActive'
          }`}
        >
          {formattedDate(record.startTime)}
        </ColDiv>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className={loginUserType === Role.Business ? 'autismag-table-action' : ''}>
            <AddSessionNoteComponent
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              formTypeLabel="Session"
              chooseForm={loginUserType === Role.Business ? 'Edit' : 'View'}
              edit={false}
              icon={<img src={EditIcon} />}
              sessionId={record?.isDraft ? `draft-${record.id}` : record._id}
              notifyVisible
            />
            {loginUserType === Role.Business && (
              <>
                {record?.isDraft ? (
                  <div
                    className="remove-drafts"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteElement(record.id);
                    }}
                  >
                    <img src={DeleteIcon} style={{ width: '16px' }} />
                  </div>
                ) : null}
              </>
            )}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  const getSessionDrafts = () => {
    let drafts = localStorage.getItem('sessionData') as any;
    try {
      drafts = JSON.parse(drafts);
      drafts = drafts.filter(
        (x) =>
          x.loginBy === currentLoginUser?._id &&
          x?.memberId === customerSelectedMember[0]?._id &&
          customerAccessData?.role === 'BusinessAccesCustomer'
      );
      return drafts?.length ? drafts : [];
    } catch (err) {
      return [];
    }
  };

  let modifyData =
    (sessionData as Array<any>)
      .filter((itemData) => itemData.isDeleted !== true && itemData)
      .map((x, i) => ({ ...x, key: i })) || [];

  modifyData = [...(getSessionDrafts() as []), ...modifyData];
  const customExpandIconMobileVIew = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandUpArrow} style={{ width: '15px' }} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandDownArrow} style={{ width: '15px' }} />
      </a>
    );
  };

  const deleteElement = (id) => {
    let drafts = localStorage.getItem('sessionData') as any;
    drafts = JSON.parse(drafts);
    drafts = drafts.filter((itemData) => itemData.id !== id);
    localStorage.setItem('sessionData', JSON.stringify(drafts));
    dispatch(setRefreshSessionData(!refreshSessionNotes));
  };

  const SelectedRows = (record) => {
    setRow(true);
    setRecord(record);
  };

  const onTodaySession = () => {
    const nextDay = new Date();
    nextDay.setHours(24, 0, 0, 0);

    let todaySessionFirstIndex = modifyData.findIndex((d) => new Date(d.startTime) < nextDay);
    if (todaySessionFirstIndex < 0) {
      // if not found, and go to the last page
      todaySessionFirstIndex = modifyData.length - 1;
    }
    const pageSize = pagination.pageSize || 10;
    const pageNumber = Math.floor(todaySessionFirstIndex / pageSize) + 1;
    console.log(todaySessionFirstIndex);
    setPagination((pagination) => ({ ...pagination, current: pageNumber }));
    setSorter({});
    setIsTodaySessionHighlight(true);
  };
  const rowClassNameFn = (record) =>
    `row-pointer ${record.endtimeCancellation ? 'noShow' : ''} ${
      isTodaySessionHighlight && isSameDate(record.startTime, new Date()) ? 'highlight' : ''
    }`;
  return (
    <>
      {todaySessionContainerEl &&
        ReactDOM.createPortal(
          <ProgramAddButton onClick={onTodaySession} icon={<CalendarOutlined />} type="primary">
            View Todays Sessions
          </ProgramAddButton>,
          todaySessionContainerEl
        )}

      {isMobile ? (
        <Table
          className={`${props.className} sessionTableIconBorderBottom mobileViewIconStyling`}
          columns={Mobilecolumns}
          dataSource={modifyData}
          showHeader={false}
          rowClassName={rowClassNameFn}
          locale={{
            emptyText: (
              <div className="empty-data-table-container-show">
                <div className="empty-data-table-container-image">
                  <img src={EmptyTableData} width="250" />
                </div>
                <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
              </div>
            )
          }}
          pagination={pagination}
          expandIcon={(props) => customExpandIconMobileVIew(props)}
          expandIconColumnIndex={3}
          expandedRowRender={(record) => (
            <div>
              <MobileViewAccordian
                panelColumns={columns}
                dataSource={record}
                type="mySessionTable"
                dispatch={dispatch}
                handleDuration={handleDuration}
                loginUserType={loginUserType}
                SessionReadFun={SessionReadFun}
              />
            </div>
          )}
        />
      ) : (
        <Table
          className={`${props.className} sessionTableIconBorderBottom`}
          columns={loginUserType === Role.Business ? businessSessionColumns : columns}
          dataSource={modifyData}
          onChange={handleChange}
          rowClassName={rowClassNameFn}
          locale={{
            emptyText: (
              <div className="empty-data-table-container-show">
                <div className="empty-data-table-container-image">
                  <img src={EmptyTableData} width="250" />
                </div>
                <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
              </div>
            )
          }}
          onRow={(record) => ({
            onClick: (e) => {
              // e.persist()
              if ((e.target as any).className === 'ant-drawer-mask') {
                return;
              }
              SelectedRows(record);
            }
          })}
          pagination={pagination}
        />
      )}
      {onRow ? (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <ViewEditPopOver
              onCloseDrawer={onCloseDrawer}
              sessionId={!onRecord.isDraft ? onRecord._id : `draft-${onRecord.id}`}
              visiblePopup={visiblePopup}
              viewDrawer={onRow}
              SessionReadFun={SessionReadFun}
              dispatch={dispatch}
              sessionRead={onRecord.read}
              loginUserType={loginUserType}
            />
          </EditViewIcon>
        </Space>
      ) : null}
    </>
  );
};
function mapStateToProps(state: any) {
  return {
    state,
    visiblePopup: state.customerRuducer.visiblePopup,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    isMobile: state.trackReducer.isMobile,
    refreshSessionNotes: state.sessionReducer.refreshSessionNotes,
    userLocalData: state.customerRuducer.userLocalData,
    customerAccessData: state.customerRuducer.customerAccessData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(MySessionTableComponent);
