import { Col, Form, Input, Radio, Row, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { createMemberApi, updateMemberApi } from '../api/member';
import {
  createUserApi,
  getPublicUserApi,
  getUserApi,
  listCSMApi,
  updateUsersApi
} from '../api/user';
import {
  BasicForm,
  Country,
  CountryCodeProps,
  RoleTypeProps,
  TimeZoneProps,
  userMemberDataType
} from '../constant';
import CrossFormButton from '../images/icons/crossFormButton.svg';
import { getCustomerApi } from '../redux/actions/notificationAction';
import { setCurrentLoginUser, setMemberList } from '../redux/actions/userMemberAction';
import { Role } from '../utils/types/role';
import BusinessForm from './BusinessForm';
import FileUploader from './common/FileUploader';
import { DrawerFooter, PhoneInput, SubmitButton, UserLastRow } from './common/styles';
import MemberList from './MemberList/MemberList';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;

interface AdminFormProps {
  countryList: Country[];
  timeZone: TimeZoneProps[];
  roleType: RoleTypeProps[];
  userStatus: RoleTypeProps[];
  userType: RoleTypeProps[];
  choosePlan: RoleTypeProps[];
  listingType: RoleTypeProps[];
  countryCode: CountryCodeProps[];
  userRole: Role;
  close: any;
  cancel: any;
  userId?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  userMemberData: userMemberDataType;
  isMobile?: any;
  customerDataList?: any;
  adminUserType?: any;
  currentLoginUser: any;
}

const AdminForm: React.FC<AdminFormProps> = (props) => {
  const [activeForm, setActiveForm] = useState<BasicForm>({
    type: props.userRole,
    avatar: ''
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { dispatch, editMode, isMobile, currentLoginUser } = props;

  const disableBusinessField = !!(editMode && currentLoginUser.type === Role.Business);
  const disableCustomerField = !!(editMode && currentLoginUser.type === Role.Customer);

  const { data: customerManagerList } = useQuery('csmList', listCSMApi, {
    initialData: [],
    enabled: props.userRole === Role.Customer && !disableCustomerField
  });
  const customerManagerQuery = useQuery(
    ['customerManager', currentLoginUser.csm],
    () => getPublicUserApi(currentLoginUser.csm, 'BusinessUser'),
    {
      enabled: Boolean(currentLoginUser.csm)
    }
  );

  const userTypeLabel = (role: string) => {
    switch (role) {
      case Role.Admin:
        return 'Admin';
      case Role.Business:
        return 'Business';
      case Role.Customer:
        return 'Customer';
    }
  };

  const onChangeImage = (url) => {
    setActiveForm({
      ...activeForm,
      avatar: url
    });
  };

  const onSubmit = () => {
    setLoading(true);
    if (props.userId) {
      updateUsersApi(props.userId, {
        ...activeForm,
        type: props.userRole
      })
        .then((res) => {
          if (currentLoginUser._id === props.userId) {
            dispatch(setCurrentLoginUser(res.data));
            dispatch(getCustomerApi(res.data));
          }

          setLoading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          if (!isLoading) {
            props.close(
              'Success',
              `${userTypeLabel(props.userRole)} user information updated successfully!`
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    } else {
      createUserApi({
        ...activeForm,
        type: props.userRole,
        flag: 'main'
      })
        .then((res) => {
          setLoading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          if (!isLoading) {
            props.close('Success', `${userTypeLabel(props.userRole)} user created successfully!`);
          }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          if (!isLoading) {
            props.close('Error', err?.response?.data?.message);
          }
        });
    }
  };

  const onSubmitCustomer = () => {
    const { memberList = [] } = props.userMemberData;
    const validationPromises = memberList.map((member) => member?.formRef?.validateFields());

    Promise.all(validationPromises)
      .then(() => {
        setLoading(true);
        const updateMembers = memberList.map((member) => ({
          ...member?.formRef?.getFieldsValue(),
          parent: props.userId,
          _id: member._id
        }));
        if (props.userId) {
          if (activeForm.uid) {
            delete activeForm.uid;
          }
          updateUsersApi(props.userId, {
            ...activeForm,
            type: props.userRole,
            members: updateMembers
          })
            .then((res) => {
              const updateMembersData = memberList.map((member) => ({
                ...member?.formRef?.getFieldsValue(),
                parent: props.userId,
                _id: member._id
              }));

              if (currentLoginUser.type !== Role.Customer && updateMembersData.length) {
                const updateMemberPromises = updateMembersData.map((member) =>
                  updateMemberApi(member._id, member)
                );
                Promise.all(updateMemberPromises)
                  .then((res) => {
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                  });
              }

              if (currentLoginUser._id === props.userId) {
                dispatch(setCurrentLoginUser(res.data));
                dispatch(getCustomerApi(res.data));
              }
              setLoading(false);
              setActiveForm({});
              form?.resetFields();

              memberList.forEach((member) => member?.formRef?.resetFields());

              props.cancel();
              if (!isLoading) {
                props.close(
                  'Success',
                  `${userTypeLabel(props.userRole)} user information updated successfully!`
                );
              }
            })
            .catch((err) => {
              setLoading(false);
              props.cancel();
              if (!isLoading) {
                props.close('Error', err?.response?.data?.message);
              }
            })
            .finally(() => {
              dispatch(setMemberList([]));
            });

          return;
        }
        createUserApi({
          ...activeForm,
          type: props.userRole,
          flag: 'main'
        })
          .then((res) => {
            const { data } = res;
            let membersToAdd;
            const memberitem = memberList.map((member) => ({
              ...member?.formRef?.getFieldsValue(),
              parent: data._id
            }));
            memberitem[0].listingType === ''
              ? (membersToAdd = memberitem.map(({ listingType, ...member }) => member))
              : (membersToAdd = memberitem.map((member) => member));

            const memberPromises = membersToAdd.map((member) => createMemberApi(member));
            Promise.all(memberPromises)
              .then((res) => {
                props.close(
                  'Success',
                  `${userTypeLabel(props.userRole)} user created successfully!`
                );
              })
              .catch((err) => {
                props.close('Error', err.response.data.message);
              })
              .finally(() => {
                setLoading(false);
                setActiveForm({});
                dispatch(setMemberList([]));
                form?.resetFields();
                props.cancel();
              });
          })
          .catch((err) => {
            setLoading(false);
            props.close('Error', err.response.data.message);
          });
      })
      .catch(() => {});
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    const initialMemberList: any = {
      parent: null,
      firstName: '',
      lastName: '',
      dob: '',
      memberShipStart: moment(),
      memberShipEnd: '',
      listingType: '',
      listingIdentifier: '',
      formRef: null
    };

    if (!props.userId && props.userRole === Role.Customer) {
      dispatch(
        setMemberList([
          {
            ...initialMemberList
          }
        ])
      );
    }

    if (props.userId && props.visible) {
      setLoading(true);
      getUserApi(props.userId)
        .then((res) => {
          let data = res;
          if (props.userRole === Role.Customer) {
            const { members, ...restData } = data;
            const membersData = members.map((member) => ({
              ...member,
              dob: moment(member.dob).isValid() ? moment(member.dob) : null,
              memberShipStart: moment(member.memberShipStart).isValid()
                ? moment(member.memberShipStart)
                : null,
              memberShipEnd: moment(member.memberShipEnd).isValid()
                ? moment(member.memberShipEnd)
                : null
            }));

            dispatch(setMemberList(membersData));
            data = restData;
          }
          form.setFieldsValue(data);
          setActiveForm(data);
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
        })
        .finally(() => setLoading(false));
    }
  }, [props.userId, form, props.visible, props.userRole, dispatch]);

  const handleClickOutside = (e) => {
    e.stopPropagation();
  };

  const onReset = (e) => {
    setActiveForm({});
    form.resetFields();
    setLoading(false);
    props.cancel();
    dispatch(setMemberList([]));
  };

  const onChangeNumber = (e) => {
    const { name, value } = e.target;
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setActiveForm({ ...activeForm, [name]: value });
    }
  };

  const handleChange = (name: string, value: string) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const addMemberData = (list: any) => {
    dispatch(setMemberList(list));
  };

  const checkInteger = (data: any) => {
    if (data === parseInt(data, 10)) {
      return true;
    }
    return false;
  };

  const numberError = (value, defaultError) => {
    if (!checkInteger(value)) {
      return 'Please input only Number!';
    }
    return defaultError;
  };
  const choosePlanColorSwitch = (ChoosePlan) => {
    switch (ChoosePlan) {
      case 'Flagship':
        return 'Flagship-color';
      case 'Subscriber':
        return 'subscriber-color';
      case 'Choice':
        return 'choice-color';
      case 'Care':
        return 'care-color';
      case 'Others':
        return 'other-color';
      case 'Lite':
        return 'lite-color';
    }
  };
  const onresourceAdminChange = (e: any) => {
    console.log(e.target.value, 'blue');
    setActiveForm({ ...activeForm, resourceAdmin: e.target.value });
  };
  const getUserSpecificFields = (userRoleType: Role) => {
    const { memberList = [] } = props.userMemberData;
    switch (userRoleType) {
      case Role.Admin:
        return (
          <Form.Item
            label="Resource Admin"
            name="resourceAdmin"
            className="no-boder"
            rules={[{ required: true, message: 'Please select Resource Admin' }]}
          >
            <Radio.Group onChange={onresourceAdminChange} value={activeForm?.resourceAdmin}>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        );
      case Role.Business:
        return (
          <BusinessForm
            userType={props.userType}
            handleChange={(value) => handleChange('businessUserType', value)}
            disabled={disableBusinessField}
          />
        );
      case Role.Customer:
        return (
          <div
            className={
              props.viewMode === 'View'
                ? 'viewMemberContainer edit-user-profile-member-outer-container'
                : 'edit-user-profile-member-outer-container'
            }
          >
            <div className={props.viewMode === 'View' ? 'viewModeMemberScroll' : ''}>
              <Form.Item
                label="Choose Plan"
                name="choosePlan"
                className="no-boder"
                rules={[{ required: true, message: 'Please select your Plan!' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  defaultValue=""
                  onChange={(value) => handleChange('choosePlan', value)}
                  className={choosePlanColorSwitch(activeForm.choosePlan)}
                  disabled={disableCustomerField}
                >
                  {props.choosePlan.map((data: RoleTypeProps, index: number) => (
                    <Option
                      key={index}
                      value={data.label}
                      className={choosePlanColorSwitch(data.label)}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {memberList ? (
                <MemberList
                  memberList={memberList}
                  onChange={addMemberData}
                  hideAdd={props.viewMode !== 'Create'}
                  disabled={
                    props.viewMode === 'Create'
                      ? props.viewMode !== 'Create'
                      : currentLoginUser.type !== Role.Admin
                  }
                  noRemoveTab={memberList.length === 1}
                  viewMode={props.viewMode === 'View'}
                />
              ) : null}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const formStyle: CSSProperties = {};
  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style edit-user-profile-form-container"
      name="basic"
      onFinish={activeForm.type === Role.Customer ? onSubmitCustomer : onSubmit}
      initialValues={activeForm}
      onClick={(e) => e.stopPropagation()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Row>
            <Col span={8}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' },
                  { required: true, message: 'Please input your First Name!' }
                ]}
              >
                <Input
                  name="firstName"
                  value={activeForm.firstName}
                  onChange={(e) => onHandleChange(e, 'firstName')}
                  disabled={disableCustomerField}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' },
                  {
                    required: props.userRole != 'Customer',
                    message: 'Please input your Last Name!'
                  }
                ]}
              >
                <Input
                  name="lastName"
                  value={activeForm.lastName}
                  title="Name must contain alphabets"
                  onChange={(e) => onHandleChange(e, 'lastName')}
                  disabled={disableCustomerField}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  { required: true, message: 'Please input your Email!', whitespace: true }
                ]}
              >
                <Input
                  name="email"
                  onChange={(e) => onHandleChange(e, 'email')}
                  disabled={disableBusinessField || disableCustomerField}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Address Line 1"
                name="address"
                className="no-boder"
                rules={[
                  {
                    required: props.userRole != 'Customer',
                    message: 'Please input your Address!',
                    whitespace: true
                  }
                ]}
              >
                <Input.TextArea name="address" onChange={(e) => onHandleChange(e, 'address')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Address Line 2" name="otherAddress" className="no-boder">
                <Input.TextArea
                  name="otherAddress"
                  onChange={(e) => onHandleChange(e, 'otherAddress')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  { pattern: /^(?!\s)[a-zA-Z\s]*$/, message: 'Please input valid city name' },
                  { required: props.userRole != 'Customer', message: 'Please input city name' }
                ]}
              >
                <Input
                  name="city"
                  onChange={(e) => onHandleChange(e, 'city')}
                  disabled={disableCustomerField}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  { pattern: /^(?!\s)[a-zA-Z\s]*$/, message: 'Please input valid state name' },
                  { required: props.userRole != 'Customer', message: 'Please input state name' }
                ]}
              >
                <Input
                  name="state"
                  onChange={(e) => onHandleChange(e, 'state')}
                  disabled={disableCustomerField}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Post Code"
                name="postCode"
                rules={[
                  { pattern: /^[0-9]*$/, message: 'Please input valid post code' },
                  { required: props.userRole != 'Customer', message: 'Please input post code' }
                ]}
              >
                <Input
                  type="text"
                  maxLength={6}
                  name="postCode"
                  onChange={(e) => onHandleChange(e, 'postCode')}
                  disabled={disableCustomerField}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="Country Code/Phone No"
                name="phoneCountryCode"
                className="no-boder"
                rules={[{ required: props.userRole != 'Customer', message: '' }]}
              >
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="phoneCountryCode"
                    rules={[
                      {
                        required: props.userRole != 'Customer',
                        message: 'Please Select Country Code'
                      }
                    ]}
                  >
                    <Select
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      showSearch
                      defaultValue=""
                      style={{ width: '23%' }}
                      value={activeForm.phoneCountryCode}
                      onChange={(value) => handleChange('phoneCountryCode', value)}
                    >
                      {props.countryCode.map((data: CountryCodeProps, index: number) => (
                        <Option key={index} value={data.dial_code}>
                          {data.dial_code}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="phoneNumber"
                    rules={[
                      {
                        required: props.userRole != 'Customer',
                        message: 'Please input your Phone Number'
                      },
                      {
                        pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        message: 'Please input valid Phone Number'
                      }
                    ]}
                  >
                    <PhoneInput
                      className="edit-user-phone-number-field"
                      defaultValue=""
                      type="text"
                      name="phoneNumber"
                      value={activeForm.phoneNumber}
                      onChange={onChangeNumber}
                      maxLength={10}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Country"
                name="country"
                className="no-boder"
                rules={[
                  { required: props.userRole != 'Customer', message: 'Please select your Country!' }
                ]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  defaultValue=""
                  onChange={(value) => handleChange('country', value)}
                  disabled={disableCustomerField}
                >
                  {props.countryList.map((data: Country, index: number) => (
                    <Option key={index} value={data.name}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Time Zone"
                name="timeZone"
                rules={[
                  {
                    required: props.userRole != 'Customer',
                    message: 'Please select your Time Zone!'
                  }
                ]}
                className="no-boder time-zone-container"
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  defaultValue=""
                  onChange={(value) => handleChange('timeZone', value)}
                  disabled={disableCustomerField}
                >
                  {props.timeZone.map((data: TimeZoneProps, index: number) => (
                    <Option key={index} value={data.text}>
                      {data.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ position: 'relative' }}>
            {currentLoginUser.type === 'Administrator' && (
              <Col span={16} className="edit-profile-user-status-field">
                <Form.Item
                  label="User Status"
                  name="userStatus"
                  className="no-boder"
                  rules={[{ required: true, message: 'Please select your User Status!' }]}
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue=""
                    onChange={(value) => handleChange('userStatus', value)}
                  >
                    {props.userStatus.map((data: RoleTypeProps, index: number) => (
                      <Option key={index} value={data.label}>
                        {data.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {props.userRole === Role.Customer && (
              <Col span={8} className="edit-profile-csm-field">
                <Form.Item label="Customer Success Manager" name="csm" className="no-boder">
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultValue=""
                    onChange={(value) => handleChange('csm', value)}
                    disabled={disableBusinessField || disableCustomerField}
                  >
                    {customerManagerList.map((data: any, index: number) => (
                      <Option key={index} value={data._id}>
                        {data?.firstName} {data?.lastName}
                      </Option>
                    ))}
                    {/** Show the name of csm when you can't fetch the whole customer manager list */}
                    {!customerManagerList?.length && customerManagerQuery.data && (
                      <Option value={currentLoginUser.csm}>
                        {customerManagerQuery.data?.firstName} {customerManagerQuery.data?.lastName}
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="edit-user-avatar-form-customer" span={16}>
              {props.customerDataList.type != 'Customer' && getUserSpecificFields(props.userRole)}
            </Col>
            <Col className="edit-user-avatar-form-customer" span={8}>
              <Form.Item
                label="User Avatar (max size 2mb)"
                name="avatar"
                className={
                  isMobile && props.userRole === 'BusinessUser'
                    ? 'avatar-business-user-mobile-view'
                    : 'no-boder'
                }
              >
                <FileUploader
                  onlyImageAccept
                  mode={props.viewMode}
                  onChange={onChangeImage}
                  avatarImage={activeForm.avatar}
                  limit={2097152}
                  type="user-form"
                />
              </Form.Item>
            </Col>
          </Row>
        </UserLastRow>
        <DrawerFooter className="drawer-footer-style drawerButton edit-user-drawer-footer-style">
          <Form.Item className="no-boder">
            {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button edit-profile-close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button edit-profile-close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            )}
            {props.viewMode === 'Edit'
              ? props.visible && (
                  <SubmitButton className="edit-user-save-button" htmlType="submit" type="primary">
                    Save
                  </SubmitButton>
                )
              : props.viewMode === 'Create'
                ? props.visible && (
                    <SubmitButton
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        const { memberList = [] } = props.userMemberData;
                        memberList.map((member) => {
                          member?.formRef?.submit();
                          return member?.formRef?.validateFields();
                        });
                      }}
                    >
                      Submit
                    </SubmitButton>
                  )
                : null}
          </Form.Item>
        </DrawerFooter>
      </Spin>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    isMobile: state.trackReducer.isMobile,
    customerDataList: state.notificationReducer.customerDataList,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(AdminForm);
