import { Col, Form, Input, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';

import { createAssetEventAPI, getAssetEventByIdAPI, updateAssetEventAPI } from '../../../api/asset';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { RootState } from '../../../redux/store';
import { Mode } from '../../../utils/types/role';
import { SubmitButton, UserLastRow } from '../../common/styles';
import { showNotificationDrawer } from '../../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

interface AssetEventsFormProps {
  onClose: () => void;
  onSubmit: () => void;
}

const AssetEventsForm: React.FC<AssetEventsFormProps> = ({ onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const { showFormData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);
  const { id: assetEventId, formMode } = showFormData;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (assetEventId) {
      (async () => {
        try {
          const res = await getAssetEventByIdAPI(assetEventId);
          form.setFieldsValue(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      form.resetFields();
    }
  }, [showFormData]);

  const onReset = () => {
    form.resetFields();

    onClose();
  };

  const handleChange = (name: string, value: any) => {
    form.setFields([{ name, value }]);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = form.getFieldsValue();

    if (formMode === Mode.Create) {
      createAssetEventAPI(formData)
        .then((res: any) => {
          const { data } = res;
          setIsLoading(false);
          showNotificationDrawer('Success', 'Asset Event added successfully!');

          onReset();
          onSubmit();
        })
        .catch((err) => {
          setIsLoading(false);
          showNotificationDrawer('Error', err?.response?.data?.message);

          onReset();
        });
    }

    if (formMode === Mode.Edit) {
      updateAssetEventAPI(assetEventId, formData)
        .then((res) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Asset Event updated successfully!');

          onReset();
          onSubmit();
        })
        .catch((err) => {
          setIsLoading(false);
          showNotificationDrawer('Error', err?.response?.data?.message);

          onReset();
        });
    }
  };

  const formStyle: CSSProperties = {};
  if (formMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={handleSubmit}
      // initialValues={form.getFieldsValue()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please input asset event name!', whitespace: true }
              ]}
            >
              <Input name="name" style={{ borderBottom: '1px solid #E9E9E9' }} />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                { required: true, message: 'Please select asset event status!', whitespace: true }
              ]}
            >
              <Select placeholder="select asset event status">
                <Select.Option value="Draft">Draft</Select.Option>
                <Select.Option value="Published">Published</Select.Option>
                <Select.Option value="Hidden">Hidden</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <div className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {formMode === Mode.Edit ? (
            <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
              Save
            </SubmitButton>
          ) : formMode === Mode.Create ? (
            <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
              Submit
            </SubmitButton>
          ) : null}
        </Form.Item>
      </div>
    </Form>
  );
};

export default AssetEventsForm;
