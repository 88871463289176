/**
 * Supported image types
 */
export const supportedImageContentTypeMap = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  tif: 'image/tif',
  tiff: 'image/tiff',
  webp: 'image/webp',
  gif: 'image/gif',
  avif: 'image/avif',
  jfif: 'image/jpeg'
};

/**
 * Generate resized image name path from original path
 * @param { string } imagePath Original image path
 * @param { fileSize } imageSize Size of resized image string. i.e: "200x200"
 * @return { string}
 */
export const getResizedImageName = (imagePath: string, fileSize: string = '200x200'): string => {
  if (!imagePath) return imagePath;

  const [width, height] = fileSize.split('x');

  // Create a new URL object
  const imageUrl = new URL(imagePath);

  // Get the pathname and split it into parts
  const parts = imageUrl.pathname.split('.');

  // Remove the last part (the extension)
  const extension: string = parts.pop() || '';
  if (extension === 'svg') {
    // it's vector image
    return imagePath;
  }
  // Join the rest back together, add the size, and then add the extension back
  let resizedImageName = '';
  if (Object.keys(supportedImageContentTypeMap).includes(extension.toLowerCase())) {
    resizedImageName = `${parts.join('.')}_${width}x${height}.${extension}`;
  } else {
    resizedImageName = `${parts[0]}_${width}x${height}`;
  }

  // Replace the old pathname with the new one in the URL
  imageUrl.pathname = resizedImageName;

  // Return the new URL
  return imageUrl.toString();
};

export const svgToBase64DataUrl = (svgContent: string): string => {
  return `data:image/svg+xml;base64,${btoa(svgContent)}`;
};
