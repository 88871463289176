import React, { ReactChild, useEffect, useState } from 'react';
import { Drawer, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { ProgramAddButton } from '../common/styles';
import { Role } from '../../utils/types/role';
import GoalForm from '../FormComponent/GoalForm';
import { showNotificationDrawer } from '../common/utils';
import Plus from '../../images/Plus_White.svg';
import { GoalStatus } from '../../constant';

interface AddUserProps {
    user: Role;
    icon?: ReactChild;
    id?: string;
    edit?: boolean;
    chooseForm?: string;
    onCloseDrawer?: () => void;
    formTypeLabel?: string;
    addButtonLabel?: string;
    propVisible?: boolean;
    notifyVisible?:boolean;
    isMobile?:any,

}

export interface goalTypeListType {
    title: string;
    value: string;
}

const AddGoalComponent: React.FC<AddUserProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [role, setRole] = useState<Role>(props.user);
  const { propVisible, isMobile } = props;

  useEffect(() => {
    setRole(props.user);
  }, [props.user]);

  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  const closeDrawer = (title, content) => {
    setVisible(false);
    if (title) {
      openNotification(title, content);
    }

    if (props.onCloseDrawer) {
      props.onCloseDrawer();
    }
  };

  let showTitle = '';

  switch (props.chooseForm) {
  case 'Edit':
  case 'View':
    showTitle = props.chooseForm;
    break;
  default:
    showTitle = 'Create';
  }

  const openDrawar = (e: any) => {
    e.stopPropagation();
    setVisible(true);
  };

  return (
    <>
      { props.icon
        ? <span onClick={openDrawar}>{props.icon}</span>
        : !props.notifyVisible
          ? (
            <ProgramAddButton className="add-goal-button" icon={<img src={Plus} className={isMobile ? 'Plus-Icon-mobile-view' : 'Plus-Icon-Desktop-View'} />} onClick={openDrawar}>
              {props.addButtonLabel}
            </ProgramAddButton>
          )
          : null}
      <Drawer
        maskClosable={false}
        title={`${`${showTitle} ${props.formTypeLabel}`} Form`}
        placement="right"
        closable={false}
        visible={visible || propVisible}
        width="520"
        className={props.propVisible ? 'user-drawer notificationDrawerModal' : 'user-drawer'}
        onClose={() => closeDrawer('', '')}

      >
        {visible ? (
          <GoalForm
            close={closeDrawer}
            status={GoalStatus}
            cancel={() => closeDrawer('', '')}
            userRole={role}
            userId={props.id}
            editMode={props.edit}
            viewMode={showTitle}
            visible={visible || propVisible}
          />
        ) : null}
      </Drawer>
    </>
  );
};
function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(AddGoalComponent);
