import { Checkbox, Col, Collapse, Form, Input, Modal, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSelectedMemberDashboardApi } from '../../api/customer';
import { listUserApi } from '../../api/user';
import { getGoalsApi, updateTaskApi, updateTaskStatusApi } from '../../api/user-program';
import { dashBoardFilterByDateType } from '../../constant';
import DownExpandArrow from '../../images/icons/ArrowDown.svg';
import EditIcon from '../../images/icons/EditIcon.svg';
import LeftExpandArrow from '../../images/icons/leftArrow.svg';
import ExpandMinus from '../../images/icons/Paper_Minus.svg';
import ExpandPlus from '../../images/icons/Paper_Plus.svg';
import ShowPasswordBlack from '../../images/icons/showPasswordBlack.png';
import { setCustomerInfo, setCustomerLoading } from '../../redux/actions/customerAction';
import { setAssigneeList, setMemberGoals } from '../../redux/actions/userProgramAction';
import { Role } from '../../utils/types/role';
import { getUniqueUsersByMerge } from '../../utils/users';
import AddGoalComponent from '../AddUser/AddGoalComponent';
import AddMilestone from '../AddUser/AddMilestone';
import AddTasks from '../AddUser/AddTasks';
import { EditViewIcon, EmptyCol } from '../common/styles';
import { formattedDateWithDuration } from '../common/utils';
import { ProgramRole } from '../MyGoalsComponent';

const { Panel } = Collapse;

// const rowSelection = {
//     onChange: (selectedRowKeys, selectedRows) => {
//         console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//     },
//     onSelect: (record, selected, selectedRows) => {
//         console.log(record, selected, selectedRows);
//     },
//     onSelectAll: (selected, selectedRows, changeRows) => {
//         console.log(selected, selectedRows, changeRows);
//     },
// };

const MyGoalMobileView: React.FC<any> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const {
    goalData,
    loginUserType,
    visibly,
    dispatch,
    customerSelectedMember,
    skillData,
    customerDataList,
    assigneeList,
    loginUserData,
    currentLoginUser
  } = props;
  const [selectedTask, setSelectedTask] = useState({} as any);
  const [taskNotesModalOpen, setTaskNotesModalOpen] = useState(false);
  const [taskNotes, setTaskNotes] = useState('');
  const [viewMode, setViewMode] = useState<string>('');
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const defaultDate = dashBoardFilterByDateType[dashBoardFilterByDateType.length - 1];
  const [currentSelectedTaskChecked, setCurrentSelectedTaskChecked] = useState(false);
  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
    setModalVisible(false);
  }, [chooseKey]);
  const handleCheckBox = (e: CheckboxChangeEvent, task: { assignee: any[]; _id: any }) => {
    const { checked } = e.target;
    setCurrentSelectedTaskChecked(checked);
    // check to prevent app from crashing:
    if (task.assignee.some((x: any) => !x)) return;
    const updateData = {
      ...task,
      isCompleted: checked,
      notes: ''
    };
    setSelectedTask(updateData);
    if (checked) {
      setTaskNotesModalOpen(true);
    } else if (task._id) {
      dispatch(setCustomerLoading(true));
      updateTaskApi(task._id, updateData)
        .then((res) => {
          getGoalsData();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const choosePlanColorSwitch = (complexity) => {
    switch (complexity) {
      case 'Advanced':
        return 'complex-style';
      case 'Ambitious':
        return 'highly-ambitious';
      case 'Basic':
        return 'moderate-color';
      case 'Intermediate':
        return 'simple-color';
    }
  };
  const getGoalsData = () => {
    if (customerSelectedMember && customerSelectedMember.length && customerSelectedMember[0]._id) {
      getGoalsApi(customerSelectedMember[0]._id).then((res) => {
        dispatch(setMemberGoals(res.data));
        dispatch(setCustomerLoading(false));
      });
      const data = {
        startDate: defaultDate.startDate,
        endDate: defaultDate.endDate
      };
      getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
        const { data } = res;
        dispatch(setCustomerInfo(data));
      });
    }
  };
  const handleTaskNotesOk = () => {
    setTaskNotesModalOpen(false);
    setTaskNotes('');
    if (selectedTask._id) {
      dispatch(setCustomerLoading(true));
      updateTaskStatusApi(selectedTask._id, selectedTask)
        .then((res) => {
          getGoalsData();

          const notification = {
            member: `${
              customerSelectedMember.length
                ? customerSelectedMember[0]._id
                : customerSelectedMember._id
            }`,
            businessUser: `${props.loginUserData && props.loginUserData._id}`,
            message: `${
              customerDataList && customerDataList.firstName
            } just Marked a Task as Complete`,
            user: `${
              customerSelectedMember.length
                ? customerSelectedMember[0].parent
                : customerSelectedMember.parent
            }`,
            task: `${selectedTask._id}`,
            type: 'TASK_MARKED'
          };
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const customExpandIcon = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandMinus} style={{ width: '22px' }} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandPlus} style={{ width: '15px' }} />
      </a>
    );
  };

  const columncontainer = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className={record.isPrimary ? 'goal-table-text goal-primary' : 'goal-table-text'}>
          {record.name ? record.name : '--'}
        </div>
      )
    }
  ];
  const columns = [
    // {
    //     title: 'Discription',
    //     dataIndex: 'description',
    //     key: 'description',
    //     sorter: (a, b) =>((a.description && b.description) && a.description.length - b.description.length),
    //     render: (text, record) => (record.description ? record.description : ("--") ),
    // },
    {
      title: 'Complexity',
      dataIndex: 'complexity',
      key: 'complexity',
      sorter: (a, b) =>
        a.complexity && b.complexity && a.complexity.name.localeCompare(b.complexity.name),
      render: (text, record) => (
        <div
          className={
            record.complexity && record.complexity.name === 'Ambitious'
              ? 'highly-ambitious'
              : 'complex-style'
          }
        >
          {record.complexity ? record.complexity && record.complexity.name : '--'}
        </div>
      )
    },
    {
      title: 'Outcome Type',
      dataIndex: 'typeOfGoal',
      key: 'typeOfGoal',
      sorter: (a, b) =>
        a.typeOfGoal && b.typeOfGoal && a.typeOfGoal.name.localeCompare(b.typeOfGoal.name),
      render: (text, record) => (
        <div className="goal-table-text">
          {record.typeOfGoal ? record.typeOfGoal && record.typeOfGoal.name : '--'}{' '}
        </div>
      )
    },
    {
      title: 'Last Activity Date (GMT+11)',
      dataIndex: 'lastActivity',
      key: 'lastActivity',
      sorter: (a, b) =>
        a.updatedAt.length && b.updatedAt.length && a.updatedAt.localeCompare(b.updatedAt),
      render: (text, record) => (
        <div className="goal-table-date">
          {' '}
          {record.updatedAt ? (
            <span className="lastActivity">{formattedDateWithDuration(record.updatedAt)}</span>
          ) : (
            <EmptyCol>--</EmptyCol>
          )}
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <AddGoalComponent
              onCloseDrawer={onCloseDrawer}
              user={Role.Customer}
              formTypeLabel="Outcome"
              chooseForm="View"
              edit={false}
              icon={<img src={ShowPasswordBlack} className="show-eye-icon" />}
              id={record._id}
            />
            {loginUserType === Role.Business ? (
              <AddGoalComponent
                onCloseDrawer={onCloseDrawer}
                user={Role.Customer}
                formTypeLabel="Outcome"
                chooseForm="Edit"
                edit
                icon={<img src={EditIcon} className="show-edit-icon" />}
                id={record._id}
              />
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  const modifyData = goalData.map((itemData) => ({ ...itemData, key: itemData._id })) || [];
  const StatusColorSwitch = (status_color) => {
    switch (status_color) {
      case 'Achieved':
        return 'achieved-color mileStone-mobile-text';
      case 'In-progress':
        return 'inProgress-color mileStone-mobile-text';
      case 'Complete':
        return 'inProgress-color mileStone-mobile-text';
      case 'Active':
        return 'inProgress-color mileStone-mobile-text';
      case 'Planned':
        return 'planned-color mileStone-mobile-text';
      case 'On-hold':
        return 'on-hold-color mileStone-mobile-text';
      case 'Cancelled':
        return 'cancelled-color mileStone-mobile-text';
      case 'Pending':
        return 'pending-color-for-goals mileStone-mobile-text';
    }
  };
  const PAGE_SIZE: number = 50;

  const role = Role.Business;
  const show = 'Active';
  const fetchList = useCallback((searchTerm: string = '', skip: number = 0) => {
    const options = {
      filter: show,
      search: searchTerm.toLowerCase(),
      skip: skip || 0,
      limit: PAGE_SIZE
    };
    return listUserApi(role, options);
  }, []);
  useEffect(() => {
    let dataList = [loginUserData, currentLoginUser];
    // In case of customer, loginUserData and currentLogainUser are same, remove duplication...
    if (currentLoginUser.type === Role.Customer) dataList = [currentLoginUser];

    fetchList('').then((res) => {
      // setTeamMember(res.data)
      dataList = getUniqueUsersByMerge(dataList, res.data);
      dispatch(setAssigneeList(dataList));
    });
  }, [dispatch, viewMode]);
  return (
    <>
      {/* <Table
                className={props.className}
                columns={columns}
                dataSource={modifyData}
                onChange={handleChange}
                expandIcon={(props) => customExpandIcon(props)}
                // expandedRowRender={record => <MileStoneDiv><MyGoalMobileView goalInfo={record} /></MileStoneDiv>}
                // rowSelection={{ ...rowSelection }}
            /> */}
      <div className="mileStone-mobile-container">
        <Row gutter={24}>
          <Col span={12} className="milestone-text">
            Status
          </Col>
          <Col span={12}>
            <div className={StatusColorSwitch(props.goalInfo?.status)}>
              {props.goalInfo?.status ? props.goalInfo?.status : '--'}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className="milestone-text">
            Complexity
          </Col>
          <Col span={12}>
            <div
              className={choosePlanColorSwitch(
                props.goalInfo?.complexity && props.goalInfo?.complexity?.name
              )}
            >
              {props.goalInfo?.complexity
                ? props.goalInfo?.complexity && props.goalInfo?.complexity.name
                : '--'}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className="milestone-text">
            Outcome Type
          </Col>
          <Col span={12}>
            <div className="goal-table-text">
              {props.goalInfo?.typeOfGoal
                ? props.goalInfo?.typeOfGoal && props.goalInfo?.typeOfGoal?.name
                : '--'}{' '}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className="milestone-text">
            Last Activity Date
          </Col>
          <Col span={12}>
            <div className="goal-table-date">
              {' '}
              {props.goalInfo?.updatedAt ? (
                <span className="lastActivity">
                  {formattedDateWithDuration(props.goalInfo?.updatedAt)}
                </span>
              ) : (
                <EmptyCol>--</EmptyCol>
              )}
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className="milestone-text">
            Action
          </Col>
          <Col span={12}>
            {/* <div onClick={() => setViewMilestones(!viewMilestones)}>
              <img src={ThreeDotsIcon} />
            </div> */}
            <Space size="middle">
              <EditViewIcon className="autismag-table-action">
                <AddGoalComponent
                  onCloseDrawer={onCloseDrawer}
                  user={Role.Customer}
                  formTypeLabel="Outcome"
                  chooseForm="View"
                  edit={false}
                  icon={<img src={ShowPasswordBlack} className="show-eye-icon" />}
                  id={props.goalInfo._id}
                />
                {loginUserType === Role.Business ? (
                  <AddGoalComponent
                    onCloseDrawer={onCloseDrawer}
                    user={Role.Customer}
                    formTypeLabel="Outcome"
                    chooseForm="Edit"
                    edit
                    icon={<img src={EditIcon} className="show-edit-icon" />}
                    id={props.goalInfo._id}
                  />
                ) : null}
              </EditViewIcon>
            </Space>
            {/* <EditViewIcon className="autismag-table-action" /> */}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} className="titleStyle">
            Skill
          </Col>
          <Col className="mileStone-add-drawer-container-tab" span={12}>
            {loginUserType === Role.Business &&
            props.goalInfo?.status != 'Cancelled' &&
            props.goalInfo?.status != 'Achieved' &&
            props.goalInfo?.status != 'On-hold' ? (
              <AddMilestone
                getGoalsData={getGoalsData}
                onCloseDrawer={onCloseDrawer}
                user={Role.Customer}
                formTypeLabel="Skill"
                goalFormType={ProgramRole.Milestone}
                addButtonLabel="Add Skill"
                goalData={props.goalInfo}
                teamMember={assigneeList}
              />
            ) : null}
          </Col>
        </Row>
        <div className="milestone-mobile-container-tab">
          {props?.goalInfo?.milestones?.map((x, i) => (
            <div style={{ flex: 1, width: '100%' }}>
              <Collapse>
                <Panel
                  className="skill-view-mobile-header"
                  disabled={!!(props.goalInfo?.status === 'Cancelled' || x?.status === 'Cancelled')}
                  header={
                    <div className="mileStone-panel-text">
                      <div className={StatusColorSwitch(x?.status)}>{x.name}</div>
                      <div className={StatusColorSwitch(x?.status)}>
                        {({ isActive }) =>
                          isActive ? <img src={DownExpandArrow} /> : <img src={LeftExpandArrow} />
                        }
                      </div>
                      <div className="milestone-drawer-icon-container-tab mobile-view-container-tab">
                        <EditViewIcon className="milestone-view-edit">
                          <AddMilestone
                            getGoalsData={getGoalsData}
                            onCloseDrawer={onCloseDrawer}
                            user={Role.Customer}
                            formTypeLabel="Skill"
                            goalFormType={ProgramRole.Milestone}
                            chooseForm="View"
                            addButtonLabel="View Skill"
                            icon={
                              <img
                                src={ShowPasswordBlack}
                                className={
                                  x?.status === 'Cancelled' || x?.status === 'Mastered'
                                    ? 'view-icon-styling show-eye-icon'
                                    : 'show-eye-icon'
                                }
                              />
                            }
                            goalData={props?.goalInfo}
                            mileStoneData={x}
                            milestoneId={x._id}
                            teamMember={assigneeList}
                          />
                          {loginUserType === Role.Business &&
                          props.goalInfo?.status != 'Cancelled' &&
                          props.goalInfo?.status != 'Achieved' &&
                          x?.status != 'Cancelled' &&
                          x?.status != 'Mastered' ? (
                            <AddMilestone
                              getGoalsData={getGoalsData}
                              onCloseDrawer={onCloseDrawer}
                              user={Role.Customer}
                              formTypeLabel="Skill"
                              goalFormType={ProgramRole.Milestone}
                              chooseForm="Edit"
                              addButtonLabel="Edit Skill"
                              icon={
                                <img
                                  className="edit-button-bussiness show-edit-icon"
                                  src={EditIcon}
                                />
                              }
                              goalData={props?.goalInfo}
                              mileStoneData={x}
                              milestoneId={x._id}
                              teamMember={assigneeList}
                            />
                          ) : null}
                        </EditViewIcon>
                        {loginUserType !== Role.Admin &&
                        props.goalInfo?.status != 'Cancelled' &&
                        props.goalInfo?.status != 'Achieved' &&
                        x?.status != 'Cancelled' &&
                        x?.status != 'Mastered' ? (
                          <AddTasks
                            getGoalsData={getGoalsData}
                            onCloseDrawer={onCloseDrawer}
                            user={Role.Customer}
                            goalFormType={ProgramRole.MyTasks}
                            formTypeLabel="Tasks"
                            addButtonLabel="Task"
                            mileStoneData={x}
                          />
                        ) : null}
                      </div>
                    </div>
                  }
                  key={i}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {x?.tasks
                      ?.filter((x) => !x.isDeleted)
                      ?.map((y, j) => (
                        <p className="task-mobile-container-text display-task-in-one-row">
                          <Checkbox
                            checked={goalData[0]?.status === 'Achieved' ? true : y.isCompleted}
                            disabled={goalData[0]?.status === 'Achieved'}
                            style={{
                              pointerEvents: props.customerDataList === Role.Admin ? 'none' : 'auto'
                            }}
                            onChange={(e) => handleCheckBox(e, y)}
                            className={`checkBoxWiddth ${
                              y.isCompleted === true
                                ? 'is-completed-viewTask new-completed-task'
                                : 'not-completed-task'
                            }`}
                          >
                            {/* {y.name} */}
                          </Checkbox>
                          <div
                            className={`${y.isCompleted === true ? 'is-complete-task taskData-text-isCompleted' : 'not-completed-task taskData-text-notCompleted'}`}
                            onClick={() => {
                              setViewMode('View');
                              setModalVisible(true);
                            }}
                          >
                            {y.name}
                          </div>

                          <div className="task-drawer-show-icon-container">
                            <EditViewIcon className="task-view-edit-icon">
                              <AddTasks
                                getGoalsData={getGoalsData}
                                onCloseDrawer={onCloseDrawer}
                                user={Role.Customer}
                                goalFormType={ProgramRole.MyTasks}
                                formTypeLabel="Tasks"
                                addButtonLabel="View Task"
                                icon=""
                                chooseForm={viewMode}
                                propVisible={isModalVisible}
                                taskId={y._id}
                                taskData={y}
                                mileStoneData={x}
                                notifyVisible
                              />
                              {loginUserType === Role.Business ||
                              loginUserType === Role.Customer ||
                              y.createdBy === customerDataList._id ? (
                                <AddTasks
                                  getGoalsData={getGoalsData}
                                  onCloseDrawer={onCloseDrawer}
                                  user={Role.Customer}
                                  goalFormType={ProgramRole.MyTasks}
                                  formTypeLabel="Tasks"
                                  addButtonLabel="Edit Task"
                                  icon={<img src={EditIcon} className="show-edit-icon" />}
                                  chooseForm="Edit"
                                  taskId={y._id}
                                  taskData={y}
                                  mileStoneData={x}
                                  createdByOther={
                                    x.createdBy !== customerDataList._id ? 'createdByOther' : ''
                                  }
                                />
                              ) : null}
                            </EditViewIcon>
                          </div>
                        </p>
                      ))}
                  </div>
                </Panel>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title={
          <div className="add-task-title-container">
            Add Task Note
            <div onClick={() => setTaskNotesModalOpen(false)} className="add-task-close-button">
              Close
            </div>
          </div>
        }
        visible={taskNotesModalOpen}
        onOk={handleTaskNotesOk}
        closable={false}
        onCancel={() => setTaskNotesModalOpen(false)}
        className="Add-task-modal-container"
        footer={[<button onClick={handleTaskNotesOk}>Ok</button>]}
      >
        <Form.Item
          name="notes"
          className="no-boder"
          rules={[{ required: false, message: 'Please input your app details!' }]}
        >
          <Input.TextArea
            rows={4}
            name="notes"
            value={taskNotes}
            onChange={(e) => setTaskNotes(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerInfo: state.customerRuducer.customerInfo,
    customerAccessData: state.customerRuducer.customerAccessData,
    visibly: state.notificationReducer.visible,
    notificationList: state.notificationReducer.notificationList,
    customerDataList: state.notificationReducer.customerDataList,
    assigneeList: state.programRuducer.assigneeList,
    loginUserData: state.userMemberData.loginUserData,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(MyGoalMobileView);

function fetchList(arg0: string) {
  throw new Error('Function not implemented.');
}
