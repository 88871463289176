import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { CustomerContainer } from './common/styles';
import MyFavTableComponent from './TableData/MyFavTableComponent';
import { dashBoardFilterByDateType } from '../constant';
import { getSelectedMemberDashboardApi } from '../api/customer';
import { setCustomerInfo } from '../redux/actions/customerAction';

const MyFavoriteComponent: React.FC<any> = (props) => {
  const { customerLoading, customerInfo, customerSelectedMember } = props;
  const favouriteList = customerInfo && customerInfo.favourites;

  const defaultDate = dashBoardFilterByDateType.find((data) => data.name === 'All Time');

  const dispatch = useDispatch();

  const getDashboardInfo = useCallback(() => {
    if (defaultDate) {
      const data = {
        startDate: defaultDate.startDate,
        endDate: defaultDate.endDate,
      };
      if (customerSelectedMember && customerSelectedMember.length && customerSelectedMember[0]._id) {
        getSelectedMemberDashboardApi(customerSelectedMember[0]._id, data).then((res) => {
          const { data } = res;
          dispatch(setCustomerInfo(data));
        });
      }
    }
  }, [customerSelectedMember]);

  useEffect(() => {
    getDashboardInfo();
  }, [getDashboardInfo]);

  return (
    <div style={{ marginTop: 20 }}>
      <Spin spinning={customerLoading}>
        <div className="site-layout-background mobile-view-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer>
              <MyFavTableComponent favouriteList={favouriteList} className="MyFavourite-layout-color" />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerLoading: state.customerRuducer.customerLoading,
    customerInfo: state.customerRuducer.customerInfo,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
  };
}

export default connect(mapStateToProps)(MyFavoriteComponent);
