import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Skeleton } from 'antd';
import * as React from 'react';

interface IUser {
  firstName: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  phoneCountryCode?: string;
  avatar?: string;
}

export interface IUserAvatarProps {
  user?: IUser;
  color?: string;
  loading?: boolean;
}

export default function UserAvatar(props: IUserAvatarProps) {
  const { user = {} as IUser, color = 'default', loading = false } = props;
  if (loading) {
    return (
      <Skeleton
        avatar={{ size: 60, shape: 'square' }}
        paragraph={{ rows: 3 }}
        active
        title={false}
      />
    );
  }
  const avatar = user?.avatar;
  const {
    firstName = '', lastName = '', phoneCountryCode = '', phoneNumber = '', email = '',
  } = user;
  const phone = `${phoneCountryCode}${phoneNumber}`;
  const fullName = `${firstName} ${lastName}`.trim();
  const shortName = fullName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .replace(/[-_!@#$%^&*()0-9]/g, '')
    .toUpperCase()
    .slice(0, 3);

  const backColor = avatar ? 'default' : color;
  return (
    <div className="flex flex-row gap-3">
      <Avatar
        src={avatar}
        icon={!shortName ? <UserOutlined /> : null}
        style={{
          backgroundColor: backColor,
          border: '3px solid white',
          boxSizing: 'content-box',
          borderRadius: '8px',
          flexShrink: 0,
        }}
        size={60}
        shape="square"
      >
        {shortName}
      </Avatar>
      <div className="gap-1 flex flex-col">
        {!fullName && <div className="text-lg my-auto">Not Specified</div>}
        {fullName && <div className="font-medium">{fullName || 'Not Specified'}</div>}
        {email && (
          <div>
            <MailOutlined />
            &ensp;
            {email}
          </div>
        )}
        {phone && (
          <div>
            <PhoneOutlined />
            &ensp;
            {phone}
          </div>
        )}
      </div>
    </div>
  );
}
