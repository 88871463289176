export const customerHierarchy = [
  {
    customerName: 'Rohan Roy',
    customerSelectionType: [
      {
        customerType: 'Today',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task',
              value: 'Apple task',
            },
            {
              label: 'Pear task',
              value: 'Pear task',
            },
            {
              label: 'Orange task',
              value: 'Orange task',
            },
            {
              label: 'Grapes task',
              value: 'Grapes task',
            },

          ],
          myFiles: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'File 1',
            },
            {
              label: 'Japanese princess to wed commoner.',
              link: 'File 2',
            },
            {
              label: 'Australian walks 100km after outback crash.',
              link: 'File 3',
            },
            {
              label: 'Man charged over missing wedding girl.',
              link: 'File 3',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'File 3',
            },
          ],
          recentActivity: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 45,
          },
          dayInProgress: {
            label: 'day 1',
            value: 74,
          },
          engagementScore: {
            label: 'day 11',
            value: 60,
          },

        },
      },
      {
        customerType: 'Last Week',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task 2.0',
              value: 'Apple task 2.0',
            },
            {
              label: 'Pear task 2.0',
              value: 'Pear task 2.0',
            },
            {
              label: 'Orange task 2.0',
              value: 'Orange task 2.0',
            },

          ],
          myFiles: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
          ],
          recentActivity: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 55,
          },
          dayInProgress: {
            label: 'day 1',
            value: 34,
          },
          engagementScore: {
            label: 'day 11',
            value: 90,
          },

        },
      },
    ],
  },
  {
    customerName: 'Rony boss',
    customerSelectionType: [
      {
        customerType: 'Today',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task 3',
              value: 'Apple task 3',
            },
            {
              label: 'Pear task 3',
              value: 'Pear task 3',
            },
            {
              label: 'Orange task 3',
              value: 'Orange task 3',
            },
            {
              label: 'Grapes task 3',
              value: 'Grapes task 3',
            },

          ],
          myFiles: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'File 1',
            },
            {
              label: 'Japanese princess to wed commoner.',
              link: 'File 2',
            },
            {
              label: 'Australian walks 100km after outback crash.',
              link: 'File 3',
            },
            {
              label: 'Man charged over missing wedding girl.',
              link: 'File 3',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'File 3',
            },
          ],
          recentActivity: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 45,
          },
          dayInProgress: {
            label: 'day 1',
            value: 74,
          },
          engagementScore: {
            label: 'day 11',
            value: 60,
          },

        },
      },
      {
        customerType: 'Last Week',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task 5',
              value: 'Apple task 5',
            },
            {
              label: 'Pear task 5',
              value: 'Pear task 5',
            },
            {
              label: 'Orange task 5',
              value: 'Orange task 5',
            },

          ],
          myFiles: [
            {
              label: 'Lorem Ipsum is simply dummy text ',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text ',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
          ],
          recentActivity: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 55,
          },
          dayInProgress: {
            label: 'day 1',
            value: 34,
          },
          engagementScore: {
            label: 'day 11',
            value: 90,
          },

        },
      },
    ],
  },
  {
    customerName: 'Manoj singh',
    customerSelectionType: [
      {
        customerType: 'Today',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task 6',
              value: 'Apple task 6',
            },
            {
              label: 'Pear task 6',
              value: 'Pear task 6',
            },
            {
              label: 'Orange task 6',
              value: 'Orange task 6',
            },
            {
              label: 'Grapes task 6',
              value: 'Grapes task 6',
            },

          ],
          myFiles: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'File 1',
            },
            {
              label: 'Japanese princess to wed commoner.',
              link: 'File 2',
            },
            {
              label: 'Australian walks 100km after outback crash.',
              link: 'File 3',
            },
            {
              label: 'Man charged over missing wedding girl.',
              link: 'File 3',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'File 3',
            },
          ],
          recentActivity: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 45,
          },
          dayInProgress: {
            label: 'day 1',
            value: 74,
          },
          engagementScore: {
            label: 'day 11',
            value: 60,
          },

        },
      },
      {
        customerType: 'Last Week',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task2',
              value: 'Apple task2',
            },
            {
              label: 'Pear task2',
              value: 'Pear task2',
            },
            {
              label: 'Orange task2',
              value: 'Orange task2',
            },

          ],
          myFiles: [
            {
              label: 'Lorem Ipsum is simply dummy text ',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text ',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
          ],
          recentActivity: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 55,
          },
          dayInProgress: {
            label: 'day 1',
            value: 34,
          },
          engagementScore: {
            label: 'day 11',
            value: 90,
          },

        },
      },
    ],
  },
  {
    customerName: 'Mohan Sharma',
    customerSelectionType: [
      {
        customerType: 'Today',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task 7',
              value: 'Apple task 7',
            },
            {
              label: 'Pear task 7',
              value: 'Pear task 7',
            },
            {
              label: 'Orange task 7',
              value: 'Orange task 7',
            },
            {
              label: 'Grapes task 7',
              value: 'Grapes task 7',
            },

          ],
          myFiles: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'File 1',
            },
            {
              label: 'Japanese princess to wed commoner.',
              link: 'File 2',
            },
            {
              label: 'Australian walks 100km after outback crash.',
              link: 'File 3',
            },
            {
              label: 'Man charged over missing wedding girl.',
              link: 'File 3',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'File 3',
            },
          ],
          recentActivity: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Ant Design, a design language for background applications',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Los Angeles battles huge wildfires.',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 45,
          },
          dayInProgress: {
            label: 'day 1',
            value: 74,
          },
          engagementScore: {
            label: 'day 11',
            value: 60,
          },

        },
      },
      {
        customerType: 'Last Week',
        selectedData: {
          myTasks: [
            {
              label: 'Apple task 8',
              value: 'Apple task 8',
            },
            {
              label: 'Pear task 8',
              value: 'Pear task 8',
            },
            {
              label: 'Orange task 8',
              value: 'Orange task 8',
            },

          ],
          myFiles: [
            {
              label: 'Lorem Ipsum is simply dummy text ',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text ',
              link: 'google.com',
            },
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
          ],
          recentActivity: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Page 2',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          achiveTarget: [
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.2',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          myFavorite: [
            {
              label: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
              link: 'google.com',
            },
            {
              label: 'Racing car sprays burning fuel into crowd.',
              link: 'google.com',
            },
            {
              label: 'Rently2',
              link: 'google.com',
            },
          ],
          goalProgress: {
            label: 'Cardio 2',
            value: 55,
          },
          dayInProgress: {
            label: 'day 1',
            value: 34,
          },
          engagementScore: {
            label: 'day 11',
            value: 90,
          },

        },
      },
    ],
  },
];
