import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BlockHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width:100%;
    height: 50px;
    border-bottom: 1px solid #a9a9a940;
    color: #fff;
    padding-left: 10px !important;
    background: #FFFFFF;

    .mobile-logo-link {
        display: none
    }

    .desktop-menu {
        .hamburgerIcon {
            margin-right: 20px;
        }
        .site-logo {
            width: 86px;
            position: relative;
            top: -3px;
        }
    }
    
}
    @media only screen and (max-width: 1042px) {
        
        .mobile-logo-link {
            display: block
        }
    }
`;
export const Title = styled.h2`
  margin: 14px 0px !important;
  color: #fff;
  font-size: 16px;
`;
export const Logo = styled.img`
  width: 135px;
  height: auto;
  margin-top: 3px;
`;
export const MenuButton = styled(Button)`
  color: #fff !important;
  margin-top: 10px;
  padding-right: 2px;
  padding-left: 10px;
`;
export const LinkTab = styled(Link)`
  li {
    height: 40px;
    line-height: 40px;
    width: 100%;
    padding-left: 24px;
    color: rgba(255, 255, 255, 0.65);

    &:hover {
      color: #ffffff;
    }

    &:active {
      color: #ffffff;
    }
    .anticon {
      min-width: 14px;
      margin-right: 10px;
      font-size: 14px;
    }
  }
`;

export const UserContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 1rem;

  @media only screen and (max-width: 992px) {
    margin-right: 0;
    margin-top: 3.25rem;
  }
`;

export const SideIcon = styled.span``;

export const DropDownSelect = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      background: linear-gradient(270deg, #783389 0%, #3a8ac9 100%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      border: none;
      &:focus {
        outline: none;
      }
    }

    .ant-select-arrow {
      color: #ffffff;
    }
  }
`;

export const SideBarFooter = styled.div`
  align-items: flex-start;
  margin-left: 27px;
  margin-top: 30px;
  margin-bottom: 20px;
  justify-content: flex-start;
  font-family: Mulish;
  font-style: normal;
  letter-spacing: 0.10000000149011612px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .autismag-div {
    font-size: 14px;
    font-weight: 800;
    line-height: 18px;
  }
  .copyright-div {
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const SideMenuSignout = styled.div`
  margin-top: 80px;

  @media only screen and (max-height: 768px) {
    margin-top: 10px;
  }
`;
export const SideMenuSignoutButton = styled.button`
  width: 100%;
  height: 40px;
  border: 0;
  float: left;
  .signout-image {
    float: left;
    margin-left: 20px;
    margin-right: 10px;
    padding: 0;
  }
  .signout-text {
    float: left;
  }
`;
export const SelectUserIcon = styled.img`
  margin-left: 13px;
  margin-right: 8px;
`;

export const DashboardLayout = styled.div`
  .site-layout {
    .ant-card-bordered {
      border-radius: 20px !important;
      background: #ffffff;
    }
    .ant-layout-content {
      background: #f8fbfc;
      z-index: 99;
    }
  }
  @media only screen and (max-width: 1042px) {
    .autismag-sidebar {
      display: none;

      .autismag-collapse-icon {
        left: 10px;
        transform: rotate(0deg);
      }
    }
    .site-layout {
      .site-notification {
        .ant-card-bordered {
          border: none !important;
          top: -5px !important;
          background: #f8fbfc !important;
        }
        .ant-card-body {
          padding-left: 14px !important;
          padding-right: 0px !important;
        }
      }
    }
    .autismag-sidebar-show-sidebar {
      display: block;
      border-radius: 0px;
      height: 100% !important;
      width: 100% !important;
      max-width: none !important;
    }

    section.ant-layout.site-layout {
      margin-left: 0 !important;
      z-index: 998;
    }

    .open-sidebar {
      z-index: 1;
      opacity: 0.3;
      pointer-events: none;
    }
  }
  @media only screen and (max-width: 992px) {
    .site-layout {
      .site-notification {
        .ant-card-bordered {
          border: none !important;
          top: 13px !important;
          background: #f8fbfc !important;
        }
        .ant-card-body {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
    }
  }
`;

export const FixedButton = styled.div`
  position: fixed;
  right: 24px;
  bottom: 30px;
  z-index: 99;
`;

export const CustomerNameDiv = styled.div``;
export const GetStartedButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const WelcomeDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const WelcomeImg = styled.img`
  width: 40px;
  height: 40px;
  text-align: center;
`;
export const WelcomeText = styled.p`
  text-align: center;
`;
