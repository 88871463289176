import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { ColDiv, IconTab } from '../common/styles';
import { Role } from '../../utils/types/role';
import { ProgramRole } from '../MyGoalsComponent';
import AddTasks from '../AddUser/AddTasks';
import { formattedDate } from '../common/utils';

const ResourceTaskTableComponent: React.FC<any> = (props) => {
  const { resourcesRelatedTasks } = props;

  const handleChange = (pagination, filters, sorter, extra) => {
    // console.log('Various parameters', pagination, filters, sorter, extra);
  };
  const StatusColorSwitch = (status) => {
    switch (status) {
    case 'Pending': return 'pending-color';
    case 'Active': return 'active-color';
    case 'Reject': return 'reject-color';
    }
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name && b.name) && a.name.localeCompare(b.name),
      render: (text, record) => <ColDiv className="resource-task-column"><AddTasks user={Role.Customer} goalFormType={ProgramRole.MyTasks} formTypeLabel="Tasks" addButtonLabel="View Task" icon={<IconTab>{record.name}</IconTab>} chooseForm="View" taskId={record._id} taskData={record} mileStoneData={record.milestone} /></ColDiv>,
    },
    {
      title: 'Created At',
      dataIndex: 'created At',
      key: 'created-by',
      sorter: (a, b) => (a.status && b.status) && a.status.localeCompare(b.status),
      render: (text, record) => <ColDiv><div className={StatusColorSwitch(record.status)}>{formattedDate(record.createdAt)}</div></ColDiv>,
    },
  ];

  const modifyData = resourcesRelatedTasks.filter((x) => x.isDeleted === false).map((itemData) => {
    if (itemData.isDeleted !== true) {
      return { ...itemData, key: itemData._id };
    }
    return [];
  }) || [];

  return (
    <Table
      className={props.className}
      columns={columns}
      dataSource={modifyData}
      onChange={handleChange}
    />
  );
};

function mapStateToProps(state: any) {
  return {
    resourcesRelatedTasks: state.formReducer.resourcesRelatedTasks,
  };
}

export default connect(mapStateToProps)(ResourceTaskTableComponent);
