import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getGoalsApi } from '../../api/user-program';
import { setMemberGoals } from '../../redux/actions/userProgramAction';
import MyGoalsComponent from '../MyGoalsComponent';
import MyResourcesComponent from '../MyResources/MyResourcesComponent';
import MySessionComponent from '../MySessionComponent';

interface eventRouteProps {
  memberId?: string;
  type?: string;
  customerDataList?: any;
  dispatch?: any;
  customerSelectedMember?: any;
}

const EventRoute: React.FC<eventRouteProps> = (props) => {
  const { id, type } = useParams();
  const { customerDataList, dispatch, customerSelectedMember } = props;
  useEffect(() => {
    const filterId =
      customerDataList &&
      customerDataList.length &&
      customerDataList.members &&
      customerDataList.members.filter((data) => data._id === id);
    if (customerSelectedMember.length) {
      getGoalsApi(customerSelectedMember.length && customerSelectedMember[0].parent).then((res) => {
        dispatch(setMemberGoals(res.data));
      });
    }
  }, []);

  const switchRouter = (pageType) => {
    switch (pageType) {
      case 'goal':
        return <MyGoalsComponent />;
      case 'session':
        return <MySessionComponent />;
      case 'resources':
        return <MyResourcesComponent />;
      default:
        return null;
    }
  };

  return switchRouter(type);
};

function mapStateToProps(state: any) {
  return {
    customerDataList: state.notificationReducer.customerDataList,
    customerSelectedMember: state.customerRuducer.customerSelectedMember
  };
}
export default connect(mapStateToProps)(EventRoute);
