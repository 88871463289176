import { SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, Pagination, Spin, Table } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { listUserApi } from '../../api/user';
import { userMemberDataType } from '../../constant';
import EmptyTableData from '../../images/empty-table-data.svg';
import { setUserList } from '../../redux/actions/userMemberAction';
import { ThemeContext } from '../../theme-context';
import { Role } from '../../utils/types/role';
import { ColDiv } from '../common/styles';
import ListingBoxActionTabs from './ListingBoxActionTabs';
import { ListingDiv, SearchContainer } from './styles';

interface ListingBoxProps {
  show: string;
  role: Role;
  activeKey: string;
  dispatch?: any;
  userMemberData: userMemberDataType;
  cardClass?: string;
  onIconClick?: any;
  loginUserType?: string;
  customerDataList?: any;
  state?: any;
  loginUserData?: any;
  userList?: any;
  planTypeFilter?: string[];
}

const ListingBox: React.FC<ListingBoxProps> = (props) => {
  const [email, setEmail] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [perPageRecords, setperPageRecords] = useState<number>(50);
  const { planTypeFilter = [] } = props;
  const fetchList = useCallback(
    (searchTerm: string = '', skip: number = 0) => {
      const options = {
        filter: props.show,
        planTypeFilter: planTypeFilter.join(','),
        search: searchTerm.toLowerCase(),
        skip: skip || 0,
        limit: perPageRecords
      };

      return listUserApi(props.role, options);
    },
    [props.show, props.role, perPageRecords, props.planTypeFilter]
  );

  const { dispatch } = props;

  // console.log(props.userList,"userMemberData")
  // console.log(props.state,"state")
  const choosePlanColorSwitch = (ChoosePlan) => {
    switch (ChoosePlan) {
      case 'Flagship':
        return 'Flagship-color';
      case 'Lite':
        return 'lite-color';
      case 'Subscriber':
        return 'subscriber-color';
      case 'Choice':
        return 'choice-color';
      case 'Care':
        return 'care-color';
      case 'Others':
        return 'other-color';
    }
  };
  const choosePlanLabelSwitch = (ChoosePlan) => {
    switch (ChoosePlan) {
      case 'Flagship':
        return 'Label-text';
      case 'Lite':
        return 'Label-text';
      case 'Subscriber':
        return 'Label-text';
      case 'Choice':
        return 'Label-text';
      case 'Care':
        return 'Label-text';
      case 'Others':
        return 'Label-text';
    }
  };
  const onCloseDrawer = useCallback(() => {
    setCurrent(1);
    setEmail('');
    setLoading(true);
    fetchList('')
      .then((res) => {
        const { data, count } = res;
        dispatch(setUserList(data));
        setTotalCount(count);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error caught!', err.message);
        setLoading(false);
      });
  }, [fetchList, dispatch]);

  useEffect(() => {
    if (props.show === props.activeKey) {
      dispatch(setUserList([]));
      setCurrent(1);
      setLoading(true);
      setEmail('');
      fetchList('')
        .then((res) => {
          const { data, count } = res;
          dispatch(setUserList(data));
          setTotalCount(count);
          setLoading(false);
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
          setLoading(false);
        });
    }
  }, [props.show, props.activeKey, props.planTypeFilter, fetchList, dispatch]);

  const handleChange = (e) => {
    const { value } = e.target || {};
    setEmail(value);
    if (!value) {
      onSearch(value);
    }
  };

  const onPageChange = useCallback(
    (page: number) => {
      setLoading(true);
      setCurrent(page);
      fetchList(email, page * perPageRecords - perPageRecords)
        .then((res) => {
          const { data, count } = res;
          dispatch(setUserList(data));
          setTotalCount(count);
          setLoading(false);
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
          setLoading(false);
        });
    },
    [fetchList, email, dispatch, perPageRecords]
  );

  const onSearch = useCallback(
    (searchTerm) => {
      setLoading(true);
      setCurrent(1);

      fetchList(searchTerm, 0)
        .then((res) => {
          const { data, count } = res;
          dispatch(setUserList(data));
          setTotalCount(count);
          setLoading(false);
        })
        .catch((err) => {
          console.log('Error caught!', err.message);
          setLoading(false);
        });
    },
    [fetchList, dispatch]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && email != '') {
        onSearch(email);
      }
    },
    [email, onSearch]
  );

  const columns = [
    {
      title: 'Plan',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <ColDiv>
          <div
            className={`${
              record && record.validated === false ? 'customer-fade-out' : ''
            } ${choosePlanColorSwitch(record.choosePlan)}`}
          >
            <div className={choosePlanLabelSwitch(record.choosePlan)}>{record.choosePlan}</div>{' '}
            <Avatar
              size={36}
              shape="square"
              src={
                record.avatar
                  ? record.avatar
                  : 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png'
              }
            />
          </div>
        </ColDiv>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.firstName && b.firstName && a.firstName.localeCompare(b.firstName),
      render: (text, record) => (
        <ColDiv
          className={
            record && record.validated === false
              ? 'customer-fade-out customerMemberListing'
              : 'customerMemberListing'
          }
        >
          {`${record.firstName} ${record.lastName ? record.lastName : ''}`}
        </ColDiv>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email && b.email && a.email.localeCompare(b.email),
      render: (text, record) =>
        record.email ? (
          <ColDiv className={record && record.validated === false ? 'customer-fade-out' : ''}>
            {record.email}
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Time Zone ',
      dataIndex: 'timeZone',
      key: 'timeZone',
      sorter: (a, b) => a.timeZone && b.timeZone && a.timeZone.localeCompare(b.timeZone),
      render: (text, record) =>
        record.timeZone ? (
          <ColDiv className={record && record.validated === false ? 'customer-fade-out' : ''}>
            {record.timeZone}
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Location',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address && b.address && a.address.localeCompare(b.address),
      render: (text, record) =>
        record.address || record.state || record.country ? (
          <ColDiv className={record && record.validated === false ? 'customer-fade-out' : ''}>
            {' '}
            {`${record.address ? record.address : ''} ${record.state ? record.state : ''} ${record.country ? record.country : ''}`}{' '}
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      sorter: (a, b) =>
        a.phoneNumber && b.phoneNumber && a.phoneNumber.localeCompare(b.phoneNumber),
      render: (text, record) =>
        record.phoneNumber ? (
          <ColDiv className={record && record.validated === false ? 'customer-fade-out' : ''}>
            {' '}
            {`${record.phoneCountryCode} ${record.phoneNumber}`}{' '}
          </ColDiv>
        ) : (
          '--'
        )
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        let showColor = '';
        if (record.userStatus === 'Active') {
          showColor = theme.activeColor;
        } else if (record.userStatus === 'Inactive') {
          showColor = theme.inactiveColor;
        } else if (record.userStatus === 'OnHold') {
          showColor = theme.inactiveColor;
        }
        return (
          <ListingBoxActionTabs
            className={props.cardClass}
            onCloseDrawer={onCloseDrawer}
            user={record}
            showColor={showColor}
            role={props.role}
            onIconClick={props.onIconClick}
            loginUserType={props.loginUserType}
            validate={record.validated}
          />
        );
      }
    }
  ];

  const theme = useContext(ThemeContext);
  const { userList = [] } = props.userMemberData;
  return (
    <ListingDiv data-testid="users-list">
      <SearchContainer className="search-box search-user-email" style={{ zIndex: 99 }}>
        <Button onClick={() => (email != '' ? onSearch(email) : null)} className="SearchButton">
          {' '}
          <SearchOutlined />
        </Button>
        <Input
          onKeyPress={handleKeyPress}
          name="search"
          value={email}
          onChange={handleChange}
          placeholder="Search with Email or Name..."
        />
      </SearchContainer>
      <Spin spinning={isLoading}>
        <Table
          columns={columns}
          dataSource={userList}
          onChange={handleChange}
          pagination={false}
          locale={{
            emptyText: (
              <div className="empty-data-table-container-show">
                <div className="empty-data-table-container-image">
                  <img src={EmptyTableData} width="250" />
                </div>
                <div style={{ display: 'block' }}>No users found</div>
              </div>
            )
          }}
        />
      </Spin>
      <Pagination
        current={current}
        total={totalCount}
        pageSize={perPageRecords}
        pageSizeOptions={['50', '100', '200']}
        onChange={onPageChange}
        onShowSizeChange={(current, pagesize) => setperPageRecords(pagesize)}
      />
    </ListingDiv>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    userMemberData: state.userMemberData,
    customerDataList: state.notificationReducer.customerDataList,
    loginUserData: state.userMemberData.loginUserData,
    userList: state.userMemberData.userList
  };
}

export default connect(mapStateToProps)(ListingBox);
