import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import _ReactPlayer, { ReactPlayerProps } from 'react-player';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export default function useVideoPlayerModal(url?: string) {
  const [visible, setVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(url);

  useEffect(() => {
    setVideoUrl(url);
  }, [url]);

  const onClose = () => {
    setVisible(false);
  };
  const close = () => {
    setVisible(false);
  };
  const open = (url?: string) => {
    if (url) {
      setVideoUrl(url);
    }
    setVisible(true);
  };
  const component = (
    videoUrl && (
      <Modal
        visible={visible}
        onCancel={onClose}
        width={1024}
        centered
        bodyStyle={{
          padding: 0,
        }}
        destroyOnClose
        footer={null}
        closeIcon={<></>}
      >
        <ReactPlayer
          url={videoUrl}
          controls
          width="100%"
          height="100%"
          className="video-player"
        />
      </Modal>
    )
  );
  return { open, close, component };
}
