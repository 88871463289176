import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchWorkShopApi } from '../../api/workshop';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import {
  setIsOpenDrawer,
  setSelectedRowData,
  setShowFormData
} from '../../redux/actions/formAction';
import { setAuthorList } from '../../redux/actions/userMemberAction';
import PRIVILEGES from '../../utils/privilege';
import { Mode, Role } from '../../utils/types/role';
import DeepLink from '../common/DeepLink';
import { ColDiv, EditViewIcon } from '../common/styles';

const WorkShopTableComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    workShopData,
    resourceListMode,
    PerPageRecords,
    selectedRowData,
    currentLoginUser
  } = props;
  const [current, setCurrent] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isloading, setisloading] = useState<boolean>(false);
  const fetchAuthorList = useCallback(
    (pageSize: number = 0, skip: number = 0, searchTerm: string) => {
      const options = {
        skip: skip || 0,
        limit: pageSize,
        search: searchTerm
      };
      dispatch(setCustomerLoading(false));
      return searchWorkShopApi(options);
    },
    [dispatch]
  );

  useEffect(() => {
    setisloading(true);
    fetchAuthorList(PerPageRecords, 0, props.searchTerm)
      .then((res) => {
        const { data, count } = res;
        setisloading(false);
        dispatch(setAuthorList(data));
        setCurrent(1);
        setTotalCount(count);
      })
      .catch(() => {
        setisloading(false);
      });
  }, [dispatch, fetchAuthorList]); // eslint-disable-line react-hooks/exhaustive-deps

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'WorkShop'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };
  const canEdit = PRIVILEGES.Workshops.Edit?.includes(currentLoginUser.type as Role);
  const columns = [
    {
      title: 'Topic',
      dataIndex: 'webinarTopic',
      key: 'webinarTopic',
      sorter: (a, b) =>
        a.webinarTopic && b.webinarTopic && a.webinarTopic.localeCompare(b.webinarTopic),
      render: (text, record) => <ColDiv>{record?.webinarTopic}</ColDiv>,
      width: 250
    },
    {
      title: 'Share Link',
      dataIndex: 'externalDeepLink',
      key: 'externalDeepLink',
      sorter: (a, b) =>
        a.externalDeepLink &&
        b.externalDeepLink &&
        a.externalDeepLink.localeCompare(b.externalDeepLink),
      render: (text, record) => (
        <ColDiv>
          <DeepLink
            type="external"
            deepLink={record.externalDeepLink}
            hidden={record.status !== 'Accepted'}
          />
        </ColDiv>
      ),
      width: 200
    },
    {
      title: 'Type',
      dataIndex: 'webinarType',
      key: 'webinarType',
      sorter: (a, b) =>
        a.webinarType && b.webinarType && a.webinarType.localeCompare(b.webinarType),
      render: (text, record) => <ColDiv>{record?.webinarType}</ColDiv>,
      width: 250
    },
    {
      title: 'Presenter#1',
      dataIndex: 'presenter1',
      key: 'presenter1',
      render: (text, record) => <ColDiv>{record?.presenter1 ? record?.presenter1 : ''}</ColDiv>,
      width: 250
    },
    {
      title: 'Presenter#2',
      dataIndex: 'presenter2',
      key: 'presenter2',
      render: (text, record) => <ColDiv>{record?.presenter2 ? record?.presenter2 : ''}</ColDiv>,
      width: 250
    },
    {
      title: 'Promoted',
      dataIndex: 'isPromoted',
      key: 'isPromoted',
      render: (text, record) => <ColDiv>{(record?.isPromoted) ? "Promoted" : ""}</ColDiv>,
      width: 140
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <Space size="middle" className="float-left">
          <EditViewIcon className="autismag-table-action">
            <DeepLink
              type="internal"
              deepLink={record.internalDeepLink}
              hidden={record.status !== 'Accepted'}
            />
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  const onSelectChange = (selectedkeys: any, selectedRows: any) => {
    const { keys, rows } = selectedRowData;
    if (keys.indexOf(selectedkeys) === -1) {
      keys.push(selectedkeys);
      rows.push(selectedRows);
    } else {
      keys.splice(keys.indexOf(selectedkeys), 1);
      rows.splice(
        rows.findIndex((a) => a._id === selectedRows._id),
        1
      );
    }
    dispatch(setSelectedRowData({ keys, rows }));
  };

  return (
    <Spin spinning={isloading}>
      <Table
        className={props.className + (resourceListMode === '' ? ' hide' : '')}
        columns={columns}
        dataSource={workShopData}
        pagination={false}
      />
    </Spin>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(WorkShopTableComponent);
