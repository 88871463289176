import Axios from 'axios';
import { apiGet, apiPost } from '../utils/api';
import { API_URL } from '../constant';

export const getSharedVideos = (userId: string) => apiGet(`${API_URL}sharedVideos/${userId}`);

export const getAllSharedVideoIds = (userId: string) => apiGet(`${API_URL}sharedVideos/${userId}/all`);

export const share = (userId: string, videos: string | string[]) => apiPost(`${API_URL}sharedVideos/${userId}/share`, {
  videos,
});

export const unshare = (userId: string, videos: string | string[]) => apiPost(`${API_URL}sharedVideos/${userId}/unshare`, {
  videos,
});
