import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  LoginContainer,
  LogoContainer,
  Logo,
  VerifyText,
  ForgetButtonContainer,
  LoginButton,
  DownloadAppButton,
} from './styles';
import SiteLogo from '../../images/autism_360_white_new_logo.png';
// import SiteBlackLogo from "../../images/autism_logo_black.svg";
import SiteBlackLogo from '../../images/icons/logo-text.png';

const confirmationText = 'Thanks! Your password has now been reset.Please use your new password to login to the Autism 360™ portal.';

const PasswordConfirmation = (props) => {
  const isMobilebrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { isMobile } = props;
  const history = useHistory();
  const gotoLogin = () => {
    // history.push("/login");
    if (isMobilebrowser) {
      window.open('viaapp://SigninPage');
    } else {
      history.push('/login');
    }
  };

  return (
    <div>
      <LoginContainer>
        <LogoContainer>
          <div className="Signin-container">
            <div className="Background-rectangle-image">
              <div className="RectangleImage" />
            </div>
            <div className="sideContainer">
              <div className="sideMenu">
                <div className="Signin-image">
                  <div className="LogoIcons">
                    <img
                      src={isMobile ? SiteLogo : SiteBlackLogo}
                      style={{ width: '150px' }}
                    />
                  </div>
                  <div className="welcomeText">
                    <div className="SignInText"> Password </div>
                    <div className="autismText"> Confirmation </div>
                    <div className="verificationCredentialsText">
                      {' '}
                      {confirmationText}
                      {' '}
                    </div>
                  </div>
                </div>

                <ForgetButtonContainer className="MainLoginButton mobile-view-login-button">
                  <LoginButton
                    type="primary"
                    className="LoginButton"
                    htmlType="submit"
                    onClick={gotoLogin}
                  >
                    Go to login
                  </LoginButton>
                </ForgetButtonContainer>
              </div>
            </div>
          </div>
        </LogoContainer>
      </LoginContainer>
    </div>
  );
};
function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(PasswordConfirmation);
