import React from 'react';
import { cardItemType } from '../../constant';
import EditUser from '../../images/icons/editProfile.svg';
import PersonDashboard from '../../images/icons/forms.svg';
import ShowPasswordBlack from '../../images/icons/showPasswordBlack.png';
import { Role } from '../../utils/types/role';
import AddUserComponent from '../AddUser/AddUserComponent';
import { ActionContainer, IconBar } from './styles';

interface CardProps {
  user: cardItemType;
  showColor: string;
  role: Role;
  onCloseDrawer: () => void;
  className?: string;
  onIconClick?: any;
  validate?: any;
  loginUserType?: string;
}

export enum IconRole {
  View = 'View',
  Edit = 'Edit',
  User = 'User'
}

const ListingBoxActionTabs: React.FC<CardProps> = (props) => {
  const formType = (role) => {
    switch (role) {
      case Role.Admin:
        return 'Admin';
      case Role.Business:
        return 'Business';
      case Role.Customer:
        return 'Customer';
    }
  };

  const IconList = [
    {
      type: IconRole.View,
      icon: (
        <IconBar className={props.validate === false ? 'customer-fade-out icon-bar' : 'icon-bar'}>
          <AddUserComponent
            onCloseDrawer={props.onCloseDrawer}
            user={props.role}
            id={props.user._id}
            chooseForm="View"
            edit={false}
            icon={<img src={ShowPasswordBlack} key="eye" />}
            formLabel={formType(props.role)}
          />
        </IconBar>
      )
    },
    {
      type: IconRole.Edit,
      icon: (
        <IconBar className={props.validate === false ? 'customer-fade-out icon-bar' : 'icon-bar'}>
          <AddUserComponent
            onCloseDrawer={props.onCloseDrawer}
            user={props.role}
            id={props.user._id}
            chooseForm="Edit"
            edit
            icon={<img src={EditUser} key="edit" />}
            formLabel={formType(props.role)}
          />
        </IconBar>
      )
    },
    {
      type: IconRole.User,
      icon: (
        <IconBar className={props.validate === false ? 'customer-fade-out icon-bar' : 'icon-bar'}>
          <span onClick={() => props.onIconClick(props.user)}>
            <img src={PersonDashboard} key="customer-vew" />
          </span>
        </IconBar>
      )
    }
  ];

  const renderTabs = () => {
    const defaultIcons = IconList.filter((item) => item.type !== IconRole.User).map(
      (elem) => elem.icon
    );
    switch (props.loginUserType) {
      case Role.Admin:
        return IconList.map((item) => item.icon);
      case Role.Business:
        return IconList.filter((item) => item.type !== IconRole.Edit).map((elem) => elem.icon);
      default:
        return defaultIcons;
    }
  };

  return <ActionContainer>{renderTabs()}</ActionContainer>;
};
export default ListingBoxActionTabs;
