import React from 'react';
import { connect } from 'react-redux';
import NotificationList from './NotificationList';

const NotificationCard = (props) => {
  const { notify, onClick, visibly } = props;

  const data = [
    {
      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
      active: true,
    },
    {

      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
      active: false,
    },
    {

      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
      active: false,
    },
    {

      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
      active: true,
    },
    {

      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
      active: false,
    },
    {

      description: 'Ant Design, a design language for background applications, is refined by Ant UED Team',
      active: true,
    },
  ];
  // useEffect(()=>{
  //   dispatch(setNotification(data))
  //   // setInterval(() => dispatch(setNotification(data)), 1000)
  // },[])

  return (
    <div>
      <NotificationList list={notify} onClick={onClick} visibly={visibly} />
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    notificationData: state.customerRuducer.notificationData,
  };
}

export default connect(mapStateToProps)(NotificationCard);
