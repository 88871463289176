import { Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BlockHeader, SettingContainer } from './styles';
import { getRouteLabel } from './utils';

import hamburgerIcon from '../../images/icons/hamburger-icon.svg';
import logoText from '../../images/icons/logo-text.png';
import SearchAndMenuItem from './SearchAndMenuItem';

const { Title } = Typography;

const BrandHeader: React.FC<any> = (props) => {
  const history = useHistory();
  const [routeLabel, setRouteLabel] = useState<string>('');

  const { dispatch, customerAccessData, customerDataList, handleChange, currentLoginUser } = props;

  useEffect(() => {
    const headerPath = getRouteLabel(
      currentLoginUser.type,
      window.location.pathname,
      customerAccessData
    );
    setRouteLabel(headerPath);

    history.listen((location, action) => {
      const headerPathName = getRouteLabel(
        currentLoginUser.type,
        window.location.pathname,
        customerAccessData
      );
      setRouteLabel(headerPathName);
    });
  }, [currentLoginUser._id, customerDataList && customerDataList.avatar]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BlockHeader data-testid="header">
      <div className="mobile-menu">
        <img src={hamburgerIcon} className="hamburgerIcon" onClick={handleChange} />
        <img src={logoText} className="site-logo" />
      </div>
      <SettingContainer className="setting-left">
        <Title level={3} style={{ display: 'inline-Block' }} className="label-mobile-view">
          {routeLabel}
        </Title>
        {/* {userData.type === Role.Customer ? (
          <Tooltip title={"Favorite"}>
            <AddButton onClick={() => bookMarkPage(window.location.pathname)}>
              icon
            </AddButton>
          </Tooltip>
        ) : null} */}
      </SettingContainer>
      <SearchAndMenuItem handleChange={handleChange} />
    </BlockHeader>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerInfo: state.customerRuducer.customerInfo,
    customerAccessData: state.customerRuducer.customerAccessData,
    notificationList: state.notificationReducer.notificationList,
    customerDataList: state.notificationReducer.customerDataList,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(BrandHeader);
