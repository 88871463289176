import React, { useState } from 'react';
import {
  Button, Input, Pagination, Popover, Spin, Tooltip, notification,
} from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CloseOutlined,
  CloseSquareOutlined,
  DeleteFilled,
  DeleteRowOutlined,
  MinusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useCustomerId } from '../../utils/hooks/useCustomerId';
import { getSharedVideos } from '../../api/sharedVideos';
import VideoTableComponent from '../TableData/VideoTableComponent';
import AddVideo from '../AddUser/AddVideo';
import { SearchContainer } from '../ListingComponent/styles';
import useSearchBar from './useSearchBar';
import VideosSearchResult from './VideosSearchResult';
import useShareVideosMutation from './useShareVideosMutation';

const MYVIDEOS_TOOLTIP_INFO = 'Search videos by title, then click Add to make them available in My Videos';

export interface ISharedVideosComponentProps {}

export default function SharedVideosComponent(props: ISharedVideosComponentProps) {
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const client = useQueryClient();
  const customerId = useCustomerId();
  const sharedVideosQuery = useQuery(
    ['sharedVideos', customerId],
    () => getSharedVideos(customerId),
    { enabled: Boolean(customerId) },
  );

  const { data: sharedVideos = [], count: total = 0 } = sharedVideosQuery.data || {};
  const videoList = sharedVideos.map((x) => x.video).filter((video) => Boolean(video));

  const { onUnshare, unShareVideoMutation: { isLoading: unshareLoding } } = useShareVideosMutation(customerId);

  const info = (
    <span className="shrink-0">
      <div className="text-lg font-medium leading-none">
        A place for my videos &ensp;
        <Tooltip title={MYVIDEOS_TOOLTIP_INFO} arrowPointAtCenter={false} placement="topRight">
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      <div>Search and add videos</div>
    </span>
  );

  const [searchTerm, searchBarHolder] = useSearchBar();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const onSelectChange = (selectedKeys: string[]) => {
    setSelectedIds(selectedKeys);
  };

  const onUnshareClick = async () => {
    await onUnshare(selectedIds);
    setSelectedIds([]);
  };
  const removeButton = (
    <Button
      icon={<DeleteFilled />}
      onClick={onUnshareClick}
      type="link"
      shape="round"
      className="bg-slate-700 hover:bg-slate-600 border-slate-700 text-white hover:text-white disabled:border-slate-300"
      disabled={selectedIds.length <= 0 || unshareLoding}
    >
      Remove Selected
    </Button>
  );
  return (
    <div className="resource-mobile-view-container">
      {notificationContextHolder}
      <Spin spinning={sharedVideosQuery.isLoading}>
        <div className="site-layout-background">
          <div className="flex items-center gap-x-8 gap-y-4 flex-wrap px-4">
            {removeButton}
            {searchBarHolder}
            {info}
          </div>
          <VideoTableComponent
            className="resources-table"
            videoList={videoList}
            tableProps={{
              pagination: { pageSize: 5 },
              rowSelection: {
                onChange: onSelectChange,
                selectedRowKeys: selectedIds,
              },
              rowKey: '_id',
            }}
          />
          <AddVideo />
        </div>
      </Spin>
    </div>
  );
}
