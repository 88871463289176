import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, Input, Button, Row, Col, Typography,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;
const FieldBox: React.FC<any> = (props) => {
  // const [form] = Form.useForm();
  const [items, setItems] = useState<any>([
    {
      text: '',
      id: Date.now(),
    },
  ]);

  const {
    SkillList, addData, buttonLabel, viewMode, requiredField, disabled, view, taskcontentLink,
  } = props;
  useEffect(() => {
    if (SkillList && (SkillList.length || SkillList.length === 0)) {
      const modifyList = SkillList.map((elem, index) => ({
        text: elem,
	        id: Date.now() + index,
      }));
      setItems(modifyList);
    }
  }, [SkillList]);

  const setArrayData = (data) => {
    setItems(data);
  };

  const AddItem = (e) => {
    e.preventDefault();
    const newItem = {
      text: '',
      id: Date.now(),
    };
    const newData = items.concat(newItem);
    setItems(newData);
    const modifyData = newData.map((elem) => elem.text);
    addData(modifyData);
  };

  const handleDelete = (index) => {
    const data:any = items.filter((todo, todoIndex) => todoIndex !== index);
    setItems(data);
    const modifyData = data.map((elem) => elem.text);
    addData(modifyData);
  };

  return (
    <>
      <Listing items={items} delete={handleDelete} viewMode={viewMode} setArrayData={setArrayData} addData={addData} requiredField={requiredField} disabled={disabled} view={view} taskcontentLink={taskcontentLink} />
      {view != 'View'
        ? (
          <Button
            type="dashed"
            onClick={AddItem}
            className="add-more-content"
          >
            <PlusOutlined />
            {' '}
            {buttonLabel || 'Add more skills'}
          </Button>
        )
        : null}
    </>
  );
};

const Listing: React.FC<any> = (props) => {
  interface listItemProps {
    id: number,
    text: string
  }

  const [text, setText] = useState<string>('');
  const [value, setValue] = useState<listItemProps>({
    id: 0,
    text: '',
  });

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleOnFocus = (data) => {
    setText(data.text);
    setValue(data);
  };
  const handleOnBlur = () => {
    const dataList = props.items.map((item) => (item.id === value.id ? { ...item, text } : item));
    props.setArrayData(dataList);
    const modifyData = dataList.map((elem) => elem.text);
    props.addData(modifyData);
  };

  return (
    <Row>
      {props.items.map((data: any, index: number) => (

        <>
          <Col xs={!props.viewMode ? 20 : 24} sm={!props.viewMode ? 22 : 24}>
            <Form.Item
              style={{ marginBottom: 12 }}
            >
              {(data.id === value.id) && props.taskcontentLink
                ? (
                  <Input
                    name="text"
                    style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                    placeholder="https://example.com"
                    pattern="https://.*"
                    onClick={() => { props.view === 'View' && text && text.startsWith('https://') && window.open(text, '_blank'); }}
                    value={text}
                    onChange={(e) => { props.view != 'View' && handleChange(e); }}
                    onBlur={handleOnBlur}
                  />
                )
                : (props.taskcontentLink) ? (props.view === 'View'
                  ? <span style={{ pointerEvents: 'auto', cursor: 'pointer', color: '#2a8ff7' }} onClick={() => { data.text && window.open(data.text, '_blank'); }} onFocus={() => handleOnFocus(data)}>{data.text}</span>
                  : (
                    <Input
                      style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                      placeholder="https://example.com"
                      pattern="https://.*"
                      onClick={() => { data.text && window.open(data.text, '_blank'); }}
                      value={data.text}
                      onChange={(e) => props.view != 'View' && handleChange(e)}
                      onFocus={() => handleOnFocus(data)}
                    />
                  ))
                  : (data.id === value.id)
                    ? <Input name="text" value={text} onChange={handleChange} onBlur={handleOnBlur} disabled={props.viewMode === 'View'} />
                    : <Input value={data.text} onChange={handleChange} onFocus={() => handleOnFocus(data)} disabled={props.viewMode === 'View'} />}

            </Form.Item>
          </Col>
          {!props.viewMode
            ? (
              <Col xs={4} sm={2}>
                {(props.items.length > 1) ? <MinusCircleOutlined style={{ padding: 10 }} onClick={(e) => props.delete(index)} /> : null}
              </Col>
            )
            : null}
        </>
      ))}
      {props.requiredField ? <Text style={{ color: '#ff4d4f', paddingTop: 0 }}>Please add some member skills</Text> : null}
    </Row>
  );
};

function mapStateToProps(state: any) {
  return {
    contentLinkList: state.programRuducer.contentLinkList,
  };
}

export default connect(mapStateToProps)(FieldBox);
