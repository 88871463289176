import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import PostReferralLinkModal from './PostReferralLinkModal';

interface PostResponseFormProps {
  activeForm: PostFormState;
  setActiveForm: (value: PostFormState) => void;
  readOnly: boolean;
}

const PostResponseForm = ({ activeForm, setActiveForm, readOnly }: PostResponseFormProps) => {
  const [isRefferalModalOpened, setIsReferralModalOpened] = useState(false);

  const onHandleInput = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleReferralLinkClick = () => {
    setIsReferralModalOpened(true);
  };

  const handleAddReferralLink = (referralLinks: PostFormState['referralLinks']) => {
    const newReferralLinks = [...activeForm.referralLinks];
    referralLinks.forEach((referralLink) => {
      if (
        !activeForm.referralLinks.find(
          (one) => one.internalDeepLink === referralLink.internalDeepLink
        )
      ) {
        newReferralLinks.push(referralLink);
      }
    });
    setActiveForm({ ...activeForm, referralLinks: newReferralLinks });
    setIsReferralModalOpened(false);
  };

  const handleReferralLinkDelete = (referralLink) => {
    const newReferralLinks = activeForm.referralLinks.filter(
      (one: any) => one.title != referralLink.title
    );

    setActiveForm({ ...activeForm, referralLinks: newReferralLinks });
  };

  return (
    <>
      <Form.Item
        label="Body"
        name="body"
        rules={[
          { required: true, message: 'Please input your body!' },
          { max: 2000, message: '2000 characters maximum!' }
        ]}
        className="no-boder time-zone-container-s"
      >
        <Input.TextArea
          name="body"
          onChange={(e) => onHandleInput(e, 'body')}
          style={{ borderBottom: '1px solid #E9E9E9' }}
          readOnly={readOnly}
          rows={6}
        />
      </Form.Item>

      <Form.Item
        label="Referral Links"
        name="referralLinks"
        className="no-boder time-zone-container-s"
      >
        <div className="w-full internal-deeplink-container">
          {activeForm.referralLinks.map((one) => (
            <div className="internal-deeplink mt-2 flex items-center">
              <span>{one.title}</span>
              {!readOnly && (
                <CloseCircleOutlined
                  className="ml-auto cursor-pointer"
                  onClick={() => handleReferralLinkDelete(one)}
                />
              )}
            </div>
          ))}
          {!readOnly && (
            <Button className="btn-border w-full mt-2" onClick={handleReferralLinkClick}>
              + Add Referral Link
            </Button>
          )}
        </div>
      </Form.Item>

      <Form.Item
        name="status"
        fieldKey="status"
        label="Status"
        style={{ borderBottom: '1px solid #E9E9E9' }}
        className="no-boder time-zone-container-s"
      >
        <Select onChange={(value) => onHandleChange('status', value)} disabled={readOnly}>
          <Select.Option value="Draft">Draft</Select.Option>
          <Select.Option value="Published">Published</Select.Option>
          <Select.Option value="Archived">Archived</Select.Option>
        </Select>
      </Form.Item>

      <PostReferralLinkModal
        isOpened={isRefferalModalOpened}
        onClose={() => setIsReferralModalOpened(false)}
        onAddReferralLink={handleAddReferralLink}
      />
    </>
  );
};

export default PostResponseForm;
