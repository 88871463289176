import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import MyNotificationTableComponent from './TableData/MyNotificationTableComponent';
import { CustomerContainer } from './common/styles';

const MyNotificationComponent: React.FC<any> = (props) => {
  const { customerLoading, customerInfo } = props;
  const favouriteList = customerInfo && customerInfo.favourites;

  return (
    <div style={{ marginTop: 20 }}>
      <Spin spinning={customerLoading}>
        <div className="site-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer className="site-notification">
              <MyNotificationTableComponent favouriteList={favouriteList} />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerLoading: state.customerRuducer.customerLoading,
    customerInfo: state.customerRuducer.customerInfo,
  };
}

export default connect(mapStateToProps)(MyNotificationComponent);
