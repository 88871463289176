import styled from 'styled-components';
import { Button, Input } from 'antd';

export const BlockHeader = styled.div`
    width:100%;
    height: 80px;
    box-shadow: 12px 4px 16px rgba(0, 0, 0, 0.08);
    z-index: 999;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    .setting-left {
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
        letter-spacing: 0.1px;
        color: #242C36;
        display: inline-block;
    }

    .mobile-menu {
        display: none;
    }

    @media only screen and (max-width: 1042px){
        display: flex;
        height: 56px;

        .mobile-menu {
            width: 100%;
            display: flex;
            align-items: center;
            padding-left: 20px;

            .hamburgerIcon {
                margin-right: 20px;
            }

            img.site-logo {
                height: 40px;
            }

        }
       
    .collapseHeader{
        border:none;
        background: none;
        
        .ant-collapse-header{
            text-align: right;
            border: 0;
            padding: 6px 12px !important;
            margin-right: 10px;
        }
        .ant-collapse-content > .ant-collapse-content-box {
        padding: 5.5px;
        padding-right: 20px;
    }
    
    .ant-collapse-content {
        float: left;
        width: 100%;
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 999;
    }
    .ant-collapse-content-box{
        float: left;
        width: 100%;
        background: #F8FBFC;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
    }
    .setting-right setting-filter{
        text-align: right;
    }
    }
    
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        position: relative;
        top: 0;
        left: 0;
    }
    .ant-collapse > .ant-collapse-item {
        border-bottom: none !important;
    }
    }
    @media only screen and (max-width: 1042px) {
        margin-bottom: 0px;

        .setting-left{
            position: absolute;
            top: 70px;
            left: 22px;
            
            h3.ant-typography {
                margin-bottom: 0.1em;
                font-size: 22px;
            }
        }
        
    }
    
`;
export const Title = styled.h2`
    margin: 8px 15px !important;
`;

export const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-left: 15px;
`;

export const SettingContainer = styled.div`
    font-size: 20px;
    padding: 20px 15px;
    z-index: 999;
    display:inline-block;
    &.setting-left h3.ant-typography {
        margin: 0px;
        padding-top: 5px;
        padding-left: 10px;
    }
    &.setting-right .active span.anticon {
        color: #4393ff;
    }
    &.setting-filter .ant-select .ant-select-selector{
        background: #F5B04A;
        box-shadow: 0px 4px 4px rgba(29, 29, 45, 0.25);
        border-radius: 20px;
        color:#ffffff;
    }
    &.setting-filter .ant-select .ant-select-selector .ant-select-selection-item{
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
    }
    &.setting-right {
        float:right;
    }
    
    @media only screen and (max-width: 1042px){
        padding: 0px;
        &.setting-filter .ant-select .ant-select-selector{
            background: #F5B04A;
            box-shadow: 0px 4px 4px rgba(29, 29, 45, 0.25);
            border-radius: 20px;
            color:#ffffff;
            margin-top: 0px;
           
        }
        &.setting-left h3.ant-typography {
            margin: 0px;
            padding-top: 0px;
            padding-left: 4px;
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 100%;
            letter-spacing: 0.1px;
            color: #242C36;
        }
        &.setting-filter{
            margin-left: 12px;
            display: flex;
            align-items: center;
            
        }
        &.setting-filter-mobile {
            width: 100%;
            .setting-filter-drop-down .ant-select {
                width: auto;
                overflow: visible;
            }
            h5 {
                margin-top: 8px;
                margin-bottom: 0px;
                color: #242C36;
                margin-right: 10px;
            }
            span:first-child {
                display: flex;
            }
        }
        &.setting-filter-drop-down .ant-select-selector {
            min-width: 100px;
        }
        &.setting-filter .ant-select .ant-select-selector .ant-select-selection-item{
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
        }
       
    }
    @media only screen and (max-width: 1042px) {
        &.setting-filter .ant-select .ant-select-selector{
            background: #F5B04A;
            box-shadow: 0px 4px 4px rgba(29, 29, 45, 0.25);
            border-radius: 20px;
            color:#ffffff;
            margin-top: 0px;
           
        }
        &.setting-filter .ant-select .ant-select-selector .ant-select-selection-item{
            font-family: Mulish;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
        }
       
    }
`;

export const ProfileName = styled.div`
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.2px;
    color: #242C36;

`;

export const AddButton = styled(Button)`
    background: none !important;
    border: none !important;
    color: #333 !important;
    box-shadow: none !important;
    font-size: 20px;
    padding: 0px;
    margin: 3px 10px;
    vertical-align: middle;
`;

export const ProgramAddButton = styled(Button)`
    margin: 8px 15px;
    background: rgb(251 146 60) !important;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 32px;
    color: #FFFFFF !important;
    font-style: normal;
    font-size: .875rem;
    height: 40px;
    border-color: white !important;
    &:hover{
        background: rgb(249 115 22) !important;
    }
`;

export const ProfileButton = styled(Button)`
    background: none !important;
    border: none !important;
    color: #333 !important;
    box-shadow: none !important;
    font-size: 20px;
    padding: 0px;
    margin: 0px 10px;
    margin-right: 0px;
`;

export const SubmitButton = styled(Button)`
    margin-left: 15px;
`;

export const DrawerFooter = styled.div`
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 520px;
    background: #fff;
    border-top: 1px solid #eee;
    z-index: 99;
`;

export const UserLastRow = styled.div`
    padding-bottom: 50px;

    
    .Check-goal-as-primary span.ant-checkbox .ant-checkbox-inner {
        width: 18px;
        height: 18px;
      }
      
    .Check-goal-as-primary span.ant-checkbox-disabled .ant-checkbox-inner {
        width: 25px;
        height: 25px;
      }
      .Check-goal-as-primary span.ant-checkbox-inner::after {
        width: 6px;
        height: 14px;
     }
    .Check-goal-as-primary span.ant-checkbox-checked .ant-checkbox-inner {
        width: 18px;
        height: 18px;
      }

    .right-select {
        // padding-left: 10px;

        @media only screen and (max-width: 992px) {
            padding-left: 0;
        }
    }
`;
export const BackButton = styled.div`
    display: inline-block;
`;
export const UserProfileContainer = styled.div`
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 999;
    right: 29px;
    border-radius: 4px;
    margin-top: -7px;
    @media only screen and (max-width: 1042px) {
        right: 20px;
        top: 70px;
    }

    @media only screen and (max-width: 992px) {
        right: 20px;
        top: 70px;
    }

`;
export const ProfileHeader = styled.header`
    padding: 0px 10px 4px 0px;
    .header-edit-edit{
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 20px;
        margin-left:3px;
        text-align: right;
        color: #1D1D2D;
    }
`;

export const ProfileFooter = styled.footer`
    padding: 10px 15px;
`;
// @ts-ignore
export const PhoneInput = styled(Input)`
    width: 75% !important;
    float: right !important;
    clear: both;
`;

export const RemoveButton = styled(Button)`
    position: absolute;
    top: -5px;
    right: 0px;
    width: 20px;
    padding: 0px;
    background: none !important;
`;

export const UserContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 10px;

    &.add-milestone {
        margin-top: 0;

        button {
            margin: 0 -10px 8px 0;
        }
    }

    // @media only screen and (max-width: 992px) {
    //     display: inline-block !important;
    //     position: absolute;
    //     top: 50px;
    //     right: 0;
    // }
`;
export const ListingDivContainer = styled.div`
    height: 180px;
    overflow-y: auto;
   
    &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
            background: #F3F3F3;
             border-radius: 2px;
           }
           &::-webkit-scrollbar-thumb {
                 border-radius: 10px;
                 background: #E9E9E9;
                 border-radius: 2px;
               }
    &.no-more-tab {
        height: 162px;
    }

    &.task-list-card {
        // height: calc(140px - 22px);
    }
    .ant-checkbox-group .ant-checkbox-group-item {
        display: block;
        margin-bottom: 1em;
    }

    li.ant-list-item{
        margin-right:10px;
    }

    li.ant-list-item:first-child {
        padding-top: 0;
    }

    li.ant-list-item:last-child {
        padding-bottom: 0;
    }

    .completed-task {
        color: green;
        font-weight: 600;
        font-size: 15px;
        text-transform: capitalize;
           
    }

    .pending-task {
        color: red;
        font-weight: 600;
        font-size: 15px;
        text-transform: capitalize;
       
    }
    


`;

export const ChartDiv = styled.div`
    transition: 0.3s;

    .ant-card-head {
        text-align: center;
        border-bottom: none;
        min-height: 40px;
        .ant-card-head-title {
            padding-bottom: 5px;
        }
    }

    .ant-card-body {
        padding-top: 0px;
    }

    .progress-chart {
        margin: auto;
        display: grid;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .progress-chart .ant-progress .ant-progress-inner {
        min-width: 190px !important;
        width: 100% !important;
        height: 100% !important;
    }

    .sub-title {
        text-align: left;
        padding: 0px 0px 15px 0;
         min-height: 40px;
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.1px;
        color: #1D1D2D;
        opacity: 0.5;
    }
     .sub-icon{
        top: 14px;
        position: absolute;
        right: 14px;
     }
     .progress-menu{
         display:flex;
         font-family: Mulish;
         font-style: normal;
         font-weight: normal;
         font-size: 13px;
         line-height: 20px;
         letter-spacing: 0.1px;
         color: #1D1D2D;
         margin-top: 15px;
         .sub-menu{
             span{
                 margin-left:10px;
             }
         }
         .sub-menu-center{
             padding-left:25px;
             padding-right:26px;
             span{
                margin-left:10px;
            }
         }
     }
     .progress-container{
         position:relative
     }
     .progress-sub-list{
         position:absolute;
            top: 63%;
            transform: translate(-50%, -50%);
            left: 50%;
            width: 100%;
            text-align: center;
            font-family: Mulish;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            letter-spacing: 0.1px;
            color: #242C36;
            opacity: 0.5;
     }
    &.hoverable-card:hover {
        position: relative;
        z-index: 1;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
        -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    }
`;

export const ListBoxDiv = styled.div`
    .task-list-div {

    }
`;

export const CustomerContainer = styled.div`
    .ant-col {
        width: 100%;
    }

    .custom-progresBar {
        .ant-progress-circle-path {
            stroke: url(#goalProgressBar);
        }
    }

    .day-progresBar {
        .ant-progress-circle-path {
            stroke: url(#daysProgressBar);
        }
    }
    .My-fav{
        min-height:316px !important;
    }
    .my-tasks{
        min-height : 316px !important;
    }
    .autismag-extra-bottom{
        border-radius: 20px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        margin-right: 10px;
        min-height:300px;
        .ant-card-head{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-bottom: none;
            background: none;
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    text-align:left;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #242C36;
                }
            }
        } 
    }

    .autismag-margin-bottom {
        border-radius: 20px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
        .ant-card-head{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            background: none;
            border-bottom: none;
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    text-align:left;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #242C36;
                }
            }
        }
        .ant-card-bordered{
            border-radius: 20px;
        }
        margin-bottom: 24px;
        margin-right: 10px;
        min-height : 300px;
        @media only screen and (max-width: 992px) {
            .ant-card-bordered{
                border:none;
            }
            .autismag-margin-bottom {
                margin-bottom: 24px;
                margin-right: 10px;
                min-height : 300px;
        }
        .My-fav{
            min-height : 316px !important;
        }
        .my-tasks{
            min-height : 316px !important;
        }
    }
    

    .my-goal-table {

        .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
            padding: 10px 2px !important;
        }

        .ant-table-column-sorters {
            padding: 5px 10px 5px 0px !important;
        }

        .ant-table-expanded-row {

        }

        .ant-table-content {
            td:first-child, th:first-child {
                padding-left: 12px !important;
            }
        }

        @media only screen and (max-width: 992px) {
          .autismag-sidebar{
               .site-card-inner-layout{
                margin-top:24px;
                padding-left: 20px;
                padding-right: 12px;
              }
            }
            .ant-table-content {
                overflow: auto;

                td, th {
                    min-width: 150px;
                }

                td:first-child, th:first-child {
                    min-width: 50px;
                    padding-left: 12px !important;
                }

                td:last-child, th:last-child {
                    min-width: 100px;
                }

                span.lastActivity {
                    min-width: 252px !important;
                }
            }
        }
    }

    .resources-table {

        .ant-table-content {
            td:first-child, th:first-child {
                min-width: 50px !important;
            }
        }

        &.hide {
            .ant-table-selection-column {
                display: none;
            }
        }
    }
`;

export const SidebarCollapseIcon = styled.span`
    display: none;
    background: none !important;
    color: #333 !important;
    box-shadow: none !important;
    font-size: 24px;
    margin: 8px 0;
    position: fixed;
    left: 210px;
    opacity: 1;
    transition: all 0.2s;

    @media only screen and (max-width: 992px) {
        display: block;
        transform: rotate(180deg);

        .anticon.anticon-right-circle {
            background: #021529;
            border: 1px solid #021529;
            border-radius: 50%;
            color: #fff;
            cursor: pointer;
        }
    }
`;

export const OnBoardContainer = styled.div`
    .ant-col {
        width: 100%;
    }
    .autismag-extra-bottom{
        border-radius: 20px;
        .ant-card-head{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    text-align:left;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #242C36;
                }
            }
        } 
    }

    .autismag-margin-bottom {
        border-radius: 20px;
        .ant-card-head{
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            .ant-card-head-wrapper{
                .ant-card-head-title{
                    text-align:left;
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #242C36;
                }
            }
        }
        .ant-card-bordered{
            border-radius: 20px;
        }
        margin-bottom: 24px;
        margin-right: 10px;
        min-height : 300px;
    }

    .onBoard-img {
        max-width: 100px;
        margin: auto;
        text-align: center;
        width: 100%;
        padding: 30px 0;
    }

    .ant-card-meta-detail {
        text-align: center;
    }
`;

export const FormContainer = styled.div`
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 10px;

    // .form-label {
    //     font-weight: bold;
    // }
`;

export const UnderDevelopmentDiv = styled.div`
    height: calc(100vh - 145px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const DefaultButton = styled(Button)`

    &.align-right {
        float: right;
    }
    &.grey {
        background-color: transparent;
        color: rgba(0,0,0,.85);

        &:hover, &:focus {
            background-color: transparent;
            color: rgba(0,0,0,.85);
            border-color: #d9d9d9;
        }
    } 
`;

export const HeaderTabContainer = styled.div`
    display: block;
    margin: 15px 24px;
    overflow: hidden;
    
    @media only screen and (max-width: 992px) {
        margin-top: 26px;
    }
`;

export const ButtonBox = styled.span`
    button:first-child {
        margin-left: 15px;
    }
`;

export const IconSpan = styled.span`
    cursor: pointer;
    padding: 10px 0;
`;

export const CardBottomLink = styled.div`
    overflow: hidden;
    .right-link {
        float: right;
        display: inline-block;
        cursor: pointer;
        font-family: Mulish;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        margin-top:18px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #1D1D2D;
    }

    .right-link:hover {
        color: #0e77d6;
    }
`;

export const ColDiv = styled.div`
    .ant-avatar {
        margin-right: 10px;
    }

    .icons {
        margin-right: 5px;
    }
`;

export const EmptyCol = styled.div`
    text-align: center;
`;

export const CompletedTaskDiv = styled.div`
    position: relative;
    top: -8px;
    text-align: center;
`;

export const MileStoneDiv = styled.div`
    padding: 12px;
`;

export const DashedMessageDiv = styled.div`
    text-align: center;
    padding: 19px 0;
    border: dashed;
    border-color: #e1e5e6;
`;

export const MileStoneTitle = styled.div`
    .add-milestone-task-tab {
        position: absolute;
        right: 10px;
        top: 5px;

        button.ant-btn {
            margin: 4px -2px;
            height: 29px;
            line-height: 1.3;
            padding: 4px 12px;
            font-size: 12px;
        }
    }
`;
export const EditViewIcon = styled.div`

    color: #40a9ff;
    span.anticon {
        margin-right: 25px;
        cursor: pointer;
    }

    &.milestone-view-edit {
        display: inline-block;
        cursor:pointer;
    }
`;

export const DeleteTabDiv = styled.div`
    display: inline-block;
    float: right;
    margin-right: 27px;
    font-size: 20px;
    color: #2a8ff7;
    cursor: pointer;
`;

export const TitleDiv = styled.div`
    position: absolute;
    left: 28px;
    display: inline-block;

    &.milestone-label {
        position: relative;
        left: 0;
    }
`;

export const MultipleFileDiv = styled.div`
    .ant-typography {
        font-family: Mulish;
        font-style: normal;
        font-weight: bolder;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #1D1D2D;
    }

    .autismag-required-field::before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }

    .ant-form-item{
        margin-top: 7px;
    }

    form.ant-form {
        margin-bottom: 20px;
        
        .ant-row {
            margin-bottom: 14px;
        }
    }
`;

export const CommandInputDiv = styled.div`
    .ant-select {
        width: auto !important;
        min-width: 200px;
        max-width: 585px;
    }
`;

export const InputComponentContainer = styled.div`
    .input-box {
        width: auto !important;
        min-width: 200px;
        max-width: 585px;
    }
`;

export const CommentDataDiv = styled.div`
    background: #fff;
    width: auto;
    display: inline-block;
    border: 1px Solid #f0f2f5;
    border-radius: 2px;
    padding: 2px 10px;
    min-width: 200px;

    @media only screen and (max-width: 992px) {
        width: 100%;
    }

    &.comments {
        padding: 8px 10px;
        margin-top: 8px;
        min-height: 59px;

        @media only screen and (max-width: 992px) {
            width: 100%;
        }
    }
`;

export const EmptyInputDiv = styled.div`
    background: #fff;
    width: auto;
    display: inline-block;
    border: 1px Solid #f0f2f5;
    border-radius: 2px;
    padding: 13px 10px;
    max-width: 200px;
    width: 100%;
`;

export const SessionColDiv = styled.div`
    display: inline-block;
    color: #3d3d3d;

    .anticon.anticon-field-time {
        margin-right: 5px;
    }
`;

export const VerticalDivider = styled.div`
    display: inline-block;
    width: 2px;
    background: #565555;
    height: 22px;
    position: absolute;
    margin: 0 11px;
`;

export const StarRatingDiv = styled.div`

    .rating-icon {
        margin: 0 6px;
        font-size: 18px;
        color: #f9c502;
        cursor: pointer;
        display: inline-block;
    }

    .rating-icon:first-child {
        margin-left: 0;
    }

    .rating-icon:last-child {
        margin-left: 0;
    }
`;

export const StarDiv = styled.div`
    min-width: 40px;
    padding: 0 10px;
    width: 30px;

    .icon {
        cursor: pointer;
        font-size: 16px;

        &.active {
            color: #FFC107;
        }
    }
`;
export const LoadMore = styled.div`
        background: none;
        border: none;
        text-decoration-color: #1890ff;
        text-decoration: underline;
        color: #1890ff;
        font-size: 16px;
        font-weight:700;
        cursor:pointer;
`;

export const FilterDiv = styled.div`

    &.search-box {
        width: 100%;
        margin: 0;
        padding-right: 15px;
        display: flex;
        margin-bottom: 24px;
        button.ant-btn .anticon {
            margin-left: 0;
        }
    }

    &.select-box {
        margin-right: 15px;
    }

    &.check-box {
        margin: 5px 0 5px 20px;
    }
`;

export const OrderListDiv = styled.div`
    display: inline-block;
    color: #2a8ff7;
    cursor: pointer;

    .disc-icon {
        padding: 4px 4px;
        margin-right: 15px;
        background: #323232;
        border-radius: 50%;
        display: inline-block;
    }
`;

export const CheckTaskButton = styled(Button)`
    font-family: Mulish;
    height: 22px;
    line-height: 13px;
    padding: 3px 15px;
    background: rgba(255, 88, 89, 0.1);
    border: 1px solid rgba(255, 88, 89, 0.2);
    box-sizing: border-box;
    border-radius: 11px;
    color: #FF5859;
    letter-spacing: 0.1px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
`;
export const IconTab = styled.span`
    color: #1890ff;
    cursor: pointer;
`;

export const DragAndDropBox = styled.div`
    margin-right: 8px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-icon {
        display: inline-block;
        color: inherit;
        font-size: 20px;
        font-style: normal;
        line-height: 0;
        text-align: center;
        text-transform: none;
        vertical-align: -0.125em;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
`;

export const UploadActionTabs = styled.span`
    position: absolute;
    left: 12px;
    top: 32px;
    color: #fff;
    opacity: 0;

    .action-icon {
        padding: 12px;
        cursor: pointer;
    }
`;

export const ImageViewer = styled.div`
    display: inline-block;
    border: 1px dashed #d9d9d9;
    width: 106px;
    height: 106px;

    .action-icon {
        opacity: 1;
    }

    :hover .icons-span {
        opacity: 1;
    }

    .ant-avatar-square {
        width: 104px;
        height: 104px;
    }

    :hover .icon-box {
        overflow: hidden;
        float: left;
        width: 104px;
        height: 104px;
        position: absolute;
        left: 1px;
        top: 1px;
        color: #fff;
        opacity: 0.4;
        background-color: black;
    }
    
`;

export const ErrorText = styled.div`
    color: red;
    padding-top: 10px;
    font-weight: 600;
    padding: 10px 0px;
`;

export const ImageFileName = styled.div`
    display: block;
`;
export const AddedFileName = styled.label`
    font-weight: 900;
    margin-right: 10px;
    color: #00ADEE ;
    cursor: pointer;
    pointer-events: AUTO;
`;
