import { Checkbox, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { getSingleMilestonAPI } from '../../../../api/user-program';
import { MilestoneFormType } from '../../../../constant';
import CrossFormButton from '../../../../images/icons/crossFormButton.svg';
import { setAddTarget } from '../../../../redux/actions/userProgramAction';
import { DrawerFooter, MultipleFileDiv, SubmitButton, UserLastRow } from '../../../common/styles';
import FieldBox from '../../../FormComponent/FieldBox';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const needHelpLabel = 'I need help from other Team member/Coaches to master this skill';
const { Option } = Select;
const { Title, Text } = Typography;

interface MilestoneFormProps {
  currentLoginUser: any;
  milestoneId: string;
  onCloseDrawer: any;
  visible?: boolean;
  dispatch?: any;
  addTargetList?: any;
}

const useGetSingleMilestone = (milestoneId: string): any => {
  const [milestone, setMilestone] = useState(null);
  const getMilestone = async (milestoneId: string): Promise<any> => {
    if (!milestoneId) {
      return;
    }

    try {
      const { data } = await getSingleMilestonAPI(milestoneId);
      setMilestone(data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getMilestone(milestoneId);
  }, [milestoneId]);

  return milestone;
};

const MilestoneForm: React.FC<MilestoneFormProps> = ({
  currentLoginUser,
  milestoneId,
  onCloseDrawer,
  visible,
  dispatch,
  addTargetList
}) => {
  const [activeForm, setActiveForm] = useState<MilestoneFormType>({
    needHelp: false,
    goalName: ''
  });

  const [form] = Form.useForm();
  const [targetsToBeAchieved, settargetsToBeAchieved] = useState<any>([]);
  const milestone = useGetSingleMilestone(milestoneId);

  useEffect(() => {
    if (milestone) {
      const formData = {
        name: milestone.name,
        goalName: milestone.goal.name,
        milestoneType: milestone.milestoneType.name,
        notes: milestone.notes,
        needHelp: milestone.needHelp,
        targetSkills: milestone.targetSkills || [],
        teamMember: (milestone.teamMember || []).map(
          (member) => `${member.firstName} ${member.lastName}`
        )
      };

      form.setFieldsValue(formData);
      setActiveForm(formData);
      settargetsToBeAchieved(milestone.targetsToBeAchieved || []);
    }
  }, [milestone]);

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onCheck = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, needHelp: checked });
  };

  const multiSelectHandleChange = (value: any) => {
    setActiveForm({ ...activeForm, targetSkills: value });
  };
  const addContentSkill = (data: any) => {
    settargetsToBeAchieved(data);
    dispatch(setAddTarget(data));
  };
  const getContentLinkSpecificFields = () => (
    <MultipleFileDiv>
      <Text>What targets need to be achieved?</Text>
      <FieldBox
        fieldMsg="Please input target"
        SkillList={targetsToBeAchieved}
        addData={addContentSkill}
        buttonLabel="Add new Targets"
        viewMode={currentLoginUser.type !== 'BusinessUser'}
      />
    </MultipleFileDiv>
  );
  const formStyle: CSSProperties = {};

  formStyle.pointerEvents = 'none';

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style"
      layout="vertical"
      name="basic"
      initialValues={activeForm}
      onClick={(e) => e.stopPropagation()}
    >
      <Spin spinning={false}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Title level={5} className="titleStyle" />
            <Form.Item
              label="Skill Definition"
              name="name"
              className="no-boder"
              rules={[{ required: true, message: 'Please add name of skill!', whitespace: true }]}
            >
              <Input.TextArea name="name" value={activeForm.name} onChange={onHandleChange} />
            </Form.Item>
            <Row>
              <Col xs={24} sm={12} className="left-select">
                <Form.Item
                  label="What Outcome it belong to?"
                  name="goalName"
                  rules={[{ required: false, message: 'Please select Outcome belong to' }]}
                  className="time-zone-container-s"
                >
                  <Select disabled>
                    <Option value={activeForm.goalName || ''}>{activeForm.goalName}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="right-select">
                <Form.Item
                  label="Skill Type"
                  name="milestoneType"
                  rules={[{ required: true, message: 'Please select your Time Zone!' }]}
                  className="time-zone-container-s"
                >
                  <Select defaultValue={activeForm.milestoneType} disabled>
                    <Option value={activeForm.milestoneType || ''}>
                      {activeForm.milestoneType}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Which targets does this Skill address?"
              name="targetSkills"
              rules={[{ required: true, message: 'Please add target Skills!' }]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                defaultValue={activeForm.targetSkills}
                onChange={multiSelectHandleChange}
              >
                {(activeForm.targetSkills || []).map((skill: string, index: number) => (
                  <Option key={index} value={skill}>
                    {skill}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {(currentLoginUser.type === 'BusinessUser' ||
              currentLoginUser.type === 'Customer' ||
              addTargetList?.length) &&
            ((currentLoginUser.type !== 'BusinessUser' && targetsToBeAchieved.length) ||
              currentLoginUser.type === 'BusinessUser')
              ? getContentLinkSpecificFields()
              : null}
            <Form.Item name="needHelp" label="" className="no-boder">
              <Checkbox
                className="Check-goal-as-primary"
                checked={activeForm.needHelp}
                onChange={onCheck}
              >
                {needHelpLabel}
              </Checkbox>
            </Form.Item>
            {activeForm.needHelp ? (
              <Form.Item
                label="Team Member"
                name="teamMember"
                rules={[{ required: true, message: 'Please add target team member!' }]}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue={activeForm.teamMember}
                >
                  {activeForm.teamMember &&
                    activeForm.teamMember.map((name: any, index: number) => (
                      <Option key={index} value={name}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item
              label="Notes / Comments..."
              name="notes"
              className="no-boder"
              rules={[
                { required: true, message: 'Please input your note or comments!', whitespace: true }
              ]}
            >
              <Input.TextArea
                name="notes"
                onChange={onHandleChange}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="no-boder">
          <SubmitButton
            onClick={onCloseDrawer}
            className={
              visible ? 'close-drawer-button' : 'close-drawer-button hide-close-drawer-button'
            }
          >
            <img src={CrossFormButton} />
          </SubmitButton>
          {/* <SubmitButton type="primary" onClick={onCloseDrawer}>Close</SubmitButton> */}
        </Form.Item>
      </DrawerFooter>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    currentLoginUser: state.userMemberData.currentLoginUser,
    memberGoals: state.programRuducer.memberGoals,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerDataList: state.notificationReducer.customerDataList,
    addTargetList: state.programRuducer.addTargetList,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(MilestoneForm);
