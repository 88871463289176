import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Avatar, message, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { uploadFileAPI, uploadFileWithResizeAPI } from '../../api/file';
import ModalCloser from '../../images/modal-closer.svg';
import { ErrorContainer } from '../../pages/auth/styles';
import { setfileloading } from '../../redux/actions/uploaderAction';
import { FileDropzone } from './FileDropzone';
import { AddedFileName, ImageFileName, ImageViewer, UploadActionTabs } from './styles';

const { confirm } = Modal;

export interface FileUploaderProps {
  onChange: (url: string, name?: string, duration?: number) => void;
  dispatch: (action: any) => void;
  avatarImage?: string;
  errorMessage?: string;
  limit?: number;
  onlyImageAccept?: boolean;
  onlyVideoAccept?: boolean;
  onlyFileAccept?: boolean;
  previewVideo?: boolean;
  type?: string;
  fileName?: string;
  mode: string;
  downloadFile?: boolean;
  isMobile?: boolean;
  resize?: boolean;
}

const FileUploader = (props: FileUploaderProps) => {
  const {
    onChange,
    dispatch,
    avatarImage,
    errorMessage,
    limit,
    onlyImageAccept,
    onlyVideoAccept,
    onlyFileAccept,
    previewVideo,
    isMobile,
    resize = false
  } = props;
  const [image, setImage] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [showImageName, setImageName] = useState<string>('');
  const [formUrl, setformUrl] = useState('');
  const maxSize = limit || 104857600;
  const error = () => {
    message.error(`Your file was larger than ${maxSize / 1048576}MB in file size.`);
  };

  useEffect(() => {
    if (avatarImage) {
      setImage(avatarImage);
    }
  }, [avatarImage]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles[0]) {
        const formData = new FormData();
        setImageName(acceptedFiles[0].name);
        formData.append('file', acceptedFiles[0], acceptedFiles[0].name);
        setLoading(true);
        if (onlyFileAccept) dispatch(setfileloading(true));
        if (formData) {
          (resize ? uploadFileWithResizeAPI : uploadFileAPI)(formData)
            .then((res) => {
              setLoading(false);
              dispatch(setfileloading(false));
              setImage(res.public_url);
              if (onChange) {
                onChange(res.public_url, acceptedFiles[0].name, res.duration);
                setTimeout(() => message.success('File uploaded successfully'), 1000);
              }
            })
            .catch(() => {
              setLoading(false);
              dispatch(setfileloading(false));
              message.error('Something went wrong please try again later');
            });
        }
      } else {
        error();
      }
    },

    [onChange]
  );

  // const showDeleteConfirm =() => {
  // confirm({
  //   title: 'Do you really want to delete this image?',
  //   icon: <ExclamationCircleOutlined />,
  //   content: '',
  //   okText: 'Yes',
  //   okType: 'danger',
  //   cancelText: 'No',
  //   onOk() {
  //     setImage("");
  //     if(onChange){
  //       onChange("")
  //     }
  //     message.success(`Image removed successfully`)
  //   },
  //   onCancel() {
  //     // console.log('Cancel');
  //   },
  // });
  // }

  const deleteImage = () => {
    // showDeleteConfirm()
    setImage('');
    if (onChange) {
      onChange('');
    }
    message.success('File removed successfully');
  };

  const dropzoneProps: any = { onDrop, maxSize } as any;
  if (onlyImageAccept) {
    dropzoneProps.accept = { 'image/*': [] };
  }
  if (onlyVideoAccept) {
    dropzoneProps.accept = { 'video/*': [] };
  }
  if (onlyFileAccept) {
    dropzoneProps.accept = {
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    };
  }
  const startDownload = () => {
    const url = image;
    window.open(url, 'Download');
  };
  const handleCancel = () => {
    setPreviewModal(false);
    setformUrl('');
    document.getElementById('hidePointerInputFile')?.setAttribute('src', '');
  };
  const handlePreviewVideo = (image) => {
    setPreviewModal(true);
    setformUrl(image);
    document.getElementById('hidePointerInputFile')?.setAttribute('src', image);
  };
  return image ? (
    <>
      {props.type === 'resources' ? (
        <span>
          <AddedFileName onClick={() => startDownload()}>{props.fileName}</AddedFileName>
          {props.mode !== 'View' && (
            <DeleteOutlined onClick={deleteImage} className="action-icon" />
          )}
        </span>
      ) : (
        <>
          <ImageViewer className="view-avatar">
            {props.downloadFile ? (
              <a href="#" onClick={() => startDownload()}>
                <Avatar shape="square" src={image} />
              </a>
            ) : (
              <Avatar shape="square" src={image} />
            )}
            <div className="icon-box">
              <UploadActionTabs className="icons-span">
                {previewVideo ? (
                  <EyeOutlined className="action-icon" onClick={() => handlePreviewVideo(image)} />
                ) : (
                  <a href={image} target="_blank" rel="noopener noreferrer">
                    <EyeOutlined className="action-icon" />
                  </a>
                )}
                {props.mode !== 'View' && (
                  <DeleteOutlined onClick={deleteImage} className="action-icon" />
                )}
                {/* <DeleteOutlined onClick={deleteImage} className="action-icon" /> */}
              </UploadActionTabs>
            </div>
          </ImageViewer>
          <ImageFileName>{showImageName}</ImageFileName>
        </>
      )}
      <Modal
        maskClosable={false}
        className="onboardFormsModal videoPlayModal"
        title={
          <div className="add-task-title-container">
            {' '}
            <div
              onClick={handleCancel}
              className={props.isMobile ? 'add-task-close-button' : 'add-task-close-button'}
            >
              <img src={ModalCloser} />
            </div>
          </div>
        }
        visible={previewModal}
        closable={false}
        onCancel={handleCancel}
      >
        {onlyVideoAccept ? (
          <video
            controls
            controlsList="nodownload"
            className="video-play-modal"
            id="hidePointerInputFile"
            width="100%"
            height="100%"
            src={formUrl}
          />
        ) : (
          <img className="video-play-modal" width="100%" height="100%" src={formUrl} />
        )}
      </Modal>
    </>
  ) : (
    <>
      <FileDropzone
        className="edit-user-upload-profile-photo w-[106px] h-[106px]"
        loading={loading}
        onDrop={onDrop}
        maxSize={maxSize}
        accept={dropzoneProps.accept}
      />

      {errorMessage !== '' ? (
        <ErrorContainer className="file-error">{errorMessage}</ErrorContainer>
      ) : null}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    state
  };
}

export default connect(mapStateToProps)(FileUploader);
