import { MinusOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Pagination, Table, TableColumnType, Tag } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getAllSharedVideoIds } from '../../api/sharedVideos';
import { useCustomerId } from '../../utils/hooks/useCustomerId';
import { ColDiv } from '../common/styles';
import useShareVideosMutation from './useShareVideosMutation';
import useVideoSearch from './useVideoSearch';

export interface IVideosSearchResultProps {
  searchTerm?: string;
}

export default function VideosSearchResult(props: IVideosSearchResultProps) {
  const { searchTerm = '' } = props;

  const customerId = useCustomerId();
  const {
    onShare,
    onUnshare,
    shareVideoMutation: { isLoading: shareLoading },
    unShareVideoMutation: { isLoading: unshareLoading }
  } = useShareVideosMutation(customerId);

  const { data: allVideos = [] } = useQuery(
    ['all-shared-videos', customerId],
    () => getAllSharedVideoIds(customerId),
    {
      enabled: Boolean(customerId)
    }
  );
  const allSharedVideoIds = allVideos.map((x) => x.video);

  const { data, pagination, setPagination, isFetching } = useVideoSearch(searchTerm);
  const { data: videoData = [], count = 0 } = data || {};

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onSelectChange = (selectedKeys, selectedRows) => {
    setSelectedIds(selectedKeys);
  };

  const isAllSelectedUnshared =
    selectedIds.length > 0 && selectedIds.every((id) => !allSharedVideoIds.includes(id));
  const isAllSelectedShared =
    selectedIds.length > 0 && selectedIds.every((id) => allSharedVideoIds.includes(id));

  const columns: TableColumnType<any>[] = [
    {
      title: 'Video',
      dataIndex: '',
      key: 'thumbnail',
      width: 150,
      render: (text, record) => (
        <ColDiv>
          {record.thumbnail ? (
            <img
              style={{ cursor: 'pointer' }}
              alt="thumbnail"
              width="90px"
              height="60px"
              src={record.thumbnail}
            />
          ) : (
            ''
          )}
        </ColDiv>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 400
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      render: (value, record) => {
        const shared = allSharedVideoIds.includes(record._id);
        if (shared) return <Tag color="lime">Shared</Tag>;
        return <Tag color="blue">{record.status}</Tag>;
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (_, record) => {
        const shared = allSharedVideoIds.includes(record._id);
        return (
          <>
            {!shared ? (
              <Button
                icon={<ShareAltOutlined />}
                type="text"
                disabled={shareLoading}
                onClick={() => onShare(record._id)}
              />
            ) : (
              <Button
                icon={<MinusOutlined />}
                type="text"
                disabled={unshareLoading}
                onClick={() => onUnshare(record._id)}
              />
            )}
          </>
        );
      }
    }
  ];
  const paginationConfig = {
    onChange: (page, pageSize = 10) => {
      setPagination({ pageSize, current: page });
    },
    current: pagination.current,
    total: count,
    pageSize: pagination.pageSize
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={videoData}
        scroll={{ y: 'calc(100vh - 22rem)' }}
        pagination={false}
        rowSelection={{
          onChange: onSelectChange,
          selectedRowKeys: selectedIds
        }}
        rowKey="_id"
      />
      <div className="flex justify-between mt-4">
        {isAllSelectedShared ? (
          <Button
            icon={<MinusOutlined />}
            type="default"
            shape="round"
            disabled={unshareLoading}
            onClick={() => onUnshare(selectedIds)}
          >
            Remove
          </Button>
        ) : (
          <Button
            icon={<ShareAltOutlined />}
            type="ghost"
            shape="round"
            disabled={!isAllSelectedUnshared || shareLoading}
            onClick={() => onShare(selectedIds)}
          >
            Add
          </Button>
        )}

        <Pagination {...paginationConfig} />
      </div>
    </>
  );
}
