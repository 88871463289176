import { useMutation, useQueryClient } from 'react-query';
import {
  share as shareVideoApi,
  unshare as unshareVideoApi,
} from '../../api/sharedVideos';

export default function useShareVideosMutation(customerId: string) {
  const client = useQueryClient();
  const shareVideoMutation = useMutation(['share-video'], (videos: string | string[]) => shareVideoApi(customerId, videos));
  const unShareVideoMutation = useMutation(['unshare-video'], (videos: string | string[]) => unshareVideoApi(customerId, videos));

  const refetch = () => {
    client.refetchQueries(['sharedVideos', customerId]);
    client.refetchQueries(['all-shared-videos', customerId]);
  };
  const onShare = async (videos: string | string[]) => {
    await shareVideoMutation.mutateAsync(videos);
    refetch();
  };
  const onUnshare = async (videos: string | string[]) => {
    await unShareVideoMutation.mutateAsync(videos);
    refetch();
  };

  return {
    shareVideoMutation, onShare, unShareVideoMutation, onUnshare,
  };
}
