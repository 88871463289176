import { useMutation, useQueryClient } from 'react-query';
import {
  share as shareResourceApi,
  unshare as unshareResourceApi,
} from '../../api/resources';

export default function useShareResourcesMutation(memberId: string, sharedCurPage: number) {
  const client = useQueryClient();
  const shareResourceMutation = useMutation(['share-resource'], (resources: string | string[]) => shareResourceApi(memberId, resources));
  const unShareResourceMutation = useMutation(['unshare-resource'], (resources: string | string[]) => unshareResourceApi(memberId, resources));

  const refetch = () => {
    client.refetchQueries(['sharedResources', memberId, sharedCurPage]);
  };
  const onShare = async (resources: string | string[]) => {
    await shareResourceMutation.mutateAsync(resources);
    refetch();
  };
  const onUnshare = async (resources: string | string[]) => {
    await unShareResourceMutation.mutateAsync(resources);
    refetch();
  };

  return {
    shareResourceMutation, onShare, unShareResourceMutation, onUnshare,
  };
}
