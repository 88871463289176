import {
  SET_NOTIFICATION_ALERT, ADD_NOTIFICATION_ALERT, PUT_NOTIFICATION_ALERT, GET_NOTIFICATION_ALERT, SESSION_VISIBLE, NOTIFICATION_VISIBLE, GET_CUSTOMER_API, WIZARD_VISIBLE,
} from '../types/types';

const INITIAL_STATE = {
  notificationList: [],
  visible: false,
  sessionVisible: false,
  wizardVisible: false,
  customerDataList: {},
};

const notificationReducer = (state = INITIAL_STATE, action: { type: any, payload: any }) => {
  switch (action.type) {
  case SET_NOTIFICATION_ALERT:
    return {
      ...state, notificationList: action.payload,
    };
  case ADD_NOTIFICATION_ALERT:
    return {
      ...state, notificationList: action.payload,
    };
    // return {
    //     ...state, notificationList: [action.payload, ...state.notificationList]
    // }
  case GET_NOTIFICATION_ALERT: {
    return {
      ...state, notificationList: action.payload,
    };
  }
  case PUT_NOTIFICATION_ALERT: {
    return {
      ...state, notificationList: action.payload,
    };
  }
  case NOTIFICATION_VISIBLE: {
    return {
      ...state, visible: action.payload,
    };
  }
  case SESSION_VISIBLE: {
    return {
      ...state, sessionVisible: action.payload,
    };
  }
  case WIZARD_VISIBLE: {
    return {
      ...state, wizardVisible: action.payload,
    };
  }
  case GET_CUSTOMER_API: {
    return {
      ...state, customerDataList: { ...action.payload },
    };
  }
  default:
    return state;
  }
};

export default notificationReducer;
