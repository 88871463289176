import React from 'react';
import { List, Button } from 'antd';

interface WizardStepButtonProps {
  text: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  timezoneStyle?:string;
  uploadfileStyle?:string;
}

interface WizardStepProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  buttonProps: WizardStepButtonProps;
}

const WizardStep: React.FC<WizardStepProps> = ({
  icon,
  title,
  description,
  buttonProps: {
    text, onClick, disabled, timezoneStyle, uploadfileStyle,
  },
}) => (
  <List.Item>
    <List.Item.Meta avatar={icon} title={title} description={description} />
    <Button onClick={onClick} disabled={disabled} className={disabled ? 'disabled' : timezoneStyle ? `wizard-modal-button ${timezoneStyle}` : uploadfileStyle ? `wizard-modal-upload-button ${uploadfileStyle}` : 'wizard-modal-button'}>
      {text}
    </Button>
  </List.Item>
);

export default WizardStep;
