import { Drawer, Spin } from 'antd';
import React, { ReactChild, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getMilestoneTypesApi, getTaskTypesApi } from '../../api/user-program';
import { RoleType, UserType } from '../../constant';
import Plus from '../../images/Plus_White.svg';
import { Role } from '../../utils/types/role';
import { ProgramAddButton } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';
import TaskForm from '../FormComponent/TaskForm';

interface AddUserProps {
  user: Role;
  icon?: ReactChild;
  taskId?: string;
  edit?: boolean;
  chooseForm?: string;
  onCloseDrawer?: (newTask?: any) => void;
  goalFormType?: string;
  formTypeLabel?: string;
  addButtonLabel?: string;
  taskData?: any;
  mileStoneData?: any;
  getGoalsData?: any;
  notifyVisible?: boolean;
  propVisible?: boolean;
  createdByOther?: string;
  isMobile?: any;
  isSession?: any;
  currentLoginUser: any;
}

export interface goalTypeListType {
  title: string;
  value: string;
}

const AddTasks: React.FC<AddUserProps> = (props) => {
  const { propVisible, isMobile, isSession, currentLoginUser } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<Role>(props.user);
  const [mileStoneTypes, setMileStoneTypes] = useState<any>([]);
  const [taskTypes, setTaskTypes] = useState<any>([]);

  useEffect(() => {
    setRole(props.user);
    setLoading(true);
    getMilestoneTypesApi().then((res) => {
      setMileStoneTypes(res.data);
      setLoading(false);
    });
    getTaskTypesApi().then((res) => {
      setTaskTypes(res.data);
      setLoading(false);
    });
    // getStrategyApi().then(res => {
    //     setStrategyTypes(res.data)
    // })
  }, [props.user]);

  const openNotification = (title, content) => {
    showNotificationDrawer(title, content);
  };

  const closeDrawer = (title, content, newTask = {}) => {
    setVisible(false);
    if (title) {
      openNotification(title, content);
    }

    if (props.onCloseDrawer) {
      props.onCloseDrawer(newTask);
    }
  };

  let showTitle = '';

  switch (props.chooseForm) {
    case 'Edit':
    case 'View':
      showTitle = props.chooseForm;
      break;
    default:
      showTitle = 'Create';
  }

  const openDrawar = (e: any) => {
    e.stopPropagation();
    setVisible(true);
  };

  if (currentLoginUser.type === Role.Admin && showTitle === 'Edit') {
    return null;
  }

  return (
    <>
      {props.icon ? (
        <span onClick={openDrawar}>{props.icon}</span>
      ) : !props.notifyVisible ? (
        <ProgramAddButton
          className="button-add-task"
          icon={
            <img
              src={Plus}
              className={isMobile ? 'Plus-Icon-mobile-view ' : 'Plus-Icon-Desktop-View'}
            />
          }
          onClick={openDrawar}
        >
          {props.addButtonLabel}
        </ProgramAddButton>
      ) : null}

      <Drawer
        maskClosable={false}
        title={`${`${showTitle} ${props.formTypeLabel}`} Form`}
        placement="right"
        closable={false}
        visible={visible || propVisible}
        width="560"
        className={props.propVisible ? 'user-drawer ' : 'user-drawer'}
        onClose={() => closeDrawer('', '')}
      >
        <Spin spinning={isLoading}>
          <TaskForm
            mileStoneTypes={mileStoneTypes}
            taskTypesList={taskTypes}
            roleType={RoleType}
            userType={UserType}
            close={closeDrawer}
            cancel={() => closeDrawer('', '')}
            userRole={role}
            taskId={props.taskId}
            editMode={props.edit}
            viewMode={showTitle}
            visible={visible || propVisible}
            isSession={isSession}
            goalFormType={props.goalFormType}
            strategyTypes={[
              'AAC',
              'Visual System',
              'Social Story',
              'Modelling strategy',
              'Peer mediated intervention and Instructio',
              'Sensory regulation strategy',
              'Shaping',
              'Discrete trial training',
              'Natural environment training',
              'Oral motor exercise',
              'Flashcards',
              'Visual timer',
              'Mindset work',
              'Community access plan',
              'Others'
            ]}
            taskData={props.taskData}
            mileStoneData={props.mileStoneData}
            getGoalsData={props.getGoalsData}
            createdByOther={props.createdByOther}
          />
        </Spin>
      </Drawer>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(AddTasks);
