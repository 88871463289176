import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getAuthor = () => apiGet(`${API_URL}author`);

export const postAuthor = (data) => apiPost(`${API_URL}author`, data);

export const patchAuthor = (id, data) => apiPatch(`${API_URL}author/${id}`, data);

export const deleteAuthor = (id) => apiDelete(`${API_URL}author/${id}`);

export const searchAuthorApi = (options) => apiGet(`${API_URL}author`, options);

export const authorDetailsApi = (id) => apiGet(`${API_URL}author/${id}`);
