import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Checkbox, Space, Table, Tag } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchVideoApi } from '../../api/video';
import { setCustomerLoading } from '../../redux/actions/customerAction';
import {
  setIsOpenDrawer,
  setSelectedRowData,
  setShowFormData
} from '../../redux/actions/formAction';
import { setVideoList } from '../../redux/actions/userMemberAction';
import { getResizedImageName } from '../../utils/file';
import useVideoPlayerModal from '../../utils/hooks/useVideoPlayerModal';
import PRIVILEGES from '../../utils/privilege';
import { Mode, Role } from '../../utils/types/role';
import DeepLink from '../common/DeepLink';
import { ColDiv, EditViewIcon } from '../common/styles';

const colors = ['tag1-style', 'tag2-style', 'tag3-style', 'tag4-style', 'tag5-style'];

const VideoTableComponent: React.FC<any> = (props) => {
  const {
    dispatch,
    videoList = [],
    resourceListMode,
    setPagination,
    pagination,
    searchTerm,
    selectedRowData,
    customActions,
    tableProps = {},
    currentLoginUser
  } = props;
  const [current, setCurrent] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [formUrl, setformUrl] = useState('');
  const [videotitle, setvideotitle] = useState('');

  const { component: videoPlayerComponent, open: openVideoPlayer } = useVideoPlayerModal(formUrl);
  // const { customerLoading } = userMemberData;
  const fetchVideoList = useCallback((option, searchTerm: string) => {
    const options = {
      skip: option?.current * option?.pageSize - option?.pageSize,
      limit: option?.pageSize,
      search: searchTerm
    };
    dispatch(setCustomerLoading(true));
    return searchVideoApi(options);
  }, []);
  // const fetchAuthorList = useCallback((searchTerm: string = "", isActive) => {
  //     dispatch(setCustomerLoading(false))
  //     return getAuthor()
  // }, [dispatch]);

  useEffect(() => {
    fetchVideoList(pagination, searchTerm)
      .then((res) => {
        const { data, count } = res;
        dispatch(setVideoList(data));
        setPagination({
          ...pagination,
          total: count
        });
        setCurrent(1);
        setTotalCount(count);
        dispatch(setCustomerLoading(false));
      })
      .catch((err) => {});
  }, [dispatch, fetchVideoList]);

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Video'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const handleCancel = () => {
    setformUrl('');
    document.getElementById('hidePointerInput')?.setAttribute('src', '');
  };

  const handlethumbnailclick = (video: any, title: string) => {
    setformUrl(video);
    document.getElementById('hidePointerInput')?.setAttribute('src', video);
    setvideotitle(title);
    openVideoPlayer();
  };
  const onSelectChange = (selectedkeys: any, selectedRows: any) => {
    const { keys, rows } = selectedRowData;
    if (keys.indexOf(selectedkeys) === -1) {
      keys.push(selectedkeys);
      rows.push(selectedRows);
    } else {
      keys.splice(keys.indexOf(selectedkeys), 1);
      rows.splice(
        rows.findIndex((a) => a._id === selectedRows._id),
        1
      );
    }
    dispatch(setSelectedRowData({ keys, rows }));
  };

  const canEdit = PRIVILEGES.Videos.Edit?.includes(currentLoginUser.type as Role);

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Checkbox
          onChange={() => onSelectChange(record._id, record)}
          checked={selectedRowData.keys.indexOf(record._id) !== -1}
        />
      )
    },
    {
      title: 'Video',
      dataIndex: '',
      key: 'video',
      width: 300,
      render: (text, record) => (
        <ColDiv>
          {record.thumbnail ? (
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => handlethumbnailclick(record.video, record.title)}
              src={getResizedImageName(record.thumbnail, '400x400')}
              alt="thumbnail"
              width="180px"
              height="120px"
            />
          ) : (
            ''
          )}
        </ColDiv>
      )
    },
    {
      title: 'Share Link',
      dataIndex: 'externalDeepLink',
      key: 'externalDeepLink',
      width: 250,
      sorter: (a, b) =>
        a.externalDeepLink &&
        b.externalDeepLink &&
        a.externalDeepLink.localeCompare(b.externalDeepLink),
      render: (text, record) => (
        <ColDiv>
          <DeepLink
            type="external"
            deepLink={record.externalDeepLink}
            hidden={record.status !== 'Published'}
          />
        </ColDiv>
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
      sorter: (a, b) => a.title && b.title && a.title.localeCompare(b.title),
      render: (text, record) => <ColDiv>{record.title}</ColDiv>
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
      width: 250,
      render: (text, record) => (
        <ColDiv>
          {record.categories?.map((category, index) => (
            <Tag className={`${colors[index % 5]} video-tag-style`}>{category.name}</Tag>
          ))}
        </ColDiv>
      )
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      render: (text, record) => (
        <ColDiv>
          {record.tags?.map((i, index) => (
            <Tag className={`${colors[index % 5]} video-tag-style`}>{i.name}</Tag>
          ))}
        </ColDiv>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 250,
      render: (text, record) => <ColDiv>{record.status}</ColDiv>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 250,
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <DeepLink
              type="internal"
              deepLink={record.internalDeepLink}
              hidden={record.status !== 'Published'}
            />
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
            {customActions && customActions(record)}
          </EditViewIcon>
        </Space>
      )
    }
  ];
  const modifyColumn =
    resourceListMode === ''
      ? columns.filter((item, index) => index !== 0)
      : columns.filter((elem) => elem.title !== 'Favourites' && elem.title !== 'Action');
  return (
    <>
      <Table
        className={props.className + (resourceListMode === '' ? ' hide' : '')}
        columns={modifyColumn}
        dataSource={videoList}
        pagination={false}
        scroll={{ x: 1000 }}
        {...tableProps}
      />

      {videoPlayerComponent}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer,
    resourcesList: state.formReducer.resourcesList,
    favResourcesList: state.formReducer.favResourcesList,
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    authorList: state.userMemberData.authorList,
    customerLoading: state.customerRuducer.customerLoading,
    isMobile: state.trackReducer.isMobile,
    currentLoginUser: state.userMemberData.currentLoginUser
  };
}

export default connect(mapStateToProps)(VideoTableComponent);
