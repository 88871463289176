import { Checkbox, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import { postNotificationApi } from '../../api/notification';
import {
  addGoalApi,
  getComplexityApi,
  getGoalsApi,
  getSkillsApi,
  updateGoalApi
} from '../../api/user-program';
import { GoalFormType, StatusProps, userMemberDataType } from '../../constant';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import DownArrow from '../../images/icons/down arrrow.svg';
import { setNotificationAlert } from '../../redux/actions/notificationAction';
import {
  setComplexityList,
  setMemberGoals,
  setSkillList,
  setTargetSkillList
} from '../../redux/actions/userProgramAction';
import { Role } from '../../utils/types/role';
import RichTextComponent from '../common/RichTextComponent';
import { DrawerFooter, MultipleFileDiv, SubmitButton, UserLastRow } from '../common/styles';
import FieldBox from './FieldBox';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

const { Option } = Select;
const { Title, Text } = Typography;

interface GoalFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  userId?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  userMemberData: userMemberDataType;
  customerSelectedMember?: any;
  memberGoals?: any;
  programSkills?: any;
  programComlexityList?: any;
  targetSkillList?: any;
  customerDataList?: any;
  status: StatusProps[];
  comment?: string;
}

const GoalForm: React.FC<GoalFormProps> = (props) => {
  const [activeForm, setActiveForm] = useState<GoalFormType>({
    type: props.userRole,
    neededSkills: ['']
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [goalStatus, setGoalStatus] = useState<any>([]);
  const [requiredField, setRequiredField] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {
    dispatch,
    memberGoals,
    userId,
    userRole,
    visible,
    programSkills,
    programComlexityList,
    viewMode,
    customerSelectedMember,
    userMemberData,
    customerDataList,
    status
  } = props;

  const selectedGoal = memberGoals && memberGoals.filter((goal) => goal._id === userId);
  const selectedGoalInfo = selectedGoal && selectedGoal.length && selectedGoal[0];

  const defaultTypeOfGoal = userId
    ? selectedGoalInfo && selectedGoalInfo.typeOfGoal && selectedGoalInfo.typeOfGoal._id
    : '';
  const defaultComplexity = userId
    ? selectedGoalInfo && selectedGoalInfo.complexity && selectedGoalInfo.complexity._id
    : '';

  useEffect(() => {
    getSkillsApi().then((res) => {
      dispatch(setSkillList(res.data));
    });

    getComplexityApi().then((res) => {
      dispatch(setComplexityList(res.data));
    });

    if (viewMode === 'Create' && !userId) {
      const createData = {
        type: userRole,
        neededSkills: [''],
        name: '',
        isPrimary: false,
        typeOfGoal: '',
        // complexity : '',
        performanceEvaluation: '',
        membersBaseLineSkills: '',
        challenges: '',
        status: 'Planned'
      };
      dispatch(setTargetSkillList(createData.neededSkills));
      form.setFieldsValue(createData);
      setActiveForm(createData);
    }

    if (userId && visible) {
      const data = selectedGoalInfo;
      if (data) {
        const viewData = {
          member: data && data.member,
          name: data && data.name,
          // description: data.description,
          isPrimary: data && data.isPrimary,
          typeOfGoal: data.typeOfGoal && data.typeOfGoal._id,
          complexity: data.complexity && data.complexity._id,
          performanceEvaluation: data && data.performanceEvaluation,
          neededSkills: data && data.neededSkills,
          membersBaseLineSkills: data && data.membersBaseLineSkills,
          challenges: data && data.challenges,
          status:
            (data && data?.status === 'Pending') ||
            data?.status === 'Complete' ||
            data?.status === 'Active'
              ? 'Planned'
              : data?.status,
          comment: data && data.comment
        };

        dispatch(setTargetSkillList(data && data.neededSkills));
        form.setFieldsValue(viewData);
        setActiveForm(viewData);
        if (form && form.setFieldsValue) {
          form.setFieldsValue(activeForm);
        }
      }
    }
  }, [dispatch, userId, userRole, memberGoals, visible, form, selectedGoalInfo, viewMode]);

  const onReset = (e: any) => {
    e.stopPropagation();
    dispatch(setTargetSkillList(['']));
    form.setFieldsValue({
      ...activeForm,
      neededSkills: ['']
    });
    setActiveForm({
      ...activeForm,
      neededSkills: ['']
    });
    setLoading(false);
    props.cancel();
  };

  const handleChange = (name: string, value: string) => {
    form.setFieldsValue({ [name]: value });
    setActiveForm({ ...activeForm, [name]: value });
    setGoalStatus({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onCheck = (e: any) => {
    const { checked } = e.target;
    setActiveForm({ ...activeForm, isPrimary: checked });
  };

  const onSubmit = () => {
    const { customerSelectedMember, targetSkillList } = props;
    const skillData = targetSkillList;
    if ((targetSkillList && !targetSkillList.length) || targetSkillList[0] === '') {
      setRequiredField(true);
      return;
    }

    setRequiredField(false);

    const goalData = {
      ...activeForm,
      member: customerSelectedMember[0]._id,
      neededSkills: skillData
    };

    setLoading(true);
    if (props.userId) {
      updateGoalApi(props.userId, {
        ...activeForm,

        neededSkills: skillData
      })
        .then((res) => {
          setGoalStatus(res && res.data && res.data.Status);
          getGoalsApi(res.data.member).then((res) => {
            dispatch(setMemberGoals(res.data));
            const notificationGoal = {
              member: `${customerSelectedMember.length ? customerSelectedMember[0]._id : customerSelectedMember._id}`,
              businessUser: `${userMemberData.loginUserData._id}`,
              message: `${customerDataList && customerDataList.firstName} just updated this Outcome`,
              user: `${customerSelectedMember[0].parent}`,
              goal: res.data._id,
              type: 'GOAL_UPDATED'
            };

            postNotificationApi(notificationGoal).then((res) => {
              dispatch(setNotificationAlert(res.data));
            });
          });
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Success', 'Outcome information updated successfully!');
          }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    } else {
      addGoalApi(goalData)
        .then((res) => {
          getGoalsApi(res.data.member).then((res) => {
            dispatch(setMemberGoals(res.data));
          });
          const notificationGoal = {
            member: `${customerSelectedMember.length ? customerSelectedMember[0]._id : customerSelectedMember._id}`,
            businessUser: `${userMemberData.loginUserData._id}`,
            message: `${customerDataList && customerDataList.firstName} has added a new Outcome for ${customerSelectedMember[0].firstName}`,
            user: `${customerSelectedMember[0].parent}`,
            goal: res.data._id,
            type: 'GOAL_ADDED'
          };

          postNotificationApi(notificationGoal).then((res) => {
            dispatch(setNotificationAlert(res.data));
          });
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Success', 'Outcome created successfully!');
          }
          // if (!isLoading) {
          //     props.close(`${userMemberData.loginUserData.firstName}`, " has added a new Goal for", `${customerSelectedMember[0].firstName}`);
          // }
        })
        .catch((err) => {
          setLoading(false);
          setActiveForm({});
          form.resetFields();
          if (!isLoading) {
            props.close('Error', err.response.data.message);
          }
        });
    }
  };

  const addNeedSkill = (data: any) => {
    dispatch(setTargetSkillList(data));
  };

  const onHandleRichTxt = (value: any) => {
    setActiveForm({ ...activeForm, membersBaseLineSkills: value });
  };

  const getUserSpecificFields = () => {
    const { targetSkillList = [] } = props;
    return (
      <MultipleFileDiv>
        <Text className="autismag-required-field">To Achieve Outcome</Text>
        <FieldBox
          fieldLabel=""
          fieldName="neededSkills"
          requiredField={requiredField}
          fieldMsg="Please input some needed skills"
          SkillList={targetSkillList}
          addData={addNeedSkill}
          viewMode={props.viewMode === 'View'}
          dataId={userId}
        />
      </MultipleFileDiv>
    );
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }
  const StatusColorSwitch = (status_color) => {
    switch (status_color) {
      case 'Achieved':
        return 'achieved-color';
      case 'In-progress':
        return 'inProgress-color';
      case 'Planned':
        return 'planned-color';
      case 'On-hold':
        return 'on-hold-color';
      case 'Cancelled':
        return 'cancelled-color';
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style goal-form-style"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
      onClick={(e) => e.stopPropagation()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Title level={5} className="titleStyle">
              Outcome Definition
            </Title>
            <Form.Item
              label="What Are we Trying to Achieve?"
              name="name"
              rules={[{ required: true, message: 'Please add name of input!', whitespace: true }]}
            >
              <Input
                style={{ borderBottom: '1px solid #E9E9E9' }}
                name="name"
                value={activeForm.name}
                onChange={(e) => onHandleChange(e, 'name')}
              />
            </Form.Item>
            <Form.Item
              name="isPrimary"
              label=""
              className="no-boder"
              rules={[{ required: false, message: 'Please check as the box!' }]}
            >
              <Checkbox
                className="Check-goal-as-primary"
                onChange={onCheck}
                checked={activeForm.isPrimary}
              >
                Primary
              </Checkbox>
            </Form.Item>

            <Row>
              <Col xs={24} sm={12} className="left-select">
                <Form.Item
                  label="Type of Outcome"
                  name="typeOfGoal"
                  rules={[{ required: true, message: 'Please select your Type of goal!' }]}
                  className="time-zone-container-s"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    value={form.getFieldValue('typeOfGoal')}
                    defaultValue={defaultTypeOfGoal}
                    onChange={(value) => handleChange('typeOfGoal', value)}
                  >
                    {programSkills.map((data: any, index: number) => (
                      <Option key={index} value={data._id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                  <img src={DownArrow} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="right-select">
                <Form.Item
                  label="Complexity"
                  name="complexity"
                  // rules={[{ required: true, message: 'Please select your Complexity!'}]}
                  className="time-zone-container-s"
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    value={form.getFieldValue('complexity')}
                    defaultValue={defaultComplexity && defaultComplexity}
                    onChange={(value) => handleChange('complexity', value)}
                  >
                    {programComlexityList.map((data: any, index: number) => (
                      <Option key={index} value={data._id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                  <img src={DownArrow} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Outcome Description"
              name="membersBaseLineSkills"
              // rules={[{ required: true, message: 'Please add skill description!', whitespace: true }]}
            >
              <RichTextComponent
                className="rich-text-div"
                onChange={onHandleRichTxt}
                data={activeForm.membersBaseLineSkills ? activeForm.membersBaseLineSkills : ''}
              />
            </Form.Item>

            <Title level={5} className="titleStyle">
              Success Criteria
            </Title>

            {getUserSpecificFields()}

            <Form.Item
              label="How will you measure progress"
              name="performanceEvaluation"
              className="no-boder"
              rules={[{ required: true, message: 'Please input your progress!', whitespace: true }]}
            >
              <Input.TextArea
                name="performanceEvaluation"
                value={activeForm.performanceEvaluation}
                onChange={(e) => onHandleChange(e, 'performanceEvaluation')}
              />
            </Form.Item>

            <Form.Item
              label="Also Mention any Potential health risk/hazards/alerts, if required notify Manager"
              name="challenges"
              className="no-boder"
            >
              <Input.TextArea
                name="challenges"
                value={activeForm.challenges}
                onChange={(e) => onHandleChange(e, 'challenges')}
              />
            </Form.Item>
          </Col>
          <Row style={{ position: 'relative' }}>
            <Col span={16} className="edit-profile-user-status-field">
              <Form.Item
                label="Status"
                name="status"
                className="no-boder"
                rules={[{ required: true, message: 'Please select your User Status!' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  defaultValue="Planned"
                  onChange={(value) => handleChange('status', value)}
                >
                  {props.status.map((data: StatusProps, index: number) => (
                    <Option key={index} value={data.label}>
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {form.getFieldValue('status') === 'Cancelled' ||
          form.getFieldValue('status') === 'On-hold' ||
          form.getFieldValue('status') === 'Achieved' ? (
            <Form.Item
              label="Comments"
              name="comment"
              className="no-boder"
              rules={[{ required: true, message: 'leave your comment!', whitespace: true }]}
            >
              <Input.TextArea
                name="comment"
                value={activeForm?.comment}
                onChange={(e) => onHandleChange(e, 'comment')}
              />
            </Form.Item>
          ) : null}
        </UserLastRow>
        <DrawerFooter className="drawer-footer-style drawerButton">
          <Form.Item className="no-boder">
            {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            ) : (
              <SubmitButton
                onClick={onReset}
                className={
                  props.visible
                    ? 'close-drawer-button'
                    : 'close-drawer-button hide-close-drawer-button'
                }
              >
                <img src={CrossFormButton} />
              </SubmitButton>
            )}
            {props.viewMode === 'Edit'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary">
                    Save
                  </SubmitButton>
                )
              : props.viewMode === 'Create'
                ? props.visible && (
                    <SubmitButton id="goal-submit-diabled" htmlType="submit" type="primary">
                      Submit
                    </SubmitButton>
                  )
                : null}
          </Form.Item>
        </DrawerFooter>
      </Spin>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    memberGoals: state.programRuducer.memberGoals,
    programSkills: state.programRuducer.programSkills,
    programComlexityList: state.programRuducer.programComlexityList,
    targetSkillList: state.programRuducer.targetSkillList,
    customerDataList: state.notificationReducer.customerDataList
  };
}

export default connect(mapStateToProps)(GoalForm);
