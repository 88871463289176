import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSProperties } from 'styled-components';

import {
  addResourceObjective,
  deleteResourceObjective,
  getResourceObjectiveById,
  updateResourceObjective
} from '../../api/resources';
import { FormReduxState } from '../../redux/reducers/formReducer';
import { ResourceObjectiveReduxState } from '../../redux/reducers/resourceObjectiveReducer';
import { RootState } from '../../redux/store';
import { Mode } from '../../utils/types/role';
import { DeleteTabDiv, SubmitButton, UserLastRow } from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

interface ResourceObjectiveFormProps {
  onClose: () => void;
  onSubmit: (pagination) => void;
}

const ResourceObjectiveForm: React.FC<ResourceObjectiveFormProps> = ({ onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const { showFormData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);
  const { id: objectiveId, formMode } = showFormData;
  const { pagination } = useSelector<RootState, ResourceObjectiveReduxState>(
    (state) => state.resourceObjectiveReducer
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (objectiveId) {
      (async () => {
        try {
          const res = await getResourceObjectiveById(objectiveId);
          form.setFieldsValue(res.data);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      form.resetFields();
    }
  }, [showFormData]);

  const onReset = () => {
    form.resetFields();

    onClose();
  };

  const handleChange = (name: string, value: any) => {
    form.setFields([{ name, value }]);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = form.getFieldsValue();

    if (formMode === Mode.Create) {
      addResourceObjective(formData)
        .then((res: any) => {
          const { data } = res;
          setIsLoading(false);
          showNotificationDrawer('Success', 'Video category added successfully!');

          onReset();
          onSubmit(pagination);
        })
        .catch((err) => {
          setIsLoading(false);
          showNotificationDrawer('Error', err?.response?.data?.message);

          onReset();
        });
    }

    if (formMode === Mode.Edit) {
      updateResourceObjective(objectiveId, formData)
        .then((res) => {
          setIsLoading(false);
          showNotificationDrawer('Success', 'Video category Updated successfully!');

          onReset();
          onSubmit(pagination);
        })
        .catch((err) => {
          setIsLoading(false);
          showNotificationDrawer('Error', err?.response?.data?.message);

          onReset();
        });
    }
  };

  const handleResourceObjectiveDelete = (id: string) => {
    setIsLoading(true);
    deleteResourceObjective(id)
      .then((res) => {
        setIsLoading(false);
        showNotificationDrawer('Success', 'Video category deleted successfully!');

        onReset();
        onSubmit(pagination);
      })
      .catch((err) => {
        setIsLoading(false);
        showNotificationDrawer('Error', err?.response?.data?.message);

        onReset();
      });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this resource objective?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleResourceObjectiveDelete(id);
      },
      onCancel() {}
    });
  };

  const formStyle: CSSProperties = {};
  if (formMode === 'View') {
    formStyle.pointerEvents = 'none';
  }

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form manage-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={handleSubmit}
      // initialValues={form.getFieldsValue()}
    >
      <Spin spinning={isLoading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: 'Please input your category name!', whitespace: true }
              ]}
            >
              <Input
                name="name"
                onChange={(e) => handleChange('name', e.target.value)}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <div className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {formMode === Mode.Edit ? (
            <div className="flex gap-x-4 items-center">
              <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
                Save
              </SubmitButton>
              <DeleteTabDiv className="ml-5 mt-2" onClick={() => showDeleteConfirm(objectiveId)}>
                <DeleteOutlined />
              </DeleteTabDiv>
            </div>
          ) : formMode === Mode.Create ? (
            <SubmitButton htmlType="submit" type="primary" disabled={isLoading}>
              Submit
            </SubmitButton>
          ) : null}
        </Form.Item>
      </div>
    </Form>
  );
};

export default ResourceObjectiveForm;
