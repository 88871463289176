import React from 'react';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import ModalCloser from '../../images/modal-closer.svg';

const OnBoardingModal: React.FC<any> = (props) => {
  const {
    handleCancel, convertToQueryString, renderParentFormInput, renderChildFormInput, visible, showFormType, formUrl, wizardFormStyle, wizardButtonStyle, isMobile,
  } = props;

  return (
    <Modal
      maskClosable={false}
      className={`onboardFormsModal ${wizardFormStyle && wizardFormStyle}`}
      title={(
        <div className="add-task-title-container">
          {showFormType}
          {' '}
          <div onClick={handleCancel} className={isMobile ? 'add-task-close-button' : `add-task-close-button ${wizardButtonStyle && wizardButtonStyle}`}><img src={ModalCloser} /></div>
        </div>
      )}
      visible={visible}
      closable={false}
      onCancel={handleCancel}
    >
      <iframe id="hidePointerInput" width="100%" height="470" frameBorder="0" src={`${formUrl}?${convertToQueryString(showFormType === 'Parent Onboarding Profile' ? renderParentFormInput() : renderChildFormInput())}`} />
    </Modal>

  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerDataList: state.notificationReducer.customerDataList,
    selectCustomer: state.customerRuducer.selectCustomer,
    loginUserData: state.userMemberData.loginUserData,
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(OnBoardingModal);
