import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function debounce<T extends Function>(func: T, delay?: number): T {
  let timer;
  const callable = (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(args);
    }, delay || 1000);
  };
  return <T>(<any>callable);
}

export default useDebounce;
