import { Drawer } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import ResourcesForm from '../FormComponent/ResourcesForm';

interface AddUserProps {
  isOpenDrawer?: boolean;
  showFormData?: any;
  dispatch?: any;
  pagination?: any;
  activeForm?: any;
}

export interface goalTypeListType {
  title: string;
  value: string;
}

const AddResources: React.FC<AddUserProps> = (props) => {
  const { dispatch, isOpenDrawer, showFormData, pagination, activeForm } = props;

  const closeDrawer = () => {
    dispatch(setIsOpenDrawer(false));
  };

  return (
    <Drawer
      maskClosable={false}
      title={`${`${showFormData.formMode} ${showFormData.formLabel}`} Form`}
      placement="right"
      closable={false}
      visible={isOpenDrawer}
      width="520"
      className="user-drawer"
      onClose={closeDrawer}
    >
      <ResourcesForm
        close={closeDrawer}
        cancel={() => dispatch(setIsOpenDrawer(false))}
        userRole={showFormData.userType}
        resourceID={showFormData.id}
        editMode={showFormData.edit}
        viewMode={showFormData.formMode}
        visible={isOpenDrawer}
        pagination={pagination}
        filterForm={activeForm}
      />
    </Drawer>
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer
  };
}

export default connect(mapStateToProps)(AddResources);
