import { useSelector } from 'react-redux';
import { userMemberDataType } from '../../constant';
import { CustomerState } from '../../redux/reducers/customerRuducer';
import { RootState } from '../../redux/store';
import { Role } from '../types/role';

export const useCustomerId = () => {
  const { userLocalData } = useSelector<RootState, CustomerState>((state) => state.customerRuducer);
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const clientId =
    currentLoginUser?.type === Role.Customer ? currentLoginUser?._id : (userLocalData as any)._id;
  return clientId;
};

export const useMemberId = () => {
  const { customerSelectedMember } = useSelector<RootState, CustomerState>(
    (state) => state.customerRuducer
  );
  const member = customerSelectedMember?.at(0);

  return member?._id;
};
