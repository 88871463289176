import React, { useEffect, useState } from 'react';
import { Typography, Spin } from 'antd';
import { UnderDevelopmentDiv } from './common/styles';

const { Title } = Typography;

const UnderDevelopmentComponent: React.FC = () => {
  const [isLoader, setIsLoader] = useState<boolean>(false);

  useEffect(() => {
    setIsLoader(true);
    setTimeout(
      () => {
        setIsLoader(false);
      },
      1500,
    );
  }, []);

  return (
    <div>
      <div className="site-layout-background">
        <Spin spinning={isLoader}>
          <UnderDevelopmentDiv>
            <Title>Webpage is under development</Title>
          </UnderDevelopmentDiv>
        </Spin>
      </div>
    </div>
  );
};

export default UnderDevelopmentComponent;
