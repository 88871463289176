import styled from 'styled-components';

export const SelectTypeContainer = styled.div`
   width: 200px;
   padding: 10px 14px 15px 14px;

   .ant-select-selector {
      min-width: 172px;
   }

   @media only screen and (max-width: 992px) {
      padding: 10px 0 15px 0;
      width: 100%;
      

      .ant-select {
         width: 100%;
         overflow: hidden;
     }
   }
`;

export const SelectTypeContainerInline = styled.div`
   display: inline;
   padding: 10px 14px 15px 14px;

   .ant-select-selector {
      min-width: 132px;
   }

   @media only screen and (max-width: 992px) {
      padding: 0px 0 0px 10px;
      width: 100%;
      position: relative;
      top: 0px;

      .ant-select {
         width: 100%;
         overflow: hidden;
     }
   }
`;
