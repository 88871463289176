import { Image } from 'antd';
import React from 'react';

import QRCodeImage from '../images/qr_code_with_phone.png';

const MyLearningComponent: React.FC<any> = (props) => (
  <div className="my-learning-container py-6 px-12 h-full">
    <div className="block sm:flex h-full">
      <div className="flex justify-center items-center">
        <Image width="60%" src={QRCodeImage} preview={false} />
      </div>
      <div className="text-center sm:text-left mt-12 sm:my-auto">
        <h2 className="heading">Looking for more?</h2>
        <p className="description">Scan the QR code and download the Autism360 app</p>
      </div>
    </div>
  </div>
);

export default MyLearningComponent;
