import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, message, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { connect } from 'react-redux';
import { downloadFileAPI } from '../../api/file';
import { API_URL } from '../../constant';
import { setResourceListMode, setSelectedRowData } from '../../redux/actions/formAction';
import { setFileLinkList } from '../../redux/actions/userProgramAction';
import useVideoPlayerModal from '../../utils/hooks/useVideoPlayerModal';
import { Mode } from '../../utils/types/role';
import { OrderListDiv } from '../common/styles';
import VideoComponent from '../VideoComponent';

const VideoFiles: React.FC<any> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const {
    dispatch,
    itemList,
    addData,
    buttonLabel,
    viewMode,
    selectedRowData,
    view,
    customerDataList,
    filterVideoList
  } = props;
  const { keys, rows } = selectedRowData;

  const { open: openVideoPlayer, component: videoPlayerComponent } = useVideoPlayerModal();
  const success = () => {
    message.success('file downloaded successfully');
  };

  useEffect(() => {
    if (itemList && (itemList.length || itemList.length === 0)) {
      setItems(itemList);
    } else {
      setItems([]);
    }
  }, [itemList]);

  const AddItem = (e) => {
    e.preventDefault();
    setItems(keys);
    addData(keys);
    hideModal();
  };

  const handleDelete = (itemId) => {
    const data: any = items.filter((todo) => todo !== itemId);
    setItems(data);
    addData(data);
  };

  const showModal = () => {
    const filteredRows = rows.filter((x) => itemList.includes(x._id));
    dispatch(setSelectedRowData({ keys: itemList, rows: filteredRows }));
    dispatch(setResourceListMode(Mode.Create));
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    dispatch(setResourceListMode(''));
  };

  const onCancelModal = () => {
    hideModal();
  };

  const downloadFile = (fileUrl: string) => {
    if (fileUrl) {
      const data = {
        url: fileUrl
      };
      setLoading(true);
      downloadFileAPI(data).then((res) => {
        const url = `${API_URL}static/${res.fileName}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(this.response);
          const tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = res.fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);
          setLoading(false);
          success();
        };
        xhr.send();
      });
    }
  };
  // openDrawar={openResorceDrawar}
  return (
    <>
      <Spin spinning={isLoading} />
      <Listing
        dispatch={dispatch}
        itemList={itemList}
        items={items}
        isLoading={isLoading}
        customerDataList={customerDataList}
        downloadFile={downloadFile}
        selectedRow={rows}
        delete={handleDelete}
        viewMode={viewMode}
        addData={addData}
        onClickItem={(item) => {
          openVideoPlayer(item.video);
        }}
      />
      {!viewMode ? (
        <>
          {view != 'View' && (
            <Button type="dashed" onClick={showModal} className="add-more-content">
              <PlusOutlined /> {buttonLabel || 'Add more Target skills'}
            </Button>
          )}
          <Modal
            title="Videos"
            visible={isOpen}
            onOk={AddItem}
            className="wide-modal"
            onCancel={onCancelModal}
          >
            <Form.Item
              name="notes"
              rules={[{ required: false, message: 'Please input your app details!' }]}
            >
              <VideoComponent addskillresource filterVideoList={filterVideoList} />
              {/* <ResourcesComponent addskillresource={true} onlyDisplayActiveResources={true}/> */}
            </Form.Item>
          </Modal>
        </>
      ) : null}
      {videoPlayerComponent}
    </>
  );
};

const Listing: React.FC<any> = (props) => {
  const {
    selectedRow,
    dispatch,
    items,
    itemList,
    downloadFile,
    customerDataList,
    isLoading,
    onClickItem
  } = props;
  const onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = [...selectedRow];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    dispatch(setFileLinkList(items.map((i: any) => i._id)));
    const filteredRows = selectedRow.filter((x) => itemList.includes(x._id));
    dispatch(setSelectedRowData({ keys: itemList, rows: items }));
  };
  const modifyItem = selectedRow.filter((word) => items.includes(word._id)) || [];
  if (modifyItem.length) {
    return (
      <Row>
        <Col xs={24}>
          {
            /* @ts-ignore */
            <ReactDragListView nodeSelector=".ant-list-item.draggble" onDragEnd={onDragEnd}>
              <List
                itemLayout="horizontal"
                dataSource={modifyItem}
                renderItem={(item: any) => {
                  const draggble = item !== 'Racing car sprays burning fuel into crowd.';
                  return (
                    <List.Item
                      className={draggble ? 'draggble' : ''}
                      style={{ cursor: !props.viewMode ? 'pointer' : 'auto' }}
                      onClick={() => {
                        onClickItem && onClickItem(item);
                      }}
                    >
                      {/* <Col xs={!props.viewMode ? 20 : 24} sm={!props.viewMode ? 22 : 24}> */}
                      {/* <Form.Item
                                            style={{ marginBottom: 12, pointerEvents: "auto" }}
                                        > */}
                      <OrderListDiv>
                        {' '}
                        <span className="disc-icon" />
                        {item.title}
                      </OrderListDiv>
                      {/* </Form.Item> */}
                      {/* </Col> */}
                      {!props.viewMode ? (
                        <Col xs={4} sm={2}>
                          <DeleteOutlined
                            style={{ padding: 10 }}
                            onClick={(e) => props.delete(item._id)}
                          />
                        </Col>
                      ) : null}
                    </List.Item>
                  );
                }}
              />
            </ReactDragListView>
          }
        </Col>
      </Row>
    );
  }
  return (
    <Row>
      <Col xs={24}>{null}</Col>
    </Row>
  );
};

function mapStateToProps(state: any) {
  return {
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    customerDataList: state.notificationReducer.customerDataList
  };
}

export default connect(mapStateToProps)(VideoFiles);
