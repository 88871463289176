import { Button, Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AvatarAssetTypes, userMemberDataType } from '../../constant';
import { AssetEvent } from './AssetEvent/AssetEventsComponent';

import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetsAPI } from '../../api/asset';
import { setIsOpenDrawer, setShowFormData } from '../../redux/actions/formAction';
import { RootState } from '../../redux/store';
import PRIVILEGES from '../../utils/privilege';
import { Mode } from '../../utils/types/role';
import { AddButton, CustomerContainer } from '../common/styles';
import './Avatar.scss';
import AssetsModal from './components/AssetsModal';
import AssetsTableComponent from './components/AssetsTableComponent';

export interface Asset {
  _id: string;
  type: 'Eyes' | 'Mouth' | 'Hair' | 'Accessories';
  name: string;
  svg: string;
  fixColour: boolean;
  colour: string;
  event: AssetEvent;
  status: 'Draft' | 'Published' | 'Hidden';
}

const AvatarComponent = () => {
  const dispatch = useDispatch();
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const [assetType, setAssetType] = useState<Asset['type']>('Eyes');
  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0
  });

  const canCreate = PRIVILEGES.Assets.Create?.includes(currentLoginUser.type);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchAssets();
      setIsLoading(false);
    })();
  }, [pagination.page, pagination.pageSize, assetType]);

  const handleTabChange = (key: Asset['type']) => {
    setAssetType(key);
    setPagination({
      ...pagination,
      page: 1
    });
  };

  const fetchAssets = async () => {
    const res = await getAssetsAPI(assetType, pagination);
    const { data, count } = res;
    setAssets(data);
    setPagination((prev) => ({
      ...prev,
      total: count
    }));
  };

  const openDrawar = (e: any) => {
    e.stopPropagation();

    const createFormData = {
      id: '',
      userType: currentLoginUser.type,
      formMode: Mode.Create,
      formLabel: 'Asset'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onPageChange = (page: number, pageSize?: number) => {
    const newPagination = { ...pagination, page, pageSize: pageSize || pagination.pageSize };
    console.log('newPagination =>', newPagination);
    setPagination(newPagination);
  };

  return (
    <div className="avatar-container site-layout-background mt-8">
      <div className="flex">
        {AvatarAssetTypes.map((type) => (
          <Button
            key={type}
            className={`btn-outlined-orange w-[100px] ${assetType === type ? 'active' : ''}`}
            onClick={() => handleTabChange(type)}
          >
            {type}
          </Button>
        ))}
        <Link
          to="/dashboard/avatar/events"
          className="rounded border-w-2 border border-solid ml-auto mr-6 p-1"
        >
          View Events
        </Link>
        {canCreate && (
          <AddButton type="primary" onClick={openDrawar}>
            <PlusOutlined />
          </AddButton>
        )}
        <AssetsModal onSubmit={fetchAssets} />
      </div>
      <Spin spinning={isLoading}>
        <div className="site-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer>
              <AssetsTableComponent className="resources-table" assets={assets} />
              <Pagination
                current={pagination.page}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={onPageChange}
                className="my-pagination"
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default AvatarComponent;
