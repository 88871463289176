import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getVideoCategoriesAPI = (options?) => apiGet(`${API_URL}videoCategory`, options);

export const getVideoCategoryByIdAPI = (id) => apiGet(`${API_URL}videoCategory/${id}`);

export const postVideoCategoryAPI = (data) => apiPost(`${API_URL}videoCategory`, data);

export const patchVideoCategoryAPI = (id, data) => apiPatch(`${API_URL}videoCategory/${id}`, data);

export const deleteVideoCategoriesAPI = (id) => apiDelete(`${API_URL}videoCategory`, { id });
