import { SET_HOME_CATEGORY_LIST, SET_HOME_CATEGORY_PAGINATION } from '../types/types';

export function setHomeCategoryList(data) {
  return {
    type: SET_HOME_CATEGORY_LIST,
    payload: data
  };
}

export function setHomeCategoryPagination(data) {
  return {
    type: SET_HOME_CATEGORY_PAGINATION,
    payload: data
  };
}
