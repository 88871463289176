import { useSelector } from 'react-redux';
import { userMemberDataType } from '../../constant';
import { RootState } from '../../redux/store';

export const useLoginUser = () => {
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  return currentLoginUser;
};
