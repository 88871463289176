import {
  apiPost, apiGet, apiPatch, apiDelete,
} from '../utils/api';
import { API_URL } from '../constant';

export const getGoalsApi = (memberId) => apiGet(`${API_URL}goal/${memberId}`);

export const addGoalApi = (data) => apiPost(`${API_URL}goal`, data);

export const updateGoalApi = (id, data) => apiPatch(`${API_URL}goal/${id}`, data);

export const getSkillsApi = () => apiGet(`${API_URL}skill`);

export const CreateSkillsApi = (data) => apiPost(`${API_URL}skill`, data);

export const getComplexityApi = () => apiGet(`${API_URL}complexity`);

export const CreateComplexityApi = (data) => apiPost(`${API_URL}complexity`, data);

export const addMilestoneApi = (data) => apiPost(`${API_URL}milestone`, data);

export const getMilestoneApi = (id) => apiGet(`${API_URL}milestone/${id}`);

export const getSingleMilestonAPI = (milestonId: string): Promise<any> => apiGet(`${API_URL}milestone/details/${milestonId}`);

export const updateMilestoneApi = (id, data) => apiPatch(`${API_URL}milestone/${id}`, data);

export const deleteMilestoneApi = (id, data) => apiDelete(`${API_URL}milestone/${id}`, data);

export const CreateMilestoneTypeApi = (data) => apiPost(`${API_URL}milestoneType`, data);

export const getMilestoneTypesApi = () => apiGet(`${API_URL}milestoneType`);

export const getTaskTypesApi = () => apiGet(`${API_URL}taskType`);

export const CreateTaskTypeApi = (data) => apiPost(`${API_URL}taskType`, data);

export const getTaskApi = () => apiGet(`${API_URL}task`);

export const getTaskDetailsApi = (id) => apiGet(`${API_URL}task/details/${id}`);

export const addTaskApi = (data) => apiPost(`${API_URL}task`, data);

export const updateTaskApi = (id, data) => apiPatch(`${API_URL}task/${id}`, data);

export const deleteTaskApi = (id, data) => apiDelete(`${API_URL}task/${id}`, data);

export const updateTaskStatusApi = (id, data) => apiPatch(`${API_URL}task/status/${id}`, data);

export const CreateStrategyApi = (data) => apiPost(`${API_URL}strategy`, data);

export const getStrategyApi = () => apiGet(`${API_URL}strategy`);
