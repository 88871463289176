import {
  SET_NOTIFICATION_ALERT, ADD_NOTIFICATION_ALERT, GET_NOTIFICATION_ALERT, SESSION_VISIBLE, PUT_NOTIFICATION_ALERT, NOTIFICATION_VISIBLE, GET_CUSTOMER_API, WIZARD_VISIBLE,
} from '../types/types';

export function setNotificationAlert(data: Array<any>) {
  return {
    type: SET_NOTIFICATION_ALERT,
    payload: data,
  };
}
export function addNotificationAlert(data: Array<any>) {
  return {
    type: ADD_NOTIFICATION_ALERT,
    payload: data,
  };
}
export function getNotificationAlert(data: Array<any>) {
  return {
    type: GET_NOTIFICATION_ALERT,
    payload: data,
  };
}
export function putNotificationAlert(data: Array<any>) {
  return {
    type: PUT_NOTIFICATION_ALERT,
    payload: data,
  };
}
export function notificationVisible(data:boolean) {
  return {
    type: NOTIFICATION_VISIBLE,
    payload: data,
  };
}
export function sessionVisibleDrawer(data:boolean) {
  return {
    type: SESSION_VISIBLE,
    payload: data,
  };
}
export function wizardVisibleDrawer(data:boolean) {
  return {
    type: WIZARD_VISIBLE,
    payload: data,
  };
}
export function getCustomerApi(data: Object) {
  return {
    type: GET_CUSTOMER_API,
    payload: data,
  };
}
