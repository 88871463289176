import React from 'react';

export const themes = {
  light: {
    foreground: '#ffffff',
    background: '#11CEEF',
    primaryBackground: '#11CEEF',
    secondaryBackground: '#0000CD',
    primaryColor: '#ffffff',
    secondaryColor: '#ffffff',
    activeColor: 'green',
    inactiveColor: 'grey',
  },
  dark: {
    foreground: '#ffffff',
    background: '#5d4e8f',
    primaryBackground: '#11CEEF',
    secondaryBackground: '#0000CD',
    primaryColor: '#ffffff',
    secondaryColor: '#ffffff',
    activeColor: 'green',
    inactiveColor: 'grey',
  },
};

export const ThemeContext = React.createContext(
  themes.light, // default value
);
