import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';

const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 0 12px;

    .ant-modal-header {
      background: transparent;
    }
  }
`;

export default Modal;
