import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getVideo = () => apiGet(`${API_URL}video`);

export const postVideo = (data) => apiPost(`${API_URL}video`, data);

export const patchVideo = (id, data) => apiPatch(`${API_URL}video/${id}`, data);

export const searchVideoApi = (options) => apiGet(`${API_URL}video`, options);

export const searchPublishedVideoApi = (options) => apiGet(`${API_URL}video/published`, options);

export const videoDetails = (id) => apiGet(`${API_URL}video/details/${id}`);

export const deleteVideo = (id) => apiDelete(`${API_URL}video/${id}`);
