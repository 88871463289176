import {
  SET_CUSTOMER_INFO, SET_SELECTED_CUSTOMER, SET_CUSTOMER_LOADING, SET_SELECTED_MEMBER_DATA, SET_CUSTOMER_FORM_DETAILS, SET_CUSTOMER_ACCESS_DATA, SET_USER_LOCAL_DATA, SET_WELCOME_MODAL, SET_NOTIFICATION, SET_VISIBLE_POPUP,
} from '../types/types';

export function setCustomerInfo(data: Array<any>) {
  return {
    type: SET_CUSTOMER_INFO,
    payload: data,
  };
}

export function setSelectedCustomer(data: any) {
  return {
    type: SET_SELECTED_CUSTOMER,
    payload: data,
  };
}

export function setCustomerLoading(data: boolean) {
  return {
    type: SET_CUSTOMER_LOADING,
    payload: data,
  };
}

export function setSelectedMemberData(data: any) {
  return {
    type: SET_SELECTED_MEMBER_DATA,
    payload: data,
  };
}

export function setCustomerFormDetails(data: any) {
  return {
    type: SET_CUSTOMER_FORM_DETAILS,
    payload: data,
  };
}

export function setCustomerAccessData(data: any) {
  return {
    type: SET_CUSTOMER_ACCESS_DATA,
    payload: data,
  };
}

export function setUserLocalData(data: any) {
  return {
    type: SET_USER_LOCAL_DATA,
    payload: data,
  };
}

export function setWelcomeModal(data: any) {
  return {
    type: SET_WELCOME_MODAL,
    payload: data,
  };
}
export function setNotification(data: any) {
  return {
    type: SET_NOTIFICATION,
    payload: data,
  };
}
export function setVisiblePopup(data: boolean) {
  return {
    type: SET_VISIBLE_POPUP,
    payload: data,
  };
}
