import React, { useState } from 'react';
import ColorPicker, { Color, ColorObject } from 'react-pick-color';

interface ColorInputProps {
  value: Color;
  onChange: (value: Color) => void;
}

const ColorInput = ({ value = '#00FF00', onChange }: ColorInputProps) => {
  const [color, setColor] = useState(value);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const toggleColorPicker = () => {
    if (showColorPicker) {
      onChange(color);
    }

    setShowColorPicker(!showColorPicker);
  };

  const handleChange = (color: ColorObject) => {
    setColor(color.hex);
  };

  return (
    <div className="relative">
      <div className="border-solid border border-gray-300 cursor-pointer rounded-md p-1 w-fit">
        <span
          className="w-[100px] h-[40px] block rounded-md"
          style={{ background: color.toString() }}
          onClick={toggleColorPicker}
        ></span>
      </div>
      <div hidden={!showColorPicker}>
        <ColorPicker className="absolute z-[9999] mt-1" color={color} onChange={handleChange} />
      </div>
    </div>
  );
};

export default ColorInput;
