import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import { FormReduxState } from '../../redux/reducers/formReducer';
import { RootState } from '../../redux/store';
import Modal from '../../styled-components/Modal/Modal';
import ResourceObjectiveForm from './ResourceObjectiveForm';

interface ResourceObjectiveModalProps {
  onSubmit: (pagination) => void;
}

const ResourceObjectiveModal: React.FC<ResourceObjectiveModalProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { showFormData, isOpenDrawer } = useSelector<RootState, FormReduxState>(
    (state) => state.formReducer
  );

  const closeModal = () => {
    dispatch(setIsOpenDrawer(false));
  };
  return (
    <Modal
      className="user-drawer"
      maskClosable={false}
      title={`${`${showFormData.formMode} ${showFormData.formLabel}`} Form`}
      centered
      visible={isOpenDrawer}
      onCancel={closeModal}
      closeIcon={<img src={CrossFormButton} />}
      footer={null}
    >
      <ResourceObjectiveForm onClose={closeModal} onSubmit={onSubmit} />
    </Modal>
  );
};

export default ResourceObjectiveModal;
