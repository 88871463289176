import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
// import './index.css';
import { SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Comment, Form, Input, List } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { getComments, postComment } from '../../../api/comment';

const { TextArea } = Input;

interface CommentsProp {
  customerSelectedMember?: any;
  contentId?: any;
  customerDataList?: any;
  loginUserData?: any;
  state?: any;
}
const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    // header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
    itemLayout="horizontal"
    className="comment-list-spacing"
    renderItem={(props) => <Comment {...(props as any)} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <Form.Item>
    <div className="main-div-commentbox">
      <TextArea
        rows={1}
        onChange={onChange}
        value={value}
        onPressEnter={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        placeholder="Leave Your Comment"
        className="comment-textArea"
      />
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        className="add-comment"
      >
        <SendOutlined style={{ fontSize: '30px' }} />
      </Button>
    </div>
  </Form.Item>
);

const Comments: React.FC<CommentsProp> = (props) => {
  {
    const [comments, setComments] = useState<any>([]);
    const [submitting, setsubmitting] = useState<boolean>(false);
    const [value, setvalue] = useState<any>('');
    const { customerSelectedMember, contentId, customerDataList, loginUserData, state } = props;

    const getDataOfComments = () => {
      getComments(contentId).then((res) => {
        setComments(
          res.data.map((item) => ({
            ...item,
            content: item.comment,
            avatar: item.createdBy.avatar,
            datetime: moment(item.createdAt).fromNow(),
            author: `${item.createdBy.firstName} ${item.createdBy.lastName ? item.createdBy.lastName : ''}`
          }))
        );
      });
    };
    useEffect(() => {
      getDataOfComments();
    }, []);

    const handleSubmit = () => {
      if (!value) {
        return;
      }

      setsubmitting(true);
      const Data = {
        user: loginUserData?._id,
        comment: value,
        type: loginUserData?.type,
        contentId: `${contentId}`
      };
      postComment(Data).then((res) => {
        // setComments([{...res.data, avatar:}]);
        getDataOfComments();
        setsubmitting(false);
        setvalue('');
      });
    };

    const handleChange = (e) => {
      setvalue(e.target.value);
    };

    return (
      <>
        {customerDataList?.type != 'Administrator' && (
          <Comment
            avatar={
              <Avatar
                size={50}
                shape="circle"
                src={customerDataList?.avatar}
                alt={
                  customerSelectedMember &&
                  customerSelectedMember.length &&
                  `${customerSelectedMember[0]?.firstName}${customerSelectedMember[0]?.lastName ? customerSelectedMember[0]?.lastName : ''}`
                }
                className="avatar-border-style"
              />
            }
            content={
              <Editor
                onChange={handleChange}
                onSubmit={handleSubmit}
                submitting={submitting}
                value={value}
              />
            }
          />
        )}
        {comments.length > 0 && <CommentList comments={comments} />}
      </>
    );
  }
};

function mapStateToProps(state: any) {
  return {
    state,
    notificationData: state.customerRuducer.notificationData,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerDataList: state.notificationReducer.customerDataList,
    loginUserData: state.userMemberData.loginUserData
  };
}
export default connect(mapStateToProps)(Comments);
