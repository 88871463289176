import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

// apis for short levels
export const getShortLevelsAPI = (options?) => apiGet(`${API_URL}short/level`, options);

export const getShortLevelByIdAPI = (id) => apiGet(`${API_URL}short/level/${id}`);

export const createShortLevelAPI = (data) => apiPost(`${API_URL}short/level`, data);

export const updateShortLevelAPI = (id, data) => apiPatch(`${API_URL}short/level/${id}`, data);

export const deleteShortLevelAPI = (id) => apiDelete(`${API_URL}short/level`, { id });

// apis for short categories
export const getShortCategoriesAPI = (options?) => apiGet(`${API_URL}short/category`, options);

export const getShortCategoryByIdAPI = (id) => apiGet(`${API_URL}short/category/${id}`);

export const createShortCategoryAPI = (data) => apiPost(`${API_URL}short/category`, data);

export const updateShortCategoryAPI = (id, data) =>
  apiPatch(`${API_URL}short/category/${id}`, data);

export const deleteShortCategoryAPI = (id) => apiDelete(`${API_URL}short/category`, { id });

// apis for shorts
export const getShortsAPI = (options, search) => apiGet(`${API_URL}short`, { ...options, search });

export const getPublishedShortsAPI = () => apiGet(`${API_URL}short/getPublishedShorts`);

export const getShortByIdAPI = (id) => apiGet(`${API_URL}short/${id}`);

export const createShortAPI = (data) => apiPost(`${API_URL}short`, data);

export const updateShortAPI = (id, data) => apiPatch(`${API_URL}short/${id}`, data);

export const deleteShortAPI = (id) => apiDelete(`${API_URL}short`, { id });
