import { Table } from 'antd';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listMemberApi } from '../../api/member';
import { getUserApi } from '../../api/user';
import { paginationConfig } from '../../constant';
import ExpandDownArrow from '../../images/icons/down arrrow.svg';
import ExpandUpArrow from '../../images/icons/UpArrow.svg';
import { setSelectedMemberData } from '../../redux/actions/customerAction';
import { getCustomerApi } from '../../redux/actions/notificationAction';
import { setCurrentLoginUser } from '../../redux/actions/userMemberAction';
import OnboardingModal from '../common/OnboardingModal';
import SocialSkillModal from '../common/SocialSkillModal';
import { CustomerContainer } from '../common/styles';
import MobileViewAccordian from './MobileViewAccordianComponent';

const MyFormTableComponent: React.FC<any> = (props) => {
  const {
    favouriteList,
    customerSelectedMember,
    customerDataList,
    selectCustomer,
    loginUserData,
    currentLoginUser,
    dispatch,
    isMobile
  } = props;
  const formData =
    customerDataList.type != 'Customer'
      ? loginUserData && loginUserData.forms
      : customerDataList && customerDataList.forms;
  const ChildformData =
    customerSelectedMember && customerSelectedMember[0] && customerSelectedMember[0].forms;
  const parentParseData = formData && JSON.parse(formData);
  const childParseData = ChildformData && JSON.parse(ChildformData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showFormType, setFormType] = useState('');
  const [formUrl, setFormUrl] = useState('');
  const [wizardModalVisible, setWizardModalVisible] = useState(false);

  const { type } = useParams();

  const showModal = (key) => {
    setIsModalVisible(true);
    const keyVal = key.replace(/ /g, '');
    if (keyVal === 'ChildOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211082864962460');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210528488490058');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083462625451');
      }
    } else if (keyVal === 'ParentOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211082955638461');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210528623971861');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083629361453');
      }
    }
    setFormType(key);
  };

  const showViewModal = (data) => {
    setIsModalVisible(true);

    if (data.key === 'ChildOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211083342939456');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210541135931446');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083888586469');
      }
    } else if (data.key === 'ParentOnboardingProfile') {
      if (process.env.FORM_ENV === 'development') {
        setFormUrl('https://form.jotform.com/211083802328449');
      }
      if (process.env.FORM_ENV === 'test') {
        setFormUrl('https://form.jotform.com/210541697731457');
      }
      if (process.env.FORM_ENV === 'production') {
        setFormUrl('https://form.jotform.com/211083644185454');
      }
    }

    setFormType(data.name);
  };

  const handleCancel = () => {
    getUserApi(customerDataList._id).then((res) => {
      dispatch(getCustomerApi(res));
      dispatch(setCurrentLoginUser(res));
    });

    listMemberApi(customerSelectedMember[0]._id).then((res) => {
      const data = res;
      dispatch(setSelectedMemberData([data]));

      getUserApi(customerDataList._id).then((res) => {
        dispatch(getCustomerApi(res));
        dispatch(setCurrentLoginUser(res));
      });
    });
    setIsModalVisible(false);
    setFormUrl('');
    setWizardModalVisible(false);
  };

  useEffect(() => {
    if (type === 'child') {
      const data = {
        name: 'Child Onboarding Profile',
        key: 'ChildOnboardingProfile'
      };
      childParseData && childParseData.ChildOnboardingProfile
        ? showViewModal(data)
        : showModal(data.name);
    } else if (type === 'parent') {
      const data = {
        name: 'Parent Onboarding Profile',
        key: 'ParentOnboardingProfile'
      };
      parentParseData && parentParseData.ParentOnboardingProfile
        ? showViewModal(data)
        : showModal(data.name);
    }
  }, [type]);

  const renderParentFormInput = () => {
    let parentData = {};
    if (formData === '' || formData === undefined) {
      parentData = {
        'yourName[first]':
          customerDataList.type != 'Customer'
            ? loginUserData.firstName
            : customerDataList.firstName,
        'yourName[last]':
          customerDataList.type != 'Customer'
            ? loginUserData.lastName
              ? loginUserData.lastName
              : ''
            : customerDataList.lastName
              ? customerDataList.lastName
              : '',
        accountEmail:
          customerDataList.type != 'Customer' ? loginUserData.email : customerDataList.email,
        doYou3:
          customerDataList.type != 'Customer'
            ? `${loginUserData.firstName} ${loginUserData.lastName ? loginUserData.lastName : ''}`
            : customerDataList.length
              ? `${customerDataList[0].firstName} ${customerDataList[0].lastName ? customerDataList[0].lastName : ''}`
              : `${customerDataList.firstName} ${customerDataList.lastName ? customerDataList.lastName : ''}`,
        member: customerSelectedMember.length
          ? `${customerSelectedMember[0].firstName} ${customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''}`
          : `${customerSelectedMember.firstName} ${customerSelectedMember.lastName ? customerSelectedMember.lastName : ''}`,
        formtype: showFormType.replace(/ /g, ''),
        userid: customerDataList.type != 'Customer' ? loginUserData._id : customerDataList._id
      };
    } else {
      parentData = {
        ...(parentParseData && parentParseData.ParentOnboardingProfile),
        'yourName[first]':
          parentParseData &&
          (parentParseData.ParentOnboardingProfile.yourName.first
            ? parentParseData.ParentOnboardingProfile.yourName.first
            : ''),
        'yourName[last]':
          parentParseData &&
          (parentParseData.ParentOnboardingProfile.yourName.last
            ? parentParseData.ParentOnboardingProfile.yourName.last
            : '')
      };
    }
    return parentData;
  };
  const renderChildFormInput = () => {
    let childData = {};
    if (ChildformData === '' || ChildformData === undefined) {
      childData = {
        'yourName[first]':
          customerSelectedMember.length &&
          customerSelectedMember &&
          (customerSelectedMember[0].firstName ? customerSelectedMember[0].firstName : ''),
        'yourName[last]':
          customerSelectedMember.length &&
          customerSelectedMember &&
          (customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''),
        accountEmail: '',
        childsPreferred: customerSelectedMember.length
          ? `${customerSelectedMember[0].firstName} ${customerSelectedMember[0].lastName ? customerSelectedMember[0].lastName : ''}`
          : `${customerSelectedMember.firstName} ${customerSelectedMember.lastName ? customerSelectedMember.lastName : ''}`,
        member: customerSelectedMember.length
          ? `${customerSelectedMember[0].firstName} ${customerSelectedMember[0].lastName}`
          : `${customerSelectedMember.firstName} ${customerSelectedMember.lastName ? customerSelectedMember.lastName : ''}`,
        formtype: showFormType.replace(/ /g, ''),
        userid: customerSelectedMember.length
          ? `${customerSelectedMember[0]._id}`
          : `${customerSelectedMember._id}`
      };
    } else {
      childData = {
        ...(childParseData && childParseData.ChildOnboardingProfile),
        'yourName[first]':
          childParseData && childParseData.ChildOnboardingProfile.yourName.first
            ? childParseData.ChildOnboardingProfile.yourName.first
            : '',
        'yourName[last]':
          childParseData && childParseData.ChildOnboardingProfile.yourName.last
            ? childParseData.ChildOnboardingProfile.yourName.last
            : ''
      };
    }
    return childData;
  };

  const dataSource = [
    {
      key: 'ChildOnboardingProfile',
      name: 'Child Onboarding Profile',
      type: 'Onboarding',
      status:
        childParseData && childParseData.ChildOnboardingProfile ? 'submitted' : 'Not submitted'
    },
    {
      key: 'ParentOnboardingProfile',
      name: 'Parent Onboarding Profile',
      type: 'Onboarding',
      status:
        parentParseData && parentParseData.ParentOnboardingProfile ? 'submitted' : 'Not submitted'
    }
    // {
    //     key: 'SocialSkillAssessment',
    //     name: 'Social Skill Assessment',
    //     type: 'Assessment',
    //     status: customerDataList?.socialSkillAssesment ? 'Submitted' : 'Not Submitted',
    // },
    // {
    //     key: 'SensoryProfileAssessment',
    //     name: 'Sensory Profile Assessment',
    //     type: 'Assessment',
    //     status: 'Not submitted',
    // },
  ];

  const columns = [
    {
      title: 'Form Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        if (record.key === 'SocialSkillAssessment')
          return (
            <a className="onboard-from-title-style" onClick={() => setWizardModalVisible(true)}>
              {text}
            </a>
          );
        if (customerDataList.type === 'Customer') {
          switch (record.key) {
            case 'ParentOnboardingProfile':
              if (parentParseData && parentParseData.ParentOnboardingProfile) {
                return (
                  <a className="onboard-from-title-style" onClick={() => showViewModal(record)}>
                    {text}
                  </a>
                );
              }
              return (
                <a className="onboard-from-title-style" onClick={() => showModal(text)}>
                  {text}{' '}
                </a>
              );
            default:
              if (childParseData && childParseData.ChildOnboardingProfile) {
                return (
                  <a className="onboard-from-title-style" onClick={() => showViewModal(record)}>
                    {text}
                  </a>
                );
              }
              return (
                <a className="onboard-from-title-style" onClick={() => showModal(text)}>
                  {text}{' '}
                </a>
              );
          }
        } else {
          return (
            <a className="onboard-from-title-style" onClick={() => showViewModal(record)}>
              {text}{' '}
            </a>
          );
        }
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => <span className="goal-table-date">{text}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) =>
        text === 'Not submitted' ? (
          <span className="goal-table-date" style={{ color: '#EA197A' }}>
            {text}
          </span>
        ) : (
          <span className="goal-table-date">{text}</span>
        )
    }
  ];
  const mobileColumns = [
    {
      title: 'Form Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        if (record.key === 'SocialSkillAssessment')
          return (
            <a className="onboard-from-title-style" onClick={() => setWizardModalVisible(true)}>
              {text}
            </a>
          );
        if (customerDataList.type === 'Customer') {
          switch (record.key) {
            case 'ParentOnboardingProfile':
              if (parentParseData && parentParseData.ParentOnboardingProfile) {
                return (
                  <a className="onboard-from-title-style" onClick={() => showViewModal(record)}>
                    {text}
                  </a>
                );
              }
              return (
                <a className="onboard-from-title-style" onClick={() => showModal(text)}>
                  {text}{' '}
                </a>
              );
            default:
              if (childParseData && childParseData.ChildOnboardingProfile) {
                return (
                  <a className="onboard-from-title-style" onClick={() => showViewModal(record)}>
                    {text}
                  </a>
                );
              }
              return (
                <a className="onboard-from-title-style" onClick={() => showModal(text)}>
                  {text}{' '}
                </a>
              );
          }
        } else {
          return (
            <a className="onboard-from-title-style" onClick={() => showViewModal(record)}>
              {text}{' '}
            </a>
          );
        }
      }
    }
  ];

  const convertToQueryString = (formInput) => {
    const encoded = `${qs.stringify(formInput)}`;
    const decoded = decodeURI(encoded);
    return decoded;
  };
  const customExpandIconMobileVIew = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandDownArrow} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandUpArrow} />
      </a>
    );
  };
  return (
    <div style={{ marginTop: 20 }}>
      <div className="site-layout-background">
        <div className="site-card-wrapper mobile-view-form-wrapper">
          <CustomerContainer>
            {isMobile ? (
              <Table
                className={`${props.className} classof-mobile-column`}
                columns={mobileColumns}
                dataSource={dataSource}
                pagination={paginationConfig}
                expandIcon={(props) => customExpandIconMobileVIew(props)}
                expandIconColumnIndex={3}
                showHeader={false}
                expandedRowRender={(record) => (
                  <div>
                    <MobileViewAccordian
                      panelColumns={columns}
                      dataSource={record}
                      type="myForms"
                    />
                  </div>
                )}
              />
            ) : (
              <Table
                className={props.className}
                columns={columns}
                dataSource={dataSource}
                pagination={paginationConfig}
              />
            )}
          </CustomerContainer>
        </div>

        {/* <Modal className="onboardFormsModal" title={<div className="add-task-title-container">{showFormType} <div onClick={handleCancel} className="add-task-close-button"><img src={ModalCloser} /></div></div>} visible={isModalVisible} closable={false} onCancel={handleCancel}>
                    <iframe id="hidePointerInput" width="100%" height="470" frameBorder="0" src={`${formUrl}?${convertToQueryString(showFormType === 'Parent Onboarding Profile' ? renderParentFormInput() : renderChildFormInput() )}`}></iframe>
                </Modal> */}
        <OnboardingModal
          handleCancel={handleCancel}
          visible={isModalVisible}
          onCancel={handleCancel}
          formUrl={formUrl}
          convertToQueryString={convertToQueryString}
          showFormType={showFormType}
          renderParentFormInput={renderParentFormInput}
          renderChildFormInput={renderChildFormInput}
        />
        <SocialSkillModal
          visible={wizardModalVisible}
          handleCancel={handleCancel}
          onCancel={handleCancel}
          setVisibility={setWizardModalVisible}
          showFormType="Social Skill Assessment"
        />
        {/* <WizardModal visible={wizardModalVisible} setVisibility={setWizardModalVisible} step={4}/> */}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    customerDataList: state.notificationReducer.customerDataList,
    selectCustomer: state.customerRuducer.selectCustomer,
    loginUserData: state.userMemberData.loginUserData,
    currentLoginUser: state.userMemberData.currentLoginUser,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(MyFormTableComponent);
