import { Drawer } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import countryCode from '../../countryCode.json';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import AuthorForm from '../FormComponent/AuthorForm';

interface AddUserProps {
  isOpenDrawer?: boolean;
  showFormData?: any;
  dispatch?: any;
  pagination?: number;
  PerPageRecords?: number;
  changePageToOne?: () => void;
  searchTerm?: string;
}

export interface goalTypeListType {
  title: string;
  value: string;
}

const AddAuthor: React.FC<AddUserProps> = (props) => {
  const {
    dispatch,
    isOpenDrawer,
    showFormData,
    pagination,
    PerPageRecords,
    changePageToOne,
    searchTerm
  } = props;

  const closeDrawer = () => {
    dispatch(setIsOpenDrawer(false));
  };

  return (
    <Drawer
      maskClosable={false}
      title={`${`${showFormData.formMode} ${showFormData.formLabel}`} Form`}
      placement="right"
      closable={false}
      visible={isOpenDrawer}
      width="520"
      className="user-drawer"
      onClose={closeDrawer}
    >
      <AuthorForm
        close={closeDrawer}
        countryCode={countryCode}
        cancel={() => dispatch(setIsOpenDrawer(false))}
        userRole={showFormData.userType}
        authorID={showFormData?.id}
        editMode={showFormData.edit}
        viewMode={showFormData.formMode}
        visible={isOpenDrawer}
        pagination={pagination}
        PerPageRecords={PerPageRecords}
        changePageToOne={changePageToOne}
        searchTerm={searchTerm}
      />
    </Drawer>
  );
};

function mapStateToProps(state: any) {
  return {
    showFormData: state.formReducer.showFormData,
    isOpenDrawer: state.formReducer.isOpenDrawer
  };
}

export default connect(mapStateToProps)(AddAuthor);
