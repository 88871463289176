import {
  DeleteOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Button, notification, Spin, Switch, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { Accept, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import {
  createClientFile,
  deleteClientFiles,
  downloadClientFile,
  getClientFiles,
  restoreClientFiles
} from '../api/clientFiles';
import { useCustomerId, useMemberId } from '../utils/hooks/useCustomerId';
import { DragAndDropBox } from './common/styles';
import { showNotificationDrawer } from './common/utils';
import ClientFilesTableComponent from './TableData/ClientFilesTableComponent';

export interface IClientFilesComponentProps {}

const ACCEPT_FILES: Accept = {
  'image/*': [],
  'video/*': [],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
};
const MAX_SIZE_MB = 50;
const DRAGANDDROP_TOOLTIP_INFO =
  'Accepted file types are .doc .docx .jpeg .jpg .png .mp4 .xls .xlsx less than 50 MB';
const AVERAGE_MALWARE_SCANNING_SPEED_MB = 5;

export default function ClientFilesComponent(props: IClientFilesComponentProps) {
  const queryClient = useQueryClient();
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const [showAll, setShowAll] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // const { userLocalData } = useSelector<RootState, CustomerState>((state) => state.customerRuducer);
  // const [loginUser, setLoginUser] = useState<any>();
  // const clientId = loginUser?.type === Role.Customer ? loginUser?._id : (userLocalData as any)._id;

  const clientId = useCustomerId();
  const memberId = useMemberId();
  /**
   * * Query & Mutation
   */
  const fetchFilesQuery = useQuery<{ data: ClientFile[]; count: number }>(
    ['client-files', clientId, memberId, showAll],
    () => getClientFiles(clientId, !showAll ? memberId : null),
    {
      enabled: Boolean(clientId && memberId)
    }
  );
  const files = fetchFilesQuery.data?.data;
  const isFilesEmpty = !(files && files.length > 0);

  const uploadFileMutation = useMutation(
    ['client-files', clientId, memberId],
    (data: Parameters<typeof createClientFile>[2]) =>
      createClientFile(clientId, memberId, data, onUploadProgress)
  );
  const downloadFileMutation = useMutation(['client-files-download'], (fileId: string) =>
    downloadClientFile(clientId, fileId)
  );
  const restoreMutation = useMutation(['client-files-restore', clientId], (idArray: string[]) =>
    restoreClientFiles(clientId, idArray)
  );

  /**
   * * Upload & Download
   */
  const onUploadProgress = (event: ProgressEvent) => {
    console.log(event);
    setUploadingProgress(Math.round((event.loaded * 100) / event.total));
  };

  const uploadFile = async (file: File) => {
    if (!clientId) return;
    if (uploadFileMutation.isLoading || isUploading) return;

    try {
      setIsUploading(true);
      setUploadingProgress(0);
      await uploadFileMutation.mutateAsync(file);

      const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
      const scanningTimeSeconds = Math.round(fileSizeMB / AVERAGE_MALWARE_SCANNING_SPEED_MB); // Calculate scanning time
      setTimeout(async () => {
        setIsUploading(false);
        showNotificationDrawer('Success', 'Uploaded successfully.');
        await fetchFilesQuery.refetch(); // Refresh file list after scanning completes
      }, scanningTimeSeconds * 1000); // Convert scanning time to milliseconds
    } catch (err) {
      showNotificationDrawer('Error', 'Upload Failed');
      setIsUploading(false);
    }
  };

  const download = async (fileId: string) => {
    try {
      const data = await downloadFileMutation.mutateAsync(fileId);
      const href = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.target = '_blank';
      const fileName = files?.find((f) => f._id === fileId)?.fileName;
      link.setAttribute('download', fileName || 'file');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      showNotificationDrawer('Error', 'Download Failed');
    }
  };

  /**
   * * Restore & Move to Bin
   */
  const restore = async (selectedIds: string[]) => {
    const res = await restoreMutation.mutateAsync(selectedIds);
    // await fetchFileList();
    queryClient.invalidateQueries(['client-files', clientId]);
  };
  const moveToBin = async (selectedIds: string[]) => {
    const res = await deleteClientFiles(clientId, selectedIds);
    // await fetchFileList();
    await fetchFilesQuery.refetch();
    const undo = async () => {
      await restore(selectedIds);
      notification.destroy();
    };
    notificationApi.success({
      message: 'Success',
      description: 'File moved to bin',
      btn: (
        <Button size="small" onClick={undo}>
          Undo
        </Button>
      ),
      duration: 5
    });
  };

  /**
   * * Drag and Drop View
   */
  const onDrop = async (accepted: File[], rejected: FileRejection[], event) => {
    if (accepted.length > 0) {
      await uploadFile(accepted[0]);
    } else if (rejected.length > 0) {
      const error = rejected[0].errors.at(0);
      if (error) {
        // console.log(error);
        let { message } = error;
        if (error.code === ErrorCode.FileInvalidType) {
          message = 'File type is not supported. (Only image, video, pdf, doc, xls)';
        } else if (error.code === ErrorCode.FileTooLarge) {
          message = `File is larger than ${MAX_SIZE_MB}MB`;
        }
        showNotificationDrawer('Error', message);
      }
    }
  };

  const maxSize = MAX_SIZE_MB * 1024 * 1024;

  const {
    open: openUpload,
    getRootProps: getOuterRootProps,
    isDragActive: isOuterDragActive
  } = useDropzone({
    maxSize,
    onDrop,
    accept: ACCEPT_FILES,
    //    disabled: !(files && files?.length > 0),
    noClick: true // * !isFilesEmpty
  });

  const onUploadClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    openUpload();
    e.stopPropagation();
  };

  const spinIndicator = <LoadingOutlined style={{ fontSize: 64, margin: '-16px -32px' }} spin />;
  const dragView = (
    <div className="border-2 border-solid border-blue-400 bg-blue-100 opacity-50 absolute left-0 top-0 w-full h-full" />
  );
  const dropzoneView = (
    <div
      className="flex flex-col max-w-[30rem] rounded-[1rem] mx-auto px-24 py-8 text-center justify-center items-center border border-solid border-blue-500 bg-blue-50 self-stretch text-blue-400"
      onClick={() => openUpload()}
    >
      <UploadOutlined className="text-2xl w-[4rem]" />
      <h3 className="text-blue-500 font-bold text-2xl">Drag & drop</h3>
      <p>.doc .docx .jpeg .jpg .mp4 .xls .xlsx Max file size 50 MB</p>
      <p className="text-gray-400">
        Drag a file here or click in this area to browse in your folder explorer
      </p>
    </div>
  );
  const uploadInfo = (
    <div className="flex items-center gap-3 text-gray-500 px-3">
      <UploadOutlined className="text-3xl" />
      <span>
        <div className="text-lg font-medium leading-none">
          A place for client files &ensp;
          <Tooltip title={DRAGANDDROP_TOOLTIP_INFO} arrowPointAtCenter={false} placement="topRight">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        <div>Drag and drop on this screen</div>
      </span>
    </div>
  );
  return (
    <div className="resource-mobile-view-container">
      {notificationContextHolder}
      <Spin spinning={fetchFilesQuery.isLoading}>
        <div className="site-layout-background">
          <DragAndDropBox {...getOuterRootProps()} className="my-8 !cursor-auto !border-none">
            <div className="flex flex-col py-8 gap-5 text-left relative w-full">
              <div className="flex flex-wrap items-center gap-5">
                <Typography.Title level={2} className="px-3 !mb-0">
                  My Files
                </Typography.Title>
                <Button
                  className="rounded-md"
                  type="primary"
                  icon={<UploadOutlined />}
                  onClick={onUploadClick}
                  disabled={uploadFileMutation.isLoading || isUploading}
                >
                  Upload
                </Button>
                <div className="rounded-full p-1 bg-gray-200 mx-3">
                  <Switch
                    checked={showAll}
                    onChange={(checked, e) => {
                      e.stopPropagation();
                      setShowAll(checked);
                    }}
                  />
                  <span className="mx-2">Show All Files</span>
                </div>
                <Link to="/dashboard/clientFiles/bin" className="ml-auto">
                  <DeleteOutlined className="text-xl text-red-500 hover:text-red-600" />
                </Link>
                {!isFilesEmpty && uploadInfo}
              </div>
              {(uploadFileMutation.isLoading || isUploading) && (
                <Spin
                  indicator={spinIndicator}
                  tip={`Uploading ${uploadingProgress}%...`}
                  wrapperClassName="h-[4rem] -mt-8"
                >
                  <div className="content" />
                </Spin>
              )}
              {isFilesEmpty && (
                <div className="px-3 my-6">
                  {/* <ClientFileDropzone onDrop={onDrop} maxSize={maxSize} accept={ACCEPT_FILES} /> */}
                  {dropzoneView}
                </div>
              )}
              {!isFilesEmpty && (
                <ClientFilesTableComponent
                  fileList={files}
                  onDelete={moveToBin}
                  onDownload={download}
                />
              )}
              {isOuterDragActive && dragView}
            </div>
          </DragAndDropBox>
        </div>
      </Spin>
    </div>
  );
}
