import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getShortCategoriesAPI } from '../../../api/short';
import { userMemberDataType } from '../../../constant';
import { UserContainer } from '../../../pages/dashboard/styles';
import { setIsOpenDrawer, setShowFormData } from '../../../redux/actions/formAction';
import { RootState } from '../../../redux/store';
import PRIVILEGES from '../../../utils/privilege';
import { Mode } from '../../../utils/types/role';
import { AddButton } from '../../common/styles';
import { ShortLevel } from '../ShortLevel/ShortLevelsComponent';
import ShortCategoriesModal from './ShortCategoriesModal';
import ShortCategoriesTable from './ShortCategoriesTable';

export interface ShortCategory {
  name: string;
  level: ShortLevel;
}
const ShortCategoriesComponent = () => {
  const dispatch = useDispatch();

  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const [shortCategories, setShortCategories] = useState<ShortCategory[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0
  });
  const [loading, setLoading] = useState(false);

  const canCreate = PRIVILEGES.ShortCategories.Create?.includes(currentLoginUser.type);

  const fetchShortCategories = async () => {
    try {
      const res = await getShortCategoriesAPI(pagination);
      setShortCategories(res.data);

      const newPagination = { ...pagination, total: res.count };
      setPagination(newPagination);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      await fetchShortCategories();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    fetchShortCategories();
  }, [pagination.page]);

  const onPageChange = (page: number, pageSize?: number) => {
    const newPagination = { ...pagination, page, pageSize: pageSize || pagination.pageSize };
    setPagination(newPagination);
  };

  const openModal = (id: string = '', mode: Mode) => {
    const formData = {
      id,
      formMode: mode,
      formLabel: 'Short Category'
    };
    dispatch(setShowFormData(formData));
    dispatch(setIsOpenDrawer(true));
  };

  return (
    <Spin spinning={loading}>
      <UserContainer className="items-center">
        {canCreate && (
          <AddButton type="primary" onClick={() => openModal(undefined, Mode.Create)}>
            <PlusOutlined />
          </AddButton>
        )}
        <Link
          to="/dashboard/shorts"
          className="rounded border-w-2 border border-solid mx-4 p-1 px-4"
        >
          Back
        </Link>
        <ShortCategoriesModal onSubmit={fetchShortCategories} />
      </UserContainer>

      <div className="site-layout-background">
        <ShortCategoriesTable
          shortCategories={shortCategories}
          className="resources-table"
          onOpenModal={openModal}
        />
        <Pagination
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onPageChange}
          className="my-pagination"
        />
      </div>
    </Spin>
  );
};

export default ShortCategoriesComponent;
