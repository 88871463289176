import { Tag } from 'antd';
import * as React from 'react';

export interface IResourceStatusTagProps {
  status: 'Shared' | 'Reject' | 'Approved' | 'Pending' | 'Active';
}

export default function ResourceStatusTag(props: IResourceStatusTagProps) {
  const { status } = props;
  const colors = {
    Shared: 'lime',
    Reject: 'red',
    Approved: 'green',
    Pending: 'orange',
    Active: 'green'
  };
  return <Tag color={colors[status]}>{status}</Tag>;
}
