import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { listUserApi } from '../../api/user';
import { ListingType } from '../../constant';
import { Role } from '../../utils/types/role';
import { RemoveButton, UserLastRow } from '../common/styles';
import { dateFormat } from '../common/utils';

const { Option } = Select;

interface MemberItemProps {
  member: any;
  onRemove: () => void;
  onRefUpdate: (formRef: any) => void;
  existingFormRef: any;
  noRemoveTab?: boolean;
  userMemberData?: any;
  hideadd?: boolean;
}

const MemberItemLocal = ({
  member,
  onRemove,
  onRefUpdate,
  existingFormRef,
  noRemoveTab,
  hideadd
}: MemberItemProps) => {
  const [formRef] = Form.useForm();
  const role = Role.Business;
  const [businessusers, setbusinessUsers] = useState<any>([]);
  useEffect(() => {
    if (!existingFormRef) {
      onRefUpdate(formRef);
    }
  }, [formRef, existingFormRef, onRefUpdate]);

  const RemoveTab = () => {
    if (!noRemoveTab) {
      return (
        <RemoveButton type="text" onClick={() => onRemove()} block>
          <MinusCircleOutlined />
        </RemoveButton>
      );
    }
    return null;
  };

  const fetchList = useCallback((searchTerm: string = '', skip: number = 0) => {
    const options = {
      filter: 'Active',
      skip: 0
    };
    return listUserApi(role, options);
  }, []);

  useEffect(() => {
    fetchList('').then((res) => {
      setbusinessUsers(res.data);
    });
  }, []);

  useEffect(() => {
    const parent = `${member?.parent?.firstName} ${member?.parent?.lastName}`;
    const firstName = member?.firstName;
    const primaryContact = member?.primaryContact?._id || member?.primaryContact;
    const { lastName } = member;
    const { dob } = member;
    const { listingIdentifier } = member;
    const { listingType } = member;
    const { memberShipEnd } = member;
    const { memberShipStart } = member;
    formRef.setFieldsValue({
      firstName,
      primaryContact,
      parent,
      lastName,
      dob,
      listingIdentifier,
      listingType,
      memberShipEnd,
      memberShipStart
    });
  }, [member?._id]);

  const memberCalculatedAge = () => {
    const a = moment();
    const b = moment(formRef.getFieldsValue().dob);
    if (!formRef.getFieldsValue().dob) {
      return '';
    }
    const years = a.diff(b, 'year');
    b.add(years, 'years');

    const months = a.diff(b, 'months');
    b.add(months, 'months');

    const days = a.diff(b, 'days');
    if (years >= 2) {
      return `${years} years `;
    }
    if (years === 1) {
      return `${years} year `;
    }

    return `${months} months `;
  };
  const onChangeDate = (date) => {
    const data = {
      memberShipEnd: date
    };
    if (date >= formRef.getFieldValue('memberShipEnd')) {
      formRef.setFieldsValue(data);
    }
  };
  return (
    <Form
      form={formRef}
      className="admin-form manage-form-style"
      layout="vertical"
      name="basic"
      initialValues={{
        ...member,
        primaryContact: `${member?.primaryContact?._id || member?.primaryContact || member[0]?.primaryContact || ''}`,
        calculatedAge: moment().diff(member.dob, 'years')
      }}
    >
      <div className="customer-children">
        <UserLastRow>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Member First Name"
                name="firstName"
                fieldKey="firstName"
                rules={[
                  { pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' },
                  { required: true, message: 'Please input your First Name!' }
                ]}
              >
                <Input name="firstName" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label="Member Last Name"
                name="lastName"
                fieldKey="lastName"
                rules={[{ pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' }]}
              >
                <Input name="lastName" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="dob"
                fieldKey="dob"
                label="Member DOB"
                // rules={[{ required: true, message: 'Please input your DOB' }]}
              >
                {/* @ts-ignore */}
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode}
                  name="dob"
                  style={{ width: '100%' }}
                  format={dateFormat}
                  disabledDate={(currentDate) => moment().add(0, 'days') <= currentDate}
                />
              </Form.Item>
            </Col>
          </Row>
          {formRef.getFieldsValue().dob && (
            <Row>
              <Col span={24}>
                <div className="ant-form-item-label memberAgeContainer">
                  <label className="member-label">Member Age</label>
                  <span>{memberCalculatedAge()}</span>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Form.Item
                name="primaryContact"
                fieldKey="primaryContact"
                label="Primary Clinician"
                // rules={[{ required: true, message: 'Please enter your Primary Clinician' }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  defaultValue=""
                >
                  {businessusers.map((data, index: number) => (
                    <Option key={index} value={data._id}>
                      {data?.firstName} {data?.lastName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="memberShipStart"
                fieldKey="memberShipStart"
                label="Membership Start"
                // rules={[{ required: true, message: 'Please input your Membership Start Date' }]}
              >
                {/* @ts-ignore */}
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode}
                  onChange={onChangeDate}
                  name="memberShipStart"
                  style={{ width: '100%' }}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="membership-end-mobileview"
                name="memberShipEnd"
                fieldKey="memberShipEnd"
                label="Next Rollover Date"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        new Date(getFieldValue('memberShipStart')).getTime() <
                          new Date(value).getTime()
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Start date should be smaller than end date!');
                    }
                  })
                ]}
              >
                {/* @ts-ignore */}
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode}
                  name="memberShipEnd"
                  style={{ width: '100%' }}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                className="listing-type-mobile-view"
                label="Listing Type"
                name="listingType"
                fieldKey="listingType"
                // rules={[{ required: true, message: 'Please select your Listing Type!' }]}
              >
                <Select allowClear getPopupContainer={(triggerNode) => triggerNode.parentElement}>
                  {ListingType.map((data, index: number) => (
                    <Option key={index} value={data.value}>
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Listing Identifier"
                name="listingIdentifier"
                fieldKey="listingIdentifier"
                // rules={[{ required: true, message: 'Please input your Listing Identifier!', whitespace: true }]}
              >
                <Input name="listingIdentifier" />
              </Form.Item>
            </Col>
          </Row>
        </UserLastRow>

        {hideadd ? null : RemoveTab()}
      </div>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData
  };
}

export const MemberItem = connect(mapStateToProps)(MemberItemLocal);
