import { Drawer } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenDrawer } from '../../../redux/actions/formAction';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { RootState } from '../../../redux/store';
import ShortsForm from './ShortsForm';

interface ShortsModalProps {
  onSubmit: () => void;
}

export interface goalTypeListType {
  title: string;
  value: string;
}

const ShortsModal = ({ onSubmit }: ShortsModalProps) => {
  const dispatch = useDispatch();
  const { isOpenDrawer, showFormData } = useSelector<RootState, FormReduxState>(
    (state) => state.formReducer
  );

  const closeDrawer = () => {
    dispatch(setIsOpenDrawer(false));
  };
  return (
    <Drawer
      maskClosable={false}
      title={`${`${showFormData.formMode} ${showFormData.formLabel}`} Form`}
      placement="right"
      closable={false}
      visible={isOpenDrawer}
      width="520"
      className="user-drawer"
      onClose={closeDrawer}
    >
      <ShortsForm
        onClose={closeDrawer}
        userRole={showFormData.userType}
        shortId={showFormData.id}
        viewMode={showFormData.formMode}
        visible={isOpenDrawer}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

export default ShortsModal;
