import { API_URL } from '../constant';
import { apiGet, apiPatch, apiPost } from '../utils/api';

export const postNotificationApi = (notification) =>
  apiPost(`${API_URL}notification`, notification);

export const getNotificationApi = (userId, params = {}) =>
  apiGet(`${API_URL}notification/${userId}`, params);

export const putNotificationApi = (userId) => apiPatch(`${API_URL}notification/read/${userId}`);
