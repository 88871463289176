import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import * as React from 'react';
import {
  Accept, DropEvent, DropzoneOptions, FileRejection, useDropzone,
} from 'react-dropzone';
import FileUploader from './FileUploader';
import { DragAndDropBox } from './styles';

export interface IFileDropzoneProps {
  initialView?: React.ReactElement;
  dragView?: React.ReactElement;
  className?: string;
  onDrop?: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => void;
  maxSize: number;
  loading?: boolean;
  accept?: Accept;
}
export const FileDropzone = (props: IFileDropzoneProps) => {
  const {
    initialView, dragView, className, onDrop, maxSize, loading = false, accept,
  } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize,
    accept,
  });

  return (
    <DragAndDropBox className={`${className}`} {...getRootProps()}>
      <input {...getInputProps()} />
      {loading && <LoadingOutlined className="upload-icon" />}
      {!loading && !isDragActive && (initialView || <UploadOutlined className="upload-icon" />)}
      {!loading && isDragActive && (dragView || <UploadOutlined className="upload-icon" />)}
    </DragAndDropBox>
  );
};

export interface IClientFileDropzone extends DropzoneOptions {

}
export const ClientFileDropzone = (props: IClientFileDropzone) => {
  const {
    onDrop, maxSize = 0, accept, ...other
  } = props;
  const dropzoneView = (
    <div className="flex flex-col max-w-[30rem] rounded-[1rem] mx-auto px-24 py-8 text-center justify-center items-center border border-solid border-blue-500 bg-blue-50 self-stretch text-blue-400">
      <UploadOutlined className="text-2xl w-[4rem]" />
      <h3 className="text-blue-500 font-bold text-2xl">Drag & drop</h3>
      <p>.doc .docx .jpeg .jpg .mp4 .xls .xlsx Max file size 12MB</p>
      <p className="text-gray-400">
        Drag a file here or click in this area to browse in your folder explorer
      </p>
    </div>
  );
  const dragView = <div className="border-2 border-solid border-blue-400 bg-blue-100 w-full h-full" />;
  return (
    <div className="">
      <FileDropzone
        {...other}
        maxSize={maxSize}
        dragView={dragView}
        initialView={dropzoneView}
        onDrop={onDrop}
        className="h-[15rem]"
        accept={accept}
      />
    </div>
  );
};
