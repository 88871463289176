import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Select, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSProperties } from 'styled-components';
import {
  authorDetailsApi,
  deleteAuthor,
  patchAuthor,
  postAuthor,
  searchAuthorApi
} from '../../api/author';
import { CountryCodeProps } from '../../constant';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import { setIsOpenDrawer } from '../../redux/actions/formAction';
import { setAuthorList } from '../../redux/actions/userMemberAction';
import { Mode, Role } from '../../utils/types/role';
import FileUploader from '../common/FileUploader';
import {
  DeleteTabDiv,
  DrawerFooter,
  PhoneInput,
  SubmitButton,
  UserLastRow
} from '../common/styles';
import { showNotificationDrawer } from '../common/utils';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;
interface AuthorFormProps {
  userRole: Role;
  close: any;
  cancel: any;
  authorID?: string;
  editMode?: boolean;
  visible?: boolean;
  viewMode: string;
  dispatch: any;
  countryCode: CountryCodeProps[];
  showFormData: any;
  state: any;
  customerDataList: any;
  authorList?: any;
  pagination?: any;
  PerPageRecords?: any;
  changePageToOne?: () => void;
  searchTerm?: string;
}

const AuthorForm: React.FC<AuthorFormProps> = (props) => {
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const {
    dispatch,
    authorID,
    showFormData,
    visible,
    authorList,
    pagination,
    PerPageRecords,
    changePageToOne,
    searchTerm
  } = props;
  const [activeForm, setActiveForm] = useState<any>({
    phone: ''
  });
  const [isloading, setisloading] = useState<boolean>(false);
  const [authorEmail, setAuthorEmail] = useState<string>('');
  useEffect(() => {
    const activeFormLength = Object.keys(activeForm).length;
    if (!activeFormLength) {
      if (showFormData.formMode === Mode.Create) {
        const createData = {
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          phone: '',
          title: '',
          qualifications: '',
          // userId: "",
          description: '',
          profileLink: '',
          profilePicture: '',
          salutation: 'Mrs',
          phoneCountryCode: ''
        };
        setActiveForm(createData);
        form.setFieldsValue(createData);
      }
    }
    if (authorID && visible) {
      setisloading(true);
      authorDetailsApi(authorID)
        .then((res) => {
          const autherdata = res;
          const stateData = {
            firstName: autherdata.firstName,
            middleName: autherdata.middleName,
            lastName: autherdata.lastName,
            email: autherdata.email,
            phoneCountryCode: autherdata.phoneCountryCode,
            phone: autherdata.phone,
            title: autherdata && autherdata.title,
            qualifications: autherdata.qualifications,
            _id: autherdata._id,
            description: autherdata.description,
            profileLink: autherdata.profileLink,
            profilePicture: autherdata.profilePicture,
            salutation: autherdata.salutation
          };
          setAuthorEmail(autherdata.email);
          setActiveForm(stateData);
          form.setFieldsValue(stateData);
          setisloading(false);
        })
        .catch((err) => {
          setisloading(false);
        });
    }
  }, [form, authorID, showFormData, visible, props.userRole]);

  const onReset = () => {
    form.resetFields();
    setActiveForm({});
    props.close();
    props.cancel();
  };

  const handleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onHandleChange = (e, name) => {
    const { value } = e.target;
    setActiveForm({ ...activeForm, [name]: value });
  };

  const onSubmit = () => {
    setisloading(true);
    const updateData = {
      ...activeForm
    };
    if (authorID) {
      patchAuthor(authorID, updateData)
        .then((res) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          showNotificationDrawer('Success', 'Author Updated successfully!');
          dispatch(setIsOpenDrawer(false));
          searchAuthorApi({ skip: pagination, limit: PerPageRecords, search: searchTerm }).then(
            (res) => {
              const { data } = res;
              dispatch(setAuthorList(data));
            }
          );
          onReset();
        })
        .catch((err) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
        });
    }

    const createData = {
      ...activeForm
    };
    if (!authorID) {
      postAuthor(createData)
        .then((res: any) => {
          setisloading(false);
          const { data } = res;
          onReset();
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          showNotificationDrawer('Success', 'Author created successfully!');
          searchAuthorApi({ skip: 0, limit: PerPageRecords, search: searchTerm }).then((res) => {
            const { data } = res;
            dispatch(setAuthorList(data));
          });
          changePageToOne?.();
        })
        .catch((err) => {
          setisloading(false);
          setActiveForm({});
          form?.resetFields();
          props.cancel();
          onReset();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }
  };

  const onChangeFile = (url, name) => {
    setActiveForm({ ...activeForm, profilePicture: url });
  };

  const handleDeleteAuthor = (id: string) => {
    setisloading(true);
    deleteAuthor(id)
      .then((res) => {
        setisloading(false);
        setActiveForm({});
        props.cancel();
        onReset();
        showNotificationDrawer('Success', 'Author deleted successfully!');
        searchAuthorApi({ skip: 0, limit: PerPageRecords, search: searchTerm }).then((res) => {
          const { data } = res;
          dispatch(setAuthorList(data));
        });
      })
      .catch((err) => {
        setisloading(false);
        showNotificationDrawer('Error', err?.response?.data?.message);
      });
  };

  const formStyle: CSSProperties = {};

  if (props.viewMode === 'View') {
    formStyle.pointerEvents = 'none';
  }
  const prefixSelector = (
    <Form.Item name="salutation" noStyle>
      <Select
        style={{
          width: 70,
          marginBottom: '-3px',
          borderBottom: '1px solid #e9e9e9'
        }}
        defaultValue="Mrs"
        onChange={(value) => handleChange('salutation', value)}
      >
        <Option value="Mr">Mr</Option>
        <Option value="Ms">Ms</Option>
        <Option value="Mrs">Mrs</Option>
        <Option value="Dr">Dr</Option>
      </Select>
    </Form.Item>
  );
  const onChangeNumber = (e) => {
    const { name, value } = e.target;
    const reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/; //eslint-disable-line
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setActiveForm({ ...activeForm, [name]: value });
    }
  };

  const emailExists = (value: string) => {
    if (authorID && authorEmail === value) {
      return true;
    }
    if (authorList) {
      const data = authorList.filter((item: any) => item.email === value);
      return !(data.length > 0);
    }
    return true;
  };
  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Do you really want to delete this Author?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteAuthor(id);
      },
      onCancel() {}
    });
  };
  return (
    <Form
      {...layout}
      form={form}
      className="admin-form author-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' },
                { required: true, message: 'Please input your Name!', whitespace: true }
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                name="firstName"
                onChange={(e) => onHandleChange(e, 'firstName')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Middle Name"
              name="middleName"
              rules={[
                { pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' },
                { required: false, message: 'Please input your Name!', whitespace: true }
              ]}
            >
              <Input
                name="middleName"
                onChange={(e) => onHandleChange(e, 'middleName')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { pattern: /^(?!\s)[a-zA-Z\s]+$/, message: 'Name must contain alphabets' },
                { required: false, message: 'Please input your Name!', whitespace: true }
              ]}
            >
              <Input
                name="lastName"
                onChange={(e) => onHandleChange(e, 'lastName')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', required: true, message: 'Please input your email!' },
                {
                  validator(_, value) {
                    if (emailExists(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject('email already exists!');
                  }
                }
              ]}
              className="no-boder time-zone-container-s"
            >
              <Input
                name="email"
                onChange={(e) => onHandleChange(e, 'email')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item label="Country Code/Phone No" name="CountryCode" className="contact-pno">
              <Input.Group>
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  showSearch
                  defaultValue=""
                  style={{ width: '20%' }}
                  value={activeForm.phoneCountryCode}
                  onChange={(value) => handleChange('phoneCountryCode', value)}
                >
                  {props.countryCode.map((data: CountryCodeProps, index: number) => (
                    <Option key={index} value={data.dial_code}>
                      {data.dial_code}
                    </Option>
                  ))}
                </Select>
                <PhoneInput
                  className="edit-user-phone-number-field"
                  defaultValue=""
                  type="text"
                  name="phone"
                  value={activeForm.phone}
                  onChange={onChangeNumber}
                  maxLength={10}
                />
              </Input.Group>
            </Form.Item>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: false, message: 'Please input your Title!', whitespace: true }]}
            >
              <Input
                name="title"
                onChange={(e) => onHandleChange(e, 'title')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Qualification"
              name="qualifications"
              rules={[{ required: false, message: 'Please input your qualification!' }]}
              className="no-boder time-zone-container-s"
            >
              <Input
                name="qualifications"
                onChange={(e) => onHandleChange(e, 'qualifications')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Profile Description"
              name="description"
              rules={[{ required: false, message: 'Please input your profileDescription!' }]}
              className="no-boder time-zone-container-s"
            >
              <Input.TextArea
                name="description"
                onChange={(e) => onHandleChange(e, 'description')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Profile Link"
              name="profileLink"
              rules={[{ required: false, message: 'Please input your profileLink!' }]}
              className="no-boder time-zone-container-s"
            >
              <Input
                name="profileLink"
                placeholder="https://example.com"
                pattern="https://.*"
                onChange={(e) => onHandleChange(e, 'profileLink')}
                style={{ borderBottom: '1px solid #E9E9E9' }}
              />
            </Form.Item>
            <Form.Item
              label="Upload File (max size 2mb)"
              name="profilePicture"
              className="no-boder"
            >
              <FileUploader
                limit={2097152}
                onlyImageAccept
                mode={props.viewMode}
                onChange={onChangeFile}
                avatarImage={activeForm.profilePicture}
                type="user-form"
              />
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        <Form.Item className="full-row no-border">
          {props.viewMode === 'Create' || props.viewMode === 'Edit' ? (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          ) : (
            <SubmitButton
              onClick={onReset}
              className={
                props.visible
                  ? 'close-drawer-button'
                  : 'close-drawer-button hide-close-drawer-button'
              }
              disabled={isloading}
            >
              <img src={CrossFormButton} />
            </SubmitButton>
          )}
          {props.viewMode === 'Edit'
            ? props.visible && (
                <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                  Save
                </SubmitButton>
              )
            : props.viewMode === 'Create'
              ? props.visible && (
                  <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
                    Submit
                  </SubmitButton>
                )
              : null}

          {props.userRole === Role.Admin && authorID && props.viewMode === 'Edit' ? (
            <DeleteTabDiv
              style={{ marginLeft: '20px', marginTop: '10px' }}
              className=""
              onClick={() => showDeleteConfirm(authorID)}
            >
              <DeleteOutlined />
            </DeleteTabDiv>
          ) : null}
        </Form.Item>
      </DrawerFooter>
    </Form>
  );
};

function mapStateToProps(state: any) {
  return {
    state,
    showFormData: state.formReducer.showFormData,
    customerDataList: state.notificationReducer.customerDataList,
    authorList: state.userMemberData.authorList
  };
}

export default connect(mapStateToProps)(AuthorForm);
