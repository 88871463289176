import { Col, Collapse, Row } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

const { Panel } = Collapse;

const MobileViewAccordian: React.FC<any> = (props) => {
  const [chooseKey, setKey] = useState<string>('Active');
  const {
    panelColumns,
    dataSource,
    onChange,
    type,
    handleDuration,
    visiblePopup,
    SessionReadFun,
    loginUserType,
    dispatch
  } = props;

  const onCloseDrawer = useCallback(() => {
    const keyToUpdate = chooseKey;
    setKey('');
    setKey(keyToUpdate);
  }, [chooseKey]);

  const render = () => {
    switch (type) {
      case 'myFav':
        return renderMyFav;
      case 'mySessionTable':
        return renderMySession;
      case 'myForms':
        return renderForms;
      case 'myResources':
        return renderResources;
      default:
        return null;
    }
  };

  const renderResources = (
    <>{panelColumns.map((col) => col.render(dataSource[col.key], dataSource))}</>
  );
  const renderForms = (
    <>
      <Row className="resourceborderBottom">
        <Col span={12} className="resourceType ">
          Type
        </Col>
        <Col span={12} className="resourceType resourceData">
          {' '}
          {dataSource?.type}
        </Col>
      </Row>
      <Row>
        <Col span={12} className="resourceType">
          Status
        </Col>
        <Col span={12} className="resourceType textAlignRight">
          {dataSource?.status}
        </Col>
      </Row>
    </>
  );

  const renderMyFav = (
    <Row>
      <Col span={12} className="resourceType ">
        Updated At
      </Col>
      <Col span={12} className="resourceType resourceData">
        {' '}
        {moment(dataSource?.updatedAt).format('DD-MM-YYYY hh:mm A')}
      </Col>
    </Row>
  );
  const renderMySession = (
    <>
      <Row>
        <Col span={12} className="resourceType ">
          Notes
        </Col>
        {dataSource.about ? (
          <Col
            span={12}
            className={`${
              dataSource && dataSource.read === true
                ? 'mySessionCol special-truncate-style-container resourceType resourceData'
                : 'sessionReadActive special-truncate-style-container resourceType resourceData'
            } ${
              dataSource && dataSource.endtimeCancellation
                ? 'sessionNoShow resourceType resourceData'
                : 'sessionReadActive resourceType resourceData'
            }`}
          >
            {' '}
            {dataSource?.about}
          </Col>
        ) : (
          '---'
        )}
      </Row>
      <Row>
        <Col span={12} className="resourceType ">
          Duration
        </Col>
        {dataSource?.startTime ? (
          <Col
            span={12}
            className={`${
              dataSource && dataSource.read === true
                ? 'mySessionCol resourceType resourceData'
                : 'sessionReadActive resourceType resourceData'
            } ${
              dataSource && dataSource.endtimeCancellation
                ? 'sessionNoShow resourceType resourceData'
                : 'sessionReadActive resourceType resourceData'
            }`}
          >
            {' '}
            {handleDuration(dataSource?.startTime, dataSource?.endTime)}
          </Col>
        ) : (
          '---'
        )}
      </Row>
      <Row>
        <Col span={12} className="resourceType ">
          Created By
        </Col>
        {dataSource.createdBy?.firstName ? (
          <Col
            span={12}
            className={`${
              dataSource && dataSource.read === true
                ? 'mySessionCol resourceType resourceData'
                : 'sessionReadActive resourceType resourceData'
            } ${
              dataSource && dataSource?.endtimeCancellation
                ? 'sessionNoShow resourceType resourceData'
                : 'sessionReadActive resourceType resourceData'
            }`}
          >
            {' '}
            {dataSource?.createdBy &&
              `${dataSource?.createdBy.firstName} ${dataSource?.createdBy.lastName}`}{' '}
          </Col>
        ) : (
          '---'
        )}
      </Row>
      {/* <Row>
        <Col span={12} className="resourceType ">
          Action
        </Col>
        <Col span={12} className="resourceType resourceData">
          <Space size="middle">
            <EditViewIcon className="autismag-table-action">
              <ViewEditPopOver
                onCloseDrawer={onCloseDrawer}
                sessionId={dataSource?._id}
                visiblePopup={visiblePopup}
                SessionReadFun={SessionReadFun}
                dispatch={dispatch}
                sessionRead={dataSource.read}
                loginUserType={loginUserType}
              />
            </EditViewIcon>
          </Space>
        </Col>
      </Row> */}
    </>
  );

  return <div className="ResourceMobileContainer">{render()}</div>;
};

export default MobileViewAccordian;
