import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getResourceObjective, getResourcesAPI } from '../api/resources';
import { searchVideoApi } from '../api/video';
import { getVideoCategoriesAPI } from '../api/videoCategory';
import { UserContainer } from '../pages/dashboard/styles';
import { setCustomerLoading } from '../redux/actions/customerAction';
import {
  setFetchingResource,
  setIsOpenDrawer,
  setResourceObjective,
  setShowFormData
} from '../redux/actions/formAction';
import {
  setVideoCurrentPage,
  setVideoList,
  setVideoPageCount
} from '../redux/actions/userMemberAction';
import { setVideoCategoryList } from '../redux/actions/videoCategoryAction';
import PRIVILEGES from '../utils/privilege';
import { Mode, Role } from '../utils/types/role';
import AddVideo from './AddUser/AddVideo';
import { AddButton, CustomerContainer } from './common/styles';
import { SearchContainer } from './ListingComponent/styles';
import VideoTableComponent from './TableData/VideoTableComponent';

const VideoComponent: React.FC<any> = (props) => {
  const { dispatch, customerLoading, userMemberData, resourceListMode, filterVideoList } = props;
  const { videoList, currentLoginUser, videoCurrentPage } = userMemberData;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: null
  });
  const canCreate = PRIVILEGES.Videos.Create?.includes(currentLoginUser.type);

  const fetchVideoList = useCallback(
    (option, searchTerm: string) => {
      const options = {
        skip: option?.current * option?.pageSize - option?.pageSize,
        limit: option?.pageSize,
        search: searchTerm
      };
      return searchVideoApi(options);
    },
    [pagination]
  );

  const fetchResouceList = useCallback(
    (value, option) => {
      const data = {
        search: value.search || '',
        type: value.type?.length ? value.type : '',
        objective: value.objective?.length ? value.objective : '',
        owner: value.owner?.length ? value.owner : '',
        isFavourites: value.isFavourites
      };

      const options = {
        skip: option.current * option.pageSize - option.pageSize || 0
      };

      dispatch(setCustomerLoading(true));
      return getResourcesAPI(data, options);
    },
    [dispatch]
  );

  useEffect(() => {
    getVideoCategoriesAPI().then((res) => {
      dispatch(setVideoCategoryList(res.data));
    });

    fetchResouceList({}, {}).then((res) => {
      const { data, count } = res;
      dispatch(setFetchingResource(data));
      dispatch(setCustomerLoading(false));
    });

    getResourceObjective().then((res) => {
      if (res && res.data) {
        dispatch(setResourceObjective(res.data));
      }
    });
  }, [dispatch, fetchVideoList]);

  const openDrawar = (e: any) => {
    e.stopPropagation();
    const createFormData = {
      id: '',
      userType: currentLoginUser.type,
      formMode: Mode.Create,
      formLabel: 'Video'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onSearch = useCallback(
    (searchTerm) => {
      dispatch(setCustomerLoading(true));
      fetchVideoList(pagination, searchTerm)
        .then((res) => {
          const { data, count } = res;
          dispatch(setVideoList(data));
          setPagination({
            ...pagination,
            total: count
          });
          dispatch(setVideoCurrentPage(1));
          dispatch(setVideoPageCount(count));
          dispatch(setCustomerLoading(false));
        })
        .catch(() => {
          dispatch(setCustomerLoading(false));
        });
    },
    [dispatch, searchTerm]
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (!value) {
      onSearch(value);
    }
  };

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter' && searchTerm != '') {
        onSearch(searchTerm);
      }
    },
    [searchTerm, onSearch]
  );

  const onPageChange = useCallback(
    (page: number, pagesize) => {
      // const pageCount = perPageRecords;
      // const skipPageCount = (page === 1 ? 0 : perPageRecords * (page - 1));
      const option = {
        ...pagination,
        current: page,
        pageSize: pagesize
      };
      setPagination(option);
      dispatch(setVideoCurrentPage(page));
      dispatch(setCustomerLoading(true));

      fetchVideoList(option, searchTerm)
        .then((res) => {
          const { data, count } = res;
          dispatch(setCustomerLoading(false));
          dispatch(setVideoList(data));
          dispatch(setVideoPageCount(count));
        })
        .catch((error) => {
          dispatch(setCustomerLoading(false));
        });
    },
    [dispatch, fetchVideoList, pagination, searchTerm]
  );

  return (
    <div>
      <Spin spinning={customerLoading}>
        {resourceListMode !== Mode.Create &&
        (currentLoginUser.type === Role.Business || currentLoginUser.type === Role.Admin) ? (
          <UserContainer>
            {canCreate && (
              <AddButton type="primary" onClick={openDrawar}>
                <PlusOutlined />
              </AddButton>
            )}
            <AddVideo
              searchTerm={searchTerm}
              pagination={pagination}
              changePageToOne={() => dispatch(setVideoCurrentPage(1))}
            />
          </UserContainer>
        ) : null}

        <div className="site-layout-background">
          <div className="site-card-wrapper">
            <CustomerContainer>
              <div className="flex justify-end gap-x-3 items-center mr-5">
                {currentLoginUser.type === Role.Admin && (
                  <Link
                    to="/dashboard/videos/categories"
                    className="rounded border-w-2 border border-solid p-1"
                  >
                    View Categories
                  </Link>
                )}
                <SearchContainer className="!my-0 search-box">
                  <Button
                    onClick={() => (searchTerm != '' ? onSearch(searchTerm) : null)}
                    className="SearchButton"
                  >
                    <SearchOutlined />
                  </Button>
                  <Input
                    onKeyPress={handleKeyPress}
                    name="search"
                    value={searchTerm}
                    onChange={handleChange}
                    placeholder="Search video"
                  />
                </SearchContainer>
              </div>
              <VideoTableComponent
                searchTerm={searchTerm}
                setPagination={setPagination}
                pagination={pagination}
                className="resources-table"
                videoList={filterVideoList || videoList}
              />
              <Pagination
                current={videoCurrentPage}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={onPageChange}
                className="my-pagination"
                // onShowSizeChange={(current, pagesize) => {
                //     return setperPageRecords(pagesize);
                // }}
              />
            </CustomerContainer>
          </div>
        </div>
      </Spin>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    userMemberData: state.userMemberData,
    customerLoading: state.customerRuducer.customerLoading,
    resourceListMode: state.formReducer.resourceListMode
  };
}

export default connect(mapStateToProps)(VideoComponent);
