import React from 'react';
import {
  Link,
} from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { formattedDateWithDuration } from '../common/utils';
import ExpandDownArrow from '../../images/icons/down arrrow.svg';
import ExpandUpArrow from '../../images/icons/UpArrow.svg';
import MobileViewAccordian from './MobileViewAccordianComponent';
import { paginationConfig } from '../../constant';

const MyFavTableComponent: React.FC<any> = (props) => {
  const { favouriteList, isMobile, visiblePopup } = props;
  const columns = [
    {
      title: 'Page Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name && b.name && (a.name).localeCompare(b.name),
      render: (text, record) => (record.name ? (<Link to={record.url}>{record.name}</Link>) : ('--')),
    },
    {
      title: 'Update at',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => a.updatedAt && b.updatedAt && (a.updatedAt).localeCompare(b.updatedAt),
      render: (text, record) => (record.updatedAt ? formattedDateWithDuration(record.updatedAt) : ('--')),
    },
  ];
  const mobileColumns = [
    {
      title: 'Page Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name && b.name) && a.name.localeCompare(b.name),
      render: (text, record) => (record.name ? (<Link to={record.url}>{record.name}</Link>) : ('--')),
    },

  ];

  const modifyData = favouriteList && favouriteList.length && favouriteList.map((itemData, index) => ({ ...itemData, key: index })) || [];
  const customExpandIconMobileVIew = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandDownArrow} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandUpArrow} />
      </a>
    );
  };
  return (
    <>
      {isMobile
        ? (
          <Table
            className={props.className}
            columns={mobileColumns}
            dataSource={modifyData}
            expandIcon={(props) => customExpandIconMobileVIew(props)}
            expandIconColumnIndex={3}
            showHeader={false}
            expandedRowRender={(record) => <div><MobileViewAccordian panelColumns={columns} dataSource={record} type="myFav" /></div>}
            pagination={paginationConfig}
          />
        )
        : (
          <Table
            className={props.className}
            columns={columns}
            dataSource={modifyData}
            pagination={paginationConfig}
          />
        )}
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    isMobile: state.trackReducer.isMobile,
  };
}

export default connect(mapStateToProps)(MyFavTableComponent);
