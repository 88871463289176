import Axios from 'axios';
import {
  apiPost, apiGet, apiPatch, apiDelete,
} from '../utils/api';
import { API_URL } from '../constant';

export const getClientFiles = (clientId: string, memberId?: string) => apiGet(`${API_URL}clientFiles/${clientId}`, { deleted: false, member: memberId });

// export const createClientFile = (clientId: string, data: Omit<ClientFile, "_id" | "createdAt" | "createdBy">) => {
export const createClientFile = (
  clientId: string,
  memberId: string,
  file: File,
  onUploadProgress?: (event: ProgressEvent) => void,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('member', memberId);

  return apiPost(
    `${API_URL}clientFiles/${clientId}`,
    formData,
    {
      'Content-Type': 'multipart/form-data',
    },
    {
      onUploadProgress,
    },
  );
};

export const deleteClientFiles = (clientId: string, idArray: string[]) => apiDelete(`${API_URL}clientFiles/${clientId}`, { idArray });

export const getClientBinFiles = (clientId: string, memberId?: string) => apiGet(`${API_URL}clientFiles/${clientId}`, { deleted: true, member: memberId });

export const restoreClientFiles = (clientId: string, idArray: string[]) => apiPatch(`${API_URL}clientFiles/${clientId}/restore`, { idArray });

export const downloadClientFile = (clientId: string, fileId: string) => apiGet(
    `${API_URL}clientFiles/${clientId}/${fileId}/download`,
    {},
    {},
    {
      responseType: 'blob',
    },
);
