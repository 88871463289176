import { SET_FILE_LOADING } from '../types/types';

const INITIAL_STATE = {
  fileUploaderLoading: false,
};

const uploaderReducer = (state = INITIAL_STATE, action: { type: any, payload: any }) => {
  switch (action.type) {
  case SET_FILE_LOADING:
    return {
      ...state, fileUploaderLoading: action.payload,
    };
  default:
    return state;
  }
};
export default uploaderReducer;
