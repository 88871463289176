import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getResourceObjective = (pagination?) =>
  apiGet(`${API_URL}resourceObjective`, pagination);

export const getResourceObjectiveById = (id: string) => apiGet(`${API_URL}resourceObjective/${id}`);

export const addResourceObjective = (data) => apiPost(`${API_URL}resourceObjective`, data);

export const updateResourceObjective = (id, data) =>
  apiPatch(`${API_URL}resourceObjective/${id}`, data);

export const deleteResourceObjective = (id: string) =>
  apiDelete(`${API_URL}resourceObjective`, { id });

export const getResourceType = () => apiGet(`${API_URL}resourceType`);

export const addResourceType = (data) => apiPost(`${API_URL}resourceType`, data);

export const updateResourceType = (id, data) => apiPatch(`${API_URL}resourceType/${id}`, data);

export const getResourcesAPI = (data, options) =>
  apiGet(
    `${API_URL}resource?search=${data.search}&type=${data.type}&objective=${data.objective}&owner=${data.owner}&favourites=${data.isFavourites}`,
    options
  );

export const addResourceAPI = (data) => apiPost(`${API_URL}resource`, data);

export const updateResourceAPI = (id, data) => apiPatch(`${API_URL}resource/${id}`, data);

export const updateResourceStatusAPI = (id, data) =>
  apiPatch(`${API_URL}resource/changeStatus/${id}`, data);

export const getResourceDetailsAPI = (id) => apiGet(`${API_URL}resource/details/${id}`);

export const deleteResourceAPI = (id) => apiDelete(`${API_URL}resource/${id}`);

export const togglefavResourceAPI = (id) => apiGet(`${API_URL}favresource/${id}`);

export const getAllFavResourceAPI = (id) => apiGet(`${API_URL}favresource/all/${id}`);

export const getUserMemberResourcesAPI = (id, options) =>
  apiGet(`${API_URL}resource/usersResources/${id}`, options);

export const getResourceRelatedTasksAPI = (resourceId, memberId) =>
  apiGet(`${API_URL}resource/relatedTasks/${resourceId}?memberId=${memberId}`);

export const share = (memberId: string, resources: string | string[]) =>
  apiPost(`${API_URL}resource/${memberId}/share`, {
    resources
  });

export const unshare = (memberId: string, resources: string | string[]) =>
  apiPost(`${API_URL}resource/${memberId}/unshare`, {
    resources
  });
