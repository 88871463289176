import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getResourcesAPI } from '../../api/resources';

export default function useResourceSearch(searchTerm: string) {
  const PAGE_SIZE = 10;
  const defaultPagination = {
    current: 1,
    pageSize: PAGE_SIZE,
  };
  const [pagination, setPagination] = useState(defaultPagination);

  useEffect(() => {
    setPagination(defaultPagination);
  }, [searchTerm]);

  const { data, isLoading, isFetching } = useQuery(['resource-search', searchTerm, pagination.current, pagination.pageSize], () => {
    const { current, pageSize } = pagination;
    const data = {
      search: searchTerm,
      type: '',
      objective: '',
      owner: '',
      isFavourites: false,
    };
    const options = {
      skip: (current - 1) * pageSize,
      limit: pageSize,
    };
    return getResourcesAPI(data, options);
  }, {
    keepPreviousData: true,
  });

  return {
    data, pagination, setPagination, isFetching,
  };
}
