import { CopyOutlined, ExportOutlined, LinkOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { Role } from '../../utils/types/role';

interface DeepLinkProps {
  type: 'internal' | 'external';
  deepLink: string;
  hidden?: boolean;
  currentLoginUser: any;
}
const DeepLink: React.FC<DeepLinkProps> = ({
  type,
  deepLink,
  hidden = false,
  currentLoginUser
}: DeepLinkProps) => {
  const copyDeepLink = (evt: MouseEvent, deepLink: string) => {
    evt.stopPropagation();

    notification.open({
      message: <span className="text-green-600">Copied to clipboard</span>,
      icon: <CopyOutlined className="text-green-600" />
    });
    navigator.clipboard.writeText(deepLink);
  };
  if (hidden) return null;
  if (type === 'internal')
    return currentLoginUser.type === Role.Admin ? (
      <ExportOutlined className="text-blue-400" onClick={(evt) => copyDeepLink(evt, deepLink)} />
    ) : null;
  return (
    <div
      className="w-fit py-[1px] px-2 bg-green-600 rounded-lg text-white cursor-pointer"
      onClick={(evt) => copyDeepLink(evt, deepLink)}
    >
      <LinkOutlined />
      <span className="text-xs ml-1">Copy External Link</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.userMemberData.currentLoginUser
});
export default connect(mapStateToProps)(DeepLink);
