import { Button, Checkbox, Col, Form, Input, Select, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { getCurrentPost, patchAnswer } from '../../../api/post';
import { DrawerFooter, SubmitButton, UserLastRow } from '../../common/styles';
import { showNotificationDrawer } from '../../common/utils';
import PostInteractions from './PostInteractions';
import PostResponseForm from './PostResponseForm';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 16 }
};

const { Option } = Select;

interface PostFormProps {
  permission: 'view' | 'edit' | 'create';
  onClose: () => void;
  onSuccess: () => void;
  currentPostId: string;
}

const PostForm: React.FC<PostFormProps> = ({ permission, onSuccess, currentPostId }) => {
  const [form] = Form.useForm();
  const defaultData: PostFormState = {
    user: '',
    post: '',
    body: '',
    referralLinks: [],
    status: '',
    type: '',
    createdAt: moment()
  };
  const [isloading, setIsloading] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<PostFormState>(defaultData);
  const [interactionInEdit, setInteractionInEdit] = useState<PostInteraction | null>(null);
  const [postInteractions, setPostInteractions] = useState<PostInteraction[]>([]);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const isDeletedRef = useRef(null);

  const fetchPostData = (postId) => {
    getCurrentPost(postId).then((res) => {
      const { data } = res;
      const { user, post, createdAt, isDeleted } = data;

      const postData = {
        user,
        post,
        body: '',
        referralLinks: [],
        status: 'Draft',
        createdAt: moment(createdAt),
        isDeleted
      };
      const formData = { ...postData };

      setPostInteractions(data.interactions);
      setActiveForm(formData);
      form.setFieldsValue(formData);
      setInteractionInEdit(null);
      isDeletedRef.current = isDeleted;
    });
  };

  useEffect(() => {
    if (currentPostId) {
      (async () => {
        setIsloading(true);
        await fetchPostData(currentPostId);
        setIsloading(false);
      })();
    }

    setIsReadOnly(permission === 'view');
  }, [currentPostId, permission]);

  const onHandleChange = (name: string, value: any) => {
    setActiveForm({ ...activeForm, [name]: value });
  };

  const handleNewResponseClick = () => {
    const updateData = {
      body: '',
      referralLinks: [],
      status: 'Draft'
    };

    setActiveForm({
      ...activeForm,
      ...updateData
    });
    form.setFieldsValue(updateData);
    setInteractionInEdit({} as PostInteraction);
  };

  const handleInteractionEdit = (interaction: PostInteraction) => {
    const updateData = {
      body: interaction.body,
      referralLinks: interaction.referralLinks ?? [],
      status: interaction.status
    };

    setActiveForm({
      ...activeForm,
      ...updateData
    });
    form.setFieldsValue(updateData);
    setInteractionInEdit(interaction);
  };

  const formStyle: CSSProperties = {};

  const hasUpdate = () => {
    console.log(activeForm.isDeleted, isDeletedRef.current);
    return (
      activeForm.body !== interactionInEdit?.body ||
      JSON.stringify(activeForm.referralLinks) !==
        JSON.stringify(interactionInEdit?.referralLinks) ||
      activeForm.status !== interactionInEdit?.status ||
      activeForm.isDeleted !== isDeletedRef.current
    );
  };

  const onSubmit = () => {
    setIsloading(true);
    if (!hasUpdate()) {
      showNotificationDrawer('Warning', 'Nothing changed');
      setIsloading(false);
    } else {
      // The first therapist response which opens conversation with original poster
      const topTherapistInteraction = postInteractions.find(
        (interaction: PostInteraction) => interaction.type === 'Response' && !interaction.parentId
      );

      const postData: any = {
        postInteractionId: interactionInEdit?._id,
        body: activeForm.body,
        status: activeForm.status,
        referralLinks: activeForm.referralLinks,
        isDeleted: activeForm.isDeleted
      };
      if (interactionInEdit?._id !== topTherapistInteraction?._id)
        postData.parentId = topTherapistInteraction?._id; // The first therapist response's id

      patchAnswer(currentPostId, postData)
        .then((res) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Success', 'Answer for the post updated successfully!');
          fetchPostData(currentPostId);
          onSuccess();
        })
        .catch((err) => {
          setIsloading(false);
          form?.resetFields();
          showNotificationDrawer('Error', err?.response?.data?.message);
        });
    }
  };

  return (
    <Form
      {...layout}
      form={form}
      className="admin-form author-form-style resource-form"
      layout="vertical"
      name="basic"
      onFinish={onSubmit}
      initialValues={activeForm}
    >
      <Spin spinning={isloading}>
        <UserLastRow style={formStyle}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item label="Post" name="post">
              <Input.TextArea name="post" readOnly rows={6} />
            </Form.Item>

            <PostInteractions interactions={postInteractions} onEdit={handleInteractionEdit} />
            {!isReadOnly && (
              <Button className="btn-border w-full my-2" onClick={handleNewResponseClick}>
                + Add New Response
              </Button>
            )}

            {interactionInEdit && (
              <PostResponseForm
                activeForm={activeForm}
                setActiveForm={setActiveForm}
                readOnly={isReadOnly}
              />
            )}

            <Form.Item name="isDeleted">
              <Checkbox
                onChange={(e) => onHandleChange('isDeleted', e.target.checked)}
                checked={activeForm.isDeleted}
                disabled={isReadOnly}
              >
                Is Deleted
              </Checkbox>
            </Form.Item>
          </Col>
        </UserLastRow>
      </Spin>
      <DrawerFooter className="drawer-footer-style drawerButton">
        {permission !== 'view' && (
          <Form.Item className="full-row no-border">
            <SubmitButton htmlType="submit" type="primary" disabled={isloading}>
              Submit
            </SubmitButton>
          </Form.Item>
        )}
      </DrawerFooter>
    </Form>
  );
};

export default PostForm;
