import {
  SET_AUTHOR_CURRENT_PAGE,
  SET_AUTHOR_LIST,
  SET_AUTHOR_PAGE_COUNT,
  SET_COURSE_CURRENT_PAGE,
  SET_COURSE_LIST,
  SET_COURSE_PAGE_COUNT,
  SET_CURRENT_LOGIN_USER,
  SET_LOGIN_USER_DATA,
  SET_MEMBER_CURRENT_PAGE,
  SET_MEMBER_DATA,
  SET_MEMBER_LIST,
  SET_MEMBER_LOADING,
  SET_TOTAL_MEMBER_COUNT,
  SET_USER_LIST,
  SET_VIDEO_CURRENT_PAGE,
  SET_VIDEO_LIST,
  SET_VIDEO_PAGE_COUNT,
  SET_WORKSHOP_CURRENT_PAGE,
  SET_WORKSHOP_LIST,
  SET_WORKSHOP_PAGE_COUNT
} from '../types/types';

const INITIAL_STATE = {
  memberList: [],
  userList: [],
  memberData: [],
  memberLoading: false,
  totalMemberCount: 0,
  totalAuthorCount: 0,
  totalWorkShopCount: 0,
  totalVideoCount: 0,
  memberCurrentPage: 0,
  authorCurrentPage: 1,
  courseCurrentPage: 1,
  videoCurrentPage: 1,
  currentLoginUser: {},
  loginUserData: {},
  videoList: [],
  workShopCurrentPage: 1,
  totalCourseCount: 0
};

const userMemberReducer = (state = INITIAL_STATE, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload
      };
    case SET_AUTHOR_LIST:
      return {
        ...state,
        authorList: action.payload
      };
    case SET_WORKSHOP_LIST:
      return {
        ...state,
        workShopList: action.payload
      };
    case SET_VIDEO_LIST:
      return {
        ...state,
        videoList: action.payload
      };
    case SET_MEMBER_LIST:
      return {
        ...state,
        memberList: action.payload
      };
    case SET_MEMBER_DATA:
      return {
        ...state,
        memberData: action.payload
      };
    case SET_MEMBER_LOADING:
      return {
        ...state,
        memberLoading: action.payload
      };
    case SET_TOTAL_MEMBER_COUNT:
      return {
        ...state,
        totalMemberCount: action.payload
      };
    case SET_AUTHOR_PAGE_COUNT:
      return {
        ...state,
        totalAuthorCount: action.payload
      };
    case SET_WORKSHOP_PAGE_COUNT:
      return {
        ...state,
        totalWorkShopCount: action.payload
      };
    case SET_AUTHOR_CURRENT_PAGE:
      return {
        ...state,
        authorCurrentPage: action.payload
      };
    case SET_VIDEO_PAGE_COUNT:
      return {
        ...state,
        totalVideoCount: action.payload
      };
    case SET_VIDEO_CURRENT_PAGE:
      return {
        ...state,
        videoCurrentPage: action.payload
      };
    case SET_MEMBER_CURRENT_PAGE:
      return {
        ...state,
        memberCurrentPage: action.payload
      };
    case SET_CURRENT_LOGIN_USER:
      return {
        ...state,
        currentLoginUser: action.payload
      };
    case SET_LOGIN_USER_DATA:
      return {
        ...state,
        loginUserData: action.payload
      };
    case SET_WORKSHOP_CURRENT_PAGE:
      return {
        ...state,
        workShopCurrentPage: action.payload
      };
    case SET_COURSE_LIST:
      return {
        ...state,
        courseList: action.payload
      };
    case SET_COURSE_CURRENT_PAGE:
      return {
        ...state,
        courseCurrentPage: action.payload
      };
    case SET_COURSE_PAGE_COUNT:
      return {
        ...state,
        totalCourseCount: action.payload
      };
    default:
      return state;
  }
};
export default userMemberReducer;
