import styled from 'styled-components';

export const ListingDiv = styled.div`
    display: block;
`;

export const CardDiv = styled.div`
    display: inline-block;
    margin-right: 20px;
`;

export const SearchContainer = styled.div`
    width: 300px;
    margin: 30px 0px;
    display: flex;
`;
export const IconBar = styled.div`
    .anticon {
        display: block;
    }
`;
export const ActionContainer = styled.div`
    display: flex;
    div {
        margin: 2px
    }
`;
