import { SET_SESSION_DATA, REFRESH_SESSION_NOTES } from '../types/types';

export function setSessionData(data: Array<any>) {
  return {
    type: SET_SESSION_DATA,
    payload: data,
  };
}

export function setRefreshSessionData(data: boolean) {
  return {
    type: REFRESH_SESSION_NOTES,
    payload: data,
  };
}
