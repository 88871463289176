import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getVideoCategoriesAPI } from '../../api/videoCategory';
import { userMemberDataType } from '../../constant';
import { UserContainer } from '../../pages/dashboard/styles';
import { setIsOpenDrawer, setShowFormData } from '../../redux/actions/formAction';
import {
  setVideoCategoryList,
  setVideoCategoryPagination
} from '../../redux/actions/videoCategoryAction';
import { VideoCategoryReduxState } from '../../redux/reducers/videoCategoryReducer';
import { RootState } from '../../redux/store';
import PRIVILEGES from '../../utils/privilege';
import { Mode } from '../../utils/types/role';
import { AddButton } from '../common/styles';
import VideoCategoriesModal from './VideoCategoriesModal';
import VideoCategoriesTable from './VideoCategoriesTable';

const VideoCategoriesComponent = () => {
  const dispatch = useDispatch();

  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );
  const { pagination } = useSelector<RootState, VideoCategoryReduxState>(
    (state) => state.videoCategoryReducer
  );
  const canCreate = PRIVILEGES.VideoCategories.Create?.includes(currentLoginUser.type);

  const [loading, setLoading] = useState(false);

  const fetchVideoCategories = async (pagination?) => {
    try {
      const res = await getVideoCategoriesAPI(pagination);
      dispatch(setVideoCategoryList(res.data));

      const newPagination = { ...pagination, total: res.count };
      dispatch(setVideoCategoryPagination(newPagination));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      await fetchVideoCategories(pagination);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    fetchVideoCategories(pagination);
  }, [pagination.page]);

  const onPageChange = (page: number, pageSize?: number) => {
    const newPagination = { ...pagination, page, pageSize };
    dispatch(setVideoCategoryPagination(newPagination));
  };

  const openModal = (id: string = '', mode: Mode) => {
    const formData = {
      id,
      formMode: mode,
      formLabel: 'Video Category'
    };
    dispatch(setShowFormData(formData));
    dispatch(setIsOpenDrawer(true));
  };

  return (
    <Spin spinning={loading}>
      <UserContainer className="items-center">
        {canCreate && (
          <AddButton type="primary" onClick={() => openModal(undefined, Mode.Create)}>
            <PlusOutlined />
          </AddButton>
        )}
        <Link
          to="/dashboard/videos"
          className="rounded border-w-2 border border-solid mx-4 p-1 px-4"
        >
          Back
        </Link>
        <VideoCategoriesModal onSubmit={fetchVideoCategories} />
      </UserContainer>

      <div className="site-layout-background">
        <VideoCategoriesTable className="resources-table" onOpenModal={openModal} />
        <Pagination
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onPageChange}
          className="my-pagination"
        />
      </div>
    </Spin>
  );
};

export default VideoCategoriesComponent;
