import { LoadingOutlined } from '@ant-design/icons';
import { Col, Image, Modal, Row, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { downloadFileAPI } from '../../api/file';
import { getResourceRelatedTasksAPI } from '../../api/resources';
import { API_URL } from '../../constant';
import EmptyTableData from '../../images/empty-table-data.svg';
import CrossFormButton from '../../images/icons/crossFormButton.svg';
import ExpandDownArrow from '../../images/icons/down arrrow.svg';
import DownLoadIcon from '../../images/icons/download.svg';
import ExpandUpArrow from '../../images/icons/UpArrow.svg';
import altThumbnailImage from '../../images/thumbnailAlt.png';
import { setResourcesRelatedTasks, setSelectedRowData } from '../../redux/actions/formAction';
import { getResizedImageName } from '../../utils/file';
import { CheckTaskButton, ColDiv, IconTab } from '../common/styles';
import MobileViewAccordian from './MobileViewAccordianComponent';
import ResourceTaskTableComponent from './ResourceTaskTableComponent';

const MyResourcesTableComponent: React.FC<any> = (props) => {
  const [isOpen, setIsOpen] = useState<any>(false);
  const [downloadingIds, setDownloadingIds] = useState<string[]>([]);

  const {
    dispatch,
    selectedRowData,
    resourcesList = [],
    resourceListMode,
    isMobile,
    customerSelectedMember
  } = props;
  const handleChange = (pagination, filters, sorter, extra) => {};
  const onSelectChange = (selectedKeys, selectedRows) => {
    dispatch(setSelectedRowData({ keys: selectedKeys, rows: selectedRows }));
  };
  const onCheckTask = (id) => {
    if (id) {
      getResourceRelatedTasksAPI(id, customerSelectedMember[0]?._id).then((res) => {
        const { data } = res;
        dispatch(setResourcesRelatedTasks(data));
        openModal();
      });
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const downloadFile = (fileUrl: string, id: string) => {
    if (fileUrl) {
      const data = {
        url: fileUrl
      };
      // add id to downloadingIds
      setDownloadingIds((ids) => [...ids, id]);

      downloadFileAPI(data).then((res) => {
        const url = `${API_URL}static/${res.fileName}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(this.response);
          const tag = document.createElement('a');
          tag.href = imageUrl;
          tag.download = res.fileName;
          document.body.appendChild(tag);
          tag.click();
          document.body.removeChild(tag);

          // remove id from downloadingIds
          setDownloadingIds((ids) => {
            ids.splice(ids.indexOf(id), 1);
            return [...ids];
          });
        };
        xhr.send();
      });
    }
  };
  const customExpandIconMobileVIew = (props) => {
    if (props.expanded) {
      return (
        <a
          style={{ color: 'black' }}
          onClick={(e) => {
            props.onExpand(props.record, e);
          }}
        >
          <img src={ExpandDownArrow} />
        </a>
      );
    }
    return (
      <a
        style={{ color: 'black' }}
        onClick={(e) => {
          props.onExpand(props.record, e);
        }}
      >
        <img src={ExpandUpArrow} />
      </a>
    );
  };
  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'sel-checkbox',
      render: (text, record) => {
        const downloading = downloadingIds.includes(record._id);
        return (
          <ColDiv>
            {downloading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24, color: 'orange' }} spin />}
              />
            )}
            {!downloading && (
              <IconTab onClick={() => downloadFile(record.file, record._id)}>
                <img src={DownLoadIcon} />
              </IconTab>
            )}
          </ColDiv>
        );
      }
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (text, record) => (
        <ColDiv>
          {record?.thumbnail ? (
            <Image
              width="150px"
              height="100px"
              src={getResizedImageName(record.thumbnail, '800x800')}
            />
          ) : (
            <img width="150px" height="100px" src={altThumbnailImage} />
          )}
        </ColDiv>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
      render: (text, record) => <ColDiv>{record.name}</ColDiv>
    },
    {
      title: 'Resource Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.name && b.type.name && a.type.name.localeCompare(b.type.name),
      render: (text, record) =>
        record.type && record.type.name ? <ColDiv>{record.type.name}</ColDiv> : '--'
    },
    // {
    //     title: 'Added At',
    //     dataIndex: 'addedAt',
    //     key: 'addedAt',
    //     sorter: (a, b) => (a.createdAt && b.createdAt) && new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    //     render: (text, record) => (record.createdAt ? <ColDiv>{formattedDate(record.createdAt)}</ColDiv> : ("--")),
    // },
    {
      title: 'Check Task',
      dataIndex: 'fileSize',
      key: 'fileSize',
      render: (text, record) =>
        record.hasTasks ? (
          <ColDiv>
            <CheckTaskButton onClick={() => onCheckTask(record._id)}>Check Task</CheckTaskButton>
          </ColDiv>
        ) : (
          ''
        )
    }
  ];
  const mobileColumns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        const downloading = downloadingIds.includes(record._id);
        return (
          <ColDiv>
            {downloading && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24, color: 'orange' }} spin />}
              />
            )}
            {!downloading && (
              <IconTab onClick={() => downloadFile(record.file, record._id)}>
                <img src={DownLoadIcon} />
              </IconTab>
            )}
          </ColDiv>
        );
      },
      width: '15%'
    },
    {
      // sorter: (a, b) => (a.name && b.name) && a.name.length - b.name.length,
      render: (text, record) => <ColDiv>{record.name}</ColDiv>
    }
  ];
  const panelColumns = [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (text, record) => (
        <Row className="resourceborderBottom items-center">
          <Col span={12} className="resourceType ">
            Thumbnail
          </Col>
          <Col span={12} className="resourceType">
            {record?.thumbnail ? (
              <Image
                width="120px"
                height="80px"
                src={getResizedImageName(record.thumbnail, '200x200')}
              />
            ) : (
              <img width="120px" height="80px" src={altThumbnailImage} />
            )}
          </Col>
        </Row>
      )
    },
    {
      title: 'Resource Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.name && b.type.name && a.type.name.localeCompare(b.type.name),
      render: (text, record) => (
        <Row>
          <Col span={12} className="resourceType ">
            Resource Type
          </Col>
          <Col span={12} className="resourceType resourceData">
            {' '}
            {record?.type?.name ?? '--'}
          </Col>
        </Row>
      )
    },
    {
      title: 'Check Task',
      dataIndex: 'fileSize',
      key: 'fileSize',
      render: (text, record) => (
        <Row>
          <Col span={12} className="resourceType">
            Check Task
          </Col>
          <Col span={12} className="resourceType textAlignRight">
            <CheckTaskButton onClick={() => onCheckTask(record._id)}>Check Task</CheckTaskButton>
          </Col>
        </Row>
      )
    }
  ];
  const modifyData =
    resourcesList.map((itemData) => {
      if (itemData.isDeleted !== true) {
        return { ...itemData, key: itemData._id };
      }
      return [];
    }) || [];
  return (
    <>
      {isMobile ? (
        <Table
          className={props.className + (resourceListMode === '' ? ' hide' : '')}
          columns={mobileColumns}
          dataSource={modifyData}
          showHeader={false}
          onChange={handleChange}
          pagination={false}
          expandIcon={(props) => customExpandIconMobileVIew(props)}
          expandIconColumnIndex={3}
          expandedRowRender={(record) => (
            <div>
              <MobileViewAccordian
                panelColumns={panelColumns}
                dataSource={record}
                onChange={handleChange}
                type="myResources"
              />
            </div>
          )}
          rowSelection={{
            getCheckboxProps: (record) => ({
              disabled: record.hasTasks === true,
              className: record.hasTasks === true ? 'hidden' : ''
            }),
            onChange: onSelectChange,
            selectedRowKeys: selectedRowData.keys
          }}
        />
      ) : (
        <Table
          className={props.className + (resourceListMode === '' ? ' hide' : '')}
          columns={columns}
          dataSource={modifyData}
          onChange={handleChange}
          pagination={false}
          locale={{
            emptyText: (
              <div className="empty-data-table-container-show">
                <div className="empty-data-table-container-image">
                  <img src={EmptyTableData} width="250" />
                </div>
                <div style={{ display: 'block' }}>Opps.. Nothing Here...</div>
              </div>
            )
          }}
          rowSelection={{
            getCheckboxProps: (record) => ({
              disabled: record.hasTasks === true,
              className: record.hasTasks === true ? 'hidden' : ''
            }),
            onChange: onSelectChange,
            selectedRowKeys: selectedRowData.keys
          }}
        />
      )}
      <Modal
        title={
          <div className="add-task-title-container">
            Resources task details
            <div onClick={closeModal} className="close-drawer-button">
              <img src={CrossFormButton} />
            </div>
          </div>
        }
        closable={false}
        visible={isOpen}
        onOk={closeModal}
        className="wide-modal resource-detail-modal-container"
        footer={[
          <button className="close-resource-task" onClick={closeModal}>
            Ok
          </button>
        ]}
      >
        <ResourceTaskTableComponent />
      </Modal>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    resourcesList: state.formReducer.resourcesList,
    resourceListMode: state.formReducer.resourceListMode,
    selectedRowData: state.formReducer.selectedRowData,
    customerSelectedMember: state.customerRuducer.customerSelectedMember,
    isMobile: state.trackReducer.isMobile
  };
}

export default connect(mapStateToProps)(MyResourcesTableComponent);
