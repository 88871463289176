import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { controlCalendar, getCalSyncState } from '../api/user';
import { ReactComponent as GoogleCalendarSVG } from '../images/icons/googleCalendar.svg';
import { getGoogleUrl } from '../utils/getGoogleUrl';

const { confirm } = Modal;

function authorize(id) {
  window.open(getGoogleUrl(id), '_self');
}

const GoogleCalendarToggle: React.FC<any> = (props) => {
  const { currentLoginUser } = props;

  const [calState, setCalState] = useState<any>({
    connected: false,
    token: ''
  });
  const { Title } = Typography;

  useEffect(() => {
    getCalSyncState(currentLoginUser._id).then((value) => {
      setCalState({
        connected: value?.googleCalendarConnected || false,
        token: value?.googleCalendarToken || ''
      });
    });
  }, []);

  const handleToggleButton = async (value) => {
    if (!value) {
      confirm({
        title:
          'Are you sure that you want to disconnect your A360 Google calendar? By doing this you will no longer be able to create the sessions for your clients from your calendar.',
        icon: <ExclamationCircleOutlined />,
        content: '',
        okText: 'OK',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: async () => {
          await controlCalendar(currentLoginUser._id, false);
          setCalState({ token: '', connected: false });
        },
        onCancel() {}
      });
    } else {
      const { token } = calState;
      if (token.length === 0) {
        authorize(currentLoginUser._id);
      } else {
        await controlCalendar(currentLoginUser._id, true);
      }
      setCalState({ token: calState.token, connected: true });
    }
  };
  return (
    <div className="rounded-full p-1 px-2 bg-gray-200 flex items-center w-max">
      <GoogleCalendarSVG />
      <span className="mx-2">Google Calendar Sync</span>
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        checked={calState.connected}
        onChange={handleToggleButton}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.userMemberData.currentLoginUser
});
export default connect(mapStateToProps)(GoogleCalendarToggle);
