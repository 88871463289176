import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';

const RichTextComponent:React.FC<any> = (props) => {
  const { disabled } = props;
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      { label: 'Heading Large', style: 'header-one' },
      { label: 'Heading Medium', style: 'header-two' },
      { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  };

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const dataValue = RichTextEditor.createValueFromString(props.data, 'html');

  useEffect(() => {
    if (value.toString('html') !== dataValue.toString('html')) {
      setValue(dataValue);
    }
  }, [dataValue, value]);

  const onChangeRichText = (value:any) => {
    setValue(value);
    if (props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      props.onChange(value.toString('html'));
    }
  };

  return (
    <div className={props.className}>
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={value}
        onChange={onChangeRichText}
        disabled={disabled}
      />
    </div>
  );
};

export default RichTextComponent;
