import {
  SET_MEMBER_GOALS, SET_SKILL_LIST, SET_COMPLEXITY_LIST, SET_ASSIGNEE_LIST, SET_TARGET_SKILL_LIST, SET_FILE_LINK_LIST, SET_CONTENT_LINK_LIST, SET_ADD_TARGET_ACHIEVED,
} from '../types/types';

export function setMemberGoals(data: Array<any>) {
  return {
    type: SET_MEMBER_GOALS,
    payload: data,
  };
}

export function setSkillList(data: Array<any>) {
  return {
    type: SET_SKILL_LIST,
    payload: data,
  };
}

export function setComplexityList(data: Array<any>) {
  return {
    type: SET_COMPLEXITY_LIST,
    payload: data,
  };
}

export function setAssigneeList(data: Array<any>) {
  return {
    type: SET_ASSIGNEE_LIST,
    payload: data,
  };
}

export function setTargetSkillList(data: Array<any>) {
  return {
    type: SET_TARGET_SKILL_LIST,
    payload: data,
  };
}

export function setFileLinkList(data: Array<any>) {
  return {
    type: SET_FILE_LINK_LIST,
    payload: data,
  };
}

export function setContentLinkList(data: Array<any>) {
  return {
    type: SET_CONTENT_LINK_LIST,
    payload: data,
  };
}

export function setAddTarget(data: Array<any>) {
  return {
    type: SET_ADD_TARGET_ACHIEVED,
    payload: data,
  };
}
