import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getBlogCategory = () => apiGet(`${API_URL}blogCategory`);

export const getBlog = () => apiGet(`${API_URL}blog`);

export const getCurrentBlog = (id) => apiGet(`${API_URL}blog/${id}`);

export const postBlog = (data) => apiPost(`${API_URL}blog`, data);

export const patchBlog = (id, data) => apiPatch(`${API_URL}blog/${id}`, data);

export const deleteBlog = (id) => apiDelete(`${API_URL}blog/${id}`);

export const searchBlogApi = (options) => apiGet(`${API_URL}blog`, options);
