import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Checkbox, Space, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userMemberDataType } from '../../../constant';
import {
  setIsOpenDrawer,
  setSelectedRowData,
  setShowFormData
} from '../../../redux/actions/formAction';
import { FormReduxState } from '../../../redux/reducers/formReducer';
import { RootState } from '../../../redux/store';
import { svgToBase64DataUrl } from '../../../utils/file';
import PRIVILEGES from '../../../utils/privilege';
import { Mode, Role } from '../../../utils/types/role';
import { ColDiv, EditViewIcon } from '../../common/styles';
import { Asset } from '../Avatar';

const colors = ['tag1-style', 'tag2-style', 'tag3-style', 'tag4-style', 'tag5-style'];

interface AssetsTableComponentProps {
  className?: string;
  assets: Asset[];
}

const AssetsTableComponent = ({ className, assets, ...tableProps }: AssetsTableComponentProps) => {
  const dispatch = useDispatch();
  const { selectedRowData } = useSelector<RootState, FormReduxState>((state) => state.formReducer);
  const { currentLoginUser } = useSelector<RootState, userMemberDataType>(
    (state) => state.userMemberData
  );

  const openDrawar = (data, mode) => {
    const createFormData = {
      id: data._id,
      userType: currentLoginUser.type,
      formMode: mode,
      formLabel: 'Asset'
    };
    dispatch(setShowFormData(createFormData));
    dispatch(setIsOpenDrawer(true));
  };

  const onSelectChange = (selectedkeys: any, selectedRows: any) => {
    const { keys, rows } = selectedRowData;
    if (keys.indexOf(selectedkeys) === -1) {
      keys.push(selectedkeys);
      rows.push(selectedRows);
    } else {
      keys.splice(keys.indexOf(selectedkeys), 1);
      rows.splice(
        rows.findIndex((a) => a._id === selectedRows._id),
        1
      );
    }
    dispatch(setSelectedRowData({ keys, rows }));
  };

  const canEdit = PRIVILEGES.Assets.Edit?.includes(currentLoginUser.type as Role);

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <Checkbox
          onChange={() => onSelectChange(record._id, record)}
          checked={selectedRowData.keys.indexOf(record._id) !== -1}
        />
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      render: (text, record) => <ColDiv>{record.type}</ColDiv>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
      render: (text, record) => <ColDiv>{record.name}</ColDiv>
    },
    {
      title: 'Svg',
      dataIndex: 'svg',
      key: 'svg',
      width: 300,
      render: (text, record) => (
        <ColDiv>
          <img src={svgToBase64DataUrl(record.svg)} width="50" height="50" />
        </ColDiv>
      )
    },
    {
      title: 'Fix Colour',
      dataIndex: 'fixColour',
      key: 'fixColour',
      width: 150,
      render: (text, record) => <ColDiv>{!record.fixColour ? 'No' : 'Yes'}</ColDiv>
    },
    {
      title: 'Colour',
      dataIndex: 'colour',
      key: 'colour',
      width: 200,
      render: (text, record) => (
        <ColDiv className="flex gap-2 items-center">
          <span>{record.colour}</span>
          <span
            className="w-[60px] h-[20px] block rounded-md border-solid border border-gray-300 cursor-pointer"
            style={{ background: record.colour }}
          ></span>
        </ColDiv>
      )
    },
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      width: 300,
      render: (text, record) => <ColDiv>{record.event?.name}</ColDiv>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 50,
      render: (text, record) => <ColDiv>{record.status}</ColDiv>
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 120,
      render: (text, record) => (
        <Space size="middle" className="float-right">
          <EditViewIcon className="autismag-table-action">
            <span onClick={() => openDrawar(record, Mode.View)}>
              <EyeOutlined key="eye" />
            </span>
            {canEdit ? (
              <span onClick={() => openDrawar(record, Mode.Edit)}>
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <Table
      className={className}
      columns={columns}
      dataSource={assets}
      pagination={false}
      scroll={{ x: 1000 }}
      {...tableProps}
    />
  );
};

export default AssetsTableComponent;
