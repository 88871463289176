import { API_URL } from '../constant';
import { apiGet, apiPatch, apiPost } from '../utils/api';

// apis for avatar asset events
export const getAssetEventsAPI = (options?) => apiGet(`${API_URL}asset/event`, options);

export const getAssetEventByIdAPI = (id) => apiGet(`${API_URL}asset/event/${id}`);

export const createAssetEventAPI = (data) => apiPost(`${API_URL}asset/event`, data);

export const updateAssetEventAPI = (id, data) => apiPatch(`${API_URL}asset/event/${id}`, data);

// apis for avatar assets
export const getAssetsAPI = (type, options?) => apiGet(`${API_URL}asset`, { ...options, type });

export const getAssetByIdAPI = (id) => apiGet(`${API_URL}asset/${id}`);

export const createAssetAPI = (data) => apiPost(`${API_URL}asset`, data);

export const updateAssetAPI = (id, data) => apiPatch(`${API_URL}asset/${id}`, data);
