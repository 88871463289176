import { API_URL } from '../constant';
import { apiDelete, apiGet, apiPatch, apiPost } from '../utils/api';

export const getCourse = () => apiGet(`${API_URL}course`);

export const postCourse = (data) => apiPost(`${API_URL}course`, data);

export const patchCourse = (id, data) => apiPatch(`${API_URL}course/${id}`, data);

export const deleteCourse = (id) => apiDelete(`${API_URL}course/${id}`);

export const searchCourseApi = (options) => apiGet(`${API_URL}course`, options);

export const courseDetailsApi = (id) => apiGet(`${API_URL}course/details/${id}`);
