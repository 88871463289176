import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Drawer, Space, Spin, Table } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Role } from '../../utils/types/role';
import { ColDiv, EditViewIcon } from '../common/styles';
import { formattedDate } from '../common/utils';
import BroadcastMessageForm from '../FormComponent/BroadcastMessageForm';

export interface BroadcastMessageTableComponentProps {
  className: string;
  broadcastMessageData: BroadcastMessage[];
  onlyDisplayActiveResources?: boolean;
  fetchData: () => void;
  currentLoginUser: any;
}
const BroadcastMessageTableComponent: React.FC<BroadcastMessageTableComponentProps> = (
  props: BroadcastMessageTableComponentProps
) => {
  const {
    broadcastMessageData,
    className,
    onlyDisplayActiveResources,
    fetchData,
    currentLoginUser
  } = props;

  const [isloading, setisloading] = useState<boolean>(false);
  const [permission, setPermission] = useState<'view' | 'edit' | 'create'>('view');
  const [currentMessage, setCurrentMessage] = useState<BroadcastMessage | undefined>();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const onView = () => {
    setPermission('view');
    showDrawer();
  };

  const onEdit = () => {
    setPermission('edit');
    showDrawer();
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title && b.title && a.title.localeCompare(b.title),
      render: (text, record) => <ColDiv>{record?.title}</ColDiv>,
      width: 250
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) =>
        a.description && b.description && a.description.localeCompare(b.description),
      render: (text, record) => <ColDiv>{record?.description}</ColDiv>,
      width: 300
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (text, record) => <ColDiv>{record?.link}</ColDiv>,
      width: 250
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      sorter: (a, b) => a.priority - b.priority,
      render: (text, record) => <ColDiv>{record?.priority}</ColDiv>,
      width: 100
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text, record) => <ColDiv>{formattedDate(record?.endDate)}</ColDiv>,
      width: 200
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <ColDiv>{record?.status}</ColDiv>,
      width: 100
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <EditViewIcon className="autismag-table-action">
            <span
              onClick={() => {
                setCurrentMessage(record);
                onView();
              }}
            >
              <EyeOutlined key="eye" />
            </span>
            {currentLoginUser.type === Role.Admin ? (
              <span
                onClick={() => {
                  setCurrentMessage(record);
                  onEdit();
                }}
              >
                <EditOutlined key="edit" />
              </span>
            ) : null}
          </EditViewIcon>
        </Space>
      )
    }
  ];

  return (
    <Spin spinning={isloading}>
      <Table
        className={`${className} ${onlyDisplayActiveResources === true ? 'hide' : ''}`}
        columns={columns}
        dataSource={broadcastMessageData}
        rowKey="_id"
      />
      <Drawer
        title={`${permission === 'edit' ? 'Edit' : 'View'} Broadcast Message Form`}
        placement="right"
        onClose={closeDrawer}
        visible={open}
        width="520"
        className="user-drawer"
      >
        <BroadcastMessageForm
          fetchData={fetchData}
          currentMessage={currentMessage}
          permission={permission}
          onClose={closeDrawer}
        />
      </Drawer>
    </Spin>
  );
};

const mapStateToProps = (state) => ({
  currentLoginUser: state.userMemberData.currentLoginUser
});
export default connect(mapStateToProps)(BroadcastMessageTableComponent);
